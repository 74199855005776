import React, { useState } from "react";
import { FaInfoCircle, FaPlus, FaMinus } from "react-icons/fa";
import $ from 'jquery';

const ContactInformations = (props) => {
  const [TogglePlusIons, setTogglePlusIons] = useState(true);
  const CollapseButton = () => {
    setTogglePlusIons((prev) => !prev)
    $("#ContactInfo_id").slideToggle(0);
  };
  return (
    <div>
      <div id="Contect_info" className="Contect_info">
       
        <div className="Basic_Informations" style={{display:"flex",justifyContent:"space-between",alignItems:"center",alignContent:"center"}}>
          <div>{props.title}</div>
          <div className="toggleIonsPlusicons" style={{cursor:"pointer",paddingRight:'10px'}} onClick={CollapseButton}>
            {TogglePlusIons ? <FaMinus color="#c1c1c1" fontSize={20} /> : <FaPlus color="#c1c1c1" fontSize={20}/>}
            </div>
        </div>

        <br/>
        <div id="ContactInfo_id" className="ContactInfo">
          <table className="contact_info_new">
            <tbody>
              <tr>
                <th colSpan={2}>Contact informations</th>
                <th colSpan={1}>Name</th>
                <th>Email</th>
              </tr>
              <tr>
                <td className="text_align_center" rowSpan={3}>
                  Vendor Side
                </td>
                <th>
                  <span className="flexbody">
                    <span>Account Manager</span>
                    <span className="grid_body">
                      <FaInfoCircle className="infoCircleico" fontSize={18} />
                      <span className="tooltiptext">Snow</span>
                    </span>
                  </span>
                </th>
                <td>{props.Vendor_Account_Manager_ContactName}</td>
                <td>{props.Account_email}</td>
              </tr>
              <tr>
                <th>
                  <span className="flexbody">
                    <span>Technical Contact</span>
                    <span className="grid_body">
                      <FaInfoCircle className="infoCircleico" fontSize={18} />
                      <span className="tooltiptext">Snow</span>
                    </span>
                  </span>
                </th>
                <td>{props.technical_name}</td>
                <td>{props.technical_email}</td>
              </tr>

              <tr>
                <th>
                  <span className="flexbody">
                    <span>Vendor Contact</span>
                    <span className="grid_body">
                      <FaInfoCircle className="infoCircleico" fontSize={18} />
                      <span className="tooltiptext">Snow</span>
                    </span>
                  </span>
                </th>
                <td>{props.Vendor_Contact}</td>
                <td>{props.Vendor_Contact_Email_ID}</td>
              </tr>
              <tr>
                <td className="text_align_center" rowSpan={3}>
                  Mars
                </td>
                <th>
                  <span className="flexbody">
                    <span>Business Contact</span>
                    <span className="grid_body">
                      <FaInfoCircle className="infoCircleico" fontSize={18} />
                      <span className="tooltiptext">Archer</span>
                    </span>
                  </span>
                </th>
                <td>{props.Business_Partner_Manager}</td>
                <td>{props.Business_Partner_Email_ID}</td>
              </tr>
              <tr>
                <th>
                  <span className="flexbody">
                    <span>Commercial Contact</span>
                    <span className="grid_body">
                      <FaInfoCircle className="infoCircleico" fontSize={18} />
                      <span className="tooltiptext">Snow</span>
                    </span>
                  </span>
                </th>
                <td>{props.MarsCommercialContact}</td>
                <td>{props.Commercial_email}</td>
              </tr>
              <tr>
                <th>
                  <span className="flexbody">
                    <span>Owner Details</span>
                    <span className="grid_body">
                      <FaInfoCircle className="infoCircleico" fontSize={18} />
                      <span className="tooltiptext">Ariba</span>
                    </span>
                  </span>
                </th>
                <td colSpan={2}>{props.Ownername}</td>
              </tr>
            </tbody>
          </table>
        </div>

        {TogglePlusIons ? <span></span> : <span>Please click the right ( + ) icons for view the {props.title}</span>}

      </div>
    </div>
  );
};

export default ContactInformations;
