import React, { useState, useEffect, useRef } from "react";
import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Title,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import Skeleton from "react-loading-skeleton";

ChartJS.register(CategoryScale, BarElement, LinearScale, Tooltip, Title);

const Vertical_BarCharts = () => {
  const chartRef = useRef(null);
  const [Barchart, setBarchart] = useState([
    {
        "2022": {
            "High": 4,
            "Very Low": 0,
            "Low": 1,
            "Moderate": 3,
            "Critical": 0
        },
        "quarter": "P9"
    },
    {
        "2022": {
            "High": 2,
            "Very Low": 0,
            "Low": 0,
            "Moderate": 1,
            "Critical": 1
        },
        "quarter": "P13"
    },
    {
        "2023": {
            "High": 2,
            "Very Low": 0,
            "Low": 0,
            "Moderate": 1,
            "Critical": 1
        },
        "2024": {
            "High": 11,
            "Very Low": 1,
            "Low": 2,
            "Moderate": 5,
            "Critical": 4
        },
        "quarter": "P2"
    },
    {
        "2023": {
            "High": 8,
            "Very Low": 1,
            "Low": 1,
            "Moderate": 4,
            "Critical": 3
        },
        "2024": {
            "High": 12,
            "Very Low": 2,
            "Low": 3,
            "Moderate": 5,
            "Critical": 4
        },
        "quarter": "P3"
    },
    {
        "2023": {
            "High": 9,
            "Very Low": 1,
            "Low": 2,
            "Moderate": 4,
            "Critical": 3
        },
        "2024": {
            "High": 22,
            "Very Low": 4,
            "Low": 5,
            "Moderate": 10,
            "Critical": 7
        },
        "quarter": "P4"
    },
    {
        "2023": {
            "High": 11,
            "Very Low": 1,
            "Low": 2,
            "Moderate": 5,
            "Critical": 4
        },
        "quarter": "P7"
    },
    {
        "2023": {
            "High": 12,
            "Very Low": 1,
            "Low": 3,
            "Moderate": 6,
            "Critical": 3
        },
        "quarter": "P8"
    },
    {
        "2023": {
            "High": 10,
            "Very Low": 1,
            "Low": 2,
            "Moderate": 5,
            "Critical": 3
        },
        "quarter": "P10"
    },
    {
        "2023": {
            "High": 10,
            "Very Low": 1,
            "Low": 2,
            "Moderate": 5,
            "Critical": 3
        },
        "quarter": "P11"
    },
    {
        "2023": {
            "High": 10,
            "Very Low": 1,
            "Low": 2,
            "Moderate": 5,
            "Critical": 3
        },
        "quarter": "P12"
    },
    {
        "2024": {
            "High": 11,
            "Very Low": 1,
            "Low": 2,
            "Moderate": 5,
            "Critical": 4
        },
        "quarter": "P1"
    }
]
);
  const [isloading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [RiskVendor,setRiskvenor] = useState("Very Low")

  function getRandomColor() {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }
  
  function generateRandomColorsArray(numColors) {
    const colorsArray = [];
    for (let i = 0; i < numColors; i++) {
        colorsArray.push(getRandomColor());
    }
    return colorsArray;
  }
  
  // Example usage to generate 5 random colors
  const randomColors = generateRandomColorsArray(10);
  // console.log(randomColors);

  const keysArray = Barchart.map((item) => Object.keys(item)[0]);
 // console.log(keysArray);
  keysArray.reverse();

  let uniqueArray = keysArray.filter((value, index, self) => {
    return self.indexOf(value) === index;
  });
 // console.log(uniqueArray);

  var data = {
    labels: [
      "P1",
      "P2",
      "P3",
      "P4",
      "P5",
      "P6",
      "P7",
      "P8",
      "P9",
      "P10",
      "P11",
      "P12",
      "P13",
    ],
    datasets: [
      {
        label: `${RiskVendor} Risk Vendor`,
        data: Barchart,
        backgroundColor: ['#BE75DF', '#103ED9', '#A4B2DF', '#096EE3', '#B6F6E7', '#3A64AC', '#79C3DD', '#31C487', '#026E29', '#7400CF'],
        borderColor: "#e3a9a9",
        borderWidth: 0,
        borderRadius: 10,
      },
    ],
  };

  var options = {
    maintainAspectRatio: false,
    parsing: {
      xAxisKey: "quarter",
      yAxisKey: `${uniqueArray[0]}.Very Low`,
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          color: "#253978",
          stepSize: 1,
        },
      },
      y: {
        grid: {
          display: true,
          drawBorder: false, // <-- this removes y-axis line
          lineWidth: 0.3,
        },
        ticks: {
          color: "#253978",
          stepSize: 1,
        },
      },
    },
    plugins: {
      tooltip: {
        callbacks: {
          title: function (context) {
            // Customize the tooltip title
            const year = document.getElementById("year").value;
            return `${(context[0].label) === 'P1' ? 'Period 1' : (context[0].label) === 'P2' ? 'Period 2' : (context[0].label) === 'P3' ? 'Period 3' : (context[0].label) === 'P4' ? 'Period 4':(context[0].label) === 'P5' ? 'Period 5' : (context[0].label) === 'P6' ? 'Period 6' :(context[0].label) === 'P7' ? 'Period 7' : (context[0].label) === 'P8' ? 'Period 8' : (context[0].label) === 'P9' ? 'Period 9' : (context[0].label) === 'P10' ? 'Period 10':(context[0].label) === 'P11' ? 'Period 11' : (context[0].label) === 'P12' ? 'Period 12'  : 'Period 13 '} of ${year}`;
          },
          label: function (context) {
            let label = context.dataset.label || '';
           
            if (context.parsed.y !== null) {
            
              console.log(label)
              label += `: ${context.parsed.y}`;
            }
            return label;
          },
        },
      },
      title: {
        display: true,
        padding: {
          top: 0,
          bottom: 10,
        },
        font: {
          size: 20,
        },
      },
    },
    indexAxis: "x",
    legend: {
      display: false,
    },
  };

  const ChangeTheData = () => {
    const year = document.getElementById("year").value;
    const rating = document.getElementById("rating").value
    setRiskvenor(rating)
    options.parsing.yAxisKey = `${year}.${rating}`;
    const chartItem = chartRef.current;
    chartItem.update();
  };

 

  return (
    <div>
      {isloading && <Skeleton baseColor="rgb(247, 249, 252)" height={400} />}

      {!isloading && (
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
            className="GraphText"
          >
            <span  style={{color:"#3d3d3d"}}>{RiskVendor} Risk Vendors</span>
          
          <div  style={{
              display: "flex",
              alignItems: "center",
              gap:"10px"
            }}>
           

            <div style={{ gridGap: "10px" }}>
              <select
                className="SelectItems"
                id="rating"
                onChange={ChangeTheData}
              >
                  <option value="Very Low">Very Low</option>;
                   <option value="Low">Low</option>;
                   <option value="Moderate">Moderate</option>;
                   <option value="High">High</option>;
                   <option value="Critical">Critical</option>;

              </select>
            </div>

            <div style={{ gridGap: "10px" }}>
              <select
                className="SelectItems"
                id="year"
                onChange={ChangeTheData}
              >
                {uniqueArray.map((year) => {
                  return <option value={year}>{year}</option>;
                })}
              </select>
            </div>
            </div>
          </div>

          <div style={{ height: "400px" }}>
            <Bar
              ref={chartRef}
              id="myChart"
              data={data}
              height={400}
              options={options}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default Vertical_BarCharts;
