import React, { useEffect, useState } from "react";
import { FaInfoCircle, FaPlus, FaMinus } from "react-icons/fa";
import $ from "jquery";

const SimilarityScore = (props) => {
  const [TogglePlusIons, setTogglePlusIons] = useState(true);
  const CollapseButton = () => {
    setTogglePlusIons((prev) => !prev)
    $("#FuzzyLogic_id").slideToggle(0);
  };
  return (
    <div>
    
    <div className="Basic_Informations" style={{display:"flex",justifyContent:"space-between",alignItems:"center",alignContent:"center"}}>
          <div>Fuzzy Logic Similarity Score</div>
          <div className="toggleIonsPlusicons" style={{cursor:"pointer",paddingRight:'10px'}} onClick={CollapseButton}>
            {TogglePlusIons ? <FaMinus color="#c1c1c1" fontSize={20} /> : <FaPlus color="#c1c1c1" fontSize={20}/>}
            </div>
        </div>
    <br/>
      <table id="FuzzyLogic_id" style={{ width: "100%" }}>
        <tbody>
          <tr>
            <th>System Name</th>
            <th>Similarity Score</th>
            <th>Name in Source</th>
          </tr>
          <tr>
            <td>Archer</td>
            <td>{props.archer_smilarity_score}</td>
            <td>{props.archer_vendor_name}</td>
          </tr>
          <tr>
            <td>Ariba</td>
            <td>{props.ariba_smilarity_score}</td>
            <td>{props.ariba_vendor_name}</td>
          </tr>
          <tr>
            <td>Bitsight</td>
            <td>{props.bitsight_smilarity_score}</td>
            <td>{props.bitsight_vendor_name}</td>
          </tr>
          <tr>
            <td>Snow</td>
            <td>{props.snow_smilarity_score}</td>
            <td>{props.snow_vendor_name}</td>
          </tr>
        </tbody>
      </table>
      {TogglePlusIons ? <span></span> : <span>Please click the right ( + ) icons for view the Fuzzy Logic Similarity Score</span>}

    </div>
  );
}

export default SimilarityScore
