import React from "react";
import "react-loading-skeleton/dist/skeleton.css";
import Horizontal_Barcharts from "../charts/Horizontal_Barcharts";
import Vertical_BarCharts from "../charts/Vertical_BarCharts";

export default function Report(props) {

  const Date = props.year;

  return (
    <div>
      <div className="charts_diagram">
      
        <div className="Charts_items">
          <Horizontal_Barcharts date={Date}/>
        </div>

      
        <div className="Charts_items">
          <Vertical_BarCharts  />
        </div>


       

      
      </div>

    </div>
  );
}
