import React, { useState, useEffect, useRef } from "react";
import { FaAngleDown, FaAngleUp, FaArrowDown, FaArrowUp, FaCalendarAlt, FaFilter, FaTimes, FaTimesCircle } from "react-icons/fa";
import { Link } from "react-router-dom";
import NavigarionBar from "../nav/NavigarionBar";
import SideMenu from "../nav/SideMenu";
import Loading from "../spinner/Loading";
import $ from "jquery";
import ReactPaginate from "react-paginate";
import Compareing from "../layout/Compareing";
import { useReactToPrint } from "react-to-print";
import Logo from "../../img/WaterMark.png";
import { FaPrint } from "react-icons/fa";
import Skeleton from "react-loading-skeleton";

const Segment = () => {
  const [error, setError] = useState(false);
  const [iserror, setisError] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [isloading, setloading] = useState(false);
  const [isloadingFilter, setloadingFilter] = useState(false);
  const [firstValue, setfirstValue] = useState("");
  const [SecondValue, setSecondValue] = useState("");
  const [items, setItems] = useState([
    {
        "VendorName": "Alpha Solutions Ltd.",
        "VendorID": "1000001",
        "Rating": "Low",
        "BitSightScore": "510",
        "Region": "Guangdong",
        "Country": "China",
        "VendorNameRegion": "Alpha Solutions Ltd.",
        "InherentRiskRating": "Low",
        "vnct": "Alpha Solutions Ltd."
    },
    {
        "VendorName": "Beta Enterprises Inc.",
        "VendorID": "1000002",
        "Rating": "High",
        "BitSightScore": "700",
        "Region": " ",
        "Country": "Japan",
        "VendorNameRegion": "Beta Enterprises Inc.",
        "InherentRiskRating": "High",
        "vnct": "Beta Enterprises Inc."
    },
    {
        "VendorName": "Gamma Tech Corp.",
        "VendorID": "1000003",
        "Rating": "Medium",
        "BitSightScore": "620",
        "Region": "California",
        "Country": "USA",
        "VendorNameRegion": "Gamma Tech Corp.",
        "InherentRiskRating": "Medium",
        "vnct": "Gamma Tech Corp."
    },
    {
        "VendorName": "Delta Innovations Ltd.",
        "VendorID": "1000004",
        "Rating": "Low",
        "BitSightScore": "550",
        "Region": "Ontario",
        "Country": "Canada",
        "VendorNameRegion": "Delta Innovations Ltd.",
        "InherentRiskRating": "Low",
        "vnct": "Delta Innovations Ltd."
    },
    {
        "VendorName": "Epsilon Solutions LLC",
        "VendorID": "1000005",
        "Rating": "High",
        "BitSightScore": "710",
        "Region": "Berlin",
        "Country": "Germany",
        "VendorNameRegion": "Epsilon Solutions LLC",
        "InherentRiskRating": "High",
        "vnct": "Epsilon Solutions LLC"
    },
    {
        "VendorName": "Zeta Systems Inc.",
        "VendorID": "1000006",
        "Rating": "Medium",
        "BitSightScore": "640",
        "Region": "Ile-de-France",
        "Country": "France",
        "VendorNameRegion": "Zeta Systems Inc.",
        "InherentRiskRating": "Medium",
        "vnct": "Zeta Systems Inc."
    },
    {
        "VendorName": "Eta Technologies Ltd.",
        "VendorID": "1000007",
        "Rating": "Low",
        "BitSightScore": "580",
        "Region": "New South Wales",
        "Country": "Australia",
        "VendorNameRegion": "Eta Technologies Ltd.",
        "InherentRiskRating": "Low",
        "vnct": "Eta Technologies Ltd."
    },
    {
        "VendorName": "Theta Industries Co.",
        "VendorID": "1000008",
        "Rating": "High",
        "BitSightScore": "720",
        "Region": "Seoul",
        "Country": "South Korea",
        "VendorNameRegion": "Theta Industries Co.",
        "InherentRiskRating": "High",
        "vnct": "Theta Industries Co."
    },
    {
        "VendorName": "Iota Solutions Pvt Ltd.",
        "VendorID": "1000009",
        "Rating": "Medium",
        "BitSightScore": "650",
        "Region": "Maharashtra",
        "Country": "India",
        "VendorNameRegion": "Iota Solutions Pvt Ltd.",
        "InherentRiskRating": "Medium",
        "vnct": "Iota Solutions Pvt Ltd."
    },
    {
        "VendorName": "Kappa Holdings Ltd.",
        "VendorID": "1000010",
        "Rating": "Low",
        "BitSightScore": "590",
        "Region": "Gauteng",
        "Country": "South Africa",
        "VendorNameRegion": "Kappa Holdings Ltd.",
        "InherentRiskRating": "Low",
        "vnct": "Kappa Holdings Ltd."
    },
    {
        "VendorName": "Lambda Services Inc.",
        "VendorID": "1000011",
        "Rating": "High",
        "BitSightScore": "730",
        "Region": "São Paulo",
        "Country": "Brazil",
        "VendorNameRegion": "Lambda Services Inc.",
        "InherentRiskRating": "High",
        "vnct": "Lambda Services Inc."
    },
    {
        "VendorName": "Mu Solutions AG",
        "VendorID": "1000012",
        "Rating": "Medium",
        "BitSightScore": "670",
        "Region": "Zurich",
        "Country": "Switzerland",
        "VendorNameRegion": "Mu Solutions AG",
        "InherentRiskRating": "Medium",
        "vnct": "Mu Solutions AG"
    }
]
);
  const [itemsFilters, setitemsFilters] = useState([
    {
        "Country": [
            {
                "countryName": "AN"
            },
            {
                "countryName": "Argentina"
            },
            {
                "countryName": "Armenia"
            },
            {
                "countryName": "Australia"
            },
            {
                "countryName": "Austria"
            },
            {
                "countryName": "Azerbaijan"
            },
            {
                "countryName": "Bahrain"
            },
            {
                "countryName": "Bangladesh"
            },
            {
                "countryName": "Belarus"
            },
            {
                "countryName": "Belgium"
            },
            {
                "countryName": "Bosnia and Herzegovina"
            },
            {
                "countryName": "Brazil"
            },
            {
                "countryName": "Bulgaria"
            },
            {
                "countryName": "Cambodia"
            },
            {
                "countryName": "Canada"
            },
            {
                "countryName": "Cayman Islands"
            },
            {
                "countryName": "Chile"
            },
            {
                "countryName": "China"
            },
            {
                "countryName": "Colombia"
            },
            {
                "countryName": "Costa Rica"
            },
            {
                "countryName": "Côte d'Ivoire"
            },
            {
                "countryName": "Croatia"
            },
            {
                "countryName": "Cyprus"
            },
            {
                "countryName": "Czechia"
            },
            {
                "countryName": "Denmark"
            },
            {
                "countryName": "Djibouti"
            },
            {
                "countryName": "Dominican Republic"
            },
            {
                "countryName": "Ecuador"
            },
            {
                "countryName": "Egypt"
            },
            {
                "countryName": "El Salvador"
            },
            {
                "countryName": "Estonia"
            },
            {
                "countryName": "Eswatini"
            },
            {
                "countryName": "Ethiopia"
            },
            {
                "countryName": "Finland"
            },
            {
                "countryName": "France"
            },
            {
                "countryName": "French Guiana"
            },
            {
                "countryName": "French Polynesia"
            },
            {
                "countryName": "Georgia"
            },
            {
                "countryName": "Germany"
            },
            {
                "countryName": "Ghana"
            },
            {
                "countryName": "Greece"
            },
            {
                "countryName": "Guadeloupe"
            },
            {
                "countryName": "Guatemala"
            },
            {
                "countryName": "Honduras"
            },
            {
                "countryName": "Hong Kong"
            },
            {
                "countryName": "Hungary"
            },
            {
                "countryName": "Iceland"
            },
            {
                "countryName": "India"
            },
            {
                "countryName": "Indonesia"
            },
            {
                "countryName": "Iraq"
            },
            {
                "countryName": "Ireland"
            },
            {
                "countryName": "Israel"
            },
            {
                "countryName": "Italy"
            },
            {
                "countryName": "Japan"
            },
            {
                "countryName": "Jersey"
            },
            {
                "countryName": "Jordan"
            },
            {
                "countryName": "Kazakhstan"
            },
            {
                "countryName": "Kenya"
            },
            {
                "countryName": "Korea, Republic of"
            },
            {
                "countryName": "Kuwait"
            },
            {
                "countryName": "Latvia"
            },
            {
                "countryName": "Lebanon"
            },
            {
                "countryName": "Liechtenstein"
            },
            {
                "countryName": "Lithuania"
            },
            {
                "countryName": "Luxembourg"
            },
            {
                "countryName": "Macao"
            },
            {
                "countryName": "Malaysia"
            },
            {
                "countryName": "Maldives"
            },
            {
                "countryName": "Malta"
            },
            {
                "countryName": "Martinique"
            },
            {
                "countryName": "Mauritius"
            },
            {
                "countryName": "Mexico"
            },
            {
                "countryName": "Morocco"
            },
            {
                "countryName": "Namibia"
            },
            {
                "countryName": "Nepal"
            },
            {
                "countryName": "Netherlands"
            },
            {
                "countryName": "New Caledonia"
            },
            {
                "countryName": "New Zealand"
            },
            {
                "countryName": "Nicaragua"
            },
            {
                "countryName": "Nigeria"
            },
            {
                "countryName": "North Macedonia"
            },
            {
                "countryName": "Norway"
            },
            {
                "countryName": "Oman"
            },
            {
                "countryName": "Pakistan"
            },
            {
                "countryName": "Panama"
            },
            {
                "countryName": "Papua New Guinea"
            },
            {
                "countryName": "Peru"
            },
            {
                "countryName": "Philippines"
            },
            {
                "countryName": "Poland"
            },
            {
                "countryName": "Portugal"
            },
            {
                "countryName": "Puerto Rico"
            },
            {
                "countryName": "Qatar"
            },
            {
                "countryName": "Réunion"
            },
            {
                "countryName": "Romania"
            },
            {
                "countryName": "Russian Federation"
            },
            {
                "countryName": "Rwanda"
            },
            {
                "countryName": "Saudi Arabia"
            },
            {
                "countryName": "Serbia"
            },
            {
                "countryName": "Singapore"
            },
            {
                "countryName": "Slovakia"
            },
            {
                "countryName": "Slovenia"
            },
            {
                "countryName": "South Africa"
            },
            {
                "countryName": "Spain"
            },
            {
                "countryName": "Sri Lanka"
            },
            {
                "countryName": "Sweden"
            },
            {
                "countryName": "Switzerland"
            },
            {
                "countryName": "Taiwan, Province of China"
            },
            {
                "countryName": "Tanzania, United Republic of"
            },
            {
                "countryName": "Thailand"
            },
            {
                "countryName": "Türkiye"
            },
            {
                "countryName": "Uganda"
            },
            {
                "countryName": "Ukraine"
            },
            {
                "countryName": "United Arab Emirates"
            },
            {
                "countryName": "United Kingdom"
            },
            {
                "countryName": "United States"
            },
            {
                "countryName": "Uruguay"
            },
            {
                "countryName": "Uzbekistan"
            },
            {
                "countryName": "Venezuela, Bolivarian Republic of"
            },
            {
                "countryName": "Viet Nam"
            },
            {
                "countryName": "Virgin Islands, British"
            }
        ]
    },
    {
        "Region": [
            {
                "region": "Aichi prefecture"
            },
            {
                "region": "Aisne"
            },
            {
                "region": "Aguascalientes"
            },
            {
                "region": "Alicante"
            },
            {
                "region": "Alpes-Maritimes"
            },
            {
                "region": "Alps (Hautes)"
            },
            {
                "region": "Alabama"
            },
            {
                "region": "Albacete"
            },
            {
                "region": " "
            },
            {
                "region": "31110 Lviv"
            },
            {
                "region": "Antrim"
            },
            {
                "region": "Armagh"
            },
            {
                "region": "Alberta"
            },
            {
                "region": "Alexandria"
            },
            {
                "region": "Aargau"
            },
            {
                "region": "Ain"
            },
            {
                "region": "Alaska"
            },
            {
                "region": "Ardennes"
            },
            {
                "region": "and Kyoto"
            },
            {
                "region": "Andhra Pradesh"
            },
            {
                "region": "Allier"
            },
            {
                "region": "Antwerp"
            },
            {
                "region": "Anhui"
            },
            {
                "region": "ANTIOCH"
            },
            {
                "region": "Arizona"
            },
            {
                "region": "Arkansas"
            },
            {
                "region": "Ardeche"
            },
            {
                "region": "Arezzo"
            },
            {
                "region": "Arunachal Pradesh"
            },
            {
                "region": "As"
            },
            {
                "region": "Aust Capital Terr"
            },
            {
                "region": "ATLANTIC"
            },
            {
                "region": "Baden-Wurttemberg"
            },
            {
                "region": "Avellino"
            },
            {
                "region": "Bacau"
            },
            {
                "region": "Badajoz"
            },
            {
                "region": "Bahia"
            },
            {
                "region": "Bas-Rhin"
            },
            {
                "region": "Bay of Plenty"
            },
            {
                "region": "Barcelona"
            },
            {
                "region": "Bath&NthEstSomerset"
            },
            {
                "region": "Berkshire"
            },
            {
                "region": "Bologna"
            },
            {
                "region": "Bouches-du-Rhône"
            },
            {
                "region": "Brabant"
            },
            {
                "region": "Beijing"
            },
            {
                "region": "Bergamo"
            },
            {
                "region": "British Columbia"
            },
            {
                "region": "Burgos"
            },
            {
                "region": "Bicol"
            },
            {
                "region": "Biella"
            },
            {
                "region": "Cadiz"
            },
            {
                "region": "Canterbury"
            },
            {
                "region": "Bihor"
            },
            {
                "region": "BOLIVAR"
            },
            {
                "region": "Catania"
            },
            {
                "region": "Ceará"
            },
            {
                "region": "Asturias"
            },
            {
                "region": "ATTICA COUNTY"
            },
            {
                "region": "Auckland"
            },
            {
                "region": "Bacs-Kiskun"
            },
            {
                "region": "Central Bohemia"
            },
            {
                "region": "Central Luzon"
            },
            {
                "region": "Bali Bali"
            },
            {
                "region": "Banbridge"
            },
            {
                "region": "Bedfordshire"
            },
            {
                "region": "Berlin"
            },
            {
                "region": "Bern"
            },
            {
                "region": "Blackburn"
            },
            {
                "region": "Ch. city ​​Prague"
            },
            {
                "region": "Braille"
            },
            {
                "region": "Brasilia"
            },
            {
                "region": "BOGOTA"
            },
            {
                "region": "Bolzano"
            },
            {
                "region": "Brandenburg"
            },
            {
                "region": "Brighton & Hove"
            },
            {
                "region": "Charente"
            },
            {
                "region": "Cher"
            },
            {
                "region": "Bremen"
            },
            {
                "region": "Bucharest"
            },
            {
                "region": "Brussels (Capital)"
            },
            {
                "region": "Chihuahua"
            },
            {
                "region": "Chongqing"
            },
            {
                "region": "Burgenland"
            },
            {
                "region": "c.Moscow"
            },
            {
                "region": "CO. Monsoon"
            },
            {
                "region": "Co. Roscommon"
            },
            {
                "region": "Buckinghamshire"
            },
            {
                "region": "Buenos Aires"
            },
            {
                "region": "CALDAS"
            },
            {
                "region": "California"
            },
            {
                "region": "Co. Wicklow"
            },
            {
                "region": "Coahuila"
            },
            {
                "region": "Côte-d'Or"
            },
            {
                "region": "CUNDINAMARCA"
            },
            {
                "region": "Dadra and Nagar Hwy."
            },
            {
                "region": "Cantal"
            },
            {
                "region": "Chandigarh"
            },
            {
                "region": "Daman and Diu"
            },
            {
                "region": "Delhi"
            },
            {
                "region": "Dordogne"
            },
            {
                "region": "Doubs"
            },
            {
                "region": "Drenthe"
            },
            {
                "region": "Dublin 18"
            },
            {
                "region": "Cambridgeshire"
            },
            {
                "region": "Caserta"
            },
            {
                "region": "Charente Maritime"
            },
            {
                "region": "Durham"
            },
            {
                "region": "Eure-et-Loir"
            },
            {
                "region": "Castellón"
            },
            {
                "region": "cha CH O鞥sao"
            },
            {
                "region": "Charente-Maritime"
            },
            {
                "region": "Federal District"
            },
            {
                "region": "Forli-Cesena"
            },
            {
                "region": "Chhattisgarh"
            },
            {
                "region": "Chonburi"
            },
            {
                "region": "Cheshire"
            },
            {
                "region": "Chibar rights"
            },
            {
                "region": "Gansu"
            },
            {
                "region": "Goa"
            },
            {
                "region": "Colorado"
            },
            {
                "region": "Connecticut"
            },
            {
                "region": "Cornwall"
            },
            {
                "region": "Csongrad"
            },
            {
                "region": "Greater Lisbon"
            },
            {
                "region": "Gwynedd"
            },
            {
                "region": "CHUNGCHEONGBUK-DO"
            },
            {
                "region": "DAEGU"
            },
            {
                "region": "Derbyshire"
            },
            {
                "region": "Cluj"
            },
            {
                "region": "Co. Wexford"
            },
            {
                "region": "Devon"
            },
            {
                "region": "District of Columbia"
            },
            {
                "region": "DKI Jakarta Jakarta"
            },
            {
                "region": "Copenhagen"
            },
            {
                "region": "Córdoba"
            },
            {
                "region": "Dolj"
            },
            {
                "region": "Drome"
            },
            {
                "region": "Delaware"
            },
            {
                "region": "Dnipropetrovsk"
            },
            {
                "region": "Dublin 2"
            },
            {
                "region": "Ferrara"
            },
            {
                "region": "Dorset"
            },
            {
                "region": "Dublin 1"
            },
            {
                "region": "Florence"
            },
            {
                "region": "Fukuoka prefecture"
            },
            {
                "region": "Durango"
            },
            {
                "region": "East Flanders"
            },
            {
                "region": "Hainan"
            },
            {
                "region": "Hawaii"
            },
            {
                "region": "East Sussex"
            },
            {
                "region": "Eastern Cape"
            },
            {
                "region": "Eastern Pyrenees"
            },
            {
                "region": "Finistère"
            },
            {
                "region": "Garonne (Haute)"
            },
            {
                "region": "Gauteng"
            },
            {
                "region": "Geneva"
            },
            {
                "region": "GMA"
            },
            {
                "region": "Greater London"
            },
            {
                "region": "Guipuzcoa"
            },
            {
                "region": "Hawke´s Bay"
            },
            {
                "region": "Hereford and Worcs."
            },
            {
                "region": "Gujarat"
            },
            {
                "region": "Hainaut"
            },
            {
                "region": "Hidalgo"
            },
            {
                "region": "Himachal Pradesh"
            },
            {
                "region": "Hubei"
            },
            {
                "region": "Ibragi-Ken"
            },
            {
                "region": "Hampshire"
            },
            {
                "region": "Haryana"
            },
            {
                "region": "Ilfov"
            },
            {
                "region": "Isère"
            },
            {
                "region": "Istanbul"
            },
            {
                "region": "Istria"
            },
            {
                "region": "Jiangsu"
            },
            {
                "region": "Jihocesky"
            },
            {
                "region": "Jilin"
            },
            {
                "region": "Kansas"
            },
            {
                "region": "hears"
            },
            {
                "region": "Hebei"
            },
            {
                "region": "Kent"
            },
            {
                "region": "Kentucky"
            },
            {
                "region": "Kerala"
            },
            {
                "region": "Kwazulu/Natal"
            },
            {
                "region": "Hertfordshire"
            },
            {
                "region": "Hesse"
            },
            {
                "region": "Hokkaido"
            },
            {
                "region": "Hunan"
            },
            {
                "region": "Genova"
            },
            {
                "region": "Georgia"
            },
            {
                "region": "Illinois"
            },
            {
                "region": "Ilocos"
            },
            {
                "region": "Lancashire"
            },
            {
                "region": "Leicestershire"
            },
            {
                "region": "Gerona"
            },
            {
                "region": "Gironde"
            },
            {
                "region": "Indiana"
            },
            {
                "region": "Jalisco"
            },
            {
                "region": "Gloucestershire"
            },
            {
                "region": "Goiás"
            },
            {
                "region": "Leon"
            },
            {
                "region": "Loir-et-Cher"
            },
            {
                "region": "Greater Manchester"
            },
            {
                "region": "Jiangxi"
            },
            {
                "region": "Karnataka"
            },
            {
                "region": "Londonderry"
            },
            {
                "region": "long calf"
            },
            {
                "region": "Kuala Lumpur"
            },
            {
                "region": "Landes"
            },
            {
                "region": "Guadalajara"
            },
            {
                "region": "Guanajuato"
            },
            {
                "region": "Lima and Callao"
            },
            {
                "region": "Lodi"
            },
            {
                "region": "Loire (Haute)"
            },
            {
                "region": "Louisiana"
            },
            {
                "region": "Lower Austria"
            },
            {
                "region": "Lucca"
            },
            {
                "region": "Lot"
            },
            {
                "region": "Lucerne"
            },
            {
                "region": "Manche"
            },
            {
                "region": "Mayenne"
            },
            {
                "region": "GuangDong II"
            },
            {
                "region": "GuangDong IV"
            },
            {
                "region": "Minnesota"
            },
            {
                "region": "Missouri"
            },
            {
                "region": "Guangxi"
            },
            {
                "region": "Guayas"
            },
            {
                "region": "Maine"
            },
            {
                "region": "Manawatu-Wanganui"
            },
            {
                "region": "Mecklenburg-Vorpommern."
            },
            {
                "region": "Mid Glamorgan"
            },
            {
                "region": "Mikolaev's"
            },
            {
                "region": "Moscow"
            },
            {
                "region": "Nakhon Ratchasima"
            },
            {
                "region": "Naples"
            },
            {
                "region": "Nebraska"
            },
            {
                "region": "Montana"
            },
            {
                "region": "Morelos"
            },
            {
                "region": "Mpumalanga"
            },
            {
                "region": "New Brunswick"
            },
            {
                "region": "No Mongols"
            },
            {
                "region": "Nograd"
            },
            {
                "region": "Guizhou"
            },
            {
                "region": "GWANGJU"
            },
            {
                "region": "Gwent"
            },
            {
                "region": "GYEONGSANGBUK-DO"
            },
            {
                "region": "North Brabant"
            },
            {
                "region": "North Yorkshire"
            },
            {
                "region": "Hamburg"
            },
            {
                "region": "Haut-Rhin"
            },
            {
                "region": "Northern Territory"
            },
            {
                "region": "New Hampshire"
            },
            {
                "region": "New South Wales"
            },
            {
                "region": "Hauts-de-Seine"
            },
            {
                "region": "Heilongjiang"
            },
            {
                "region": "Nottinghamshire"
            },
            {
                "region": "Ohio"
            },
            {
                "region": "New Territories"
            },
            {
                "region": "Nonthaburi"
            },
            {
                "region": "Holy Spirit"
            },
            {
                "region": "Hong Kong Island"
            },
            {
                "region": "Orense"
            },
            {
                "region": "Orissa"
            },
            {
                "region": "North Dakota"
            },
            {
                "region": "Ontario"
            },
            {
                "region": "Orne"
            },
            {
                "region": "Oxfordshire"
            },
            {
                "region": "Penang"
            },
            {
                "region": "Pennsylvania"
            },
            {
                "region": "Hors-France"
            },
            {
                "region": "Idaho"
            },
            {
                "region": "Palencia"
            },
            {
                "region": "Paraná"
            },
            {
                "region": "Paris"
            },
            {
                "region": "Pathumthani"
            },
            {
                "region": "Ille-et-Vilaine"
            },
            {
                "region": "INCHEON"
            },
            {
                "region": "Phra Nakhon Si Ayut."
            },
            {
                "region": "Isle of Wight"
            },
            {
                "region": "Jharkhand"
            },
            {
                "region": "Pavia"
            },
            {
                "region": "Perak"
            },
            {
                "region": "Perugia"
            },
            {
                "region": "Pest"
            },
            {
                "region": "Piacenza"
            },
            {
                "region": "Plymouth"
            },
            {
                "region": "Poltava"
            },
            {
                "region": "Pontevedra"
            },
            {
                "region": "Jura"
            },
            {
                "region": "Klaipeda"
            },
            {
                "region": "Pondicherry"
            },
            {
                "region": "Poznan"
            },
            {
                "region": "Krakow"
            },
            {
                "region": "La Coruna"
            },
            {
                "region": "Lerida"
            },
            {
                "region": "Liaoning"
            },
            {
                "region": "Limburg"
            },
            {
                "region": "Limpopo"
            },
            {
                "region": "Lincolnshire"
            },
            {
                "region": "Lodz"
            },
            {
                "region": "Loire Atlantique"
            },
            {
                "region": "Loiret"
            },
            {
                "region": "Lublin"
            },
            {
                "region": "Madrid"
            },
            {
                "region": "Manitoba"
            },
            {
                "region": "Mantua"
            },
            {
                "region": "Massachusetts"
            },
            {
                "region": "Mehedinti"
            },
            {
                "region": "Mexico state"
            },
            {
                "region": "Mississippi"
            },
            {
                "region": "Morbihan"
            },
            {
                "region": "Moselle"
            },
            {
                "region": "Murcia"
            },
            {
                "region": "Nevada"
            },
            {
                "region": "New Jersey"
            },
            {
                "region": "New York"
            },
            {
                "region": "Newfoundland and  La"
            },
            {
                "region": "Prešov region"
            },
            {
                "region": "Puy de Dome"
            },
            {
                "region": "Nidwalden"
            },
            {
                "region": "Nord"
            },
            {
                "region": "Prahova"
            },
            {
                "region": "Reggio Calabria"
            },
            {
                "region": "Queensland"
            },
            {
                "region": "Reading"
            },
            {
                "region": "North Bohemian"
            },
            {
                "region": "North Lincolnshire"
            },
            {
                "region": "Rome"
            },
            {
                "region": "Sabah"
            },
            {
                "region": "North Rhine Westphalia"
            },
            {
                "region": "Reggio Emilia"
            },
            {
                "region": "Rhône"
            },
            {
                "region": "Salzburg"
            },
            {
                "region": "Sèvres (Two)"
            },
            {
                "region": "Northamptonshire"
            },
            {
                "region": "Nottingham City"
            },
            {
                "region": "Odes'ka"
            },
            {
                "region": "Oise"
            },
            {
                "region": "Solothurn"
            },
            {
                "region": "South Corsica"
            },
            {
                "region": "Rio de Janeiro"
            },
            {
                "region": "Saarland"
            },
            {
                "region": "Oklahoma"
            },
            {
                "region": "Østfold County"
            },
            {
                "region": "Salamanca"
            },
            {
                "region": "Samutprakan"
            },
            {
                "region": "Southampton"
            },
            {
                "region": "St. Catarina"
            },
            {
                "region": "Overijssel"
            },
            {
                "region": "Page"
            },
            {
                "region": "São Paulo"
            },
            {
                "region": "Schaffhausen"
            },
            {
                "region": "St. Gallen"
            },
            {
                "region": "Staffordshire"
            },
            {
                "region": "Pardubice region"
            },
            {
                "region": "Pas-de-Calais"
            },
            {
                "region": "Segovia"
            },
            {
                "region": "Seine et Marne"
            },
            {
                "region": "Sth. Gloucestershire"
            },
            {
                "region": "Peterborough"
            },
            {
                "region": "Pichincha"
            },
            {
                "region": "Sucre"
            },
            {
                "region": "Surrey"
            },
            {
                "region": "Shropshire"
            },
            {
                "region": "Sichuan-Cheng reading"
            },
            {
                "region": "Szolnok"
            },
            {
                "region": "Taipei"
            },
            {
                "region": "Sinaloa"
            },
            {
                "region": "Singapore"
            },
            {
                "region": "Somme"
            },
            {
                "region": "Sonora"
            },
            {
                "region": "South Dakota"
            },
            {
                "region": "South Luzon"
            },
            {
                "region": "The Moscow Area"
            },
            {
                "region": "The Novosibirsk Area"
            },
            {
                "region": "Southland"
            },
            {
                "region": "Stockholm County"
            },
            {
                "region": "The Sverdlovsk Area"
            },
            {
                "region": "Quebec"
            },
            {
                "region": "Rajasthan"
            },
            {
                "region": "Toledo"
            },
            {
                "region": "Treviso"
            },
            {
                "region": "Rhineland Palatinate"
            },
            {
                "region": "Turin"
            },
            {
                "region": "Tyne and Wear"
            },
            {
                "region": "Taiwan"
            },
            {
                "region": "Tamaulipas"
            },
            {
                "region": "SANTANDER"
            },
            {
                "region": "Savoie (Upper)"
            },
            {
                "region": "Usti Region"
            },
            {
                "region": "Uttar Pradesh"
            },
            {
                "region": "Tamil Nadu"
            },
            {
                "region": "Tarragona"
            },
            {
                "region": "Seine-Saint-Denis"
            },
            {
                "region": "Territ.-de-Belfort"
            },
            {
                "region": "Volins'ka"
            },
            {
                "region": "Warsaw"
            },
            {
                "region": "The Leningrad Area"
            },
            {
                "region": "The Palms"
            },
            {
                "region": "The rivers"
            },
            {
                "region": "The Ulyanovsk Area"
            },
            {
                "region": "Thurgau"
            },
            {
                "region": "Tianjin"
            },
            {
                "region": "Uppsala county"
            },
            {
                "region": "Val de Marne"
            },
            {
                "region": "Valencia"
            },
            {
                "region": "VALLE"
            },
            {
                "region": "Warwickshire"
            },
            {
                "region": "Washington"
            },
            {
                "region": "Veracruz"
            },
            {
                "region": "Vienna (Upper)"
            },
            {
                "region": "Wedge"
            },
            {
                "region": "Wellington"
            },
            {
                "region": "Virginia"
            },
            {
                "region": "Waikato"
            },
            {
                "region": "Selangor"
            },
            {
                "region": "SEOUL"
            },
            {
                "region": "West Bengal"
            },
            {
                "region": "West Sussex"
            },
            {
                "region": "West Virginia"
            },
            {
                "region": "Western Cape"
            },
            {
                "region": "Shaanxi"
            },
            {
                "region": "Shanghai"
            },
            {
                "region": "Somerset"
            },
            {
                "region": "Sønderjylland county"
            },
            {
                "region": "Yonne"
            },
            {
                "region": "Your"
            },
            {
                "region": "South Australia"
            },
            {
                "region": "South Carolina"
            },
            {
                "region": "Yvelines"
            },
            {
                "region": "Zaporizka"
            },
            {
                "region": "South-Holland"
            },
            {
                "region": "st. Helen"
            },
            {
                "region": "Styria"
            },
            {
                "region": "Suffolk"
            },
            {
                "region": "Tarn-et-Garonne"
            },
            {
                "region": "Tasmania"
            },
            {
                "region": "Tel Aviv"
            },
            {
                "region": "Tennessee"
            },
            {
                "region": "Texas"
            },
            {
                "region": "The Belgorod Area"
            },
            {
                "region": "The Kirov Area"
            },
            {
                "region": "The Rioja"
            },
            {
                "region": "The Rostov Area"
            },
            {
                "region": "Tortola"
            },
            {
                "region": "ULSAN"
            },
            {
                "region": "Uzbekistan"
            },
            {
                "region": "Val d'Oise"
            },
            {
                "region": "Valais"
            },
            {
                "region": "Valladolid"
            },
            {
                "region": "Vermont"
            },
            {
                "region": "Vizcaya"
            },
            {
                "region": "Vosges"
            },
            {
                "region": "Zhytomyrska"
            },
            {
                "region": "Warrington"
            },
            {
                "region": "West Java West Java"
            },
            {
                "region": "West Yorkshire"
            },
            {
                "region": "Western Australia"
            },
            {
                "region": "Wiltshire"
            },
            {
                "region": "Z and flat II"
            },
            {
                "region": "Zacatecas"
            },
            {
                "region": "Zagreb"
            },
            {
                "region": "Assam"
            },
            {
                "region": "Avila"
            },
            {
                "region": "Zaragoza"
            },
            {
                "region": "Zeeland"
            },
            {
                "region": "Baja California"
            },
            {
                "region": "Balearics"
            },
            {
                "region": "Bangkok"
            },
            {
                "region": "Basel city"
            },
            {
                "region": "Basel Land"
            },
            {
                "region": "Bavaria"
            },
            {
                "region": "Bihar"
            },
            {
                "region": "Brasov"
            },
            {
                "region": "Zhejiang"
            },
            {
                "region": "Brescia"
            },
            {
                "region": "Bristol"
            },
            {
                "region": "Budapest"
            },
            {
                "region": "c.St-Petersburg"
            },
            {
                "region": "Calvados"
            },
            {
                "region": "Capital Federal"
            },
            {
                "region": "Caras-Severin"
            },
            {
                "region": "Carinthia"
            },
            {
                "region": "CAUCA"
            },
            {
                "region": "City of Zagreb"
            },
            {
                "region": "Co. Cork"
            },
            {
                "region": "Co. Dublin"
            },
            {
                "region": "Co. Limerick"
            },
            {
                "region": "Colima"
            },
            {
                "region": "Copenhagen county"
            },
            {
                "region": "Côtes-d'Armor"
            },
            {
                "region": "Cumbria"
            },
            {
                "region": "Dumfries a. Galloway"
            },
            {
                "region": "East Java East Java"
            },
            {
                "region": "Essex"
            },
            {
                "region": "Essonne"
            },
            {
                "region": "Florida"
            },
            {
                "region": "Fujian"
            },
            {
                "region": "Gard"
            },
            {
                "region": "Gelderland"
            },
            {
                "region": "Guangdong"
            },
            {
                "region": "Guangzhou"
            },
            {
                "region": "GYEONGGI-DO"
            },
            {
                "region": "Henan"
            },
            {
                "region": "Hérault"
            },
            {
                "region": "Ialomita"
            },
            {
                "region": "Indre-et-Loire"
            },
            {
                "region": "Internal"
            },
            {
                "region": "Iowa"
            },
            {
                "region": "Jammu and Kashmir"
            },
            {
                "region": "Johor"
            },
            {
                "region": "Kanagawa Prefecture"
            },
            {
                "region": "Kievs'ka"
            },
            {
                "region": "Kingston-upon-Hull"
            },
            {
                "region": "Kirovogradska"
            },
            {
                "region": "Koszalin"
            },
            {
                "region": "Kowloon"
            },
            {
                "region": "Lecco"
            },
            {
                "region": "Loire"
            },
            {
                "region": "Lower Saxony"
            },
            {
                "region": "L'vivsbka"
            },
            {
                "region": "Madhya Pradesh"
            },
            {
                "region": "Maharashtra"
            },
            {
                "region": "Maine-et-Loire"
            },
            {
                "region": "Malaga"
            },
            {
                "region": "Marne"
            },
            {
                "region": "Maryland"
            },
            {
                "region": "Merseyside"
            },
            {
                "region": "Meurthe-et-Moselle"
            },
            {
                "region": "Mexico City"
            },
            {
                "region": "Michoacan"
            },
            {
                "region": "Milan"
            },
            {
                "region": "Modena"
            },
            {
                "region": "Navarra"
            },
            {
                "region": "Nayarit"
            },
            {
                "region": "New Lion"
            },
            {
                "region": "Ningxia"
            },
            {
                "region": "Norfolk"
            },
            {
                "region": "North Carolina"
            },
            {
                "region": "North Holland"
            },
            {
                "region": "North Somerset"
            },
            {
                "region": "North&Central Luzon"
            },
            {
                "region": "Northland"
            },
            {
                "region": "Nrth Rhine Westfalia"
            },
            {
                "region": "Oaxaca"
            },
            {
                "region": "Oregon"
            },
            {
                "region": "Oslo"
            },
            {
                "region": "Padova"
            },
            {
                "region": "Parma"
            },
            {
                "region": "Pordenone"
            },
            {
                "region": "Portsmouth"
            },
            {
                "region": "Primorye Territory"
            },
            {
                "region": "Puebla"
            },
            {
                "region": "Puerto Rico"
            },
            {
                "region": "Punjab"
            },
            {
                "region": "Queretaro"
            },
            {
                "region": "Quintana Roo"
            },
            {
                "region": "Ragusa"
            },
            {
                "region": "Ravenna"
            },
            {
                "region": "Rhode Island"
            },
            {
                "region": "RISARALDA"
            },
            {
                "region": "RM - Santiago"
            },
            {
                "region": "road cancer"
            },
            {
                "region": "Saint Dominic of the"
            },
            {
                "region": "Samutsakhon"
            },
            {
                "region": "San Luis Potosi"
            },
            {
                "region": "Sarthe"
            },
            {
                "region": "Saskatchewan"
            },
            {
                "region": "Savoy"
            },
            {
                "region": "Schleswig-Holstein"
            },
            {
                "region": "Seine-Maritime"
            },
            {
                "region": "Sevilla"
            },
            {
                "region": "Shandong"
            },
            {
                "region": "Shanxi"
            },
            {
                "region": "Sichuan"
            },
            {
                "region": "Slough"
            },
            {
                "region": "South Sulawesi Sou"
            },
            {
                "region": "South Yorkshire"
            },
            {
                "region": "South-Moravian region"
            },
            {
                "region": "Szeged"
            },
            {
                "region": "Tabasco"
            },
            {
                "region": "Taranaki"
            },
            {
                "region": "Tartu county"
            },
            {
                "region": "Tayside"
            },
            {
                "region": "Telangana"
            },
            {
                "region": "The Ryazan Area"
            },
            {
                "region": "The Samara Area"
            },
            {
                "region": "Tulcea"
            },
            {
                "region": "Utah"
            },
            {
                "region": "Utrecht"
            },
            {
                "region": "Uttarakhand"
            },
            {
                "region": "Varese"
            },
            {
                "region": "Västra Götaland County"
            },
            {
                "region": "Vaucluse"
            },
            {
                "region": "Vaud"
            },
            {
                "region": "Vendée"
            },
            {
                "region": "Vicenza"
            },
            {
                "region": "Victoria"
            },
            {
                "region": "Vienna"
            },
            {
                "region": "Vinnitska"
            },
            {
                "region": "Wait. Cruz Tenerife"
            },
            {
                "region": "Was"
            },
            {
                "region": "West Midlands"
            },
            {
                "region": "Wisconsin"
            },
            {
                "region": "Wroclaw"
            },
            {
                "region": "Wyoming"
            },
            {
                "region": "Xinjiang"
            },
            {
                "region": "Yucatan"
            },
            {
                "region": "Yunnan"
            },
            {
                "region": "Zug"
            },
            {
                "region": "Zurich"
            }
        ]
    },
    {
        "Risk Rating": [
            {
                "risk_rating": "Critical"
            },
            {
                "risk_rating": "High"
            },
            {
                "risk_rating": "Low"
            },
            {
                "risk_rating": "Moderate"
            },
            {
                "risk_rating": "NA_in_Archer"
            },
            {
                "risk_rating": "None"
            },
            {
                "risk_rating": "Very Low"
            }
        ],
        "Inherent Risk Rating": [
            {
                "ISF_Inherent_Risk_Rating": "Critical"
            },
            {
                "ISF_Inherent_Risk_Rating": "High"
            },
            {
                "ISF_Inherent_Risk_Rating": "Low"
            },
            {
                "ISF_Inherent_Risk_Rating": "Moderate"
            },
            {
                "ISF_Inherent_Risk_Rating": "NA_in_Archer"
            },
            {
                "ISF_Inherent_Risk_Rating": "None"
            }
        ]
    },
    {},
    {
        "CountryRegion": {
            "Papua New Guinea": [
                {
                    "region": " "
                }
            ],
            "Cambodia": [
                {
                    "region": " "
                }
            ],
            "Kazakhstan": [
                {
                    "region": " "
                }
            ],
            "Portugal": [
                {
                    "region": " "
                },
                {
                    "region": "Greater Lisbon"
                }
            ],
            "New Caledonia": [
                {
                    "region": " "
                }
            ],
            "Cayman Islands": [
                {
                    "region": " "
                }
            ],
            "Greece": [
                {
                    "region": " "
                },
                {
                    "region": "ATTICA COUNTY"
                }
            ],
            "Latvia": [
                {
                    "region": " "
                }
            ],
            "Morocco": [
                {
                    "region": " "
                }
            ],
            "Guadeloupe": [
                {
                    "region": " "
                }
            ],
            "Panama": [
                {
                    "region": " "
                }
            ],
            "Guatemala": [
                {
                    "region": " "
                }
            ],
            "Virgin Islands, British": [
                {
                    "region": "Tortola"
                }
            ],
            "Iraq": [
                {
                    "region": " "
                }
            ],
            "Chile": [
                {
                    "region": " "
                },
                {
                    "region": "RM - Santiago"
                }
            ],
            "Nepal": [
                {
                    "region": " "
                }
            ],
            "Tanzania, United Republic of": [
                {
                    "region": " "
                }
            ],
            "Argentina": [
                {
                    "region": " "
                },
                {
                    "region": "Buenos Aires"
                },
                {
                    "region": "Capital Federal"
                },
                {
                    "region": "Córdoba"
                }
            ],
            "Ukraine": [
                {
                    "region": " "
                },
                {
                    "region": "31110 Lviv"
                },
                {
                    "region": "Dnipropetrovsk"
                },
                {
                    "region": "Kievs'ka"
                },
                {
                    "region": "Kirovogradska"
                },
                {
                    "region": "L'vivsbka"
                },
                {
                    "region": "Mikolaev's"
                },
                {
                    "region": "Odes'ka"
                },
                {
                    "region": "Poltava"
                },
                {
                    "region": "Vinnitska"
                },
                {
                    "region": "Volins'ka"
                },
                {
                    "region": "Zaporizka"
                },
                {
                    "region": "Zhytomyrska"
                }
            ],
            "Ghana": [
                {
                    "region": " "
                }
            ],
            "AN": [
                {
                    "region": " "
                }
            ],
            "Bahrain": [
                {
                    "region": " "
                }
            ],
            "India": [
                {
                    "region": " "
                },
                {
                    "region": "Andhra Pradesh"
                },
                {
                    "region": "Arunachal Pradesh"
                },
                {
                    "region": "Assam"
                },
                {
                    "region": "Bihar"
                },
                {
                    "region": "Chandigarh"
                },
                {
                    "region": "Chhattisgarh"
                },
                {
                    "region": "Dadra and Nagar Hwy."
                },
                {
                    "region": "Daman and Diu"
                },
                {
                    "region": "Delhi"
                },
                {
                    "region": "Goa"
                },
                {
                    "region": "Gujarat"
                },
                {
                    "region": "Haryana"
                },
                {
                    "region": "Himachal Pradesh"
                },
                {
                    "region": "Jammu and Kashmir"
                },
                {
                    "region": "Jharkhand"
                },
                {
                    "region": "Karnataka"
                },
                {
                    "region": "Kerala"
                },
                {
                    "region": "Madhya Pradesh"
                },
                {
                    "region": "Maharashtra"
                },
                {
                    "region": "Orissa"
                },
                {
                    "region": "Pondicherry"
                },
                {
                    "region": "Punjab"
                },
                {
                    "region": "Rajasthan"
                },
                {
                    "region": "Tamil Nadu"
                },
                {
                    "region": "Telangana"
                },
                {
                    "region": "Uttar Pradesh"
                },
                {
                    "region": "Uttarakhand"
                },
                {
                    "region": "West Bengal"
                }
            ],
            "Canada": [
                {
                    "region": " "
                },
                {
                    "region": "Alberta"
                },
                {
                    "region": "British Columbia"
                },
                {
                    "region": "Manitoba"
                },
                {
                    "region": "New Brunswick"
                },
                {
                    "region": "Newfoundland and  La"
                },
                {
                    "region": "Ontario"
                },
                {
                    "region": "Quebec"
                },
                {
                    "region": "Saskatchewan"
                }
            ],
            "Maldives": [
                {
                    "region": " "
                }
            ],
            "Belgium": [
                {
                    "region": " "
                },
                {
                    "region": "Antwerp"
                },
                {
                    "region": "Brabant"
                },
                {
                    "region": "Brussels (Capital)"
                },
                {
                    "region": "East Flanders"
                },
                {
                    "region": "Hainaut"
                },
                {
                    "region": "Limburg"
                }
            ],
            "Namibia": [
                {
                    "region": " "
                }
            ],
            "Finland": [
                {
                    "region": " "
                }
            ],
            "North Macedonia": [
                {
                    "region": " "
                }
            ],
            "South Africa": [
                {
                    "region": " "
                },
                {
                    "region": "Eastern Cape"
                },
                {
                    "region": "Gauteng"
                },
                {
                    "region": "Kwazulu/Natal"
                },
                {
                    "region": "Limpopo"
                },
                {
                    "region": "Mpumalanga"
                },
                {
                    "region": "Western Cape"
                }
            ],
            "Georgia": [
                {
                    "region": " "
                }
            ],
            "Peru": [
                {
                    "region": " "
                },
                {
                    "region": "Lima and Callao"
                }
            ],
            "Türkiye": [
                {
                    "region": " "
                },
                {
                    "region": "Istanbul"
                }
            ],
            "Germany": [
                {
                    "region": " "
                },
                {
                    "region": "Baden-Wurttemberg"
                },
                {
                    "region": "Bavaria"
                },
                {
                    "region": "Berlin"
                },
                {
                    "region": "Brandenburg"
                },
                {
                    "region": "Bremen"
                },
                {
                    "region": "Hamburg"
                },
                {
                    "region": "Hesse"
                },
                {
                    "region": "Lower Saxony"
                },
                {
                    "region": "Mecklenburg-Vorpommern."
                },
                {
                    "region": "North Rhine Westphalia"
                },
                {
                    "region": "Nrth Rhine Westfalia"
                },
                {
                    "region": "Rhineland Palatinate"
                },
                {
                    "region": "Saarland"
                },
                {
                    "region": "Schleswig-Holstein"
                }
            ],
            "Puerto Rico": [
                {
                    "region": " "
                },
                {
                    "region": "Puerto Rico"
                }
            ],
            "Viet Nam": [
                {
                    "region": " "
                }
            ],
            "Hong Kong": [
                {
                    "region": " "
                },
                {
                    "region": "Hong Kong Island"
                },
                {
                    "region": "Kowloon"
                },
                {
                    "region": "New Territories"
                }
            ],
            "United States": [
                {
                    "region": " "
                },
                {
                    "region": "Alabama"
                },
                {
                    "region": "Alaska"
                },
                {
                    "region": "Arizona"
                },
                {
                    "region": "Arkansas"
                },
                {
                    "region": "California"
                },
                {
                    "region": "Colorado"
                },
                {
                    "region": "Connecticut"
                },
                {
                    "region": "Delaware"
                },
                {
                    "region": "District of Columbia"
                },
                {
                    "region": "Florida"
                },
                {
                    "region": "Georgia"
                },
                {
                    "region": "Hawaii"
                },
                {
                    "region": "Idaho"
                },
                {
                    "region": "Illinois"
                },
                {
                    "region": "Indiana"
                },
                {
                    "region": "Iowa"
                },
                {
                    "region": "Kansas"
                },
                {
                    "region": "Kentucky"
                },
                {
                    "region": "Louisiana"
                },
                {
                    "region": "Maine"
                },
                {
                    "region": "Maryland"
                },
                {
                    "region": "Massachusetts"
                },
                {
                    "region": "Minnesota"
                },
                {
                    "region": "Mississippi"
                },
                {
                    "region": "Missouri"
                },
                {
                    "region": "Montana"
                },
                {
                    "region": "Nebraska"
                },
                {
                    "region": "Nevada"
                },
                {
                    "region": "New Hampshire"
                },
                {
                    "region": "New Jersey"
                },
                {
                    "region": "New York"
                },
                {
                    "region": "North Carolina"
                },
                {
                    "region": "North Dakota"
                },
                {
                    "region": "Ohio"
                },
                {
                    "region": "Oklahoma"
                },
                {
                    "region": "Oregon"
                },
                {
                    "region": "Pennsylvania"
                },
                {
                    "region": "Puerto Rico"
                },
                {
                    "region": "Rhode Island"
                },
                {
                    "region": "road cancer"
                },
                {
                    "region": "South Carolina"
                },
                {
                    "region": "South Dakota"
                },
                {
                    "region": "Tennessee"
                },
                {
                    "region": "Texas"
                },
                {
                    "region": "Utah"
                },
                {
                    "region": "Vermont"
                },
                {
                    "region": "Virginia"
                },
                {
                    "region": "Washington"
                },
                {
                    "region": "West Virginia"
                },
                {
                    "region": "Wisconsin"
                },
                {
                    "region": "Wyoming"
                }
            ],
            "Thailand": [
                {
                    "region": " "
                },
                {
                    "region": "Bangkok"
                },
                {
                    "region": "cha CH O鞥sao"
                },
                {
                    "region": "Chonburi"
                },
                {
                    "region": "Nakhon Ratchasima"
                },
                {
                    "region": "Nonthaburi"
                },
                {
                    "region": "Page"
                },
                {
                    "region": "Pathumthani"
                },
                {
                    "region": "Phra Nakhon Si Ayut."
                },
                {
                    "region": "Samutprakan"
                },
                {
                    "region": "Samutsakhon"
                }
            ],
            "Costa Rica": [
                {
                    "region": " "
                }
            ],
            "Sweden": [
                {
                    "region": " "
                },
                {
                    "region": "Stockholm County"
                },
                {
                    "region": "Uppsala county"
                },
                {
                    "region": "Västra Götaland County"
                }
            ],
            "Liechtenstein": [
                {
                    "region": " "
                }
            ],
            "Poland": [
                {
                    "region": " "
                },
                {
                    "region": "Koszalin"
                },
                {
                    "region": "Krakow"
                },
                {
                    "region": "Lodz"
                },
                {
                    "region": "Lublin"
                },
                {
                    "region": "Poznan"
                },
                {
                    "region": "Warsaw"
                },
                {
                    "region": "Wroclaw"
                }
            ],
            "Bulgaria": [
                {
                    "region": " "
                }
            ],
            "Jordan": [
                {
                    "region": " "
                }
            ],
            "Kuwait": [
                {
                    "region": " "
                }
            ],
            "Nigeria": [
                {
                    "region": " "
                }
            ],
            "Croatia": [
                {
                    "region": " "
                },
                {
                    "region": "City of Zagreb"
                },
                {
                    "region": "Istria"
                },
                {
                    "region": "Zagreb"
                }
            ],
            "Sri Lanka": [
                {
                    "region": " "
                }
            ],
            "Uruguay": [
                {
                    "region": " "
                }
            ],
            "Côte d'Ivoire": [
                {
                    "region": " "
                }
            ],
            "United Kingdom": [
                {
                    "region": " "
                },
                {
                    "region": "Antrim"
                },
                {
                    "region": "Armagh"
                },
                {
                    "region": "Banbridge"
                },
                {
                    "region": "Bath&NthEstSomerset"
                },
                {
                    "region": "Bedfordshire"
                },
                {
                    "region": "Berkshire"
                },
                {
                    "region": "Blackburn"
                },
                {
                    "region": "Brighton & Hove"
                },
                {
                    "region": "Bristol"
                },
                {
                    "region": "Buckinghamshire"
                },
                {
                    "region": "Cambridgeshire"
                },
                {
                    "region": "Cheshire"
                },
                {
                    "region": "Cornwall"
                },
                {
                    "region": "Cumbria"
                },
                {
                    "region": "Derbyshire"
                },
                {
                    "region": "Devon"
                },
                {
                    "region": "Dorset"
                },
                {
                    "region": "Dumfries a. Galloway"
                },
                {
                    "region": "Durham"
                },
                {
                    "region": "East Sussex"
                },
                {
                    "region": "Essex"
                },
                {
                    "region": "Gloucestershire"
                },
                {
                    "region": "Greater London"
                },
                {
                    "region": "Greater Manchester"
                },
                {
                    "region": "Gwent"
                },
                {
                    "region": "Gwynedd"
                },
                {
                    "region": "Hampshire"
                },
                {
                    "region": "Hereford and Worcs."
                },
                {
                    "region": "Hertfordshire"
                },
                {
                    "region": "Isle of Wight"
                },
                {
                    "region": "Kent"
                },
                {
                    "region": "Kingston-upon-Hull"
                },
                {
                    "region": "Lancashire"
                },
                {
                    "region": "Leicestershire"
                },
                {
                    "region": "Lincolnshire"
                },
                {
                    "region": "Londonderry"
                },
                {
                    "region": "Merseyside"
                },
                {
                    "region": "Mid Glamorgan"
                },
                {
                    "region": "Norfolk"
                },
                {
                    "region": "North Lincolnshire"
                },
                {
                    "region": "North Somerset"
                },
                {
                    "region": "North Yorkshire"
                },
                {
                    "region": "Northamptonshire"
                },
                {
                    "region": "Nottingham City"
                },
                {
                    "region": "Nottinghamshire"
                },
                {
                    "region": "Oxfordshire"
                },
                {
                    "region": "Peterborough"
                },
                {
                    "region": "Plymouth"
                },
                {
                    "region": "Portsmouth"
                },
                {
                    "region": "Reading"
                },
                {
                    "region": "Shropshire"
                },
                {
                    "region": "Slough"
                },
                {
                    "region": "Somerset"
                },
                {
                    "region": "South Yorkshire"
                },
                {
                    "region": "Southampton"
                },
                {
                    "region": "Staffordshire"
                },
                {
                    "region": "Sth. Gloucestershire"
                },
                {
                    "region": "Suffolk"
                },
                {
                    "region": "Surrey"
                },
                {
                    "region": "Tayside"
                },
                {
                    "region": "Tyne and Wear"
                },
                {
                    "region": "Warrington"
                },
                {
                    "region": "Warwickshire"
                },
                {
                    "region": "West Midlands"
                },
                {
                    "region": "West Sussex"
                },
                {
                    "region": "West Yorkshire"
                },
                {
                    "region": "Wiltshire"
                }
            ],
            "United Arab Emirates": [
                {
                    "region": " "
                }
            ],
            "Kenya": [
                {
                    "region": " "
                }
            ],
            "Switzerland": [
                {
                    "region": " "
                },
                {
                    "region": "Aargau"
                },
                {
                    "region": "Basel city"
                },
                {
                    "region": "Basel Land"
                },
                {
                    "region": "Bern"
                },
                {
                    "region": "Geneva"
                },
                {
                    "region": "Lucerne"
                },
                {
                    "region": "Nidwalden"
                },
                {
                    "region": "Schaffhausen"
                },
                {
                    "region": "Solothurn"
                },
                {
                    "region": "St. Gallen"
                },
                {
                    "region": "Thurgau"
                },
                {
                    "region": "Valais"
                },
                {
                    "region": "Vaud"
                },
                {
                    "region": "Zug"
                },
                {
                    "region": "Zurich"
                }
            ],
            "French Polynesia": [
                {
                    "region": " "
                }
            ],
            "Spain": [
                {
                    "region": " "
                },
                {
                    "region": "Albacete"
                },
                {
                    "region": "Alicante"
                },
                {
                    "region": "Asturias"
                },
                {
                    "region": "Avila"
                },
                {
                    "region": "Badajoz"
                },
                {
                    "region": "Balearics"
                },
                {
                    "region": "Barcelona"
                },
                {
                    "region": "Burgos"
                },
                {
                    "region": "Cadiz"
                },
                {
                    "region": "Castellón"
                },
                {
                    "region": "Córdoba"
                },
                {
                    "region": "Gerona"
                },
                {
                    "region": "Guadalajara"
                },
                {
                    "region": "Guipuzcoa"
                },
                {
                    "region": "La Coruna"
                },
                {
                    "region": "Leon"
                },
                {
                    "region": "Lerida"
                },
                {
                    "region": "Madrid"
                },
                {
                    "region": "Malaga"
                },
                {
                    "region": "Murcia"
                },
                {
                    "region": "Navarra"
                },
                {
                    "region": "Orense"
                },
                {
                    "region": "Palencia"
                },
                {
                    "region": "Pontevedra"
                },
                {
                    "region": "Salamanca"
                },
                {
                    "region": "Segovia"
                },
                {
                    "region": "Sevilla"
                },
                {
                    "region": "Tarragona"
                },
                {
                    "region": "The Palms"
                },
                {
                    "region": "The Rioja"
                },
                {
                    "region": "Toledo"
                },
                {
                    "region": "Valencia"
                },
                {
                    "region": "Valladolid"
                },
                {
                    "region": "Vizcaya"
                },
                {
                    "region": "Wait. Cruz Tenerife"
                },
                {
                    "region": "Zaragoza"
                }
            ],
            "Djibouti": [
                {
                    "region": " "
                }
            ],
            "Lebanon": [
                {
                    "region": " "
                }
            ],
            "Azerbaijan": [
                {
                    "region": " "
                }
            ],
            "Venezuela, Bolivarian Republic of": [
                {
                    "region": "Federal District"
                },
                {
                    "region": "Sucre"
                }
            ],
            "Israel": [
                {
                    "region": " "
                },
                {
                    "region": "Tel Aviv"
                }
            ],
            "Australia": [
                {
                    "region": " "
                },
                {
                    "region": "Aust Capital Terr"
                },
                {
                    "region": "New South Wales"
                },
                {
                    "region": "Northern Territory"
                },
                {
                    "region": "Queensland"
                },
                {
                    "region": "South Australia"
                },
                {
                    "region": "Tasmania"
                },
                {
                    "region": "Victoria"
                },
                {
                    "region": "Western Australia"
                }
            ],
            "Estonia": [
                {
                    "region": " "
                },
                {
                    "region": "Tartu county"
                }
            ],
            "Cyprus": [
                {
                    "region": " "
                }
            ],
            "Malaysia": [
                {
                    "region": " "
                },
                {
                    "region": "Johor"
                },
                {
                    "region": "Kuala Lumpur"
                },
                {
                    "region": "Penang"
                },
                {
                    "region": "Perak"
                },
                {
                    "region": "Sabah"
                },
                {
                    "region": "Selangor"
                }
            ],
            "Iceland": [
                {
                    "region": " "
                }
            ],
            "Oman": [
                {
                    "region": " "
                }
            ],
            "Armenia": [
                {
                    "region": " "
                }
            ],
            "Austria": [
                {
                    "region": " "
                },
                {
                    "region": "Burgenland"
                },
                {
                    "region": "Carinthia"
                },
                {
                    "region": "Lower Austria"
                },
                {
                    "region": "Salzburg"
                },
                {
                    "region": "Styria"
                },
                {
                    "region": "Vienna"
                }
            ],
            "El Salvador": [
                {
                    "region": " "
                }
            ],
            "Luxembourg": [
                {
                    "region": " "
                }
            ],
            "Brazil": [
                {
                    "region": "Bahia"
                },
                {
                    "region": "Brasilia"
                },
                {
                    "region": "Ceará"
                },
                {
                    "region": "Goiás"
                },
                {
                    "region": "Holy Spirit"
                },
                {
                    "region": "Paraná"
                },
                {
                    "region": "Rio de Janeiro"
                },
                {
                    "region": "São Paulo"
                },
                {
                    "region": "St. Catarina"
                }
            ],
            "Korea, Republic of": [
                {
                    "region": " "
                },
                {
                    "region": "CHUNGCHEONGBUK-DO"
                },
                {
                    "region": "DAEGU"
                },
                {
                    "region": "GWANGJU"
                },
                {
                    "region": "GYEONGGI-DO"
                },
                {
                    "region": "GYEONGSANGBUK-DO"
                },
                {
                    "region": "INCHEON"
                },
                {
                    "region": "SEOUL"
                },
                {
                    "region": "ULSAN"
                }
            ],
            "Jersey": [
                {
                    "region": " "
                }
            ],
            "Slovenia": [
                {
                    "region": " "
                }
            ],
            "Colombia": [
                {
                    "region": " "
                },
                {
                    "region": "ANTIOCH"
                },
                {
                    "region": "ATLANTIC"
                },
                {
                    "region": "BOGOTA"
                },
                {
                    "region": "BOLIVAR"
                },
                {
                    "region": "CALDAS"
                },
                {
                    "region": "CAUCA"
                },
                {
                    "region": "CUNDINAMARCA"
                },
                {
                    "region": "RISARALDA"
                },
                {
                    "region": "SANTANDER"
                },
                {
                    "region": "VALLE"
                }
            ],
            "Ecuador": [
                {
                    "region": " "
                },
                {
                    "region": "Guayas"
                },
                {
                    "region": "Pichincha"
                },
                {
                    "region": "Saint Dominic of the"
                },
                {
                    "region": "st. Helen"
                },
                {
                    "region": "The rivers"
                }
            ],
            "Hungary": [
                {
                    "region": " "
                },
                {
                    "region": "Bacs-Kiskun"
                },
                {
                    "region": "Budapest"
                },
                {
                    "region": "Csongrad"
                },
                {
                    "region": "Nograd"
                },
                {
                    "region": "Pest"
                },
                {
                    "region": "Szeged"
                },
                {
                    "region": "Szolnok"
                }
            ],
            "Réunion": [
                {
                    "region": " "
                }
            ],
            "Japan": [
                {
                    "region": " "
                },
                {
                    "region": "Aichi prefecture"
                },
                {
                    "region": "and Kyoto"
                },
                {
                    "region": "Chibar rights"
                },
                {
                    "region": "Fukuoka prefecture"
                },
                {
                    "region": "Hokkaido"
                },
                {
                    "region": "Ibragi-Ken"
                },
                {
                    "region": "Kanagawa Prefecture"
                },
                {
                    "region": "long calf"
                }
            ],
            "Belarus": [
                {
                    "region": " "
                }
            ],
            "Mauritius": [
                {
                    "region": " "
                }
            ],
            "New Zealand": [
                {
                    "region": " "
                },
                {
                    "region": "Auckland"
                },
                {
                    "region": "Bay of Plenty"
                },
                {
                    "region": "Canterbury"
                },
                {
                    "region": "Hawke´s Bay"
                },
                {
                    "region": "Manawatu-Wanganui"
                },
                {
                    "region": "Northland"
                },
                {
                    "region": "Southland"
                },
                {
                    "region": "Taranaki"
                },
                {
                    "region": "Waikato"
                },
                {
                    "region": "Wellington"
                }
            ],
            "Eswatini": [
                {
                    "region": " "
                }
            ],
            "Honduras": [
                {
                    "region": " "
                }
            ],
            "Italy": [
                {
                    "region": " "
                },
                {
                    "region": "Alexandria"
                },
                {
                    "region": "Arezzo"
                },
                {
                    "region": "As"
                },
                {
                    "region": "Avellino"
                },
                {
                    "region": "Bergamo"
                },
                {
                    "region": "Biella"
                },
                {
                    "region": "Bologna"
                },
                {
                    "region": "Bolzano"
                },
                {
                    "region": "Brescia"
                },
                {
                    "region": "Caserta"
                },
                {
                    "region": "Catania"
                },
                {
                    "region": "Ferrara"
                },
                {
                    "region": "Florence"
                },
                {
                    "region": "Forli-Cesena"
                },
                {
                    "region": "Genova"
                },
                {
                    "region": "Lecco"
                },
                {
                    "region": "Lodi"
                },
                {
                    "region": "Lucca"
                },
                {
                    "region": "Mantua"
                },
                {
                    "region": "Milan"
                },
                {
                    "region": "Modena"
                },
                {
                    "region": "Naples"
                },
                {
                    "region": "Padova"
                },
                {
                    "region": "Parma"
                },
                {
                    "region": "Pavia"
                },
                {
                    "region": "Perugia"
                },
                {
                    "region": "Piacenza"
                },
                {
                    "region": "Pordenone"
                },
                {
                    "region": "Ragusa"
                },
                {
                    "region": "Ravenna"
                },
                {
                    "region": "Reggio Calabria"
                },
                {
                    "region": "Reggio Emilia"
                },
                {
                    "region": "Rome"
                },
                {
                    "region": "Treviso"
                },
                {
                    "region": "Turin"
                },
                {
                    "region": "Varese"
                },
                {
                    "region": "Vicenza"
                },
                {
                    "region": "Wedge"
                }
            ],
            "Ethiopia": [
                {
                    "region": " "
                }
            ],
            "Russian Federation": [
                {
                    "region": " "
                },
                {
                    "region": "c.Moscow"
                },
                {
                    "region": "c.St-Petersburg"
                },
                {
                    "region": "Moscow"
                },
                {
                    "region": "Primorye Territory"
                },
                {
                    "region": "The Belgorod Area"
                },
                {
                    "region": "The Kirov Area"
                },
                {
                    "region": "The Leningrad Area"
                },
                {
                    "region": "The Moscow Area"
                },
                {
                    "region": "The Novosibirsk Area"
                },
                {
                    "region": "The Rostov Area"
                },
                {
                    "region": "The Ryazan Area"
                },
                {
                    "region": "The Samara Area"
                },
                {
                    "region": "The Sverdlovsk Area"
                },
                {
                    "region": "The Ulyanovsk Area"
                }
            ],
            "Singapore": [
                {
                    "region": " "
                },
                {
                    "region": "Singapore"
                }
            ],
            "French Guiana": [
                {
                    "region": " "
                }
            ],
            "Czechia": [
                {
                    "region": " "
                },
                {
                    "region": "Central Bohemia"
                },
                {
                    "region": "Ch. city ​​Prague"
                },
                {
                    "region": "Jihocesky"
                },
                {
                    "region": "North Bohemian"
                },
                {
                    "region": "Pardubice region"
                },
                {
                    "region": "South-Moravian region"
                },
                {
                    "region": "Usti Region"
                }
            ],
            "Egypt": [
                {
                    "region": " "
                }
            ],
            "Malta": [
                {
                    "region": " "
                }
            ],
            "Saudi Arabia": [
                {
                    "region": " "
                }
            ],
            "Netherlands": [
                {
                    "region": " "
                },
                {
                    "region": "Drenthe"
                },
                {
                    "region": "Gelderland"
                },
                {
                    "region": "Limburg"
                },
                {
                    "region": "North Brabant"
                },
                {
                    "region": "North Holland"
                },
                {
                    "region": "Overijssel"
                },
                {
                    "region": "South-Holland"
                },
                {
                    "region": "Utrecht"
                },
                {
                    "region": "Zeeland"
                }
            ],
            "Pakistan": [
                {
                    "region": " "
                }
            ],
            "China": [
                {
                    "region": " "
                },
                {
                    "region": "Anhui"
                },
                {
                    "region": "Beijing"
                },
                {
                    "region": "Chongqing"
                },
                {
                    "region": "Fujian"
                },
                {
                    "region": "Gansu"
                },
                {
                    "region": "Guangdong"
                },
                {
                    "region": "GuangDong II"
                },
                {
                    "region": "GuangDong IV"
                },
                {
                    "region": "Guangxi"
                },
                {
                    "region": "Guangzhou"
                },
                {
                    "region": "Guizhou"
                },
                {
                    "region": "Hainan"
                },
                {
                    "region": "Hebei"
                },
                {
                    "region": "Heilongjiang"
                },
                {
                    "region": "Henan"
                },
                {
                    "region": "Hubei"
                },
                {
                    "region": "Hunan"
                },
                {
                    "region": "Jiangsu"
                },
                {
                    "region": "Jiangxi"
                },
                {
                    "region": "Jilin"
                },
                {
                    "region": "Liaoning"
                },
                {
                    "region": "Ningxia"
                },
                {
                    "region": "No Mongols"
                },
                {
                    "region": "Shaanxi"
                },
                {
                    "region": "Shandong"
                },
                {
                    "region": "Shanghai"
                },
                {
                    "region": "Shanxi"
                },
                {
                    "region": "Sichuan"
                },
                {
                    "region": "Sichuan-Cheng reading"
                },
                {
                    "region": "Tianjin"
                },
                {
                    "region": "Xinjiang"
                },
                {
                    "region": "Yunnan"
                },
                {
                    "region": "Z and flat II"
                },
                {
                    "region": "Zhejiang"
                }
            ],
            "Ireland": [
                {
                    "region": " "
                },
                {
                    "region": "Co. Cork"
                },
                {
                    "region": "Co. Dublin"
                },
                {
                    "region": "Co. Limerick"
                },
                {
                    "region": "CO. Monsoon"
                },
                {
                    "region": "Co. Roscommon"
                },
                {
                    "region": "Co. Wexford"
                },
                {
                    "region": "Co. Wicklow"
                },
                {
                    "region": "Dublin 1"
                },
                {
                    "region": "Dublin 18"
                },
                {
                    "region": "Dublin 2"
                }
            ],
            "Qatar": [
                {
                    "region": " "
                }
            ],
            "Martinique": [
                {
                    "region": " "
                }
            ],
            "Slovakia": [
                {
                    "region": " "
                },
                {
                    "region": "Prešov region"
                }
            ],
            "France": [
                {
                    "region": " "
                },
                {
                    "region": "Ain"
                },
                {
                    "region": "Aisne"
                },
                {
                    "region": "Allier"
                },
                {
                    "region": "Alpes-Maritimes"
                },
                {
                    "region": "Alps (Hautes)"
                },
                {
                    "region": "Ardeche"
                },
                {
                    "region": "Ardennes"
                },
                {
                    "region": "Bas-Rhin"
                },
                {
                    "region": "Bouches-du-Rhône"
                },
                {
                    "region": "Calvados"
                },
                {
                    "region": "Cantal"
                },
                {
                    "region": "Charente"
                },
                {
                    "region": "Charente Maritime"
                },
                {
                    "region": "Charente-Maritime"
                },
                {
                    "region": "Cher"
                },
                {
                    "region": "Côte-d'Or"
                },
                {
                    "region": "Côtes-d'Armor"
                },
                {
                    "region": "Dordogne"
                },
                {
                    "region": "Doubs"
                },
                {
                    "region": "Drome"
                },
                {
                    "region": "Eastern Pyrenees"
                },
                {
                    "region": "Essonne"
                },
                {
                    "region": "Eure-et-Loir"
                },
                {
                    "region": "Finistère"
                },
                {
                    "region": "Gard"
                },
                {
                    "region": "Garonne (Haute)"
                },
                {
                    "region": "Gironde"
                },
                {
                    "region": "Haut-Rhin"
                },
                {
                    "region": "Hauts-de-Seine"
                },
                {
                    "region": "hears"
                },
                {
                    "region": "Hérault"
                },
                {
                    "region": "Hors-France"
                },
                {
                    "region": "Ille-et-Vilaine"
                },
                {
                    "region": "Indre-et-Loire"
                },
                {
                    "region": "Internal"
                },
                {
                    "region": "Isère"
                },
                {
                    "region": "Jura"
                },
                {
                    "region": "Landes"
                },
                {
                    "region": "Loire"
                },
                {
                    "region": "Loire (Haute)"
                },
                {
                    "region": "Loire Atlantique"
                },
                {
                    "region": "Loiret"
                },
                {
                    "region": "Loir-et-Cher"
                },
                {
                    "region": "Lot"
                },
                {
                    "region": "Maine-et-Loire"
                },
                {
                    "region": "Manche"
                },
                {
                    "region": "Marne"
                },
                {
                    "region": "Mayenne"
                },
                {
                    "region": "Meurthe-et-Moselle"
                },
                {
                    "region": "Morbihan"
                },
                {
                    "region": "Moselle"
                },
                {
                    "region": "Nord"
                },
                {
                    "region": "Oise"
                },
                {
                    "region": "Orne"
                },
                {
                    "region": "Paris"
                },
                {
                    "region": "Pas-de-Calais"
                },
                {
                    "region": "Puy de Dome"
                },
                {
                    "region": "Rhône"
                },
                {
                    "region": "Sarthe"
                },
                {
                    "region": "Savoie (Upper)"
                },
                {
                    "region": "Savoy"
                },
                {
                    "region": "Seine et Marne"
                },
                {
                    "region": "Seine-Maritime"
                },
                {
                    "region": "Seine-Saint-Denis"
                },
                {
                    "region": "Sèvres (Two)"
                },
                {
                    "region": "Somme"
                },
                {
                    "region": "South Corsica"
                },
                {
                    "region": "Tarn-et-Garonne"
                },
                {
                    "region": "Territ.-de-Belfort"
                },
                {
                    "region": "Val de Marne"
                },
                {
                    "region": "Val d'Oise"
                },
                {
                    "region": "Vaucluse"
                },
                {
                    "region": "Vendée"
                },
                {
                    "region": "Vienna"
                },
                {
                    "region": "Vienna (Upper)"
                },
                {
                    "region": "Vosges"
                },
                {
                    "region": "Was"
                },
                {
                    "region": "Yonne"
                },
                {
                    "region": "Your"
                },
                {
                    "region": "Yvelines"
                }
            ],
            "Lithuania": [
                {
                    "region": " "
                },
                {
                    "region": "Klaipeda"
                }
            ],
            "Serbia": [
                {
                    "region": " "
                }
            ],
            "Bosnia and Herzegovina": [
                {
                    "region": " "
                }
            ],
            "Taiwan, Province of China": [
                {
                    "region": " "
                },
                {
                    "region": "Taipei"
                },
                {
                    "region": "Taiwan"
                }
            ],
            "Romania": [
                {
                    "region": " "
                },
                {
                    "region": "Bacau"
                },
                {
                    "region": "Bihor"
                },
                {
                    "region": "Braille"
                },
                {
                    "region": "Brasov"
                },
                {
                    "region": "Bucharest"
                },
                {
                    "region": "Caras-Severin"
                },
                {
                    "region": "Cluj"
                },
                {
                    "region": "Dolj"
                },
                {
                    "region": "Ialomita"
                },
                {
                    "region": "Ilfov"
                },
                {
                    "region": "Mehedinti"
                },
                {
                    "region": "Prahova"
                },
                {
                    "region": "Tulcea"
                }
            ],
            "Philippines": [
                {
                    "region": " "
                },
                {
                    "region": "Bicol"
                },
                {
                    "region": "Central Luzon"
                },
                {
                    "region": "GMA"
                },
                {
                    "region": "Ilocos"
                },
                {
                    "region": "North&Central Luzon"
                },
                {
                    "region": "South Luzon"
                }
            ],
            "Rwanda": [
                {
                    "region": " "
                }
            ],
            "Uzbekistan": [
                {
                    "region": " "
                },
                {
                    "region": "Uzbekistan"
                }
            ],
            "Bangladesh": [
                {
                    "region": " "
                }
            ],
            "Nicaragua": [
                {
                    "region": " "
                }
            ],
            "Norway": [
                {
                    "region": " "
                },
                {
                    "region": "Oslo"
                },
                {
                    "region": "Østfold County"
                }
            ],
            "Macao": [
                {
                    "region": " "
                }
            ],
            "Denmark": [
                {
                    "region": " "
                },
                {
                    "region": "Copenhagen"
                },
                {
                    "region": "Copenhagen county"
                },
                {
                    "region": "Sønderjylland county"
                }
            ],
            "Dominican Republic": [
                {
                    "region": " "
                }
            ],
            "Mexico": [
                {
                    "region": " "
                },
                {
                    "region": "Aguascalientes"
                },
                {
                    "region": "Baja California"
                },
                {
                    "region": "Chihuahua"
                },
                {
                    "region": "Coahuila"
                },
                {
                    "region": "Colima"
                },
                {
                    "region": "Durango"
                },
                {
                    "region": "Federal District"
                },
                {
                    "region": "Guanajuato"
                },
                {
                    "region": "Hidalgo"
                },
                {
                    "region": "Jalisco"
                },
                {
                    "region": "Mexico City"
                },
                {
                    "region": "Mexico state"
                },
                {
                    "region": "Michoacan"
                },
                {
                    "region": "Morelos"
                },
                {
                    "region": "Nayarit"
                },
                {
                    "region": "New Lion"
                },
                {
                    "region": "Oaxaca"
                },
                {
                    "region": "Puebla"
                },
                {
                    "region": "Queretaro"
                },
                {
                    "region": "Quintana Roo"
                },
                {
                    "region": "San Luis Potosi"
                },
                {
                    "region": "Sinaloa"
                },
                {
                    "region": "Sonora"
                },
                {
                    "region": "Tabasco"
                },
                {
                    "region": "Tamaulipas"
                },
                {
                    "region": "Veracruz"
                },
                {
                    "region": "Yucatan"
                },
                {
                    "region": "Zacatecas"
                }
            ],
            "Uganda": [
                {
                    "region": " "
                }
            ],
            "Indonesia": [
                {
                    "region": " "
                },
                {
                    "region": "Bali Bali"
                },
                {
                    "region": "DKI Jakarta Jakarta"
                },
                {
                    "region": "East Java East Java"
                },
                {
                    "region": "South Sulawesi Sou"
                },
                {
                    "region": "West Java West Java"
                }
            ]
        }
    },
    {
        "CountryRating": {
            "Papua New Guinea": [
                {
                    "risk_rating": "NA_in_Archer"
                }
            ],
            "Cambodia": [
                {
                    "risk_rating": "NA_in_Archer"
                }
            ],
            "Kazakhstan": [
                {
                    "risk_rating": "NA_in_Archer"
                }
            ],
            "Portugal": [
                {
                    "risk_rating": "NA_in_Archer"
                }
            ],
            "New Caledonia": [
                {
                    "risk_rating": "NA_in_Archer"
                }
            ],
            "Cayman Islands": [
                {
                    "risk_rating": "NA_in_Archer"
                }
            ],
            "Greece": [
                {
                    "risk_rating": "NA_in_Archer"
                }
            ],
            "Latvia": [
                {
                    "risk_rating": "NA_in_Archer"
                }
            ],
            "Morocco": [
                {
                    "risk_rating": "NA_in_Archer"
                }
            ],
            "Guadeloupe": [
                {
                    "risk_rating": "NA_in_Archer"
                }
            ],
            "Panama": [
                {
                    "risk_rating": "NA_in_Archer"
                }
            ],
            "Guatemala": [
                {
                    "risk_rating": "NA_in_Archer"
                }
            ],
            "Virgin Islands, British": [
                {
                    "risk_rating": "NA_in_Archer"
                }
            ],
            "Iraq": [
                {
                    "risk_rating": "NA_in_Archer"
                }
            ],
            "Chile": [
                {
                    "risk_rating": "NA_in_Archer"
                }
            ],
            "Nepal": [
                {
                    "risk_rating": "NA_in_Archer"
                }
            ],
            "Tanzania, United Republic of": [
                {
                    "risk_rating": "NA_in_Archer"
                }
            ],
            "Argentina": [
                {
                    "risk_rating": "NA_in_Archer"
                }
            ],
            "Ukraine": [
                {
                    "risk_rating": "NA_in_Archer"
                }
            ],
            "Ghana": [
                {
                    "risk_rating": "NA_in_Archer"
                }
            ],
            "AN": [
                {
                    "risk_rating": "NA_in_Archer"
                }
            ],
            "Bahrain": [
                {
                    "risk_rating": "NA_in_Archer"
                }
            ],
            "India": [
                {
                    "risk_rating": "Low"
                },
                {
                    "risk_rating": "Moderate"
                },
                {
                    "risk_rating": "NA_in_Archer"
                }
            ],
            "Canada": [
                {
                    "risk_rating": "High"
                },
                {
                    "risk_rating": "Moderate"
                },
                {
                    "risk_rating": "NA_in_Archer"
                }
            ],
            "Maldives": [
                {
                    "risk_rating": "NA_in_Archer"
                }
            ],
            "Belgium": [
                {
                    "risk_rating": "Low"
                },
                {
                    "risk_rating": "Moderate"
                },
                {
                    "risk_rating": "NA_in_Archer"
                }
            ],
            "Namibia": [
                {
                    "risk_rating": "NA_in_Archer"
                }
            ],
            "Finland": [
                {
                    "risk_rating": "High"
                },
                {
                    "risk_rating": "NA_in_Archer"
                }
            ],
            "North Macedonia": [
                {
                    "risk_rating": "NA_in_Archer"
                }
            ],
            "South Africa": [
                {
                    "risk_rating": "NA_in_Archer"
                }
            ],
            "Georgia": [
                {
                    "risk_rating": "NA_in_Archer"
                }
            ],
            "Peru": [
                {
                    "risk_rating": "NA_in_Archer"
                }
            ],
            "Türkiye": [
                {
                    "risk_rating": "NA_in_Archer"
                }
            ],
            "Germany": [
                {
                    "risk_rating": "Moderate"
                },
                {
                    "risk_rating": "NA_in_Archer"
                }
            ],
            "Puerto Rico": [
                {
                    "risk_rating": "NA_in_Archer"
                }
            ],
            "Viet Nam": [
                {
                    "risk_rating": "NA_in_Archer"
                }
            ],
            "Hong Kong": [
                {
                    "risk_rating": "NA_in_Archer"
                }
            ],
            "United States": [
                {
                    "risk_rating": "High"
                },
                {
                    "risk_rating": "Low"
                },
                {
                    "risk_rating": "Moderate"
                },
                {
                    "risk_rating": "NA_in_Archer"
                },
                {
                    "risk_rating": "None"
                },
                {
                    "risk_rating": "Very Low"
                }
            ],
            "Thailand": [
                {
                    "risk_rating": "Moderate"
                },
                {
                    "risk_rating": "NA_in_Archer"
                }
            ],
            "Costa Rica": [
                {
                    "risk_rating": "NA_in_Archer"
                }
            ],
            "Sweden": [
                {
                    "risk_rating": "Moderate"
                },
                {
                    "risk_rating": "NA_in_Archer"
                }
            ],
            "Liechtenstein": [
                {
                    "risk_rating": "NA_in_Archer"
                }
            ],
            "Poland": [
                {
                    "risk_rating": "High"
                },
                {
                    "risk_rating": "Low"
                },
                {
                    "risk_rating": "Moderate"
                },
                {
                    "risk_rating": "NA_in_Archer"
                }
            ],
            "Bulgaria": [
                {
                    "risk_rating": "NA_in_Archer"
                }
            ],
            "Jordan": [
                {
                    "risk_rating": "NA_in_Archer"
                }
            ],
            "Kuwait": [
                {
                    "risk_rating": "NA_in_Archer"
                }
            ],
            "Nigeria": [
                {
                    "risk_rating": "NA_in_Archer"
                }
            ],
            "Croatia": [
                {
                    "risk_rating": "NA_in_Archer"
                }
            ],
            "Sri Lanka": [
                {
                    "risk_rating": "NA_in_Archer"
                }
            ],
            "Uruguay": [
                {
                    "risk_rating": "NA_in_Archer"
                }
            ],
            "Côte d'Ivoire": [
                {
                    "risk_rating": "NA_in_Archer"
                }
            ],
            "United Kingdom": [
                {
                    "risk_rating": "Low"
                },
                {
                    "risk_rating": "Moderate"
                },
                {
                    "risk_rating": "NA_in_Archer"
                },
                {
                    "risk_rating": "Very Low"
                }
            ],
            "United Arab Emirates": [
                {
                    "risk_rating": "NA_in_Archer"
                }
            ],
            "Kenya": [
                {
                    "risk_rating": "NA_in_Archer"
                }
            ],
            "Switzerland": [
                {
                    "risk_rating": "Low"
                },
                {
                    "risk_rating": "Moderate"
                },
                {
                    "risk_rating": "NA_in_Archer"
                }
            ],
            "French Polynesia": [
                {
                    "risk_rating": "NA_in_Archer"
                }
            ],
            "Spain": [
                {
                    "risk_rating": "NA_in_Archer"
                }
            ],
            "Djibouti": [
                {
                    "risk_rating": "NA_in_Archer"
                }
            ],
            "Lebanon": [
                {
                    "risk_rating": "NA_in_Archer"
                }
            ],
            "Azerbaijan": [
                {
                    "risk_rating": "NA_in_Archer"
                }
            ],
            "Venezuela, Bolivarian Republic of": [
                {
                    "risk_rating": "NA_in_Archer"
                }
            ],
            "Israel": [
                {
                    "risk_rating": "NA_in_Archer"
                }
            ],
            "Australia": [
                {
                    "risk_rating": "High"
                },
                {
                    "risk_rating": "Low"
                },
                {
                    "risk_rating": "NA_in_Archer"
                }
            ],
            "Estonia": [
                {
                    "risk_rating": "NA_in_Archer"
                }
            ],
            "Cyprus": [
                {
                    "risk_rating": "NA_in_Archer"
                }
            ],
            "Malaysia": [
                {
                    "risk_rating": "NA_in_Archer"
                }
            ],
            "Iceland": [
                {
                    "risk_rating": "NA_in_Archer"
                }
            ],
            "Oman": [
                {
                    "risk_rating": "NA_in_Archer"
                }
            ],
            "Armenia": [
                {
                    "risk_rating": "NA_in_Archer"
                }
            ],
            "Austria": [
                {
                    "risk_rating": "Low"
                },
                {
                    "risk_rating": "NA_in_Archer"
                }
            ],
            "El Salvador": [
                {
                    "risk_rating": "NA_in_Archer"
                }
            ],
            "Luxembourg": [
                {
                    "risk_rating": "NA_in_Archer"
                }
            ],
            "Brazil": [
                {
                    "risk_rating": "NA_in_Archer"
                }
            ],
            "Korea, Republic of": [
                {
                    "risk_rating": "NA_in_Archer"
                }
            ],
            "Jersey": [
                {
                    "risk_rating": "NA_in_Archer"
                }
            ],
            "Slovenia": [
                {
                    "risk_rating": "NA_in_Archer"
                }
            ],
            "Colombia": [
                {
                    "risk_rating": "High"
                },
                {
                    "risk_rating": "NA_in_Archer"
                }
            ],
            "Ecuador": [
                {
                    "risk_rating": "NA_in_Archer"
                }
            ],
            "Hungary": [
                {
                    "risk_rating": "NA_in_Archer"
                }
            ],
            "Réunion": [
                {
                    "risk_rating": "NA_in_Archer"
                }
            ],
            "Japan": [
                {
                    "risk_rating": "High"
                },
                {
                    "risk_rating": "Low"
                },
                {
                    "risk_rating": "NA_in_Archer"
                }
            ],
            "Belarus": [
                {
                    "risk_rating": "NA_in_Archer"
                }
            ],
            "Mauritius": [
                {
                    "risk_rating": "NA_in_Archer"
                }
            ],
            "New Zealand": [
                {
                    "risk_rating": "Moderate"
                },
                {
                    "risk_rating": "NA_in_Archer"
                }
            ],
            "Eswatini": [
                {
                    "risk_rating": "NA_in_Archer"
                }
            ],
            "Honduras": [
                {
                    "risk_rating": "NA_in_Archer"
                }
            ],
            "Italy": [
                {
                    "risk_rating": "Moderate"
                },
                {
                    "risk_rating": "NA_in_Archer"
                }
            ],
            "Ethiopia": [
                {
                    "risk_rating": "NA_in_Archer"
                }
            ],
            "Russian Federation": [
                {
                    "risk_rating": "Moderate"
                },
                {
                    "risk_rating": "NA_in_Archer"
                }
            ],
            "Singapore": [
                {
                    "risk_rating": "NA_in_Archer"
                }
            ],
            "French Guiana": [
                {
                    "risk_rating": "NA_in_Archer"
                }
            ],
            "Czechia": [
                {
                    "risk_rating": "NA_in_Archer"
                }
            ],
            "Egypt": [
                {
                    "risk_rating": "NA_in_Archer"
                }
            ],
            "Malta": [
                {
                    "risk_rating": "NA_in_Archer"
                }
            ],
            "Saudi Arabia": [
                {
                    "risk_rating": "NA_in_Archer"
                }
            ],
            "Netherlands": [
                {
                    "risk_rating": "High"
                },
                {
                    "risk_rating": "Low"
                },
                {
                    "risk_rating": "Moderate"
                },
                {
                    "risk_rating": "NA_in_Archer"
                }
            ],
            "Pakistan": [
                {
                    "risk_rating": "NA_in_Archer"
                }
            ],
            "China": [
                {
                    "risk_rating": "Critical"
                },
                {
                    "risk_rating": "Low"
                },
                {
                    "risk_rating": "Moderate"
                },
                {
                    "risk_rating": "NA_in_Archer"
                }
            ],
            "Ireland": [
                {
                    "risk_rating": "NA_in_Archer"
                }
            ],
            "Qatar": [
                {
                    "risk_rating": "NA_in_Archer"
                }
            ],
            "Martinique": [
                {
                    "risk_rating": "NA_in_Archer"
                }
            ],
            "Slovakia": [
                {
                    "risk_rating": "NA_in_Archer"
                }
            ],
            "France": [
                {
                    "risk_rating": "High"
                },
                {
                    "risk_rating": "Low"
                },
                {
                    "risk_rating": "Moderate"
                },
                {
                    "risk_rating": "NA_in_Archer"
                }
            ],
            "Lithuania": [
                {
                    "risk_rating": "NA_in_Archer"
                }
            ],
            "Serbia": [
                {
                    "risk_rating": "NA_in_Archer"
                }
            ],
            "Bosnia and Herzegovina": [
                {
                    "risk_rating": "NA_in_Archer"
                }
            ],
            "Taiwan, Province of China": [
                {
                    "risk_rating": "Low"
                },
                {
                    "risk_rating": "NA_in_Archer"
                }
            ],
            "Romania": [
                {
                    "risk_rating": "NA_in_Archer"
                }
            ],
            "Philippines": [
                {
                    "risk_rating": "NA_in_Archer"
                }
            ],
            "Rwanda": [
                {
                    "risk_rating": "NA_in_Archer"
                }
            ],
            "Uzbekistan": [
                {
                    "risk_rating": "NA_in_Archer"
                }
            ],
            "Bangladesh": [
                {
                    "risk_rating": "NA_in_Archer"
                }
            ],
            "Nicaragua": [
                {
                    "risk_rating": "NA_in_Archer"
                }
            ],
            "Norway": [
                {
                    "risk_rating": "Moderate"
                },
                {
                    "risk_rating": "NA_in_Archer"
                }
            ],
            "Macao": [
                {
                    "risk_rating": "NA_in_Archer"
                }
            ],
            "Denmark": [
                {
                    "risk_rating": "Low"
                },
                {
                    "risk_rating": "Moderate"
                },
                {
                    "risk_rating": "NA_in_Archer"
                }
            ],
            "Dominican Republic": [
                {
                    "risk_rating": "NA_in_Archer"
                }
            ],
            "Mexico": [
                {
                    "risk_rating": "High"
                },
                {
                    "risk_rating": "Low"
                },
                {
                    "risk_rating": "NA_in_Archer"
                }
            ],
            "Uganda": [
                {
                    "risk_rating": "NA_in_Archer"
                }
            ],
            "Indonesia": [
                {
                    "risk_rating": "NA_in_Archer"
                }
            ]
        }
    },
    {
        "CountryInherentRiskRating": {
            "Papua New Guinea": [
                {
                    "ISF_Inherent_Risk_Rating": "NA_in_Archer"
                }
            ],
            "Cambodia": [
                {
                    "ISF_Inherent_Risk_Rating": "NA_in_Archer"
                }
            ],
            "Kazakhstan": [
                {
                    "ISF_Inherent_Risk_Rating": "NA_in_Archer"
                }
            ],
            "Portugal": [
                {
                    "ISF_Inherent_Risk_Rating": "NA_in_Archer"
                }
            ],
            "New Caledonia": [
                {
                    "ISF_Inherent_Risk_Rating": "NA_in_Archer"
                }
            ],
            "Cayman Islands": [
                {
                    "ISF_Inherent_Risk_Rating": "NA_in_Archer"
                }
            ],
            "Greece": [
                {
                    "ISF_Inherent_Risk_Rating": "NA_in_Archer"
                }
            ],
            "Latvia": [
                {
                    "ISF_Inherent_Risk_Rating": "NA_in_Archer"
                }
            ],
            "Morocco": [
                {
                    "ISF_Inherent_Risk_Rating": "NA_in_Archer"
                }
            ],
            "Guadeloupe": [
                {
                    "ISF_Inherent_Risk_Rating": "NA_in_Archer"
                }
            ],
            "Panama": [
                {
                    "ISF_Inherent_Risk_Rating": "NA_in_Archer"
                }
            ],
            "Guatemala": [
                {
                    "ISF_Inherent_Risk_Rating": "NA_in_Archer"
                }
            ],
            "Virgin Islands, British": [
                {
                    "ISF_Inherent_Risk_Rating": "NA_in_Archer"
                }
            ],
            "Iraq": [
                {
                    "ISF_Inherent_Risk_Rating": "NA_in_Archer"
                }
            ],
            "Chile": [
                {
                    "ISF_Inherent_Risk_Rating": "NA_in_Archer"
                }
            ],
            "Nepal": [
                {
                    "ISF_Inherent_Risk_Rating": "NA_in_Archer"
                }
            ],
            "Tanzania, United Republic of": [
                {
                    "ISF_Inherent_Risk_Rating": "NA_in_Archer"
                }
            ],
            "Argentina": [
                {
                    "ISF_Inherent_Risk_Rating": "NA_in_Archer"
                }
            ],
            "Ukraine": [
                {
                    "ISF_Inherent_Risk_Rating": "NA_in_Archer"
                }
            ],
            "Ghana": [
                {
                    "ISF_Inherent_Risk_Rating": "NA_in_Archer"
                }
            ],
            "AN": [
                {
                    "ISF_Inherent_Risk_Rating": "NA_in_Archer"
                }
            ],
            "Bahrain": [
                {
                    "ISF_Inherent_Risk_Rating": "NA_in_Archer"
                }
            ],
            "India": [
                {
                    "ISF_Inherent_Risk_Rating": "High"
                },
                {
                    "ISF_Inherent_Risk_Rating": "Low"
                },
                {
                    "ISF_Inherent_Risk_Rating": "NA_in_Archer"
                }
            ],
            "Canada": [
                {
                    "ISF_Inherent_Risk_Rating": "High"
                },
                {
                    "ISF_Inherent_Risk_Rating": "NA_in_Archer"
                }
            ],
            "Maldives": [
                {
                    "ISF_Inherent_Risk_Rating": "NA_in_Archer"
                }
            ],
            "Belgium": [
                {
                    "ISF_Inherent_Risk_Rating": "High"
                },
                {
                    "ISF_Inherent_Risk_Rating": "Moderate"
                },
                {
                    "ISF_Inherent_Risk_Rating": "NA_in_Archer"
                }
            ],
            "Namibia": [
                {
                    "ISF_Inherent_Risk_Rating": "NA_in_Archer"
                }
            ],
            "Finland": [
                {
                    "ISF_Inherent_Risk_Rating": "High"
                },
                {
                    "ISF_Inherent_Risk_Rating": "NA_in_Archer"
                }
            ],
            "North Macedonia": [
                {
                    "ISF_Inherent_Risk_Rating": "NA_in_Archer"
                }
            ],
            "South Africa": [
                {
                    "ISF_Inherent_Risk_Rating": "NA_in_Archer"
                }
            ],
            "Georgia": [
                {
                    "ISF_Inherent_Risk_Rating": "NA_in_Archer"
                }
            ],
            "Peru": [
                {
                    "ISF_Inherent_Risk_Rating": "NA_in_Archer"
                }
            ],
            "Türkiye": [
                {
                    "ISF_Inherent_Risk_Rating": "NA_in_Archer"
                }
            ],
            "Germany": [
                {
                    "ISF_Inherent_Risk_Rating": "High"
                },
                {
                    "ISF_Inherent_Risk_Rating": "NA_in_Archer"
                }
            ],
            "Puerto Rico": [
                {
                    "ISF_Inherent_Risk_Rating": "NA_in_Archer"
                }
            ],
            "Viet Nam": [
                {
                    "ISF_Inherent_Risk_Rating": "NA_in_Archer"
                }
            ],
            "Hong Kong": [
                {
                    "ISF_Inherent_Risk_Rating": "NA_in_Archer"
                }
            ],
            "United States": [
                {
                    "ISF_Inherent_Risk_Rating": "Critical"
                },
                {
                    "ISF_Inherent_Risk_Rating": "High"
                },
                {
                    "ISF_Inherent_Risk_Rating": "Low"
                },
                {
                    "ISF_Inherent_Risk_Rating": "Moderate"
                },
                {
                    "ISF_Inherent_Risk_Rating": "NA_in_Archer"
                },
                {
                    "ISF_Inherent_Risk_Rating": "None"
                }
            ],
            "Thailand": [
                {
                    "ISF_Inherent_Risk_Rating": "High"
                },
                {
                    "ISF_Inherent_Risk_Rating": "NA_in_Archer"
                }
            ],
            "Costa Rica": [
                {
                    "ISF_Inherent_Risk_Rating": "NA_in_Archer"
                }
            ],
            "Sweden": [
                {
                    "ISF_Inherent_Risk_Rating": "High"
                },
                {
                    "ISF_Inherent_Risk_Rating": "NA_in_Archer"
                }
            ],
            "Liechtenstein": [
                {
                    "ISF_Inherent_Risk_Rating": "NA_in_Archer"
                }
            ],
            "Poland": [
                {
                    "ISF_Inherent_Risk_Rating": "High"
                },
                {
                    "ISF_Inherent_Risk_Rating": "Low"
                },
                {
                    "ISF_Inherent_Risk_Rating": "Moderate"
                },
                {
                    "ISF_Inherent_Risk_Rating": "NA_in_Archer"
                }
            ],
            "Bulgaria": [
                {
                    "ISF_Inherent_Risk_Rating": "NA_in_Archer"
                }
            ],
            "Jordan": [
                {
                    "ISF_Inherent_Risk_Rating": "NA_in_Archer"
                }
            ],
            "Kuwait": [
                {
                    "ISF_Inherent_Risk_Rating": "NA_in_Archer"
                }
            ],
            "Nigeria": [
                {
                    "ISF_Inherent_Risk_Rating": "NA_in_Archer"
                }
            ],
            "Croatia": [
                {
                    "ISF_Inherent_Risk_Rating": "NA_in_Archer"
                }
            ],
            "Sri Lanka": [
                {
                    "ISF_Inherent_Risk_Rating": "NA_in_Archer"
                }
            ],
            "Uruguay": [
                {
                    "ISF_Inherent_Risk_Rating": "NA_in_Archer"
                }
            ],
            "Côte d'Ivoire": [
                {
                    "ISF_Inherent_Risk_Rating": "NA_in_Archer"
                }
            ],
            "United Kingdom": [
                {
                    "ISF_Inherent_Risk_Rating": "Critical"
                },
                {
                    "ISF_Inherent_Risk_Rating": "High"
                },
                {
                    "ISF_Inherent_Risk_Rating": "Low"
                },
                {
                    "ISF_Inherent_Risk_Rating": "NA_in_Archer"
                }
            ],
            "United Arab Emirates": [
                {
                    "ISF_Inherent_Risk_Rating": "NA_in_Archer"
                }
            ],
            "Kenya": [
                {
                    "ISF_Inherent_Risk_Rating": "NA_in_Archer"
                }
            ],
            "Switzerland": [
                {
                    "ISF_Inherent_Risk_Rating": "High"
                },
                {
                    "ISF_Inherent_Risk_Rating": "Low"
                },
                {
                    "ISF_Inherent_Risk_Rating": "NA_in_Archer"
                }
            ],
            "French Polynesia": [
                {
                    "ISF_Inherent_Risk_Rating": "NA_in_Archer"
                }
            ],
            "Spain": [
                {
                    "ISF_Inherent_Risk_Rating": "NA_in_Archer"
                }
            ],
            "Djibouti": [
                {
                    "ISF_Inherent_Risk_Rating": "NA_in_Archer"
                }
            ],
            "Lebanon": [
                {
                    "ISF_Inherent_Risk_Rating": "NA_in_Archer"
                }
            ],
            "Azerbaijan": [
                {
                    "ISF_Inherent_Risk_Rating": "NA_in_Archer"
                }
            ],
            "Venezuela, Bolivarian Republic of": [
                {
                    "ISF_Inherent_Risk_Rating": "NA_in_Archer"
                }
            ],
            "Israel": [
                {
                    "ISF_Inherent_Risk_Rating": "NA_in_Archer"
                }
            ],
            "Australia": [
                {
                    "ISF_Inherent_Risk_Rating": "Critical"
                },
                {
                    "ISF_Inherent_Risk_Rating": "Low"
                },
                {
                    "ISF_Inherent_Risk_Rating": "NA_in_Archer"
                }
            ],
            "Estonia": [
                {
                    "ISF_Inherent_Risk_Rating": "NA_in_Archer"
                }
            ],
            "Cyprus": [
                {
                    "ISF_Inherent_Risk_Rating": "NA_in_Archer"
                }
            ],
            "Malaysia": [
                {
                    "ISF_Inherent_Risk_Rating": "NA_in_Archer"
                }
            ],
            "Iceland": [
                {
                    "ISF_Inherent_Risk_Rating": "NA_in_Archer"
                }
            ],
            "Oman": [
                {
                    "ISF_Inherent_Risk_Rating": "NA_in_Archer"
                }
            ],
            "Armenia": [
                {
                    "ISF_Inherent_Risk_Rating": "NA_in_Archer"
                }
            ],
            "Austria": [
                {
                    "ISF_Inherent_Risk_Rating": "High"
                },
                {
                    "ISF_Inherent_Risk_Rating": "NA_in_Archer"
                }
            ],
            "El Salvador": [
                {
                    "ISF_Inherent_Risk_Rating": "NA_in_Archer"
                }
            ],
            "Luxembourg": [
                {
                    "ISF_Inherent_Risk_Rating": "NA_in_Archer"
                }
            ],
            "Brazil": [
                {
                    "ISF_Inherent_Risk_Rating": "NA_in_Archer"
                }
            ],
            "Korea, Republic of": [
                {
                    "ISF_Inherent_Risk_Rating": "NA_in_Archer"
                }
            ],
            "Jersey": [
                {
                    "ISF_Inherent_Risk_Rating": "NA_in_Archer"
                }
            ],
            "Slovenia": [
                {
                    "ISF_Inherent_Risk_Rating": "NA_in_Archer"
                }
            ],
            "Colombia": [
                {
                    "ISF_Inherent_Risk_Rating": "High"
                },
                {
                    "ISF_Inherent_Risk_Rating": "NA_in_Archer"
                }
            ],
            "Ecuador": [
                {
                    "ISF_Inherent_Risk_Rating": "NA_in_Archer"
                }
            ],
            "Hungary": [
                {
                    "ISF_Inherent_Risk_Rating": "NA_in_Archer"
                }
            ],
            "Réunion": [
                {
                    "ISF_Inherent_Risk_Rating": "NA_in_Archer"
                }
            ],
            "Japan": [
                {
                    "ISF_Inherent_Risk_Rating": "High"
                },
                {
                    "ISF_Inherent_Risk_Rating": "Moderate"
                },
                {
                    "ISF_Inherent_Risk_Rating": "NA_in_Archer"
                }
            ],
            "Belarus": [
                {
                    "ISF_Inherent_Risk_Rating": "NA_in_Archer"
                }
            ],
            "Mauritius": [
                {
                    "ISF_Inherent_Risk_Rating": "NA_in_Archer"
                }
            ],
            "New Zealand": [
                {
                    "ISF_Inherent_Risk_Rating": "High"
                },
                {
                    "ISF_Inherent_Risk_Rating": "NA_in_Archer"
                }
            ],
            "Eswatini": [
                {
                    "ISF_Inherent_Risk_Rating": "NA_in_Archer"
                }
            ],
            "Honduras": [
                {
                    "ISF_Inherent_Risk_Rating": "NA_in_Archer"
                }
            ],
            "Italy": [
                {
                    "ISF_Inherent_Risk_Rating": "High"
                },
                {
                    "ISF_Inherent_Risk_Rating": "NA_in_Archer"
                }
            ],
            "Ethiopia": [
                {
                    "ISF_Inherent_Risk_Rating": "NA_in_Archer"
                }
            ],
            "Russian Federation": [
                {
                    "ISF_Inherent_Risk_Rating": "High"
                },
                {
                    "ISF_Inherent_Risk_Rating": "NA_in_Archer"
                }
            ],
            "Singapore": [
                {
                    "ISF_Inherent_Risk_Rating": "NA_in_Archer"
                }
            ],
            "French Guiana": [
                {
                    "ISF_Inherent_Risk_Rating": "NA_in_Archer"
                }
            ],
            "Czechia": [
                {
                    "ISF_Inherent_Risk_Rating": "NA_in_Archer"
                }
            ],
            "Egypt": [
                {
                    "ISF_Inherent_Risk_Rating": "NA_in_Archer"
                }
            ],
            "Malta": [
                {
                    "ISF_Inherent_Risk_Rating": "NA_in_Archer"
                }
            ],
            "Saudi Arabia": [
                {
                    "ISF_Inherent_Risk_Rating": "NA_in_Archer"
                }
            ],
            "Netherlands": [
                {
                    "ISF_Inherent_Risk_Rating": "Critical"
                },
                {
                    "ISF_Inherent_Risk_Rating": "High"
                },
                {
                    "ISF_Inherent_Risk_Rating": "Low"
                },
                {
                    "ISF_Inherent_Risk_Rating": "Moderate"
                },
                {
                    "ISF_Inherent_Risk_Rating": "NA_in_Archer"
                }
            ],
            "Pakistan": [
                {
                    "ISF_Inherent_Risk_Rating": "NA_in_Archer"
                }
            ],
            "China": [
                {
                    "ISF_Inherent_Risk_Rating": "Critical"
                },
                {
                    "ISF_Inherent_Risk_Rating": "High"
                },
                {
                    "ISF_Inherent_Risk_Rating": "Low"
                },
                {
                    "ISF_Inherent_Risk_Rating": "Moderate"
                },
                {
                    "ISF_Inherent_Risk_Rating": "NA_in_Archer"
                },
                {
                    "ISF_Inherent_Risk_Rating": "None"
                }
            ],
            "Ireland": [
                {
                    "ISF_Inherent_Risk_Rating": "NA_in_Archer"
                }
            ],
            "Qatar": [
                {
                    "ISF_Inherent_Risk_Rating": "NA_in_Archer"
                }
            ],
            "Martinique": [
                {
                    "ISF_Inherent_Risk_Rating": "NA_in_Archer"
                }
            ],
            "Slovakia": [
                {
                    "ISF_Inherent_Risk_Rating": "NA_in_Archer"
                }
            ],
            "France": [
                {
                    "ISF_Inherent_Risk_Rating": "High"
                },
                {
                    "ISF_Inherent_Risk_Rating": "Low"
                },
                {
                    "ISF_Inherent_Risk_Rating": "Moderate"
                },
                {
                    "ISF_Inherent_Risk_Rating": "NA_in_Archer"
                }
            ],
            "Lithuania": [
                {
                    "ISF_Inherent_Risk_Rating": "NA_in_Archer"
                }
            ],
            "Serbia": [
                {
                    "ISF_Inherent_Risk_Rating": "NA_in_Archer"
                }
            ],
            "Bosnia and Herzegovina": [
                {
                    "ISF_Inherent_Risk_Rating": "NA_in_Archer"
                }
            ],
            "Taiwan, Province of China": [
                {
                    "ISF_Inherent_Risk_Rating": "Moderate"
                },
                {
                    "ISF_Inherent_Risk_Rating": "NA_in_Archer"
                }
            ],
            "Romania": [
                {
                    "ISF_Inherent_Risk_Rating": "NA_in_Archer"
                }
            ],
            "Philippines": [
                {
                    "ISF_Inherent_Risk_Rating": "NA_in_Archer"
                }
            ],
            "Rwanda": [
                {
                    "ISF_Inherent_Risk_Rating": "NA_in_Archer"
                }
            ],
            "Uzbekistan": [
                {
                    "ISF_Inherent_Risk_Rating": "NA_in_Archer"
                }
            ],
            "Bangladesh": [
                {
                    "ISF_Inherent_Risk_Rating": "NA_in_Archer"
                }
            ],
            "Nicaragua": [
                {
                    "ISF_Inherent_Risk_Rating": "NA_in_Archer"
                }
            ],
            "Norway": [
                {
                    "ISF_Inherent_Risk_Rating": "High"
                },
                {
                    "ISF_Inherent_Risk_Rating": "NA_in_Archer"
                }
            ],
            "Macao": [
                {
                    "ISF_Inherent_Risk_Rating": "NA_in_Archer"
                }
            ],
            "Denmark": [
                {
                    "ISF_Inherent_Risk_Rating": "High"
                },
                {
                    "ISF_Inherent_Risk_Rating": "Low"
                },
                {
                    "ISF_Inherent_Risk_Rating": "NA_in_Archer"
                }
            ],
            "Dominican Republic": [
                {
                    "ISF_Inherent_Risk_Rating": "NA_in_Archer"
                }
            ],
            "Mexico": [
                {
                    "ISF_Inherent_Risk_Rating": "High"
                },
                {
                    "ISF_Inherent_Risk_Rating": "Moderate"
                },
                {
                    "ISF_Inherent_Risk_Rating": "NA_in_Archer"
                }
            ],
            "Uganda": [
                {
                    "ISF_Inherent_Risk_Rating": "NA_in_Archer"
                }
            ],
            "Indonesia": [
                {
                    "ISF_Inherent_Risk_Rating": "NA_in_Archer"
                }
            ]
        }
    }
]);
  const [Allitems, setAllItems] = useState([]);
  const [isloadingTable, setloadingTable] = useState(false);
  const [query, setQuery] = useState("");
  const [filter, setFilter] = useState("");
  const url = new URL(window.location.href);
  // const Risk = url.searchParams.get("RiskRating");
  const [filterRisk, setFilterRiks] = useState("");
  const [filterinhRisk, setfilterinhRisk] = useState("");
  const [filterBscore, setfilterBscore] = useState("");
  const [filterCountry, setfilterCountry] = useState("");
  const [filterCountryToggle, setfilterCountryToggle] = useState(false);
  const [alertMessage, setAlertMessage] = useState(false);
  const [currentPagelimit, setPageLimit] = useState(20);
  const [getOffset, setOffset] = useState(0);
  const [restCheckboxt, setrestCheckboxt] = useState(false);
  const [Limit, setLimit] = useState(1);
  const [CompareingVendor,setCompareingVendor] = useState([]);
  // const inputRef = useRef()
  const ref = useRef([]);

  const residual_ref = useRef()
  const inherent_ref = useRef()
  const bitshight_ref = useRef()
  const componentRef_1 = useRef(null);
  // const [sidePannel, setsidePannel] = useState(false);
  const [itemsFilters1, setitemsFilters1] = useState(false);
  const [InherentRiskFilter, setiInherentRiskFilter] = useState(false);
  const [BitsightFilter, setiBitsightFilter] = useState(false);

  const monthNames = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
    ];
  
    const date = new Date()
    const month = monthNames[date.getMonth()]
    const Day = date.getDate();
    const year = date.getFullYear();

 

  useEffect(() => {
    document.title = "VCRM INSIGHT";
  });
  const itemsFiltersCountry = [...new Set(itemsFilters[0]?.Country?.map((item) => item.countryName))]
  const itemsFiltersRegion= [...new Set(itemsFilters[1]?.Region?.map((item) => item.region))]
  const itemsFiltersRisk= [...new Set(itemsFilters[2]?.["Risk Rating"]?.map((item) => item.risk_rating))]
  const inherentRiskItem= [...new Set(itemsFilters[2]?.["Inherent Risk Rating"]?.map((item) => item.ISF_Inherent_Risk_Rating))]
  //console.log(inherentRiskItem)
  const ContryRegion = itemsFilters[4]?.CountryRegion
  const asandingByCountry = itemsFiltersCountry.sort()
  const asandingByRegion = itemsFiltersRegion.sort()


  const asandingByRisk = itemsFiltersRisk.sort()
  //console.log(itemsFilters)
  //const inherentRiskItem = ["Low","Very Low", "High", "Modrate", "Critical"];
  const BitsightRiskItem = ["0-101", "101-201", "201-301", "301-501","501-701","701-999"];


  const checkNAvalue = () =>{
    itemsFilters[1] &&
    itemsFilters[1]?.Region?.map((item) => {
      if(item.region === ""){
        item.region = 'N/A'
      }
    })
  }

  checkNAvalue();


  const filterByundefinedData = () => {
    setFilter("");
    setFilterRiks("");
  };

  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };

  let NUM_OF_RECORDS = 50;
  if (NUM_OF_RECORDS <= currentPagelimit) {
    $(".pagination").hide(true);
  } else {
    $(".pagination").show(true);
  }

  const pageCount = Math.ceil(50 / currentPagelimit);

  const handelepageclick = (data) => {
    console.log(data.selected);
    setCurrentPage(data.selected + 1);
    setOffset(data.selected * currentPagelimit);
    console.log(getOffset);
  };

  const resetAll = (e) => {
      e.preventDefault();
      setFilter("");
      setfilterCountry("");
      setQuery("");
      setCurrentPage(1);
      setOffset(0);
      setfilterCountryToggle(false);
      setSecondValue("")
      setfirstValue("")
      setrestCheckboxt(true)
      if(filterinhRisk !== ""){
        inherent_ref.current.checked = true
        setfilterinhRisk("");

      }
       if (filterBscore !== ""){
        bitshight_ref.current.checked = true
        setfilterBscore("");

      }
       if(filterRisk !==""){
        residual_ref.current.checked = true
        setFilterRiks("");

      }
      Unchecked()

  };

  const Unchecked = () => {

   // console.log(ref.current.length)
    for (let i = 0; i < ref.current.length; i++) {
        ref.current[i].checked = false;
        setLimit(1);
        setCompareingVendor([])

    }

}


function capitalizeWords(str) {
  return str.replace(/\b\w/g, function(match) {
    return match.toUpperCase();
  });
}

const handleCheck = event =>{
  if(event.target.checked){
    if(Limit > 2){
      console.log("Please Select 2 checkbox only");
      setAlertMessage(true)
      setTimeout(() => {
        setAlertMessage(false)
      }, 2000);
      event.target.checked = false;
      return false
    }
    else {
      setLimit(Limit  + 1)
      setCompareingVendor([...CompareingVendor, event.target.value])  
    }
  }
  if(event.target.checked === false){
    setLimit(Limit  - 1)
    var index = CompareingVendor.indexOf(event.target.value);
    setCompareingVendor([...CompareingVendor.slice(0, index), ...CompareingVendor.slice(index + 1)]);  
  }
  

}

//console.log(items)

// const handleCheck = event => {
//     const isChecked = event.target.checked;
//     const checkboxValue = event.target.value;
  
//     if (isChecked) {
//       if (Limit >= 2) {
//         console.log("Please select only 2 checkboxes.");
//         setAlertMessage(true);
//         setTimeout(() => {
//           setAlertMessage(false);
//         }, 2000);
//         return;
//       } else {
//         setLimit(prevLimit => prevLimit + 1);
//         setCompareingVendor(prevCompareingVendor => [...prevCompareingVendor, checkboxValue]);
//       }
//     } else {
//       setLimit(prevLimit => prevLimit - 1);
//       setCompareingVendor(prevCompareingVendor => prevCompareingVendor.filter(item => item !== checkboxValue));
//     }
//   };
  

const printSegment = useReactToPrint({
  content: () => {
    const tableStat_1 = componentRef_1.current.cloneNode(true);

    const PrintElem = document.createElement("div");
    const header = 
      `<img src="${Logo}" style="padding-bottom:2rem" alt="image" class="watermark"/>`;
    PrintElem.innerHTML = header;
  
    PrintElem.appendChild(tableStat_1);

    return PrintElem;
  },
});

const handleClick = (event) => {
  let itemsId = event.currentTarget.id

  //console.log(itemsId)

  if(itemsId === "residual_risk"){
    $("#residualRisk .filterContent").toggle()
    const getclssadd = $("#residualRisk .filterContent").css("display")

    if(getclssadd === 'block'){
      setitemsFilters1(true)
    }
    else if(getclssadd === 'none'){
      setitemsFilters1(false)
    }
  }

  else if(itemsId === "inherentRisk_risk"){
    $("#InherentRisk .filterContent").toggle()
    const getclssadd = $("#InherentRisk .filterContent").css("display")

    if(getclssadd === 'block'){
      setiInherentRiskFilter(true)

    }
    else if(getclssadd === 'none'){
      setiInherentRiskFilter(false)
    }
  }

  else if(itemsId === "BitsightScore_risk"){
    $("#BitsightScore .filterContent").toggle()
    const getclssadd = $("#BitsightScore .filterContent").css("display")

    if(getclssadd === 'block'){
      setiBitsightFilter(true)

    }
    else if(getclssadd === 'none'){
      setiBitsightFilter(false)
    }
  }
 

};

const filterByResidual = event =>{

  if(event.target.checked){
    setFilterRiks(`${event.target.value}`)
    handlePageChange({ selected: 1 });
  }
  else{
    setFilterRiks("")
  }
}

const filterByinherent = event =>{
   if(event.target.checked){
    setfilterinhRisk(`${event.target.value}`)
    handlePageChange({ selected: 1 });
  }
  else{
    setfilterinhRisk("")
  }
 
}

const filterByBitsightScore = event =>{

  console.log(event.target.checked, event.target.value,event)
  if(event.target.checked){
    setfilterBscore(`${event.target.value}`)
    handlePageChange({ selected: 1 });
  }
  else{
    setfilterBscore("")
  }
}

const clearAll = (e) => {
  e.preventDefault();
  setfilterBscore("");
  setfilterinhRisk("");
  setFilterRiks("");
  setLimit(1)
  setCompareingVendor([])
  residual_ref.current.checked = true
  inherent_ref.current.checked = true
  bitshight_ref.current.checked = true
};


const closePanel = () =>{
  $(".sidePannelFilter").toggle();
}

return (
  <div>
    <NavigarionBar />
    <SideMenu />
    <div style={{ position: "relative" }} id="main">
      {isloading && (
        <div
          style={{
            height: "100%",
            display: "grid",
            alignItems: "center",
            alignContent: "center",
          }}
        >
          <div className="PlaseWait">
            <div className="spinner_class">
              <Loading />
            </div>
            <div>Please wait sometime</div>
          </div>
        </div>
      )}

      {!isloading && !error && (
        <>
          <div className="wrapper">
            <div
              style={{
                paddingBottom: "1rem",
                display: "grid",
              }}
              className="searchBarSections"
            >
              <h1 className="vender_Risk_Management_System text_header_color">
                Vendor Risk Management

              </h1>

              <div style={{cursor:"initial"}} className="print_sagments">
                <div className="Print_flex">
                  <span style={{ fontWeight: "600", color: "#0000a0" }}>
                    Print
                  </span>
                  <span style={{cursor:"pointer"}} onClick={printSegment}>
                    <FaPrint color="#0000a0" fontSize={26} />
                  </span>
                </div>
              </div>
            </div>

            {Limit > 2 && (
              <div
                className="floating_div"
                style={{
                  display: "grid",
                  justifyContent: "flex-start",
                  marginBottom: "1rem",
                }}
              >
                <div className="comparing_item_div">
                  <div className="compareBtn_values">
                    {CompareingVendor &&
                      CompareingVendor.map((item,index) => {
                        return <div key={index}>{capitalizeWords(item)}</div>;
                      })}
                  </div>

                  <div className="compareBtn_sec">
                    {Limit > 2 && <Compareing dataValue={CompareingVendor} />}
                  </div>
                </div>
              </div>
            )}

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                paddingBottom: "0rem",
                flexWrap:"wrap",
                gridGap:"10px"
              }}
            >
              <div className="search-wrapper">
                <input
                  type="search"
                  name="search-form"
                  id="search-form"
                  value={query}
                  className="SearchBox_new"
                  placeholder="Search for Vendor Name"
                  autoComplete="off"
                  onChange={(e) => {
                    setQuery(e.target.value);
                    handlePageChange({ selected: 1 });
                    setOffset(0)
                  }}
                />
              </div>

              <div style={{ display: "flex", gridGap: "20px" }}>
                <div className="selectParent select">
                  <select
                    value={filterCountry}
                    onChange={(e) => {
                      setfilterCountry(e.target.value);
                      handlePageChange({ selected: 1 });
                      setfilterCountryToggle(true);
                      filterByundefinedData();
                    }}
                    className="custom-select"
                    aria-label="Filter Countries By Region"
                  >
                    {isloadingFilter && (
                      <option className="isloadingFilter">Please Wait ....</option>
                      )}

                    {!isloadingFilter && (
                      <>
                      <option value="">Filter By Country Name</option>
                      {asandingByCountry.map((item) => (
                        <option value={item}>{item}</option>
                      ))}
                      </>
 
                    )}

                   
                  </select>
                  <span className="focus"></span>
                </div>

                <div className="selectParent select">
                  <select
                    value={filter}
                    onChange={(e) => {
                      setFilter(e.target.value);
                      handlePageChange({ selected: 1 });
                    }}
                    className="custom-select"
                    aria-label="Filter Countries By Region"
                  >
                     {isloadingFilter && (
                      <option className="isloadingFilter">Please Wait ....</option>

                    )}

                    {!isloadingFilter && (
                      <>
                      <option value="">Filter By Region</option>
                    {filterCountryToggle === true ? (
                      <>
                        {ContryRegion?.[`${filterCountry}`].map((item) => (
                          <option value={item.region}>{item.region}</option>
                        ))}
                      </>
                    ) : (
                      <>
                        {asandingByRegion.map((item) => (
                          <option value={item === " " ? " " : item}>
                            {item === " " ? "N/A" : item}
                          </option>
                        ))}
                      </>
                    )}
                      </>
                    )}
                    
                  </select>
                  <span className="focus"></span>
                </div>

                <div>
                  <button
                    style={{
                      border: "1px solid #d0d8e2",
                      borderRadius: " 10px",
                      padding: "10px",
                      background: "#253978",
                      color: "white",
                    }}
                    onClick={resetAll}
                  >
                    Clear All
                  </button>
                </div>
              </div>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                paddingBottom: "1rem",
                paddingTop: "1rem",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gridGap: "10px",
                }}
              >
                <div
                  onClick={closePanel}
                  style={{
                    display: "flex",
                    justifyItems: "center",
                    alignItems: "center",
                    gridGap: "10px",
                    background: "#253978",
                    padding: "10px",
                    borderRadius: "6px",
                    color: "white",
                    cursor: "pointer",
                    fontWeight: "600",
                  }}
                >
                  <div>
                    <FaFilter cursor="pointer" color="white" fontSize={18} />
                  </div>
                </div>

                <div
                  style={{
                    fontSize: "18px",
                    paddingBottom: "1rem",
                    paddingTop: "1rem",
                  }}
                >
                  Total number of Vendors{" "}
                  <span
                    style={{
                      fontSize: "18px",
                      fontWeight: "600",
                      color: "#253978",
                    }}
                  >
                    50
                  </span>
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  gridGap: "10px",
                  alignItems: "center",
                  alignContent: "center",
                }}
              >
                <div>
                  <ReactPaginate
                    previousLabel={"Previous"}
                    nextLabel={"Next"}
                    breakLabel={"..."}
                    pageCount={pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={3}
                    onPageChange={handelepageclick}
                    containerClassName={"pagination"}
                    pageClassName={"page-item"}
                    pageLinkClassName={"page-link"}
                    previousClassName={"page-item"}
                    previousLinkClassName={"page-link"}
                    nextClassName={"page-item"}
                    nextLinkClassName={"page-link"}
                    breakClassName={"page-item"}
                    breakLinkClassName={"page-link"}
                    activeClassName={"active"}
                    forcePage={currentPage - 1}
                  />
                </div>
              </div>
            </div>

            {isloadingTable && (
              <table style={{ width: "100%" }}>
                <tbody>
                  <tr>
                    <th
                      style={{
                        background: "#d0d8e2",
                        color: "#253978",
                        width: "2%",
                        textAlign: "center",
                      }}
                    ></th>

                    <th
                      style={{
                        background: "#d0d8e2",
                        color: "#253978",
                        width: "29%",
                      }}
                    >
                      Vendor Name
                    </th>
                    <th
                      style={{
                        textAlign: "center",
                        background: "#d0d8e2",
                        color: "#253978",
                        width: "13%",
                      }}
                    >
                      Vendor ID
                    </th>
                    <th
                      style={{
                        textAlign: "center",
                        background: "#d0d8e2",
                        color: "#253978",
                        width: "12%",
                      }}
                    >
                      Region
                    </th>
                    <th
                      style={{
                        textAlign: "center",
                        background: "#d0d8e2",
                        color: "#253978",
                        width: "12%",
                      }}
                    >
                      Country
                    </th>
                    <th
                      style={{
                        textAlign: "center",
                        background: "#d0d8e2",
                        color: "#253978",
                        width: "14%",
                      }}
                    >
                      Residual Risk
                    </th>
                    <th
                      style={{
                        textAlign: "center",
                        background: "#d0d8e2",
                        color: "#253978",
                        width: "14%",
                      }}
                    >
                      Inherent Risk
                    </th>
                    <th
                      style={{
                        textAlign: "center",
                        background: "#d0d8e2",
                        color: "#253978",
                        width: "10%",
                      }}
                    >
                      Bitsight
                    </th>
                    <th
                      style={{
                        textAlign: "center",
                        background: "#d0d8e2",
                        color: "#253978",
                        width: "4%",
                      }}
                    >
                      Rating
                    </th>
                  </tr>

                  {Array.from({ length: 7 }, (_, i) => (
                    <tr>
                      <td>
                        <Skeleton width="100%" height={20} count={1} />
                      </td>
                      <td>
                        <Skeleton width="100%" height={20} count={1} />
                      </td>
                      <td>
                        <Skeleton width="100%" height={20} count={1} />
                      </td>
                      <td>
                        <Skeleton width="100%" height={20} count={1} />
                      </td>
                      <td>
                        <Skeleton width="100%" height={20} count={1} />
                      </td>
                      <td>
                        <Skeleton width="100%" height={20} count={1} />
                      </td>

                      <td>
                        <Skeleton width="100%" height={20} count={1} />
                      </td>
                      <td>
                        <Skeleton width="100%" height={20} count={1} />
                      </td>
                      <td>
                        <Skeleton width="100%" height={20} count={1} />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
            {!isloadingTable && (
              <table ref={componentRef_1} style={{ width: "100%" }}>
                <tbody>
                  <tr>
                    <th
                      style={{
                        background: "#d0d8e2",
                        color: "#253978",
                        width: "2%",
                        textAlign: "center",
                      }}
                    ></th>

                    <th
                      style={{
                        background: "#d0d8e2",
                        color: "#253978",
                        width: "29%",
                      }}
                    >
                      Vendor Name
                    </th>
                    <th
                      style={{
                        textAlign: "center",
                        background: "#d0d8e2",
                        color: "#253978",
                        width: "13%",
                      }}
                    >
                      Vendor ID
                    </th>
                    <th
                      style={{
                        textAlign: "center",
                        background: "#d0d8e2",
                        color: "#253978",
                        width: "12%",
                      }}
                    >
                      Region
                    </th>
                    <th
                      style={{
                        textAlign: "center",
                        background: "#d0d8e2",
                        color: "#253978",
                        width: "12%",
                      }}
                    >
                      Country
                    </th>
                    <th
                      style={{
                        textAlign: "center",
                        background: "#d0d8e2",
                        color: "#253978",
                        width: "14%",
                      }}
                    >
                      Residual Risk
                    </th>
                    <th
                      style={{
                        textAlign: "center",
                        background: "#d0d8e2",
                        color: "#253978",
                        width: "14%",
                      }}
                    >
                      Inherent Risk
                    </th>
                    <th
                      style={{
                        textAlign: "center",
                        background: "#d0d8e2",
                        color: "#253978",
                        width: "10%",
                      }}
                    >
                      Bitsight
                    </th>
                    <th
                      style={{
                        textAlign: "center",
                        background: "#d0d8e2",
                        color: "#253978",
                        width: "4%",
                      }}
                    >
                      Rating
                    </th>
                  </tr>

                  {items.length !== 0 && (
                    <>
                      {items.map((item, index) => (
                        <tr>
                          <td>
                            <div class="form-check">
                              <input
                                ref={(element) => { ref.current[index] = element }}
                                class="form-check-input checkbox"
                                type="checkbox"
                                value={item.vnct}
                                id={`flexCheckDefault_` + index}
                                defaultChecked={false}
                                onChange={handleCheck}
                              />
                            </div>
                          </td>

                          <td style={{ fontWeight: "600" }} class="vendor_risk">
                            <Link
                             title= {capitalizeWords(item.vnct)}
                              style={{
                                width: "100%",
                                background: "transparent",
                              }}
                              to={`/vendor?name=${item.vnct}`}
                            >
                              {capitalizeWords(item.vnct)}
                            </Link>
                          </td>

                          <td style={{ fontWeight: "normal" }}>
                            <span
                              style={{
                                display: "grid",
                                justifyContent: "center",
                              }}
                            >
                              <span class="vendor_risk_items_cls">
                                {item.VendorID}
                              </span>
                            </span>
                          </td>

                          <td style={{ fontWeight: "normal" }}>
                            <span
                              style={{
                                display: "grid",
                                justifyContent: "center",
                              }}
                            >
                              <span class="vendor_risk_items_cls">
                                {item.Region === " " ? "N/A" : item.Region}
                              </span>
                            </span>
                          </td>

                          <td style={{ fontWeight: "normal" }}>
                            <span
                              style={{
                                display: "grid",
                                justifyContent: "center",
                              }}
                            >
                              <span class="vendor_risk_items_cls">
                                {item.Country}
                              </span>
                            </span>
                          </td>

                          <td style={{ fontWeight: "normal" }}>
                            <span
                              style={{
                                display: "grid",
                                justifyContent: "center",
                              }}
                            >
                              {item.Rating === "Low" ? (
                                <div class="vendor_risk_items_low vendor_loc itemsFlex">
                                  <span>{item.Rating}</span>
                                </div>
                              ) : item.Rating === "High" ? (
                                <div class="vendor_risk_items_high vendor_loc itemsFlex">
                                  <span>{item.Rating}</span>
                                </div>
                              ) : item.Rating === "Moderate" ? (
                                <div class="vendor_risk_items_modrate vendor_loc itemsFlex">
                                  <span>{item.Rating}</span>
                                </div>
                              ) : item.Rating === "Very Low" ? (
                                <div class="vendor_risk_items_veryLow vendor_loc itemsFlex">
                                  <span>{item.Rating}</span>
                                </div>
                              ) : (
                                <div class="vendor_risk_items_critical vendor_loc itemsFlex">
                                  <span style={{background:"transparent",padding:"0px"}} className="vendor_risk_items_cls">
                                    {item.Rating === "NA_in_Archer"
                                      ? "NA In Archer"
                                      : item.Rating}
                                  </span>
                                </div>
                              )}
                            </span>
                          </td>

                          <td style={{ fontWeight: "normal" }}>
                            <span
                              style={{
                                display: "grid",
                                justifyContent: "center",
                              }}
                            >
                              {item.InherentRiskRating === "Low" ? (
                                <div class="vendor_risk_items_low vendor_loc itemsFlex">
                                  <span>{item.InherentRiskRating}</span>
                                </div>
                              ) : item.InherentRiskRating === "High" ? (
                                <div class="vendor_risk_items_high vendor_loc itemsFlex">
                                  <span>{item.InherentRiskRating}</span>
                                </div>
                              ) : item.InherentRiskRating === "Moderate" ? (
                                <div class="vendor_risk_items_modrate vendor_loc itemsFlex">
                                  <span>{item.InherentRiskRating}</span>
                                </div>
                              ) : item.InherentRiskRating === "Very Low" ? (
                                <div class="vendor_risk_items_veryLow vendor_loc itemsFlex">
                                  <span>{item.InherentRiskRating}</span>
                                </div>
                              ) : (
                                <div class="vendor_risk_items_critical vendor_loc itemsFlex">
                                  <span style={{background:"transparent",padding:"0px"}} className="vendor_risk_items_cls">
                                    {item.InherentRiskRating === "NA_in_Archer"
                                      ? "NA In Archer"
                                      : item.InherentRiskRating}
                                  </span>
                                </div>
                              )}
                            </span>
                          </td>

                          <td style={{ fontWeight: "normal" }}>
                            <span
                              style={{
                                display: "grid",
                                justifyContent: "center",
                              }}
                            >
                              <span className="vendor_risk_items_cls">
                                {item.BitSightScore === "NA_in_BitSight"
                                  ? "N/A"
                                  : item.BitSightScore}
                              </span>
                            </span>
                          </td>

                          <td>
                            <span
                              style={{
                                display: "grid",
                                justifyContent: "center",
                              }}
                            >
                              {item.Rating === "Low" ? (
                                <FaArrowDown color="green" fontSize={14} />
                              ) : item.Rating === "High" ? (
                                <FaArrowUp color="red" fontSize={14} />
                              ) : item.Rating === "Moderate" ? (
                                <FaArrowUp color="#FFA500" fontSize={14} />
                              ) : item.Rating === "Very Low" ? (
                                <FaArrowDown color="green" fontSize={14} />
                              ) : item.Rating === "Critical" ? (
                                <FaArrowUp color="red" fontSize={14} />
                              ) : (
                                <FaTimes color="red" fontSize={14} />
                              )}
                            </span>
                          </td>
                        </tr>
                      ))}
                    </>
                  )}

                  {items.length === 0 && (
                    <tr style={{ textAlign: "center" }}>
                      <td
                        style={{ textAlign: "center", color: "red" }}
                        rowSpan={4}
                        colSpan={9}
                      >
                        No Data available
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            )}
          </div>

          <div className="sidePannelFilter">
            {isloadingFilter && (
              <div
                style={{
                  height: "100%",
                  display: "grid",
                  alignItems: "center",
                  alignContent: "center",
                }}
              >
                <div className="PlaseWait">
                  <div className="spinner_class">
                    <Loading />
                  </div>
                  <div>Please wait sometime</div>
                </div>
              </div>
            )}

            {!isloadingFilter && (
              <div className="contentSections_1">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignContent: "center",
                    alignItems: "center",
                    gridGap: "10px",
                    padding: "1rem",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignContent: "center",
                      alignItems: "center",
                      gridGap: "10px",
                    }}
                  >
                    <span>
                      <FaCalendarAlt fontSize={"24px"} color="#253978" />
                    </span>
                    <span
                      style={{
                        fontSize: "18px",
                        fontWeight: "600",
                        paddingTop: "4px",
                        color: "#253978",
                      }}
                    >
                      {month} {Day}, {year}
                    </span>
                  </div>

                  <span onClick={closePanel}>
                    <FaTimesCircle color="#253978" />
                  </span>
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    padding: "1rem",
                    paddingBottom: "1rem",
                  }}
                >
                  <span
                    style={{
                      fontSize: "16px",
                      color: "#253978",
                      fontWeight: "600",
                    }}
                  >
                    Filter
                  </span>
                  <span
                    onClick={clearAll}
                    style={{
                      fontSize: "16px",
                      color: "#253978",
                      fontWeight: "600",
                      cursor: "pointer",
                    }}
                  >
                    Clear
                  </span>
                </div>

                <div
                  id="residualRisk"
                  style={{
                    paddingTop: "1rem",
                    borderBottom: "2px solid white",
                    padding: "1rem",
                  }}
                >
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <span
                      style={{
                        fontSize: "16px",
                        color: "#253978",
                        fontWeight: "600",
                      }}
                    >
                      Residual Risk
                    </span>
                    <span
                      id="residual_risk"
                      onClick={handleClick}
                      style={{
                        fontSize: "16px",
                        color: "#253978",
                        fontWeight: "600",
                        cursor: "pointer",
                      }}
                    >
                      {itemsFilters1 ? <FaAngleUp /> : <FaAngleDown />}
                    </span>
                  </div>

                  <div className="filterContent">
                    <div>
                      <div className="form-check">
                        <input
                          ref={residual_ref}
                          className="form-check-input"
                          type="radio"
                          name="flexRadioDefult_residual"
                          id={`flexRadioDefult_residual_`}
                          value=""
                          defaultChecked={true}
                          onChange={(e) => {
                            setFilterRiks("");
                          }}
                        />
                        <label
                          className="form-check-label"
                          htmlFor={`flexRadioDefult_residual_`}
                        >
                          None
                        </label>
                      </div>

                      {asandingByRisk.map((item, index) => (
                        <div key={index} className="form-check">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="flexRadioDefult_residual"
                            id={`flexRadioDefult_residual_${index}`}
                            value={item}
                            defaultChecked={false}
                            onChange={filterByResidual}
                            defaultValue={filterRisk}
                          />
                          <label
                            className="form-check-label"
                            htmlFor={`flexRadioDefult_residual_${index}`}
                          >
                            {item}
                          </label>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>

                <div
                  id="InherentRisk"
                  style={{
                    paddingTop: "1rem",
                    borderBottom: "2px solid white",
                    padding: "1rem",
                  }}
                >
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <span
                      style={{
                        fontSize: "16px",
                        color: "#253978",
                        fontWeight: "600",
                      }}
                    >
                      Inherent Risk
                    </span>
                    <span
                      id="inherentRisk_risk"
                      onClick={handleClick}
                      style={{
                        fontSize: "16px",
                        color: "#253978",
                        fontWeight: "600",
                        cursor: "pointer",
                      }}
                    >
                      {InherentRiskFilter ? <FaAngleUp /> : <FaAngleDown />}
                    </span>
                  </div>

                  <div className="filterContent">
                    <div>
                      <div className="form-check">
                        <input
                          ref={inherent_ref}
                          className="form-check-input"
                          type="radio"
                          name="flexRadioDefult_inherent"
                          id={`flexRadioDefult_inherent_`}
                          value=""
                          defaultChecked={true}
                          onChange={(e) => {
                            setfilterinhRisk("");
                          }}
                        />
                        <label
                          className="form-check-label"
                          htmlFor={`flexRadioDefult_inherent_`}
                        >
                          None
                        </label>
                      </div>

                      {inherentRiskItem.map((item, index) => (
                        <div key={index} className="form-check">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="flexRadioDefult_inherent"
                            id={`flexRadioDefult_inherent_${index}`}
                            value={item}
                            defaultChecked={false}
                            onChange={filterByinherent}
                            defaultValue={filterinhRisk}

                          />
                          <label
                            className="form-check-label"
                            htmlFor={`flexRadioDefult_inherent_${index}`}
                          >
                            {item}
                          </label>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>

                <div
                  id="BitsightScore"
                  style={{
                    paddingTop: "1rem",
                    borderBottom: "2px solid white",
                    padding: "1rem",
                  }}
                >
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <span
                      style={{
                        fontSize: "16px",
                        color: "#253978",
                        fontWeight: "600",
                      }}
                    >
                      Bitsight Score
                    </span>
                    <span
                      id="BitsightScore_risk"
                      onClick={handleClick}
                      style={{
                        fontSize: "16px",
                        color: "#253978",
                        fontWeight: "600",
                        cursor: "pointer",
                      }}
                    >
                      {BitsightFilter ? <FaAngleUp /> : <FaAngleDown />}
                    </span>
                  </div>

                  <div className="filterContent">
                    <div>
                      <div className="form-check">
                        <input
                          ref={bitshight_ref}
                          className="form-check-input"
                          type="radio"
                          name="flexRadioDefult_BitsightScore"
                          id={`flexRadioDefult_BitsightScore_`}
                          value=""
                          defaultChecked={true}
                          onChange={(e) => {
                            setfilterBscore("");
                          }}
                        />
                        <label
                          className="form-check-label"
                          htmlFor={`flexRadioDefult_BitsightScore_`}
                        >
                          None
                        </label>
                      </div>

                      {BitsightRiskItem.map((item, index) => (
                        <div key={index} className="form-check">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="flexRadioDefult_BitsightScore"
                            id={`flexRadioDefult_BitsightScore_${index}`}
                            value={item}
                            defaultChecked={false}
                            onChange={filterByBitsightScore}
                            defaultValue={filterBscore}

                          />
                          <label
                            className="form-check-label"
                            htmlFor={`flexRadioDefult_BitsightScore_${index}`}
                          >
                            {item}
                          </label>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </>
      )}

      {error && (
        <div className="PlaseWait">
          <div className="spinner_class">
            {`There is a problem fetching the post data ${iserror}`}
          </div>
          <div>Please try after sometime</div>
        </div>
      )}

      {alertMessage && (
        <div style={{ position: "fixed", right: "40px", top: "90px" }}>
          <div class="alert alert-danger" role="alert">
            Please select only 2 options for comparing
          </div>
        </div>
      )}
    </div>
  </div>
);
};

export default Segment;
