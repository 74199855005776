import React, { useEffect, useState, useRef } from "react";
import {FaPlus, FaMinus } from "react-icons/fa";
import Skeleton from "react-loading-skeleton";
import $ from "jquery";
import Active_po_popup from "./Active_po_popup";

const contentStyle = {
  maxWidth: "700px",
  width: "100%",
  background:"white",
  borderRadius: "20px"
};

const ActiveContact = (props) => {
  const [ListofPo, setListofPo] = useState([
    {
        "VendorID": "15238510",
        "VendorName": "aays advisory private limited",
        "PO_Numbers": 4700100121,
        "SourceSystem": "AEP",
        "VendorNameCombTranslation": "aays advisory private limited",
        "Region": "Telangana",
        "Finished_Good_Values": 76563
    },
    {
        "VendorID": "15238510",
        "VendorName": "aays advisory private limited",
        "PO_Numbers": 4700195925,
        "SourceSystem": "AEP",
        "VendorNameCombTranslation": "aays advisory private limited",
        "Region": "Telangana",
        "Finished_Good_Values": 304200
    },
    {
        "VendorID": "15238510",
        "VendorName": "aays advisory private limited",
        "PO_Numbers": 4700214287,
        "SourceSystem": "AEP",
        "VendorNameCombTranslation": "aays advisory private limited",
        "Region": "Telangana",
        "Finished_Good_Values": 50000
    },
    {
        "VendorID": "15238510",
        "VendorName": "aays advisory private limited",
        "PO_Numbers": 4700210956,
        "SourceSystem": "AEP",
        "VendorNameCombTranslation": "aays advisory private limited",
        "Region": "Telangana",
        "Finished_Good_Values": 49000
    },
    {
        "VendorID": "15238510",
        "VendorName": "aays advisory private limited",
        "PO_Numbers": 4700166945,
        "SourceSystem": "AEP",
        "VendorNameCombTranslation": "aays advisory private limited",
        "Region": "Telangana",
        "Finished_Good_Values": 360000
    },
    {
        "VendorID": "15238510",
        "VendorName": "aays advisory private limited",
        "PO_Numbers": 4700204162,
        "SourceSystem": "AEP",
        "VendorNameCombTranslation": "aays advisory private limited",
        "Region": "Telangana",
        "Finished_Good_Values": 8000
    },
    {
        "VendorID": "15238510",
        "VendorName": "aays advisory private limited",
        "PO_Numbers": 4700074757,
        "SourceSystem": "AEP",
        "VendorNameCombTranslation": "aays advisory private limited",
        "Region": "Telangana",
        "Finished_Good_Values": 47750
    },
    {
        "VendorID": "15238510",
        "VendorName": "aays advisory private limited",
        "PO_Numbers": 4700234195,
        "SourceSystem": "AEP",
        "VendorNameCombTranslation": "aays advisory private limited",
        "Region": "Telangana",
        "Finished_Good_Values": 65000
    },
    {
        "VendorID": "15238510",
        "VendorName": "aays advisory private limited",
        "PO_Numbers": 4700187929,
        "SourceSystem": "AEP",
        "VendorNameCombTranslation": "aays advisory private limited",
        "Region": "Telangana",
        "Finished_Good_Values": 88000
    },
    {
        "VendorID": "15238510",
        "VendorName": "aays advisory private limited",
        "PO_Numbers": 4700211704,
        "SourceSystem": "AEP",
        "VendorNameCombTranslation": "aays advisory private limited",
        "Region": "Telangana",
        "Finished_Good_Values": 32500
    }
]);
  const [isLoading, setLoading] = useState(false);
  const [isError, setError] = useState(null);
  const [TogglePlusIons, setTogglePlusIons] = useState(true);
  const [noOfPos, setnoOfPos] = useState(10);


  const CollapseButton = () => {
    setTogglePlusIons((prev) => !prev);
    $("#Active_contract_id").slideToggle(0);
  };

  // const passtoken = JSON.parse(localStorage.getItem("okta-token-storage"))[
  //   "accessToken"
  // ]["accessToken"];

  const url = new URL(window.location.href);
  let Poname = props.Po_name;
  const getUser = encodeURIComponent(Poname);
  // const baseUrlPo = "/vrm/activepos?q=";
  // const encodebaseURLPO = `${baseUrlPo}${getUser}&limit=${noOfPos}`;

  // useEffect(() => {
  //   const fetchUsersdDetails = async () => {
  //     await fetch(`${encodebaseURLPO}`, {
  //       headers: {
  //         Authorization: `Bearer ${passtoken}`,
  //       },
  //     })
  //       .then((response) => {
  //         if (response.ok) {
  //           response.json().then((json) => {
  //             setListofPo(json);
  //             setError(null);
  //             setLoading(false);
  //           });
  //         }
  //       })
  //       .catch((err) => {
  //         setError(err);
  //         setListofPo(null);
  //         console.log(isError);
  //         setLoading(false);
  //       });
  //   };
  //   fetchUsersdDetails();
  // }, [baseUrlPo]);

  let dataPo = props.Link_to_Commercial_Contract;
  const dataValidation = Math.round(dataPo);

  console.log("dataValidation")
  console.log(dataValidation)

  console.log("dataPo")
  console.log(dataPo)

  console.log("ListofPo")
  console.log(ListofPo)

  


  const dataCheckNan = () => {
    if (dataPo === NaN) {
      return (dataPo = 0);
    }
  };

  dataCheckNan();
  
  return (
    <div>
      <div id="ActiveContact">
        <div
          className="Basic_Informations"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            alignContent: "center",
          }}
        >
          <div>{props.title}</div>
          <div
            className="toggleIonsPlusicons"
            style={{ cursor: "pointer", paddingRight: "10px" }}
            onClick={CollapseButton}
          >
            {TogglePlusIons ? (
              <FaMinus color="#c1c1c1" fontSize={20} />
            ) : (
              <FaPlus color="#c1c1c1" fontSize={20} />
            )}
          </div>
        </div>

        <br />
        <div id="Active_contract_id">
          {isLoading && <Skeleton height={50} count={1} />}

          {!isLoading && dataValidation === 0 && dataPo !== "NA_in_SAP" && (
            <div className="Active_contact">
              <table>
                <tbody>
                  <tr>
                    <td>Active Contract</td>

                    <td>
                      <span>Number Of Open Contract</span>
                      <span style={{ float: "right" }}></span>
                    </td>
                  </tr>
                  <tr>
                    <th style={{ width: "35%" }}>Serial No</th>
                    <th>Title of Content</th>
                  </tr>
                  <tr>
                    <td style={{ color: "red" }} colSpan={2}>
                      No data available
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          )}

          {!isLoading && dataValidation !== 0 && dataPo === "NA_in_SAP" && (
            <div className="Active_contact">
              <table>
                <tbody>
                  <tr>
                    <td>Active Contract</td>

                    <td>
                      <span>Number Of Open Contract</span>
                      <span style={{ float: "right" }}></span>
                    </td>
                  </tr>
                  <tr>
                    <th style={{ width: "35%" }}>Serial No</th>
                    <th>Title of Content</th>
                  </tr>
                  <tr>
                    <td style={{ color: "red" }} colSpan={2}>
                      Not Available
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          )}

          {!isLoading && dataValidation !== 0 && dataPo !== "NA_in_SAP" && (
            <div className="Active_contact">
              <table id="Active_table">
                <tbody>
                  <tr>
                    <td>Active Contract</td>

                    <td colSpan={2}>
                      <span>Number Of Open Contract</span>
                      <span style={{ float: "right" }}>
                          <Active_po_popup Link_to_Commercial_Contract_1={dataValidation} name={getUser}/>
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <th style={{ width: "35%" }}>Serial No</th>
                    <th>Values</th>
                    <th>Vendors PO Number</th>
                  </tr>
                  {ListofPo &&
                    ListofPo.map((items, index) => {
                      return (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{(items.Finished_Good_Values).toLocaleString("en-US")}</td>
                          <td>{items.PO_Numbers}</td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          )}

          {!isLoading && dataValidation >= 10 && (
            <span style={{ color: "red", padding: "10px" }} id="notes_data">
              *Note: Only first 10 open POs are represneted here
            </span>
          )}
        </div>

        {TogglePlusIons ? (
          <span></span>
        ) : (
          <span>Please click the right ( + ) icons for view the {props.title}</span>
        )}
      </div>
    </div>
  );
};

export default ActiveContact;
