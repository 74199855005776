import React from "react";

export default function CardBoxs(props) {

  const monthNames = ["January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December"
  ];
  var dateObj = new Date();
  var monthName_is = monthNames[dateObj.getMonth()]
  var year = dateObj.getFullYear()
  //console.log(dateObj,monthName_is);

  const ValidateIcons = props.Diffrence

  return (
    <div className="flex_box_div">
      <div className="card_box_new_div">
        <div className="cardBox_itmes_grid">
          <span
            style={{
              display: "flex",
              justifyContent: "space-between",
              gridGap: "30px",
              minWidth: "265px",
            }}
            className="no_of_vendors_text"
          >
            <span>{props.title}</span>
            <div className="annualCss">
              <div className="annualItmes">{props.text}</div>
            </div>
          </span>
          <span className="no_of_vendors_values">{props.numbers}</span>
          <span style={{display:"flex",justifyContent:"space-between"}} className="LastWeek_text ">
            <span className="SinceLastWeek">
              {" "}
              {props.LastUpdate}
            </span>

            <span className={props.classname} style={{display:"flex",gridGap:"5px",alignItems:"center",alignContent:"center",padding:"4px"}}>    
               <span >{props.Diffrence}</span>
               <span id={props.id}>{props.iconsData}</span>
            </span>
            
          </span>
        </div>
      </div>
    </div>
  );
}
