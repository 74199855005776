import React, { useState, useEffect, useRef } from "react";
import NavigarionBar from "../nav/NavigarionBar";
import SideMenu from "../nav/SideMenu";
import Loading from "../spinner/Loading";
import $ from "jquery";
import ReactPaginate from "react-paginate";
import { useReactToPrint } from "react-to-print";
import Logo from "../../img/WaterMark.png";
import { FaPrint } from "react-icons/fa";
import ReportGenerate from "./ReportGenerate";
import { Button } from "react-bootstrap";


const WorkflowReadOnly = () => {
    const [error, setError] = useState(false);
    const [iserror, setisError] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [isloading, setloading] = useState(false);
    const [items, setItems] = useState([
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2062751",
          "state": "Closed Complete",
          "Year": "2021.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "adt",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2020-11-26 06:11:23"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2069996",
          "state": "Closed Complete",
          "Year": "2021.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "parcellab",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2020-12-09 16:13:43"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2074524",
          "state": "Closed Complete",
          "Year": "2021.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "smartbol",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2020-12-24 15:05:39"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2075396",
          "state": "Closed Complete",
          "Year": "2021.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "links business services limited",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2020-12-03 06:01:07"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2077043",
          "state": "Closed Complete",
          "Year": "2021.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "eye see research",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2020-12-04 05:31:57"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2077700",
          "state": "Closed Complete",
          "Year": "2021.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "tiger pistol",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2021-08-06 06:34:02"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2080166",
          "state": "Incompleted",
          "Year": "2021.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "point to point communications",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2020-12-04 03:38:30"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2082691",
          "state": "Closed Complete",
          "Year": "2021.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "henry v",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2021-11-16 09:33:09"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2082730",
          "state": "Closed Complete",
          "Year": "2021.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "brightx solutions",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2020-12-18 15:43:27"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2084151",
          "state": "Closed Complete",
          "Year": "2021.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "skb kontur",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2020-12-11 09:50:27"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2086791",
          "state": "Closed Complete",
          "Year": "2021.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "dxc (tribridge holdings llc)",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2021-12-14 11:28:43"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2092032",
          "state": "Closed Complete",
          "Year": "2021.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "unit9",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-05-19 11:37:39"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2092157",
          "state": "Closed Complete",
          "Year": "2021.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "onelook",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-11-04 07:32:05"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2094411",
          "state": "Closed Complete",
          "Year": "2021.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "bright horizons",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-04-28 18:47:18"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2101788",
          "state": "Closed Complete",
          "Year": "2021.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "gralminas",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2021-11-16 10:37:08"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2107412",
          "state": "Closed Complete",
          "Year": "2021.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "teksystems global services llc",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2020-12-22 11:33:32"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2108847",
          "state": "Closed Complete",
          "Year": "2021.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "korus consulting cis",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2020-12-24 15:23:56"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2109294",
          "state": "Closed Complete",
          "Year": "2021.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "suitebriar",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2020-12-22 10:09:54"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2109953",
          "state": "Closed Complete",
          "Year": "2021.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "maxwell warehouse",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2021-01-15 07:22:45"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2109956",
          "state": "Closed Complete",
          "Year": "2021.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "mondiale warehouse",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2021-12-14 11:27:42"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2110440",
          "state": "Closed Complete",
          "Year": "2021.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "garden of words",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2021-03-16 09:53:27"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2110646",
          "state": "Closed Complete",
          "Year": "2021.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "2waycom communication & consulting",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2021-03-02 05:35:11"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2116393",
          "state": "Closed Complete",
          "Year": "2021.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "artezio",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2020-12-29 06:51:52"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2118360",
          "state": "Closed Complete",
          "Year": "2021.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "venise evenements",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2021-11-16 09:30:35"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2120108",
          "state": "Incompleted",
          "Year": "2021.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "moragro",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2021-05-10 14:39:12"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2121782",
          "state": "Closed Complete",
          "Year": "2021.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "grupo igo",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2021-04-02 07:20:19"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2122514",
          "state": "Closed Complete",
          "Year": "2021.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "express relocation",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2021-11-16 09:31:58"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2129057",
          "state": "Closed Complete",
          "Year": "2021.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "outsolve",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2021-01-14 13:55:31"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2133856",
          "state": "Closed Complete",
          "Year": "2021.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "w3 digital",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-07-29 07:12:42"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2140374",
          "state": "Closed Complete",
          "Year": "2021.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "stepup",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2021-11-16 09:34:09"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2141125",
          "state": "Closed Complete",
          "Year": "2021.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "logmein technologies : gotowebinar",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2021-01-22 14:07:16"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2141613",
          "state": "Closed Complete",
          "Year": "2021.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "black bamboo",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2021-11-16 10:27:03"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2143464",
          "state": "Closed Complete",
          "Year": "2021.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "acheron software consultancy pvt ltd",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2021-01-22 13:14:57"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2147329",
          "state": "Closed Complete",
          "Year": "2021.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "beekast",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-03-15 15:10:54"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2147659",
          "state": "Closed Complete",
          "Year": "2021.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "uab softera baltic",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2021-11-16 10:27:40"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2148567",
          "state": "Closed Complete",
          "Year": "2021.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "hold still inc (hypno)",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2021-02-22 07:25:14"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2151096",
          "state": "Closed Complete",
          "Year": "2021.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "aimpoint",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2021-11-16 10:28:19"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2156521",
          "state": "Closed Complete",
          "Year": "2021.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "tall oaks enterprises, llc (dba dr. andy roarke)",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2021-11-16 10:28:51"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2159567",
          "state": "Closed Complete",
          "Year": "2021.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "poppulo",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-03-15 15:10:39"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2164269",
          "state": "Annual Reassessment Completed",
          "Year": "2021",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "pathfynder inc.",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2021-11-16 10:29:50"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2185724",
          "state": "Closed Complete",
          "Year": "2021.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "tresensa",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2021-11-16 09:24:53"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2189554",
          "state": "Closed Complete",
          "Year": "2021.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "treasure data",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2021-03-07 18:21:39"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2195743",
          "state": "Incompleted",
          "Year": "2021.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "soft solutions, llc",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2021-06-07 05:04:14"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2196432",
          "state": "Closed Complete",
          "Year": "2021.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "freightwaves",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2021-02-19 12:35:19"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2199766",
          "state": "Closed Complete",
          "Year": "2021.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "eas strategies",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2021-02-23 11:23:05"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "Moderate",
          "approval": "N/A",
          "number": "RITM2204392",
          "state": "Annual Reassessment Completed",
          "Year": "2021",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "pia for willis towers watson",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2021-03-25 14:40:16"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2209481",
          "state": "Closed Complete",
          "Year": "2021.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "flexera",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2021-02-18 09:13:00"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2212969",
          "state": "Annual Reassessment Completed",
          "Year": "2021",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "evocon",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2021-11-16 10:32:45"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2217851",
          "state": "Closed Complete",
          "Year": "2021.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "delete / kagool",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2021-11-16 10:34:17"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2220167",
          "state": "Incompleted",
          "Year": "2021.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "valuein saas",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2021-09-28 09:40:24"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2229987",
          "state": "Closed Complete",
          "Year": "2021.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "oracle",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2021-03-03 06:31:03"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2230351",
          "state": "Closed Complete",
          "Year": "2021.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "maru matchbox",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2021-03-07 18:47:44"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2236140",
          "state": "Closed Complete",
          "Year": "2021.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "7rl",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2021-03-23 07:12:29"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2240775",
          "state": "Incompleted",
          "Year": "2021.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "beedeez",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2021-04-12 10:21:27"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2241489",
          "state": "Closed Complete",
          "Year": "2021.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "ch couponing house gmbh",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-03-15 15:08:01"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2242571",
          "state": "Annual Reassessment Completed",
          "Year": "2021",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "bc intermedia sp. z o.o.",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-03-15 15:10:46"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2243041",
          "state": "Closed Complete",
          "Year": "2021.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "start web solution",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2021-03-24 14:37:39"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2244798",
          "state": "Closed Complete",
          "Year": "2021.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "steadfast freight management pty ltd",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2021-11-16 10:39:44"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2245892",
          "state": "Closed Complete",
          "Year": "2021.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "buena media plus",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2021-12-14 11:31:17"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2247369",
          "state": "Closed Complete",
          "Year": "2021.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "assembly",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2021-04-14 10:15:15"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2250965",
          "state": "Annual Reassessment Completed",
          "Year": "2021",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "2021_ebest mobile(shanghai)) technology",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2021-03-26 09:51:25"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2252060",
          "state": "Closed Complete",
          "Year": "2021.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "energy bbdo",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-11-01 12:35:23"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2255286",
          "state": "Annual Reassessment Completed",
          "Year": "2021",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "eventbuilder",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2021-03-17 12:52:29"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2260179",
          "state": "Closed Complete",
          "Year": "2021.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "innoleaps",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2021-08-24 11:02:47"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2266124",
          "state": "Closed Complete",
          "Year": "2021.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "teleperformance",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2021-04-02 07:18:13"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2271949",
          "state": "Closed Complete",
          "Year": "2021.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "kanbanize",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2021-04-09 07:38:22"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2281060",
          "state": "Closed Complete",
          "Year": "2021.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "diennea s.r.l.",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2021-04-22 10:01:11"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2283869",
          "state": "Closed Complete",
          "Year": "2021.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "solistica",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2021-11-16 10:48:56"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2283946",
          "state": "Closed Complete",
          "Year": "2021.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "risk based security",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2021-11-16 10:49:32"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2284189",
          "state": "Closed Complete",
          "Year": "2021.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "kianos",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2021-03-31 07:23:53"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2294848",
          "state": "Closed Complete",
          "Year": "2021.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "keelvar",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2021-09-30 19:30:53"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2297577",
          "state": "Closed Complete",
          "Year": "2021.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "retail insights",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2021-11-16 10:50:04"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2299767",
          "state": "Incompleted",
          "Year": "2021.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "ип  цвирко",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2021-06-28 06:35:23"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2301079",
          "state": "Closed Complete",
          "Year": "2021.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "niceincontact",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2021-04-08 14:48:06"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2302992",
          "state": "Closed Complete",
          "Year": "2021.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "branding brand",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2021-11-16 10:50:24"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2304142",
          "state": "Closed Complete",
          "Year": "2021.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "promotion mechanics",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2021-11-16 10:50:58"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2309229",
          "state": "Closed Complete",
          "Year": "2021.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "rival technologies",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2021-04-09 14:28:01"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2311041",
          "state": "Closed Complete",
          "Year": "2021.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "verisk 3e",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2021-11-16 10:54:03"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2311228",
          "state": "Closed Complete",
          "Year": "2021.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "wildpet company ltd (futerra)",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2021-11-16 10:53:18"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2311745",
          "state": "Closed Complete",
          "Year": "2021.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "global logic",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2021-05-01 19:31:35"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2314628",
          "state": "Closed Complete",
          "Year": "2021.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "innovación media",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2021-11-16 10:54:30"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2314654",
          "state": "Annual Reassessment Completed",
          "Year": "2021",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "direct 2 pet",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2021-11-16 10:54:55"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2315600",
          "state": "Annual Reassessment Completed",
          "Year": "2021",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "metapack",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2021-11-16 10:55:22"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2317568",
          "state": "Closed Complete",
          "Year": "2021.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "tpg",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2021-11-16 10:57:28"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2317589",
          "state": "Annual Reassessment In Progress",
          "Year": "2021",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "hireright ",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-04-28 18:59:07"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2318201",
          "state": "Incompleted",
          "Year": "2021.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "forgerock",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2021-09-28 09:49:18"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2320669",
          "state": "Closed Complete",
          "Year": "2021.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "elumina pty ltd",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2021-04-26 08:11:01"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2325182",
          "state": "Closed Complete",
          "Year": "2021.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "gutzmann gmbh",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2021-11-16 10:57:52"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2332045",
          "state": "Closed Complete",
          "Year": "2021.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "mouragro",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2021-11-16 10:59:09"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2332090",
          "state": "Closed Complete",
          "Year": "2021.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "brightcove",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2021-11-16 10:59:42"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2335872",
          "state": "Closed Complete",
          "Year": "2021.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "tecman",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2021-04-22 14:13:09"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2336699",
          "state": "Incompleted",
          "Year": "2021.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "e-iceblue",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2021-09-28 09:56:15"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2339937",
          "state": "Closed Complete",
          "Year": "2021.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "solace corporation",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2021-11-16 11:01:12"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2343436",
          "state": "Closed Complete",
          "Year": "2021.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "linksafe",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2021-11-16 11:01:42"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2345003",
          "state": "Closed Complete",
          "Year": "2021.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "transplace",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2021-09-30 09:29:57"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2348389",
          "state": "Closed Complete",
          "Year": "2021.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "riskonnect",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-10-25 14:02:24"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2350652",
          "state": "Annual Reassessment In Progress",
          "Year": "2021",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "ixperiential  ",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2021-05-04 09:49:11"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2351487",
          "state": "Closed Complete",
          "Year": "2021.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "applicate it solutions",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2021-05-11 09:44:14"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2353150",
          "state": "Closed Complete",
          "Year": "2021.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "anaplan",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2021-05-11 05:15:07"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2353289",
          "state": "Closed Complete",
          "Year": "2021.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "wrike",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2021-05-04 06:14:06"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2354955",
          "state": "Closed Complete",
          "Year": "2021.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "evotek",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2021-11-16 11:02:37"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2355591",
          "state": "Closed Complete",
          "Year": "2021.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "b live llc",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2021-11-16 11:02:10"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2361976",
          "state": "Closed Complete",
          "Year": "2021.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "three plus group oy   tekniikantie 12, 02150 espoo, finland",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2021-11-16 11:03:50"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2362453",
          "state": "Closed Complete",
          "Year": "2021.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "informatica",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-01-10 06:24:01"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2363379",
          "state": "Incompleted",
          "Year": "2021.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "acseo",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-09-05 07:23:19"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2363395",
          "state": "Incompleted",
          "Year": "2021.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "plan net",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2021-09-28 11:08:23"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2364810",
          "state": "Closed Complete",
          "Year": "2021.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "stibo",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2021-05-11 12:10:51"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2366569",
          "state": "Closed Complete",
          "Year": "2021.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "iri",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2021-10-26 19:53:26"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2367818",
          "state": "Closed Complete",
          "Year": "2021.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "sitra – servicio integral de transporte",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2021-05-13 10:43:34"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2374933",
          "state": "Closed Complete",
          "Year": "2021.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "huge",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2021-11-16 11:04:17"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2380991",
          "state": "Closed Complete",
          "Year": "2021.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "valtech",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2021-05-18 15:27:26"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2382326",
          "state": "Incompleted",
          "Year": "2021.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "bbdo (main agency) / tool (external development vendor)",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2021-09-28 10:01:40"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2383423",
          "state": "Closed Complete",
          "Year": "2021.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "rubius tech lcc",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2021-11-16 11:05:25"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2385060",
          "state": "Closed Complete",
          "Year": "2021.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "rapidit-cloudbera",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2021-11-16 11:06:10"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2388542",
          "state": "Closed Complete",
          "Year": "2021.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "vuealta",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2021-11-16 11:06:37"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2389889",
          "state": "Incompleted",
          "Year": "2021.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "active - infra",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2021-09-28 11:07:58"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2396526",
          "state": "Closed Complete",
          "Year": "2021.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "bzz production",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2021-11-16 11:08:48"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2397366",
          "state": "Closed Complete",
          "Year": "2021.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "畅移（上海）信息科技有限公司 changyi (shanghai) information technology co., ltd.",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2021-05-31 12:08:00"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2398031",
          "state": "Closed Complete",
          "Year": "2021.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "flipnewmedia",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2021-11-16 11:09:10"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2401747",
          "state": "Closed Complete",
          "Year": "2021.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "appcheck",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2021-06-02 20:39:27"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2403012",
          "state": "Closed Complete",
          "Year": "2021.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "biomerieux",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2021-05-31 06:52:52"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2404733",
          "state": "Closed Complete",
          "Year": "2021.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "po2go",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2021-05-31 07:15:52"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2409712",
          "state": "Closed Complete",
          "Year": "2021.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "llc cpt basis  mars atlas id 15163500",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2021-06-03 14:14:09"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2410768",
          "state": "Closed Complete",
          "Year": "2021.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "mobile telesystems pjsc",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2021-06-01 16:28:38"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2414669",
          "state": "Closed Complete",
          "Year": "2021.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "constrcutor.io",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2021-07-01 15:07:28"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2414689",
          "state": "Closed Complete",
          "Year": "2021.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "ultria inc. dba verdantis",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2021-11-16 11:12:39"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2427905",
          "state": "Closed Complete",
          "Year": "2021.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "bank of america",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2021-11-16 11:13:01"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2428720",
          "state": "Closed Complete",
          "Year": "2021.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "tecra systems inc.",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2021-06-18 13:39:42"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2430143",
          "state": "Closed Complete",
          "Year": "2021.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "anomali",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2021-06-11 08:14:35"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2431769",
          "state": "Incompleted",
          "Year": "2021.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "iconecwave",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2021-09-28 11:10:09"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2431829",
          "state": "Annual Reassessment In Progress",
          "Year": "2021",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "onesource virtual (osv) ",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2021-06-14 13:57:16"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2432540",
          "state": "Closed Complete",
          "Year": "2021.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "ada.cx",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2021-06-14 15:21:46"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2438216",
          "state": "Closed Complete",
          "Year": "2021.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "cybergrx",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2021-11-16 11:13:46"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2440620",
          "state": "Closed Complete",
          "Year": "2021.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "nais",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2021-09-20 15:10:31"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2443762",
          "state": "Closed Complete",
          "Year": "2021.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "amplience",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2021-06-18 12:54:29"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2444281",
          "state": "Closed Complete",
          "Year": "2021.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "kofax",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2021-07-13 05:25:55"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2444696",
          "state": "Closed Complete",
          "Year": "2021.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "nicus",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-08-09 16:12:56"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2445754",
          "state": "Annual Reassessment In Progress",
          "Year": "2021",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "akeneo ",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2021-10-01 06:00:01"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2447541",
          "state": "Closed Complete",
          "Year": "2021.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "consensus interactive",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2021-06-23 05:31:36"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2460813",
          "state": "Closed Complete",
          "Year": "2021.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "javis technologies private limited",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2021-06-22 14:47:59"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2470408",
          "state": "Closed Complete",
          "Year": "2021.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "广州智选网络科技有限公司 smartgo technology inc.",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2021-06-25 08:47:04"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2471350",
          "state": "Closed Complete",
          "Year": "2021.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "valuein",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2021-11-16 11:17:04"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2471770",
          "state": "Closed Complete",
          "Year": "2021.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "globalvetlink",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2021-11-16 11:17:36"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2478301",
          "state": "Closed Complete",
          "Year": "2021.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "secureview",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2021-07-13 06:36:46"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2491593",
          "state": "Closed Complete",
          "Year": "2021.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "market logic",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2021-11-16 11:21:23"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2492654",
          "state": "Closed Complete",
          "Year": "2021.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "advantix thinaer llc",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2021-11-16 11:21:53"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2493090",
          "state": "Closed Complete",
          "Year": "2021.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "dynatrace",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2021-07-26 12:46:58"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2499318",
          "state": "Closed Complete",
          "Year": "2021.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "shufflrr.com",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2021-07-12 12:45:54"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2500456",
          "state": "Closed Complete",
          "Year": "2021.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "billwerk",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2021-11-16 11:22:39"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2508899",
          "state": "Closed Complete",
          "Year": "2021.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "dqe software",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2021-11-16 11:23:05"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2524354",
          "state": "Closed Complete",
          "Year": "2021.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "auditoire",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2021-11-16 11:24:03"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2525700",
          "state": "Annual Reassessment Completed",
          "Year": "2021",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "pop up mob llc",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2021-11-16 11:24:23"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2527533",
          "state": "Closed Complete",
          "Year": "2021.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "lti infotech",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2021-09-20 15:09:47"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2530652",
          "state": "Annual Reassessment Completed",
          "Year": "2021",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "pagerduty pia",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2021-08-02 05:24:51"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2530666",
          "state": "Closed Complete",
          "Year": "2021.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "bitsight",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-07-15 12:15:42"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2535207",
          "state": "Closed Complete",
          "Year": "2021.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "brightsign/brightauthor",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2021-11-16 11:24:43"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2535650",
          "state": "Closed Complete",
          "Year": "2021.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "salesforce datorama",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-03-02 07:03:44"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2538664",
          "state": "Closed Complete",
          "Year": "2021.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "avanzed cloud develop, s.l. - ebolution",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2021-11-16 11:25:15"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2541187",
          "state": "Incompleted",
          "Year": "2021.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "surfstudio llc",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2021-09-28 11:11:26"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2547390",
          "state": "Closed Complete",
          "Year": "2021.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "photon",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2021-08-05 05:46:01"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2553830",
          "state": "Closed Complete",
          "Year": "2021.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "ds smith packaging limited",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2021-10-06 09:11:27"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2564082",
          "state": "Closed Complete",
          "Year": "2021.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "hh associates limited",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2021-09-13 06:07:21"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2567864",
          "state": "Annual Reassessment In Progress",
          "Year": "2021",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "duggal cms pia ",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2021-11-16 11:25:46"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2575550",
          "state": "Closed Complete",
          "Year": "2021.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "iesa",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2021-11-16 11:26:40"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2577345",
          "state": "Incompleted",
          "Year": "2021.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "14four.com",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2021-09-21 12:16:44"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2578289",
          "state": "Incompleted",
          "Year": "2021.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "ten feet tall",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2021-10-07 08:24:24"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2585005",
          "state": "Closed Complete",
          "Year": "2021.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "delego",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2021-11-16 11:26:59"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2585292",
          "state": "Closed Complete",
          "Year": "2021.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "castor edc",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2021-10-06 09:07:41"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2585368",
          "state": "Closed Complete",
          "Year": "2021.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "algonomy",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2021-11-16 11:27:18"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2585506",
          "state": "Closed Complete",
          "Year": "2021.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "brainsonic",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2021-11-16 14:48:10"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2586391",
          "state": "Incompleted",
          "Year": "2021.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "brazen",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2021-10-29 09:05:19"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2586462",
          "state": "Closed Complete",
          "Year": "2021.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "sai",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2021-08-23 14:42:34"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2586550",
          "state": "Closed Complete",
          "Year": "2021.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "majorel /  arvato",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-03-15 10:15:47"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2588349",
          "state": "Annual Reassessment Completed",
          "Year": "2021",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "mars",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2021-08-24 14:32:47"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2590599",
          "state": "Closed Complete",
          "Year": "2021.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "tryon solutions",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2021-11-16 11:27:35"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2591268",
          "state": "Annual Reassessment In Progress",
          "Year": "2021",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "randstad enterprise d/b/a randstad sourceright ",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2021-11-16 11:28:37"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2592472",
          "state": "Closed Complete",
          "Year": "2021.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "yimian",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2021-10-21 13:55:27"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2592492",
          "state": "Closed Complete",
          "Year": "2021.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "wolf learning consulting",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2021-10-05 07:57:54"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2593521",
          "state": "Closed Complete",
          "Year": "2021.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "logicsource",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2021-08-24 04:14:50"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2594876",
          "state": "Closed Complete",
          "Year": "2021.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "channable",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2021-09-02 14:21:27"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2605591",
          "state": "Closed Complete",
          "Year": "2021.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "causemedia group dba matchfire",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2021-09-01 05:06:50"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2609531",
          "state": "Closed Complete",
          "Year": "2021.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "ray soft",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2021-10-27 18:53:19"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2612149",
          "state": "Closed Complete",
          "Year": "2021.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "adp france",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2021-09-22 16:03:06"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2618178",
          "state": "Incompleted",
          "Year": "2021.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "ооо \"темма\"",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2021-10-21 12:37:23"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2626347",
          "state": "Closed Complete",
          "Year": "2021.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "靖达（北京）国际商务会展有限公司  the east star",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2021-09-15 10:28:38"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2630487",
          "state": "Closed Complete",
          "Year": "2021.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "cyberway",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-10-13 10:55:20"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2635018",
          "state": "Annual Reassessment Completed",
          "Year": "2021",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "exabeam poc",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-04-25 07:08:00"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2638833",
          "state": "Closed Complete",
          "Year": "2021.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "hub brasil",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2021-09-24 08:36:40"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2643507",
          "state": "Closed Complete",
          "Year": "2021.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "it strategy",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2021-09-15 05:30:06"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2658299",
          "state": "Closed Complete",
          "Year": "2021.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "sciencelogic",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2021-09-29 07:04:05"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2658503",
          "state": "Annual Reassessment Completed",
          "Year": "2021",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "bonusly",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2021-09-17 06:13:57"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2659295",
          "state": "Closed Complete",
          "Year": "2021.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "akkomplish",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2021-09-21 09:21:05"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2660228",
          "state": "Closed Complete",
          "Year": "2021.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "webershandwick",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2021-10-08 10:55:55"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2661318",
          "state": "Incompleted",
          "Year": "2021.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "zuora",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2021-11-01 05:46:09"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2662615",
          "state": "Closed Complete",
          "Year": "2021.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "liantis",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2021-09-29 06:07:02"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2664000",
          "state": "Closed Complete",
          "Year": "2021.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "netspi",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2021-09-24 06:57:38"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2683209",
          "state": "Closed Complete",
          "Year": "2021.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "bask agency",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2021-09-28 09:09:25"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2683951",
          "state": "Closed Complete",
          "Year": "2021.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "insight enterprises",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-09-05 07:18:23"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2684069",
          "state": "Closed Complete",
          "Year": "2021.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "omegapoint stockholm ab",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2021-10-18 10:49:04"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2685068",
          "state": "Incompleted",
          "Year": "2021.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "avalara",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2021-11-09 07:52:12"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2685072",
          "state": "Closed Complete",
          "Year": "2021.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "senior solutions",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2021-09-30 08:57:06"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2685135",
          "state": "Closed Complete",
          "Year": "2021.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "optiv",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2021-10-01 07:59:13"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2693089",
          "state": "Closed Complete",
          "Year": "2021.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "cloudiq",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2021-10-20 11:47:16"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2695037",
          "state": "Closed Complete",
          "Year": "2021.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "bnp cardif",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2021-09-30 06:45:30"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2699651",
          "state": "Incompleted",
          "Year": "2021.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "test 1",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2021-09-30 15:40:46"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2700710",
          "state": "Annual Reassessment Completed",
          "Year": "2021",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "lab digital",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2021-10-06 05:54:32"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2702327",
          "state": "Annual Reassessment Completed",
          "Year": "2021",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "tungsten network project",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2021-10-04 05:53:19"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2702896",
          "state": "Closed Complete",
          "Year": "2021.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "dssmith",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2021-10-06 06:59:18"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2702996",
          "state": "Annual Reassessment In Progress",
          "Year": "2021",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "citibank pai 2021 ",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2021-10-27 09:13:58"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2706134",
          "state": "Closed Complete",
          "Year": "2021.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "benby retail inc",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-06-08 10:21:24"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2708636",
          "state": "Closed Complete",
          "Year": "2021.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "ans digital",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-04-11 09:36:24"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2710074",
          "state": "Closed Complete",
          "Year": "2021.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "gladwells",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2021-10-08 07:15:54"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2713117",
          "state": "Annual Reassessment In Progress",
          "Year": "2021",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "automatic data processing pte ltd  ",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2021-10-08 14:28:02"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2713248",
          "state": "Annual Reassessment Completed",
          "Year": "2021",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "ivanti software",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2021-10-13 07:54:51"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2714755",
          "state": "Closed Complete",
          "Year": "2021.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "gazum business",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2021-10-29 10:12:51"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2718671",
          "state": "Closed Complete",
          "Year": "2021.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "celonis",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2021-10-08 13:58:03"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2724857",
          "state": "Closed Complete",
          "Year": "2021.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "cumbreco s.r.l. (simpleway)",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2021-10-21 14:46:11"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2726548",
          "state": "Closed Complete",
          "Year": "2021.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "dacker",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2021-10-20 12:02:03"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2727086",
          "state": "Closed Complete",
          "Year": "2021.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "digitalone",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2021-10-29 12:55:08"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2728993",
          "state": "Closed Complete",
          "Year": "2021.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "it forest",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2021-10-29 10:12:27"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2732916",
          "state": "Annual Reassessment Completed",
          "Year": "2021",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "learningbank",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2021-10-19 07:10:28"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2735278",
          "state": "Closed Complete",
          "Year": "2021.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "articul production",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2021-11-17 14:40:55"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2735624",
          "state": "Closed Complete",
          "Year": "2021.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "picasel",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2021-10-29 13:07:45"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2736154",
          "state": "Closed Complete",
          "Year": "2021.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "iconec wave",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2021-10-27 08:19:46"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2738923",
          "state": "Incompleted",
          "Year": "2021.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "r2win",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-01-21 08:13:20"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2741934",
          "state": "Closed Complete",
          "Year": "2021.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "thaimediaplus company limited",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2021-10-29 10:13:16"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2743013",
          "state": "Closed Complete",
          "Year": "2021.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "applearn",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2021-10-20 15:56:55"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2744133",
          "state": "Closed Complete",
          "Year": "2021.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "rsa",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2021-10-21 15:06:41"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2747150",
          "state": "Annual Reassessment Completed",
          "Year": "2021",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "lyra - vca",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2021-10-22 07:06:09"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2747551",
          "state": "Annual Reassessment Completed",
          "Year": "2021",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "concur - vca",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2021-11-10 08:32:13"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2750877",
          "state": "Closed Complete",
          "Year": "2021.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "致得科创软件（北京）有限公司 zidesoft (beijing)co., ltd",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2021-10-26 09:38:39"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2752939",
          "state": "Annual Reassessment Completed",
          "Year": "2021",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "servicenow ppm pia",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2021-10-29 12:44:00"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2756419",
          "state": "Closed Complete",
          "Year": "2021.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "xpo brand",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2021-11-11 12:52:44"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2761910",
          "state": "Incompleted",
          "Year": "2021.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "vetsafe",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-01-03 06:20:24"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2761997",
          "state": "Closed Complete",
          "Year": "2021.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "ping castle",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2021-11-19 10:22:55"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2767904",
          "state": "Incompleted",
          "Year": "2021.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "app orchid",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2021-10-28 06:10:19"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2776747",
          "state": "Closed Complete",
          "Year": "2021.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "r2",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2021-11-08 08:55:58"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2791959",
          "state": "Closed Complete",
          "Year": "2021.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "thinkerbell",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2021-11-01 09:20:11"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2811435",
          "state": "Closed Complete",
          "Year": "2021.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "inkcups",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2021-11-10 07:24:56"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2816944",
          "state": "Closed Complete",
          "Year": "2021.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "xforceplus",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2021-11-26 07:07:48"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2817334",
          "state": "Closed Complete",
          "Year": "2021.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "sparktg",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2021-11-24 08:55:10"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2826711",
          "state": "Closed Complete",
          "Year": "2021.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "crea",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2021-11-15 12:50:35"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2827432",
          "state": "Closed Complete",
          "Year": "2021.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "cirkle",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2021-11-12 15:27:31"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2827444",
          "state": "Closed Complete",
          "Year": "2021.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "repsly",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-05-12 09:11:27"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2827501",
          "state": "Closed Complete",
          "Year": "2021.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "teamtailor",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2021-11-15 11:19:45"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2832466",
          "state": "Closed Complete",
          "Year": "2021.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "thetland",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2021-12-17 08:59:03"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2832485",
          "state": "Annual Reassessment Completed",
          "Year": "2021",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "hashtings",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2021-11-16 13:06:11"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2837929",
          "state": "Annual Reassessment Completed",
          "Year": "2022",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "vonage",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-09-13 19:16:57"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2839898",
          "state": "Closed Complete",
          "Year": "2022.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "pollen consulting group",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2021-11-22 05:55:21"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2859521",
          "state": "Incompleted",
          "Year": "2022.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "icomplai ug",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-09-05 07:22:29"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2865405",
          "state": "Closed Complete",
          "Year": "2022.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "vnomic",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2021-11-26 07:37:15"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2869126",
          "state": "Closed Complete",
          "Year": "2022.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "sonora4",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2021-12-01 16:09:34"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2869548",
          "state": "Closed Complete",
          "Year": "2022.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "petpaw",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2021-12-06 14:14:48"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2881789",
          "state": "Closed Complete",
          "Year": "2022.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "benby",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-09-13 19:41:55"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2888812",
          "state": "Closed Complete",
          "Year": "2022.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "hurraytech",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2021-12-06 12:16:16"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2892589",
          "state": "Closed Complete",
          "Year": "2022.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "emaint",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-01-03 15:04:22"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2893747",
          "state": "Closed Complete",
          "Year": "2022.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "attainment holdco, llc dba \"instride\"",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-01-04 07:41:08"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2896846",
          "state": "Closed Complete",
          "Year": "2022.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "prs invivo",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2021-12-09 11:31:42"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2897027",
          "state": "Closed Complete",
          "Year": "2022.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "ddb chicago",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2021-12-21 10:29:33"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2899112",
          "state": "Closed Complete",
          "Year": "2022.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "vitesse group",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2021-12-15 09:04:58"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2901184",
          "state": "Closed Complete",
          "Year": "2022.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "smartcom  sap atlas id 15163837",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2021-12-13 11:35:47"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2904976",
          "state": "Closed Complete",
          "Year": "2022.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "empire business system",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2021-12-14 06:07:21"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2905221",
          "state": "Closed Complete",
          "Year": "2022.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "botree",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2021-12-14 13:52:08"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2906284",
          "state": "Closed Complete",
          "Year": "2022.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "synapcom",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2021-12-15 08:44:33"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2907856",
          "state": "Closed Complete",
          "Year": "2022.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "tsa the station agency",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2021-12-16 04:23:24"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2918642",
          "state": "Closed Complete",
          "Year": "2022.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "seenovate sas",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-01-16 10:46:53"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2919063",
          "state": "Closed Complete",
          "Year": "2022.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "vcg the promorisk people ltd.",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2021-12-22 07:33:45"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2919721",
          "state": "Closed Complete",
          "Year": "2022.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "business for social responsibility",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2021-12-20 15:08:12"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2922224",
          "state": "Closed Complete",
          "Year": "2022.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "ptc, inc.",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2021-12-22 07:25:43"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2922287",
          "state": "Incompleted",
          "Year": "2022.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "sitecore",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-03-10 06:50:48"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2924292",
          "state": "Closed Complete",
          "Year": "2022.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "doublek llc",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2021-12-22 08:10:33"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2929057",
          "state": "Closed Complete",
          "Year": "2022.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "space online genesis llc",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2021-12-24 08:23:09"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2929370",
          "state": "Closed Complete",
          "Year": "2022.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "foundation source",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2021-12-23 15:24:54"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2934627",
          "state": "Closed Complete",
          "Year": "2022.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "printerlogic - vca",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-01-05 06:34:10"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2961165",
          "state": "Closed Complete",
          "Year": "2022.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "cdc service",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-01-06 16:10:38"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2961840",
          "state": "Incompleted",
          "Year": "2022.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "axelliant",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-01-20 19:25:49"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2964544",
          "state": "Closed Complete",
          "Year": "2022.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "91 app",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-06-13 02:40:42"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2964685",
          "state": "Closed Complete",
          "Year": "2022.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "thoucentric",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-01-25 07:25:52"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2968422",
          "state": "Closed Complete",
          "Year": "2022.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "team computers",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-01-21 14:06:24"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2972889",
          "state": "Closed Complete",
          "Year": "2022.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "ilink",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-01-06 06:28:20"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2973200",
          "state": "Closed Complete",
          "Year": "2022.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "thermo fisher",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-01-06 16:54:42"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2973856",
          "state": "Annual Reassessment Completed",
          "Year": "2022",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "arbor day foundation",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-04-29 07:49:33"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2978652",
          "state": "Incompleted",
          "Year": "2022.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "the lemon lane / fcm",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-01-20 18:55:50"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2978854",
          "state": "Closed Complete",
          "Year": "2022.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "gunter group",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-01-14 06:53:44"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2980145",
          "state": "Closed Complete",
          "Year": "2022.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "ilionx b.v.",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-01-21 15:26:14"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2980934",
          "state": "Closed Complete",
          "Year": "2022.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "starkmont financial",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-01-19 14:35:06"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2981368",
          "state": "Closed Complete",
          "Year": "2022.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "commercetools",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-01-12 15:00:44"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2983561",
          "state": "Incompleted",
          "Year": "2022.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "ans digital pvt. ltd.",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-04-08 19:30:25"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2984892",
          "state": "Closed Complete",
          "Year": "2022.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "padilla (padilla speer beardsley, inc.)",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-01-27 05:11:42"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2989082",
          "state": "Closed Complete",
          "Year": "2022.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "shanghai yechtech technology co., ltd.",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-11-24 02:13:07"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2992059",
          "state": "Closed Complete",
          "Year": "2022.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "winchannel",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-01-19 08:23:30"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2993339",
          "state": "Closed Complete",
          "Year": "2022.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "cteh",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-04-28 19:04:10"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2994516",
          "state": "Incompleted",
          "Year": "2022.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "aptean",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-01-20 18:57:15"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2994702",
          "state": "Incompleted",
          "Year": "2022.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "cornerstone ondemand",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-01-27 04:31:53"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2994783",
          "state": "Incompleted",
          "Year": "2022.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "common bond",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-09-05 07:21:49"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2996444",
          "state": "Closed Complete",
          "Year": "2022.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "british telecom",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-01-21 08:10:28"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM2996871",
          "state": "Closed Complete",
          "Year": "2022.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "willow tree",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-01-18 09:26:43"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3003016",
          "state": "Closed Complete",
          "Year": "2022.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "verses",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-01-19 08:52:13"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3007084",
          "state": "Closed Complete",
          "Year": "2022.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "hp",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-08-09 06:23:05"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3007586",
          "state": "Closed Complete",
          "Year": "2022.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "china mobile  group zhejiang co., ltd. jiaxing branch",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-01-21 08:04:58"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3009520",
          "state": "Closed Complete",
          "Year": "2022.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "kite solutions inc.",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-01-21 07:39:27"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3012092",
          "state": "Closed Complete",
          "Year": "2022.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "lytics",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-01-21 11:12:44"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3017634",
          "state": "Incompleted",
          "Year": "2022.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "ans digital private limited",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-01-20 19:02:46"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3026588",
          "state": "Closed Complete",
          "Year": "2022.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "melissa.com",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-05-02 09:24:58"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3026829",
          "state": "Closed Complete",
          "Year": "2022.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "mckinsey",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-03-29 12:35:24"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3036828",
          "state": "Annual Reassessment Completed",
          "Year": "2022",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "temma llc",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-03-25 14:56:01"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3039695",
          "state": "Annual Reassessment Completed",
          "Year": "2022",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "omp",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-12-07 08:04:13"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3046017",
          "state": "Annual Reassessment Completed",
          "Year": "2022",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "triniti",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-04-28 19:01:08"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3046751",
          "state": "Annual Reassessment Completed",
          "Year": "2022",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "quinyx",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-03-07 14:19:25"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3053881",
          "state": "Closed Complete",
          "Year": "2022.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "esker",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-02-23 13:52:10"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3055718",
          "state": "Annual Reassessment Completed",
          "Year": "2022",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "archetype",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-06-14 07:08:21"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3057809",
          "state": "Annual Reassessment Completed",
          "Year": "2022",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "onest",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-04-07 14:31:22"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3058350",
          "state": "Annual Reassessment Completed",
          "Year": "2022",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "turnertech",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-08-12 09:38:08"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3064520",
          "state": "Annual Reassessment In Progress",
          "Year": "2022",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "filtered technologies",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-04-22 13:24:26"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3072041",
          "state": "Annual Reassessment In Progress",
          "Year": "2022",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "mktg  ",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-03-01 12:23:48"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3075536",
          "state": "Incompleted",
          "Year": "2022.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "sweetrush",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-01-20 18:35:51"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3075581",
          "state": "Annual Reassessment Completed",
          "Year": "2022",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "mindgym",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-10-11 07:17:03"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3078484",
          "state": "Annual Reassessment Completed",
          "Year": "2022",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "mentis sa",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-03-10 16:13:09"
      },
      {
          "Inherent_Risk": "None",
          "Residual_Risk": "None",
          "approval": "N/A",
          "number": "RITM3079232",
          "state": "Annual Reassessment Completed",
          "Year": "2022",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "liveagent ",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-05-13 15:12:54"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3079408",
          "state": "Closed Complete",
          "Year": "2022.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "medpro",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-04-14 15:04:04"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3084734",
          "state": "Closed Complete",
          "Year": "2022.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "mpower",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-08-30 21:12:25"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3087120",
          "state": "Annual Reassessment In Progress",
          "Year": "2022",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "eplus",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-05-25 09:39:31"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3100726",
          "state": "Closed Complete",
          "Year": "2022.0",
          "due_date": "N/A",
          "Category": "N/A",
          "VendorName": "qad",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-07-07 16:11:39"
      },
      {
          "Inherent_Risk": "Critical",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3105108",
          "state": "Annual Reassessment Completed",
          "Year": "2022",
          "due_date": "N/A",
          "Category": "Cloud Services Provider",
          "VendorName": "yandex",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-10-11 08:27:52"
      },
      {
          "Inherent_Risk": "Low",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3115749",
          "state": "Closed Complete",
          "Year": "2022.0",
          "due_date": "N/A",
          "Category": "Telecommunication Services",
          "VendorName": "iridium",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-05-04 10:05:29"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3116486",
          "state": "Annual Reassessment Completed",
          "Year": "2022",
          "due_date": "N/A",
          "Category": "Business Services",
          "VendorName": "tuft",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-08-12 09:41:02"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "Moderate",
          "approval": "N/A",
          "number": "RITM3118930",
          "state": "Annual Reassessment Completed",
          "Year": "2022",
          "due_date": "N/A",
          "Category": "Software",
          "VendorName": "chatdesk",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-03-31 12:49:01"
      },
      {
          "Inherent_Risk": "Low",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3122589",
          "state": "Closed Complete",
          "Year": "2022.0",
          "due_date": "N/A",
          "Category": "Business Services",
          "VendorName": "afo - solutions",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-03-21 15:29:34"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3122819",
          "state": "Closed Complete",
          "Year": "2022.0",
          "due_date": "N/A",
          "Category": "Cloud Services Provider",
          "VendorName": "siweb",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-03-15 10:37:39"
      },
      {
          "Inherent_Risk": "Low",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3125333",
          "state": "Closed Complete",
          "Year": "2022.0",
          "due_date": "N/A",
          "Category": "Laboratory services",
          "VendorName": "吉奥森是玛氏的供应商，吉奥森委托释普进行软件和平台的设计",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-06-10 11:47:18"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3127504",
          "state": "Closed Complete",
          "Year": "2022.0",
          "due_date": "N/A",
          "Category": "Others",
          "VendorName": "bdo audiberia abogados y asesores tributarios s.l.p.,",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-05-13 15:11:33"
      },
      {
          "Inherent_Risk": "Very Low",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3127823",
          "state": "Annual Reassessment In Progress",
          "Year": "2022",
          "due_date": "N/A",
          "Category": "Others",
          "VendorName": "contactmonkey ",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-03-11 08:33:33"
      },
      {
          "Inherent_Risk": "Low",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3128161",
          "state": "Closed Complete",
          "Year": "2022.0",
          "due_date": "N/A",
          "Category": "Marketing",
          "VendorName": "xik solutions sa de cv",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-03-10 10:57:49"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3131364",
          "state": "Closed Complete",
          "Year": "2022.0",
          "due_date": "N/A",
          "Category": "Hardware",
          "VendorName": "cisco",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-03-10 09:45:39"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3131410",
          "state": "Closed Complete",
          "Year": "2022.0",
          "due_date": "N/A",
          "Category": "Hardware",
          "VendorName": "palo alto",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-03-10 10:08:14"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3131443",
          "state": "Closed Complete",
          "Year": "2022.0",
          "due_date": "N/A",
          "Category": "Marketing",
          "VendorName": "ph creative",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-03-10 10:22:34"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3131455",
          "state": "Closed Complete",
          "Year": "2022.0",
          "due_date": "N/A",
          "Category": "Financial Institutions",
          "VendorName": "bmo - bom - bank of montreal",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-03-30 14:24:57"
      },
      {
          "Inherent_Risk": "Low",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3131486",
          "state": "Annual Reassessment Completed",
          "Year": "2022",
          "due_date": "N/A",
          "Category": "IT Services",
          "VendorName": "axl global",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-03-31 15:30:06"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3131537",
          "state": "Annual Reassessment Completed",
          "Year": "2022",
          "due_date": "N/A",
          "Category": "Others",
          "VendorName": "ingenico",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-05-06 10:31:17"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3132194",
          "state": "Annual Reassessment Completed",
          "Year": "2022",
          "due_date": "N/A",
          "Category": "Others",
          "VendorName": "ids (integrated distribution services llc)",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-11-18 13:06:36"
      },
      {
          "Inherent_Risk": "Critical",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3135752",
          "state": "Annual Reassessment Completed",
          "Year": "2022",
          "due_date": "N/A",
          "Category": "Others",
          "VendorName": "analemma commerce private limited",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-04-21 07:35:03"
      },
      {
          "Inherent_Risk": "Low",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3137213",
          "state": "Closed Complete",
          "Year": "2022.0",
          "due_date": "N/A",
          "Category": "Others",
          "VendorName": "captio tech, s.l.",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-03-23 13:24:01"
      },
      {
          "Inherent_Risk": "Critical",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3137217",
          "state": "Annual Reassessment Completed",
          "Year": "2022",
          "due_date": "N/A",
          "Category": "Business Services",
          "VendorName": "eçözüm bilgi teknolojileri a.ş.",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-08-12 09:41:58"
      },
      {
          "Inherent_Risk": "Critical",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3141208",
          "state": "Incompleted",
          "Year": "2022.0",
          "due_date": "N/A",
          "Category": "Professional Services",
          "VendorName": "mozzington",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-08-12 09:42:41"
      },
      {
          "Inherent_Risk": "Low",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3144382",
          "state": "Closed Complete",
          "Year": "2022.0",
          "due_date": "N/A",
          "Category": "IT Services",
          "VendorName": "deleteme/abine",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-05-13 15:05:42"
      },
      {
          "Inherent_Risk": "Low",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3144397",
          "state": "Closed Complete",
          "Year": "2022.0",
          "due_date": "N/A",
          "Category": "Education/Training",
          "VendorName": "dinsit",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-03-15 05:57:37"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3144528",
          "state": "Annual Reassessment Completed",
          "Year": "2022",
          "due_date": "N/A",
          "Category": "Others",
          "VendorName": "varian",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-04-12 09:31:51"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3147776",
          "state": "Annual Reassessment In Progress",
          "Year": "2022",
          "due_date": "N/A",
          "Category": "Financial Institutions",
          "VendorName": "chase",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-04-28 07:17:00"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3149619",
          "state": "Closed Complete",
          "Year": "2022.0",
          "due_date": "N/A",
          "Category": "Software",
          "VendorName": "cubex",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-12-15 09:27:06"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3151395",
          "state": "Closed Complete",
          "Year": "2022.0",
          "due_date": "N/A",
          "Category": "Logistics",
          "VendorName": "raymond corp",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-03-16 14:29:13"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3152896",
          "state": "Annual Reassessment Completed",
          "Year": "2022",
          "due_date": "N/A",
          "Category": "Others",
          "VendorName": "entec international",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-04-19 14:20:33"
      },
      {
          "Inherent_Risk": "Critical",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3154735",
          "state": "Annual Reassessment Completed",
          "Year": "2022",
          "due_date": "N/A",
          "Category": "IT Services",
          "VendorName": "inte*learn ltd",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-08-12 09:43:11"
      },
      {
          "Inherent_Risk": "Low",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3157217",
          "state": "Closed Complete",
          "Year": "2022.0",
          "due_date": "N/A",
          "Category": "Software",
          "VendorName": "greyheller llc dba appsian",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-03-21 07:35:15"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3158375",
          "state": "Closed Complete",
          "Year": "2022.0",
          "due_date": "N/A",
          "Category": "IT Services",
          "VendorName": "dentsu-tec",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-03-21 08:18:42"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3159911",
          "state": "Closed Complete",
          "Year": "2022.0",
          "due_date": "N/A",
          "Category": "IT Services",
          "VendorName": "qos networks",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-03-22 07:16:13"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3163779",
          "state": "Closed Complete",
          "Year": "2022.0",
          "due_date": "N/A",
          "Category": "IT Services",
          "VendorName": "shanghai keji information technology co., ltd",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-03-22 09:47:57"
      },
      {
          "Inherent_Risk": "Low",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3172331",
          "state": "Closed Complete",
          "Year": "2022.0",
          "due_date": "N/A",
          "Category": "Marketing",
          "VendorName": "acardo activation gmbh",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-03-24 08:28:10"
      },
      {
          "Inherent_Risk": "Low",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3172557",
          "state": "Annual Reassessment Completed",
          "Year": "2022",
          "due_date": "N/A",
          "Category": "Cloud Services Provider",
          "VendorName": "salsify pxm",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-08-15 12:46:23"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3172931",
          "state": "Annual Reassessment Completed",
          "Year": "2022",
          "due_date": "N/A",
          "Category": "Others",
          "VendorName": "ercg traxall",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-04-27 08:41:25"
      },
      {
          "Inherent_Risk": "Low",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3174638",
          "state": "Closed Complete",
          "Year": "2022.0",
          "due_date": "N/A",
          "Category": "IT Services",
          "VendorName": "salt.inc",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-03-28 07:15:26"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3177630",
          "state": "Annual Reassessment Completed",
          "Year": "2022",
          "due_date": "N/A",
          "Category": "Insurance",
          "VendorName": "hinge health",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-04-06 16:39:46"
      },
      {
          "Inherent_Risk": "Low",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3178871",
          "state": "Closed Complete",
          "Year": "2022.0",
          "due_date": "N/A",
          "Category": "Financial Institutions",
          "VendorName": "paypal",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-03-28 09:24:42"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3179594",
          "state": "Closed Complete",
          "Year": "2022.0",
          "due_date": "N/A",
          "Category": "Hosted Services Provider",
          "VendorName": "apro software solutions bv",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-09-15 11:48:55"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3179599",
          "state": "Annual Reassessment Completed",
          "Year": "2022",
          "due_date": "N/A",
          "Category": "Marketing",
          "VendorName": "trigg digital",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-04-13 11:07:51"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3179906",
          "state": "Closed Complete",
          "Year": "2022.0",
          "due_date": "N/A",
          "Category": "Education/Training",
          "VendorName": "rise up",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-03-28 10:41:57"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3180080",
          "state": "Closed Complete",
          "Year": "2022.0",
          "due_date": "N/A",
          "Category": "Hardware",
          "VendorName": "hpe aruba",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-03-25 15:46:14"
      },
      {
          "Inherent_Risk": "Low",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3180309",
          "state": "Closed Complete",
          "Year": "2022.0",
          "due_date": "N/A",
          "Category": "IT Services",
          "VendorName": "workera",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-04-28 18:58:27"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3183062",
          "state": "Closed Complete",
          "Year": "2022.0",
          "due_date": "N/A",
          "Category": "IT Services",
          "VendorName": "orion sp",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-10-26 13:18:12"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3185225",
          "state": "Annual Reassessment Completed",
          "Year": "2022",
          "due_date": "N/A",
          "Category": "Marketing",
          "VendorName": "plaid, inc.",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-07-05 08:01:29"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3188592",
          "state": "Annual Reassessment Completed",
          "Year": "2022",
          "due_date": "N/A",
          "Category": "Software",
          "VendorName": "ascent",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-04-04 15:39:09"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3195949",
          "state": "Closed Complete",
          "Year": "2022.0",
          "due_date": "N/A",
          "Category": "IT Services",
          "VendorName": "bixlabs srl",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-06-15 11:01:05"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3196021",
          "state": "Closed Complete",
          "Year": "2022.0",
          "due_date": "N/A",
          "Category": "Software",
          "VendorName": "beyond trust",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-04-01 05:57:13"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3196264",
          "state": "Annual Reassessment Completed",
          "Year": "2022",
          "due_date": "N/A",
          "Category": "Others",
          "VendorName": "dscout",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-04-01 14:46:15"
      },
      {
          "Inherent_Risk": "Critical",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3197885",
          "state": "Annual Reassessment Completed",
          "Year": "2022",
          "due_date": "N/A",
          "Category": "Software",
          "VendorName": "cyberhaven",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-08-16 12:19:51"
      },
      {
          "Inherent_Risk": "Critical",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3197895",
          "state": "Annual Reassessment In Progress",
          "Year": "2022",
          "due_date": "N/A",
          "Category": "Software",
          "VendorName": "securecircle",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-05-04 07:53:49"
      },
      {
          "Inherent_Risk": "Very Low",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3199375",
          "state": "Closed Complete",
          "Year": "2022.0",
          "due_date": "N/A",
          "Category": "Software",
          "VendorName": "sonarsource sa",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-04-04 07:01:31"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3199574",
          "state": "Closed Complete",
          "Year": "2022.0",
          "due_date": "N/A",
          "Category": "Software",
          "VendorName": "usu solutions",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-04-04 07:22:49"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3199700",
          "state": "Closed Complete",
          "Year": "2022.0",
          "due_date": "N/A",
          "Category": "IT Services",
          "VendorName": "cofense, inc.",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-04-04 14:17:05"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3199744",
          "state": "Annual Reassessment Completed",
          "Year": "2022",
          "due_date": "N/A",
          "Category": "Cloud Services Provider",
          "VendorName": "coveo",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-04-21 11:16:22"
      },
      {
          "Inherent_Risk": "Very Low",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3203913",
          "state": "Closed Complete",
          "Year": "2022.0",
          "due_date": "N/A",
          "Category": "Professional Services",
          "VendorName": "vcrm",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-04-04 15:59:10"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3204733",
          "state": "Annual Reassessment Completed",
          "Year": "2022",
          "due_date": "N/A",
          "Category": "IT Services",
          "VendorName": "medix",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-04-29 09:12:08"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3205994",
          "state": "Annual Reassessment Completed",
          "Year": "2022",
          "due_date": "N/A",
          "Category": "Advertising Services",
          "VendorName": "leadfamly",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-04-08 08:23:39"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3207093",
          "state": "Annual Reassessment Completed",
          "Year": "2022",
          "due_date": "N/A",
          "Category": "Software",
          "VendorName": "manhattan",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-01-05 11:21:09"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3207448",
          "state": "Annual Reassessment Completed",
          "Year": "2022",
          "due_date": "N/A",
          "Category": "Cloud Services Provider",
          "VendorName": "process fusion",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-04-13 10:19:48"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3207524",
          "state": "Annual Reassessment Completed",
          "Year": "2022",
          "due_date": "N/A",
          "Category": "Laboratory services",
          "VendorName": "hygiena",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-04-06 07:20:02"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3209445",
          "state": "Closed Complete",
          "Year": "2022.0",
          "due_date": "N/A",
          "Category": "IT Services",
          "VendorName": "accenture russia (will be changed)",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-09-13 12:41:02"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3210392",
          "state": "Annual Reassessment Completed",
          "Year": "2022",
          "due_date": "N/A",
          "Category": "Others",
          "VendorName": "gympass",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-04-21 13:55:20"
      },
      {
          "Inherent_Risk": "Low",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3216168",
          "state": "Closed Complete",
          "Year": "2022.0",
          "due_date": "N/A",
          "Category": "Cloud Services Provider",
          "VendorName": "dhf - 15089589",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-04-11 15:16:02"
      },
      {
          "Inherent_Risk": "Low",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3221769",
          "state": "Closed Complete",
          "Year": "2022.0",
          "due_date": "N/A",
          "Category": "Others",
          "VendorName": "blueline technology, bluetech consulting spa",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-01-13 13:56:27"
      },
      {
          "Inherent_Risk": "Low",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3222617",
          "state": "Closed Complete",
          "Year": "2022.0",
          "due_date": "N/A",
          "Category": "Others",
          "VendorName": "evolve ignite",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-04-12 06:55:17"
      },
      {
          "Inherent_Risk": "Low",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3225828",
          "state": "Closed Complete",
          "Year": "2022.0",
          "due_date": "N/A",
          "Category": "Business Services",
          "VendorName": "code & theory",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-04-13 08:31:18"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3229450",
          "state": "Annual Reassessment Completed",
          "Year": "2022",
          "due_date": "N/A",
          "Category": "IT Services",
          "VendorName": "mcchrystal",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-04-20 09:21:05"
      },
      {
          "Inherent_Risk": "Low",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3231740",
          "state": "Closed Complete",
          "Year": "2022.0",
          "due_date": "N/A",
          "Category": "Education/Training",
          "VendorName": "elearningforce international",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-04-18 06:54:06"
      },
      {
          "Inherent_Risk": "Low",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3233857",
          "state": "Closed Complete",
          "Year": "2022.0",
          "due_date": "N/A",
          "Category": "IT Services",
          "VendorName": "jsc \"inovatsionnie technologii\"",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-04-18 07:21:49"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3241043",
          "state": "Closed Complete",
          "Year": "2022.0",
          "due_date": "N/A",
          "Category": "Software",
          "VendorName": "simio",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-04-20 08:21:35"
      },
      {
          "Inherent_Risk": "Critical",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3241472",
          "state": "Closed Complete",
          "Year": "2022.0",
          "due_date": "N/A",
          "Category": "IT Services",
          "VendorName": "vendor",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-04-26 08:13:23"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3243930",
          "state": "Incompleted",
          "Year": "2022.0",
          "due_date": "N/A",
          "Category": "IT Services",
          "VendorName": "ucms group russia",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-01-20 18:50:07"
      },
      {
          "Inherent_Risk": "Critical",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3245076",
          "state": "Annual Reassessment Completed",
          "Year": "2022",
          "due_date": "N/A",
          "Category": "Professional Services",
          "VendorName": "lifeworks",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-05-02 10:18:16"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3245932",
          "state": "Annual Reassessment Completed",
          "Year": "2022",
          "due_date": "N/A",
          "Category": "Software",
          "VendorName": "eyequestion",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-05-23 04:35:10"
      },
      {
          "Inherent_Risk": "Very Low",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3249540",
          "state": "Closed Complete",
          "Year": "2022.0",
          "due_date": "N/A",
          "Category": "IT Services",
          "VendorName": "archer scripts, llc",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-04-22 10:17:13"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3251553",
          "state": "Closed Complete",
          "Year": "2022.0",
          "due_date": "N/A",
          "Category": "Business Services",
          "VendorName": "dashamail",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-08-10 10:35:54"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "High",
          "approval": "N/A",
          "number": "RITM3256654",
          "state": "Annual Reassessment In Progress",
          "Year": "2022",
          "due_date": "N/A",
          "Category": "Marketing",
          "VendorName": "bull marketing",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-07-19 05:38:09"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3256947",
          "state": "Closed Complete",
          "Year": "2022.0",
          "due_date": "N/A",
          "Category": "Advertising Services",
          "VendorName": "geometry",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-04-26 08:35:46"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3259283",
          "state": "Annual Reassessment Completed",
          "Year": "2022",
          "due_date": "N/A",
          "Category": "Professional Services",
          "VendorName": "vita health group ltd",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-05-10 12:32:16"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3260987",
          "state": "Closed Complete",
          "Year": "2022.0",
          "due_date": "N/A",
          "Category": "Software",
          "VendorName": "instinct science, llc",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-04-27 08:18:28"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3264169",
          "state": "Annual Reassessment Completed",
          "Year": "2022",
          "due_date": "N/A",
          "Category": "IT Services",
          "VendorName": "capeburn hope",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-05-06 11:25:08"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3267346",
          "state": "Annual Reassessment In Progress",
          "Year": "2022",
          "due_date": "N/A",
          "Category": "Hosted Services Provider",
          "VendorName": "arbor day - vca ",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-06-15 09:06:00"
      },
      {
          "Inherent_Risk": "Low",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3267673",
          "state": "Closed Complete",
          "Year": "2022.0",
          "due_date": "N/A",
          "Category": "Marketing",
          "VendorName": "the food group",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-04-29 08:12:41"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3270033",
          "state": "Annual Reassessment In Progress",
          "Year": "2022",
          "due_date": "N/A",
          "Category": "Software",
          "VendorName": "tops",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-11-09 16:17:33"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3273420",
          "state": "Annual Reassessment Completed",
          "Year": "2022",
          "due_date": "N/A",
          "Category": "Software",
          "VendorName": "2daysmood - vca",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-05-04 09:32:25"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "None",
          "approval": "N/A",
          "number": "RITM3273636",
          "state": "Annual Reassessment Completed",
          "Year": "2022",
          "due_date": "N/A",
          "Category": "Marketing",
          "VendorName": "klaviyo ",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-01-05 10:07:07"
      },
      {
          "Inherent_Risk": "Low",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3275201",
          "state": "Closed Complete",
          "Year": "2022.0",
          "due_date": "N/A",
          "Category": "Advertising Services",
          "VendorName": "diggi addict",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-05-04 08:11:49"
      },
      {
          "Inherent_Risk": "Very Low",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3276662",
          "state": "Closed Complete",
          "Year": "2022.0",
          "due_date": "N/A",
          "Category": "IT Services",
          "VendorName": "gdc",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-05-06 07:45:57"
      },
      {
          "Inherent_Risk": "Low",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3277758",
          "state": "Closed Complete",
          "Year": "2022.0",
          "due_date": "N/A",
          "Category": "Marketing",
          "VendorName": "easyfeedback gmbh",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-05-04 13:21:03"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3279136",
          "state": "Annual Reassessment Completed",
          "Year": "2022",
          "due_date": "N/A",
          "Category": "Software",
          "VendorName": "visma",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-06-15 12:19:57"
      },
      {
          "Inherent_Risk": "Low",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3279139",
          "state": "Closed Complete",
          "Year": "2022.0",
          "due_date": "N/A",
          "Category": "Professional Services",
          "VendorName": "dividato software company",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-05-05 09:52:59"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3279326",
          "state": "Closed Complete",
          "Year": "2022.0",
          "due_date": "N/A",
          "Category": "Software",
          "VendorName": "agillitics",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-05-10 11:13:00"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3279493",
          "state": "Annual Reassessment Completed",
          "Year": "2022",
          "due_date": "N/A",
          "Category": "Hosted Services Provider",
          "VendorName": "calpro research",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-07-01 15:19:41"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3281020",
          "state": "Annual Reassessment Completed",
          "Year": "2022",
          "due_date": "N/A",
          "Category": "Marketing",
          "VendorName": "insider",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-11-05 11:51:15"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3281445",
          "state": "Annual Reassessment Completed",
          "Year": "2022",
          "due_date": "N/A",
          "Category": "IT Services",
          "VendorName": "vi.tech",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-09-14 07:09:33"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3283521",
          "state": "Annual Reassessment Completed",
          "Year": "2022",
          "due_date": "N/A",
          "Category": "Others",
          "VendorName": "edriving",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-05-19 14:51:41"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3288773",
          "state": "Annual Reassessment Completed",
          "Year": "2022",
          "due_date": "N/A",
          "Category": "Laboratory services",
          "VendorName": "idexx",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-07-07 05:44:04"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3292793",
          "state": "Closed Complete",
          "Year": "2022.0",
          "due_date": "N/A",
          "Category": "Others",
          "VendorName": "magna",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-05-13 10:06:34"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3295176",
          "state": "Annual Reassessment Completed",
          "Year": "2022",
          "due_date": "N/A",
          "Category": "Marketing",
          "VendorName": "tezign",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-06-06 09:31:33"
      },
      {
          "Inherent_Risk": "Very Low",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3297228",
          "state": "Annual Reassessment Completed",
          "Year": "2022",
          "due_date": "N/A",
          "Category": "Software",
          "VendorName": "feedly",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-05-25 14:25:54"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3303783",
          "state": "Incompleted",
          "Year": "2022.0",
          "due_date": "N/A",
          "Category": "Others",
          "VendorName": "siemens",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-05-18 13:59:16"
      },
      {
          "Inherent_Risk": "Very Low",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3306122",
          "state": "Closed Complete",
          "Year": "2022.0",
          "due_date": "N/A",
          "Category": "Software",
          "VendorName": "jetbrains",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-05-19 12:44:42"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3306836",
          "state": "Annual Reassessment Completed",
          "Year": "2022",
          "due_date": "N/A",
          "Category": "IT Services",
          "VendorName": "6point6",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-10-07 10:40:40"
      },
      {
          "Inherent_Risk": "Low",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3307757",
          "state": "Closed Complete",
          "Year": "2022.0",
          "due_date": "N/A",
          "Category": "Software",
          "VendorName": "joes sandbox",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-05-25 14:09:29"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3308118",
          "state": "Closed Complete",
          "Year": "2022.0",
          "due_date": "N/A",
          "Category": "IT Services",
          "VendorName": "bainsights (upland software)",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-10-10 07:37:56"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3314453",
          "state": "Annual Reassessment Completed",
          "Year": "2022",
          "due_date": "N/A",
          "Category": "Logistics",
          "VendorName": "goldenfrost",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-01-20 19:24:45"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3314483",
          "state": "Annual Reassessment Completed",
          "Year": "2022",
          "due_date": "N/A",
          "Category": "Logistics",
          "VendorName": "qbox",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-06-24 08:27:43"
      },
      {
          "Inherent_Risk": "Low",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3318127",
          "state": "Closed Complete",
          "Year": "2022.0",
          "due_date": "N/A",
          "Category": "Cloud Services Provider",
          "VendorName": "tinyurl",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-05-23 08:38:36"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3320929",
          "state": "Closed Complete",
          "Year": "2022.0",
          "due_date": "N/A",
          "Category": "Hardware",
          "VendorName": "aniwee",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-05-23 09:19:23"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3326551",
          "state": "Closed Complete",
          "Year": "2022.0",
          "due_date": "N/A",
          "Category": "Cloud Services Provider",
          "VendorName": "novigo pty ltd",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-10-04 08:08:13"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3326963",
          "state": "Annual Reassessment Completed",
          "Year": "2022",
          "due_date": "N/A",
          "Category": "IT Services",
          "VendorName": "motiv",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-06-20 15:10:30"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3327519",
          "state": "Annual Reassessment Completed",
          "Year": "2022",
          "due_date": "N/A",
          "Category": "IT Services",
          "VendorName": "bearing point",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-09-08 11:04:07"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3329341",
          "state": "Closed Complete",
          "Year": "2022.0",
          "due_date": "N/A",
          "Category": "Cloud Services Provider",
          "VendorName": "premise data corporation",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-06-15 10:52:38"
      },
      {
          "Inherent_Risk": "Critical",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3332030",
          "state": "Annual Reassessment Completed",
          "Year": "2022",
          "due_date": "N/A",
          "Category": "IT Services",
          "VendorName": "orion global solutions",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-10-25 09:42:01"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3332567",
          "state": "Annual Reassessment Completed",
          "Year": "2022",
          "due_date": "N/A",
          "Category": "Professional Services",
          "VendorName": "sitel",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-06-02 08:31:51"
      },
      {
          "Inherent_Risk": "Low",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3332936",
          "state": "Annual Reassessment Completed",
          "Year": "2022",
          "due_date": "N/A",
          "Category": "Marketing",
          "VendorName": "datarobot",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-09-15 10:30:27"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3336308",
          "state": "Closed Complete",
          "Year": "2022.0",
          "due_date": "N/A",
          "Category": "Cloud Services Provider",
          "VendorName": "soti",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-05-27 09:22:13"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3338509",
          "state": "Annual Reassessment Completed",
          "Year": "2022",
          "due_date": "N/A",
          "Category": "Professional Services",
          "VendorName": "candex solutions, inc.",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-10-28 13:15:10"
      },
      {
          "Inherent_Risk": "Critical",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3342429",
          "state": "Annual Reassessment Completed",
          "Year": "2022",
          "due_date": "N/A",
          "Category": "Marketing",
          "VendorName": "neocom.ai",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-09-08 11:03:28"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3347630",
          "state": "Annual Reassessment Completed",
          "Year": "2022",
          "due_date": "N/A",
          "Category": "Education/Training",
          "VendorName": "ispring",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-06-02 10:36:42"
      },
      {
          "Inherent_Risk": "Low",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3350021",
          "state": "Closed Complete",
          "Year": "2022.0",
          "due_date": "N/A",
          "Category": "IT Services",
          "VendorName": "deloitte china",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-06-02 10:49:23"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3353565",
          "state": "Annual Reassessment Completed",
          "Year": "2022",
          "due_date": "N/A",
          "Category": "Cloud Services Provider",
          "VendorName": "media defined, inc.",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-07-07 10:02:59"
      },
      {
          "Inherent_Risk": "Very Low",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3362525",
          "state": "Incompleted",
          "Year": "2022.0",
          "due_date": "N/A",
          "Category": "Marketing",
          "VendorName": "camonapp",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-09-08 06:42:04"
      },
      {
          "Inherent_Risk": "Low",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3364978",
          "state": "Closed Complete",
          "Year": "2022.0",
          "due_date": "N/A",
          "Category": "IT Services",
          "VendorName": "softwareone",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-06-08 06:34:39"
      },
      {
          "Inherent_Risk": "Low",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3366905",
          "state": "Annual Reassessment Completed",
          "Year": "2022",
          "due_date": "N/A",
          "Category": "Cloud Services Provider",
          "VendorName": "twilio",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-01-20 19:05:34"
      },
      {
          "Inherent_Risk": "Low",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3368755",
          "state": "Closed Complete",
          "Year": "2022.0",
          "due_date": "N/A",
          "Category": "Cloud Services Provider",
          "VendorName": "sketch",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-06-09 06:08:03"
      },
      {
          "Inherent_Risk": "Very Low",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3370990",
          "state": "Closed Complete",
          "Year": "2022.0",
          "due_date": "N/A",
          "Category": "IT Services",
          "VendorName": "reflex solutions",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-06-10 06:50:53"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3371805",
          "state": "Closed Complete",
          "Year": "2022.0",
          "due_date": "N/A",
          "Category": "Cloud Services Provider",
          "VendorName": "peopleplan",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-06-10 12:58:22"
      },
      {
          "Inherent_Risk": "Very Low",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3375646",
          "state": "Closed Complete",
          "Year": "2022.0",
          "due_date": "N/A",
          "Category": "Software",
          "VendorName": "redzone",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-06-14 13:59:06"
      },
      {
          "Inherent_Risk": "Low",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3379710",
          "state": "Annual Reassessment Completed",
          "Year": "2022",
          "due_date": "N/A",
          "Category": "Cloud Services Provider",
          "VendorName": "figma",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-06-23 09:49:02"
      },
      {
          "Inherent_Risk": "None",
          "Residual_Risk": "None",
          "approval": "N/A",
          "number": "RITM3380504",
          "state": "Annual Reassessment Completed",
          "Year": "2022",
          "due_date": "N/A",
          "Category": "IT Services",
          "VendorName": "spaulding ridge",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-06-23 09:36:25"
      },
      {
          "Inherent_Risk": "Very Low",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3382660",
          "state": "Closed Complete",
          "Year": "2022.0",
          "due_date": "N/A",
          "Category": "IT Services",
          "VendorName": "wavetec",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-06-20 09:11:50"
      },
      {
          "Inherent_Risk": "Critical",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3383191",
          "state": "Annual Reassessment Completed",
          "Year": "2022",
          "due_date": "N/A",
          "Category": "IT Services",
          "VendorName": "xtremelabs llc",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-08-12 09:48:48"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3383572",
          "state": "Closed Complete",
          "Year": "2022.0",
          "due_date": "N/A",
          "Category": "Software",
          "VendorName": "untethered labs, inc.",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-06-16 13:33:41"
      },
      {
          "Inherent_Risk": "Low",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3387102",
          "state": "Annual Reassessment In Progress",
          "Year": "2022",
          "due_date": "N/A",
          "Category": "Software",
          "VendorName": "screendragon",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-06-16 10:40:33"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3387320",
          "state": "Annual Reassessment In Progress",
          "Year": "2022",
          "due_date": "N/A",
          "Category": "Software",
          "VendorName": "security scorecard - infosec ",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-06-23 11:00:54"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3387347",
          "state": "Closed Complete",
          "Year": "2022.0",
          "due_date": "N/A",
          "Category": "Software",
          "VendorName": "upguard",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-06-16 10:58:04"
      },
      {
          "Inherent_Risk": "Low",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3390103",
          "state": "Closed Complete",
          "Year": "2022.0",
          "due_date": "N/A",
          "Category": "Software",
          "VendorName": "stayinfront inc",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-06-20 10:30:54"
      },
      {
          "Inherent_Risk": "Low",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3390159",
          "state": "Closed Complete",
          "Year": "2022.0",
          "due_date": "N/A",
          "Category": "Hardware",
          "VendorName": "one diversified, llc",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-11-04 06:55:52"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3390977",
          "state": "Annual Reassessment Completed",
          "Year": "2022",
          "due_date": "N/A",
          "Category": "IT Services",
          "VendorName": "digital design it llc",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-10-10 07:40:19"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3396974",
          "state": "Annual Reassessment In Progress",
          "Year": "2022",
          "due_date": "N/A",
          "Category": "IT Services",
          "VendorName": "infinityqs",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-09-08 07:41:47"
      },
      {
          "Inherent_Risk": "Very Low",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3398420",
          "state": "Closed Complete",
          "Year": "2022.0",
          "due_date": "N/A",
          "Category": "Hardware",
          "VendorName": "petwant",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-07-01 11:35:00"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3398627",
          "state": "Annual Reassessment Completed",
          "Year": "2022",
          "due_date": "N/A",
          "Category": "IT Services",
          "VendorName": "breffi",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-07-29 12:17:35"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3402613",
          "state": "Closed Complete",
          "Year": "2022.0",
          "due_date": "N/A",
          "Category": "IT Services",
          "VendorName": "smart retail llc",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-09-08 11:39:02"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3405487",
          "state": "Annual Reassessment Completed",
          "Year": "2022",
          "due_date": "N/A",
          "Category": "IT Services",
          "VendorName": "xsap business solutions ltd",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-10-10 07:47:46"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3407949",
          "state": "Annual Reassessment Completed",
          "Year": "2022",
          "due_date": "N/A",
          "Category": "IT Services",
          "VendorName": "llc teamlead",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-10-10 07:12:32"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3408078",
          "state": "Closed Complete",
          "Year": "2022.0",
          "due_date": "N/A",
          "Category": "IT Services",
          "VendorName": "class comércio e sistemas de informática ltda",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-06-28 10:02:57"
      },
      {
          "Inherent_Risk": "Very Low",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3410249",
          "state": "Incompleted",
          "Year": "2022.0",
          "due_date": "N/A",
          "Category": "Cloud Services Provider",
          "VendorName": "cloud kinetics",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-09-08 07:40:53"
      },
      {
          "Inherent_Risk": "Low",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3410341",
          "state": "Closed Complete",
          "Year": "2022.0",
          "due_date": "N/A",
          "Category": "IT Services",
          "VendorName": "redhat",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-06-28 08:48:32"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3413160",
          "state": "Annual Reassessment Completed",
          "Year": "2022",
          "due_date": "N/A",
          "Category": "Marketing",
          "VendorName": "quadrafort technologies pvt ltd",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-07-01 11:23:12"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3414275",
          "state": "Incompleted",
          "Year": "2022.0",
          "due_date": "N/A",
          "Category": "IT Services",
          "VendorName": "siemens advanta",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-07-11 13:40:11"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3415391",
          "state": "Closed Complete",
          "Year": "2022.0",
          "due_date": "N/A",
          "Category": "Software",
          "VendorName": "quadient, inc.",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-06-28 08:27:04"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "Low",
          "approval": "N/A",
          "number": "RITM3417314",
          "state": "Annual Reassessment Completed",
          "Year": "2022",
          "due_date": "N/A",
          "Category": "Cloud Services Provider",
          "VendorName": "kisi inc ",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-07-06 05:09:16"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3418182",
          "state": "Annual Reassessment Completed",
          "Year": "2022",
          "due_date": "N/A",
          "Category": "IT Services",
          "VendorName": "confiz llc",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-06-30 09:35:41"
      },
      {
          "Inherent_Risk": "Low",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3424612",
          "state": "Annual Reassessment Completed",
          "Year": "2022",
          "due_date": "N/A",
          "Category": "Marketing",
          "VendorName": "mindbox",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-01-18 09:54:53"
      },
      {
          "Inherent_Risk": "Very Low",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3425670",
          "state": "Closed Complete",
          "Year": "2022.0",
          "due_date": "N/A",
          "Category": "Marketing",
          "VendorName": "touch rate, llc",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-07-05 10:13:16"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3429923",
          "state": "Annual Reassessment Completed",
          "Year": "2022",
          "due_date": "N/A",
          "Category": "Marketing",
          "VendorName": "mo engage",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-07-19 11:48:43"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3433080",
          "state": "Annual Reassessment In Progress",
          "Year": "2022",
          "due_date": "N/A",
          "Category": "Marketing",
          "VendorName": "sopost ",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-07-13 06:55:50"
      },
      {
          "Inherent_Risk": "Low",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3433601",
          "state": "Incompleted",
          "Year": "2022.0",
          "due_date": "N/A",
          "Category": "IT Services",
          "VendorName": "plaid",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-10-07 10:13:38"
      },
      {
          "Inherent_Risk": "Low",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3433928",
          "state": "Closed Complete",
          "Year": "2022.0",
          "due_date": "N/A",
          "Category": "Education/Training",
          "VendorName": "lionbridge technologies",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-07-05 09:25:44"
      },
      {
          "Inherent_Risk": "Very Low",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3437877",
          "state": "Closed Complete",
          "Year": "2022.0",
          "due_date": "N/A",
          "Category": "IT Services",
          "VendorName": "adictiz",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-09-22 13:28:44"
      },
      {
          "Inherent_Risk": "Low",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3438831",
          "state": "Closed Complete",
          "Year": "2022.0",
          "due_date": "N/A",
          "Category": "Telecommunication Services",
          "VendorName": "thuraya",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-07-14 14:08:51"
      },
      {
          "Inherent_Risk": "Very Low",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3439411",
          "state": "Closed Complete",
          "Year": "2022.0",
          "due_date": "N/A",
          "Category": "Others",
          "VendorName": "egyptian micro solutions (ems)",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-07-08 14:54:47"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3439583",
          "state": "Annual Reassessment Completed",
          "Year": "2022",
          "due_date": "N/A",
          "Category": "Software",
          "VendorName": "weever",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-08-03 15:36:49"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3439613",
          "state": "Annual Reassessment Completed",
          "Year": "2022",
          "due_date": "N/A",
          "Category": "Software",
          "VendorName": "sphera solutions inc",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-07-20 12:39:19"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3443100",
          "state": "Annual Reassessment Completed",
          "Year": "2022",
          "due_date": "N/A",
          "Category": "Others",
          "VendorName": "empower retirement, llc",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-08-10 14:01:43"
      },
      {
          "Inherent_Risk": "Low",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3449680",
          "state": "Closed Complete",
          "Year": "2022.0",
          "due_date": "N/A",
          "Category": "Cloud Services Provider",
          "VendorName": "vonage (primary supplier)  pci-pal (sub-contract)",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-09-13 08:40:00"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3450862",
          "state": "Annual Reassessment Completed",
          "Year": "2022",
          "due_date": "N/A",
          "Category": "Software",
          "VendorName": "walkme - mvh",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-08-08 10:36:08"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3453254",
          "state": "Incompleted",
          "Year": "2022.0",
          "due_date": "N/A",
          "Category": "IT Services",
          "VendorName": "cloud-kinetics",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-10-10 07:00:59"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3454267",
          "state": "Closed Complete",
          "Year": "2022.0",
          "due_date": "N/A",
          "Category": "Software",
          "VendorName": "smartdraw",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-07-15 16:45:44"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3454366",
          "state": "Annual Reassessment Completed",
          "Year": "2022",
          "due_date": "N/A",
          "Category": "Marketing",
          "VendorName": "hubspot",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-07-27 03:58:56"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3454416",
          "state": "Closed Complete",
          "Year": "2022.0",
          "due_date": "N/A",
          "Category": "Software",
          "VendorName": "axonius (purchased through var evotek)",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-07-15 17:05:47"
      },
      {
          "Inherent_Risk": "Low",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3454562",
          "state": "Incompleted",
          "Year": "2022.0",
          "due_date": "N/A",
          "Category": "IT Services",
          "VendorName": "sage group",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-01-20 18:55:19"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3454591",
          "state": "Closed Complete",
          "Year": "2022.0",
          "due_date": "N/A",
          "Category": "IT Services",
          "VendorName": "opg-3, inc",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-07-15 16:26:24"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3455684",
          "state": "Closed Complete",
          "Year": "2022.0",
          "due_date": "N/A",
          "Category": "IT Services",
          "VendorName": "positive technolgies",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-07-14 15:12:19"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3455701",
          "state": "Annual Reassessment Completed",
          "Year": "2022",
          "due_date": "N/A",
          "Category": "IT Services",
          "VendorName": "jet infosystems",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-01-20 12:14:03"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3455703",
          "state": "Closed Complete",
          "Year": "2022.0",
          "due_date": "N/A",
          "Category": "IT Services",
          "VendorName": "kaspersky",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-07-14 15:54:03"
      },
      {
          "Inherent_Risk": "Critical",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3455775",
          "state": "Annual Reassessment In Progress",
          "Year": "2022",
          "due_date": "N/A",
          "Category": "IT Services",
          "VendorName": "yandex 360 ",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-11-30 12:51:28"
      },
      {
          "Inherent_Risk": "Low",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3456626",
          "state": "Annual Reassessment Completed",
          "Year": "2022",
          "due_date": "N/A",
          "Category": "IT Services",
          "VendorName": "nii sokb",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-01-20 17:57:20"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3457379",
          "state": "Closed Complete",
          "Year": "2022.0",
          "due_date": "N/A",
          "Category": "Cloud Services Provider",
          "VendorName": "when i work",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-07-15 13:00:32"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3457820",
          "state": "Annual Reassessment Completed",
          "Year": "2022",
          "due_date": "N/A",
          "Category": "IT Services",
          "VendorName": "supreme",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-08-08 17:28:47"
      },
      {
          "Inherent_Risk": "Low",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3460807",
          "state": "Annual Reassessment Completed",
          "Year": "2022",
          "due_date": "N/A",
          "Category": "Software",
          "VendorName": "usertesting",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-07-21 07:56:23"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3470726",
          "state": "Annual Reassessment Completed",
          "Year": "2022",
          "due_date": "N/A",
          "Category": "Software",
          "VendorName": "remesh",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-07-27 10:09:33"
      },
      {
          "Inherent_Risk": "Low",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3472135",
          "state": "Closed Complete",
          "Year": "2022.0",
          "due_date": "N/A",
          "Category": "Others",
          "VendorName": "lancesoft",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-07-21 07:17:58"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3474843",
          "state": "Incompleted",
          "Year": "2022.0",
          "due_date": "N/A",
          "Category": "Software",
          "VendorName": "sage",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-09-06 21:04:44"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3475042",
          "state": "Annual Reassessment Completed",
          "Year": "2022",
          "due_date": "N/A",
          "Category": "Others",
          "VendorName": "adelman travel",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-08-26 07:59:31"
      },
      {
          "Inherent_Risk": "Low",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3480399",
          "state": "Closed Complete",
          "Year": "2022.0",
          "due_date": "N/A",
          "Category": "Marketing",
          "VendorName": "lintree",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-07-28 10:28:03"
      },
      {
          "Inherent_Risk": "Low",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3480411",
          "state": "Closed Complete",
          "Year": "2022.0",
          "due_date": "N/A",
          "Category": "Marketing",
          "VendorName": "linkin bio",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-07-28 08:17:43"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "Moderate",
          "approval": "N/A",
          "number": "RITM3482363",
          "state": "Annual Reassessment Completed",
          "Year": "2022",
          "due_date": "N/A",
          "Category": "Others",
          "VendorName": "smartcat platform inc.",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-10-11 07:11:50"
      },
      {
          "Inherent_Risk": "Low",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3483096",
          "state": "Closed Complete",
          "Year": "2022.0",
          "due_date": "N/A",
          "Category": "IT Services",
          "VendorName": "agence glanum",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-08-09 11:18:48"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3485038",
          "state": "Closed Complete",
          "Year": "2022.0",
          "due_date": "N/A",
          "Category": "IT Services",
          "VendorName": "rhapsody",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-07-26 09:40:17"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3485055",
          "state": "Annual Reassessment In Progress",
          "Year": "2022",
          "due_date": "N/A",
          "Category": "Cloud Services Provider",
          "VendorName": "helpscout - vca ",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-09-20 10:59:38"
      },
      {
          "Inherent_Risk": "Low",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3487728",
          "state": "Annual Reassessment In Progress",
          "Year": "2022",
          "due_date": "N/A",
          "Category": "Software",
          "VendorName": "habu ",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-08-03 08:11:35"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3489203",
          "state": "Closed Complete",
          "Year": "2022.0",
          "due_date": "N/A",
          "Category": "Professional Services",
          "VendorName": "0015142212 (equilibrium hwp ltd)",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-10-14 13:11:36"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "Moderate",
          "approval": "N/A",
          "number": "RITM3489207",
          "state": "Annual Reassessment Completed",
          "Year": "2022",
          "due_date": "N/A",
          "Category": "Business Services",
          "VendorName": "mmr",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-10-11 07:13:10"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3490776",
          "state": "Annual Reassessment In Progress",
          "Year": "2022",
          "due_date": "N/A",
          "Category": "Cloud Services Provider",
          "VendorName": "monad, inc.",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-01-20 18:53:15"
      },
      {
          "Inherent_Risk": "Very Low",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3490994",
          "state": "Closed Complete",
          "Year": "2022.0",
          "due_date": "N/A",
          "Category": "Cloud Services Provider",
          "VendorName": "galilee",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-07-29 09:35:25"
      },
      {
          "Inherent_Risk": "Low",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3493826",
          "state": "Annual Reassessment Completed",
          "Year": "2022",
          "due_date": "N/A",
          "Category": "Software",
          "VendorName": "elastic - vca",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-08-05 12:42:28"
      },
      {
          "Inherent_Risk": "Critical",
          "Residual_Risk": "Moderate",
          "approval": "N/A",
          "number": "RITM3501288",
          "state": "Annual Reassessment Completed",
          "Year": "2022",
          "due_date": "N/A",
          "Category": "Software",
          "VendorName": "cority software inc",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-10-10 15:18:19"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "Moderate",
          "approval": "N/A",
          "number": "RITM3501759",
          "state": "Annual Reassessment Completed",
          "Year": "2022",
          "due_date": "N/A",
          "Category": "Advertising Services",
          "VendorName": "jivox at mars ",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-12-15 08:34:36"
      },
      {
          "Inherent_Risk": "Critical",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3502979",
          "state": "Annual Reassessment Completed",
          "Year": "2022",
          "due_date": "N/A",
          "Category": "Marketing",
          "VendorName": "tma marketing servisez ooo",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-01-13 09:49:10"
      },
      {
          "Inherent_Risk": "Critical",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3503090",
          "state": "Annual Reassessment Completed",
          "Year": "2022",
          "due_date": "N/A",
          "Category": "IT Services",
          "VendorName": "huice",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-10-10 07:59:36"
      },
      {
          "Inherent_Risk": "Critical",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3507577",
          "state": "Closed Complete",
          "Year": "2022.0",
          "due_date": "N/A",
          "Category": "Software",
          "VendorName": "maestro qa",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-08-19 14:18:22"
      },
      {
          "Inherent_Risk": "Critical",
          "Residual_Risk": "Moderate",
          "approval": "N/A",
          "number": "RITM3511068",
          "state": "Annual Reassessment Completed",
          "Year": "2022",
          "due_date": "N/A",
          "Category": "Hosted Services Provider",
          "VendorName": "benevity",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-09-07 17:06:15"
      },
      {
          "Inherent_Risk": "Low",
          "Residual_Risk": "Low",
          "approval": "N/A",
          "number": "RITM3513787",
          "state": "Annual Reassessment Completed",
          "Year": "2022",
          "due_date": "N/A",
          "Category": "Education/Training",
          "VendorName": "be inclusive hospitality",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-09-14 15:38:35"
      },
      {
          "Inherent_Risk": "Low",
          "Residual_Risk": "Low",
          "approval": "N/A",
          "number": "RITM3513830",
          "state": "Annual Reassessment Completed",
          "Year": "2022",
          "due_date": "N/A",
          "Category": "Education/Training",
          "VendorName": "leiths school of food & wine",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-09-14 16:21:58"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3514030",
          "state": "Incompleted",
          "Year": "2022.0",
          "due_date": "N/A",
          "Category": "Cloud Services Provider",
          "VendorName": "sellentt",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-10-14 11:56:34"
      },
      {
          "Inherent_Risk": "Low",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3517067",
          "state": "Incompleted",
          "Year": "2022.0",
          "due_date": "N/A",
          "Category": "Marketing",
          "VendorName": "tony the petfluencer agency gmbh & co. kg",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-01-20 18:49:10"
      },
      {
          "Inherent_Risk": "Low",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3521748",
          "state": "Closed Complete",
          "Year": "2022.0",
          "due_date": "N/A",
          "Category": "Marketing",
          "VendorName": "kozy",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-08-16 10:42:59"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "Moderate",
          "approval": "N/A",
          "number": "RITM3525157",
          "state": "Annual Reassessment Completed",
          "Year": "2022",
          "due_date": "N/A",
          "Category": "Financial Institutions",
          "VendorName": "klarna",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-11-14 15:01:09"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3528681",
          "state": "Closed Complete",
          "Year": "2022.0",
          "due_date": "N/A",
          "Category": "IT Services",
          "VendorName": "micro focus",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-08-24 14:45:56"
      },
      {
          "Inherent_Risk": "Low",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3528874",
          "state": "Closed Complete",
          "Year": "2022.0",
          "due_date": "N/A",
          "Category": "Others",
          "VendorName": "ecolab",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-01-05 10:25:12"
      },
      {
          "Inherent_Risk": "Low",
          "Residual_Risk": "Very Low",
          "approval": "N/A",
          "number": "RITM3529779",
          "state": "Annual Reassessment Completed",
          "Year": "2022",
          "due_date": "N/A",
          "Category": "Software",
          "VendorName": "promach , inc.",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-11-01 14:07:03"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3530241",
          "state": "Closed Complete",
          "Year": "2022.0",
          "due_date": "N/A",
          "Category": "IT Services",
          "VendorName": "in russian - тензор. in english - tensor",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-10-28 07:38:43"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "Moderate",
          "approval": "N/A",
          "number": "RITM3530294",
          "state": "Annual Reassessment Completed",
          "Year": "2022",
          "due_date": "N/A",
          "Category": "Telecommunication Services",
          "VendorName": "mango office",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-12-08 08:37:50"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "Moderate",
          "approval": "N/A",
          "number": "RITM3532156",
          "state": "Annual Reassessment Completed",
          "Year": "2022",
          "due_date": "N/A",
          "Category": "Marketing",
          "VendorName": "fetch rewards",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-09-26 11:42:29"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3538427",
          "state": "Annual Reassessment Completed",
          "Year": "2022",
          "due_date": "N/A",
          "Category": "Professional Services",
          "VendorName": "ansys",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-08-20 10:11:29"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "Moderate",
          "approval": "N/A",
          "number": "RITM3540548",
          "state": "Annual Reassessment Completed",
          "Year": "2022",
          "due_date": "N/A",
          "Category": "Software",
          "VendorName": "innovid",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-09-29 11:06:03"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "Low",
          "approval": "N/A",
          "number": "RITM3545613",
          "state": "Annual Reassessment Completed",
          "Year": "2022",
          "due_date": "N/A",
          "Category": "Software",
          "VendorName": "whatfix - mvh",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-10-12 10:27:28"
      },
      {
          "Inherent_Risk": "Low",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3545855",
          "state": "Closed Complete",
          "Year": "2022.0",
          "due_date": "N/A",
          "Category": "Cloud Services Provider",
          "VendorName": "label box",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-08-24 13:47:50"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3545969",
          "state": "Closed Complete",
          "Year": "2022.0",
          "due_date": "N/A",
          "Category": "Cloud Services Provider",
          "VendorName": "canva",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-10-07 09:58:15"
      },
      {
          "Inherent_Risk": "Critical",
          "Residual_Risk": "Moderate",
          "approval": "N/A",
          "number": "RITM3547195",
          "state": "Annual Reassessment Completed",
          "Year": "2022",
          "due_date": "N/A",
          "Category": "Software",
          "VendorName": "gistlens uk ltd",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-09-29 16:30:42"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "Moderate",
          "approval": "N/A",
          "number": "RITM3548291",
          "state": "Annual Reassessment Completed",
          "Year": "2022",
          "due_date": "N/A",
          "Category": "Software",
          "VendorName": "assembled",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-10-06 12:19:02"
      },
      {
          "Inherent_Risk": "Critical",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3551578",
          "state": "Incompleted",
          "Year": "2022.0",
          "due_date": "N/A",
          "Category": "Cloud Services Provider",
          "VendorName": "cority software plc",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-08-24 10:21:54"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3552934",
          "state": "Closed Complete",
          "Year": "2022.0",
          "due_date": "N/A",
          "Category": "Others",
          "VendorName": "15175850 - arcadis us inc",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-10-05 21:44:34"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3556015",
          "state": "Annual Reassessment In Progress",
          "Year": "2022",
          "due_date": "N/A",
          "Category": "Marketing",
          "VendorName": "breinify ",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-08-25 13:35:08"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3556505",
          "state": "Closed Complete",
          "Year": "2022.0",
          "due_date": "N/A",
          "Category": "Software",
          "VendorName": "15157945 - enablon north america corp",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-10-11 07:09:33"
      },
      {
          "Inherent_Risk": "Low",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3556878",
          "state": "Incompleted",
          "Year": "2022.0",
          "due_date": "N/A",
          "Category": "Logistics",
          "VendorName": "brandspec",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-10-14 03:57:26"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "Moderate",
          "approval": "N/A",
          "number": "RITM3559467",
          "state": "Annual Reassessment Completed",
          "Year": "2022",
          "due_date": "N/A",
          "Category": "Professional Services",
          "VendorName": "skywriter md",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-11-02 14:04:36"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3559603",
          "state": "Annual Reassessment Completed",
          "Year": "2022",
          "due_date": "N/A",
          "Category": "Software",
          "VendorName": "consumer mesh",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-08-26 08:20:28"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "Moderate",
          "approval": "N/A",
          "number": "RITM3559803",
          "state": "Annual Reassessment Completed",
          "Year": "2022",
          "due_date": "N/A",
          "Category": "Cloud Services Provider",
          "VendorName": "vault",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-10-11 08:46:38"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "Moderate",
          "approval": "N/A",
          "number": "RITM3563471",
          "state": "Annual Reassessment In Progress",
          "Year": "2022",
          "due_date": "N/A",
          "Category": "Software",
          "VendorName": "kpmg",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-10-14 12:13:08"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "High",
          "approval": "N/A",
          "number": "RITM3565748",
          "state": "Annual Reassessment Completed",
          "Year": "2022",
          "due_date": "N/A",
          "Category": "IT Services",
          "VendorName": "xomnia",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-12-20 09:30:06"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3574320",
          "state": "Closed Complete",
          "Year": "2022.0",
          "due_date": "N/A",
          "Category": "Software",
          "VendorName": "р7",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-12-06 09:22:42"
      },
      {
          "Inherent_Risk": "Low",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3580750",
          "state": "Closed Complete",
          "Year": "2022.0",
          "due_date": "N/A",
          "Category": "Business Services",
          "VendorName": "recibotech;dharneesh@recibotech.com",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-12-21 07:31:50"
      },
      {
          "Inherent_Risk": "Low",
          "Residual_Risk": "Moderate",
          "approval": "N/A",
          "number": "RITM3583342",
          "state": "Annual Reassessment Completed",
          "Year": "2022",
          "due_date": "N/A",
          "Category": "Others",
          "VendorName": "tredence, inc.",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-10-10 08:06:37"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3583530",
          "state": "Closed Complete",
          "Year": "2022.0",
          "due_date": "N/A",
          "Category": "Advertising Services",
          "VendorName": "vk",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-10-28 13:00:36"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "Moderate",
          "approval": "N/A",
          "number": "RITM3583531",
          "state": "Annual Reassessment Completed",
          "Year": "2022",
          "due_date": "N/A",
          "Category": "Advertising Services",
          "VendorName": "weborama",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-10-10 14:48:17"
      },
      {
          "Inherent_Risk": "Critical",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3584020",
          "state": "Annual Reassessment In Progress",
          "Year": "2022",
          "due_date": "N/A",
          "Category": "Logistics",
          "VendorName": "gregory distribution",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-10-10 03:56:41"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "Low",
          "approval": "N/A",
          "number": "RITM3585333",
          "state": "Annual Reassessment Completed",
          "Year": "2022",
          "due_date": "N/A",
          "Category": "Others",
          "VendorName": "phasorz technologies private limited",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-10-14 12:59:19"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "Moderate",
          "approval": "N/A",
          "number": "RITM3588954",
          "state": "Annual Reassessment Completed",
          "Year": "2022",
          "due_date": "N/A",
          "Category": "Software",
          "VendorName": "infinisoft co.,ltd",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-12-09 01:59:53"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "Moderate",
          "approval": "N/A",
          "number": "RITM3589611",
          "state": "Annual Reassessment In Progress",
          "Year": "2022",
          "due_date": "N/A",
          "Category": "Marketing",
          "VendorName": "clickdimensions",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-11-18 13:58:29"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "Moderate",
          "approval": "N/A",
          "number": "RITM3598608",
          "state": "Annual Reassessment Completed",
          "Year": "2022",
          "due_date": "N/A",
          "Category": "Insurance",
          "VendorName": "express scripts",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-10-14 08:16:06"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "Low",
          "approval": "N/A",
          "number": "RITM3601537",
          "state": "Annual Reassessment Completed",
          "Year": "2022",
          "due_date": "N/A",
          "Category": "Hosted Services Provider",
          "VendorName": "tines",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-09-28 18:09:52"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "['N/A', 'Moderate']",
          "approval": "N/A",
          "number": "RITM3601701",
          "state": "Annual Reassessment Completed",
          "Year": "2022",
          "due_date": "N/A",
          "Category": "Hosted Services Provider",
          "VendorName": "cybersource",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-10-27 11:51:24"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3603575",
          "state": "Incompleted",
          "Year": "2022.0",
          "due_date": "N/A",
          "Category": "IT Services",
          "VendorName": "ken thomas",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-11-01 10:57:30"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3603591",
          "state": "Incompleted",
          "Year": "2022.0",
          "due_date": "N/A",
          "Category": "IT Services",
          "VendorName": "gxo (hull site)",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-01-20 18:38:04"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "High",
          "approval": "N/A",
          "number": "RITM3604317",
          "state": "Annual Reassessment Completed",
          "Year": "2022",
          "due_date": "N/A",
          "Category": "Marketing",
          "VendorName": "testapic",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-01-20 03:22:53"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3615652",
          "state": "Annual Reassessment Completed",
          "Year": "2022",
          "due_date": "N/A",
          "Category": "IT Services",
          "VendorName": "aspirent",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-01-20 18:36:19"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "High",
          "approval": "N/A",
          "number": "RITM3615967",
          "state": "Annual Reassessment Completed",
          "Year": "2022",
          "due_date": "N/A",
          "Category": "Marketing",
          "VendorName": "iovox",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-12-12 17:25:59"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3621481",
          "state": "Closed Complete",
          "Year": "2022.0",
          "due_date": "N/A",
          "Category": "Marketing",
          "VendorName": "retail rocket",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-11-09 09:40:44"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "['N/A', 'Moderate']",
          "approval": "N/A",
          "number": "RITM3622865",
          "state": "Annual Reassessment Completed",
          "Year": "2022",
          "due_date": "N/A",
          "Category": "Cloud Services Provider",
          "VendorName": "coreview",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-10-07 13:19:31"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "Moderate",
          "approval": "N/A",
          "number": "RITM3623513",
          "state": "Annual Reassessment Completed",
          "Year": "2022",
          "due_date": "N/A",
          "Category": "Cloud Services Provider",
          "VendorName": "boomi",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-10-27 11:03:04"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "Moderate",
          "approval": "N/A",
          "number": "RITM3630945",
          "state": "Annual Reassessment Completed",
          "Year": "2022",
          "due_date": "N/A",
          "Category": "Hosted Services Provider",
          "VendorName": "tenable.inc",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-10-27 12:11:11"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3635399",
          "state": "Annual Reassessment Completed",
          "Year": "2022",
          "due_date": "N/A",
          "Category": "IT Services",
          "VendorName": "exo data",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-11-18 12:26:06"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3643464",
          "state": "Closed Complete",
          "Year": "2022.0",
          "due_date": "N/A",
          "Category": "Marketing",
          "VendorName": "mimilab",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-10-06 15:43:23"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "Moderate",
          "approval": "N/A",
          "number": "RITM3646115",
          "state": "Annual Reassessment Completed",
          "Year": "2022",
          "due_date": "N/A",
          "Category": "Others",
          "VendorName": "escapade pr",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-11-03 06:26:06"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "Moderate",
          "approval": "N/A",
          "number": "RITM3652550",
          "state": "Annual Reassessment Completed",
          "Year": "2022",
          "due_date": "N/A",
          "Category": "IT Services",
          "VendorName": "quisitive",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-11-04 13:21:57"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3655497",
          "state": "Annual Reassessment Completed",
          "Year": "2022",
          "due_date": "N/A",
          "Category": "IT Services",
          "VendorName": "s-cubism",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-12-16 19:13:06"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "Moderate",
          "approval": "N/A",
          "number": "RITM3657281",
          "state": "Annual Reassessment Completed",
          "Year": "2022",
          "due_date": "N/A",
          "Category": "IT Services",
          "VendorName": "digital knowledge",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-11-23 07:41:24"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "Moderate",
          "approval": "N/A",
          "number": "RITM3666077",
          "state": "Annual Reassessment Completed",
          "Year": "2022",
          "due_date": "N/A",
          "Category": "Others",
          "VendorName": "resilience360inc., d/ba everstream analytics",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-10-27 11:12:24"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "Moderate",
          "approval": "N/A",
          "number": "RITM3667869",
          "state": "Annual Reassessment Completed",
          "Year": "2022",
          "due_date": "N/A",
          "Category": "Marketing",
          "VendorName": "sogec - equinix group",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-01-19 07:42:14"
      },
      {
          "Inherent_Risk": "Critical",
          "Residual_Risk": "Moderate",
          "approval": "N/A",
          "number": "RITM3670115",
          "state": "Annual Reassessment Completed",
          "Year": "2022",
          "due_date": "N/A",
          "Category": "Audit & Compliance",
          "VendorName": "auditboard ",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-11-01 15:37:10"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "High",
          "approval": "N/A",
          "number": "RITM3671616",
          "state": "Annual Reassessment Completed",
          "Year": "2022",
          "due_date": "N/A",
          "Category": "Others",
          "VendorName": "jet commerce",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-01-20 03:50:05"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "High",
          "approval": "N/A",
          "number": "RITM3675476",
          "state": "Annual Reassessment Completed",
          "Year": "2022",
          "due_date": "N/A",
          "Category": "Others",
          "VendorName": "positive by hinora group kft .",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-12-28 19:37:31"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3677569",
          "state": "Annual Reassessment Completed",
          "Year": "2022",
          "due_date": "N/A",
          "Category": "Cloud Services Provider",
          "VendorName": "商取引、サンプル提供のためのmicrosoft formsによる顧客情報取得",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-12-23 11:34:43"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3679034",
          "state": "Closed Complete",
          "Year": "2022.0",
          "due_date": "N/A",
          "Category": "Others",
          "VendorName": "orionsp",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-10-28 12:09:05"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3687211",
          "state": "Annual Reassessment Completed",
          "Year": "2022",
          "due_date": "N/A",
          "Category": "IT Services",
          "VendorName": "snp group",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-12-09 08:48:48"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3694401",
          "state": "Incompleted",
          "Year": "2022.0",
          "due_date": "N/A",
          "Category": "IT Services",
          "VendorName": "fractal analytics",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-10-31 03:59:15"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "Moderate",
          "approval": "N/A",
          "number": "RITM3694875",
          "state": "Annual Reassessment Completed",
          "Year": "2022",
          "due_date": "N/A",
          "Category": "Insurance",
          "VendorName": "generali",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-12-15 17:43:21"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "High",
          "approval": "N/A",
          "number": "RITM3695116",
          "state": "Annual Reassessment Completed",
          "Year": "2022",
          "due_date": "N/A",
          "Category": "IT Services",
          "VendorName": "koolteck systems",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-12-02 18:07:50"
      },
      {
          "Inherent_Risk": "Low",
          "Residual_Risk": "Low",
          "approval": "N/A",
          "number": "RITM3697508",
          "state": "Annual Reassessment Completed",
          "Year": "2022",
          "due_date": "N/A",
          "Category": "Software",
          "VendorName": "log-hub ",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-12-01 16:57:32"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3698598",
          "state": "Incompleted",
          "Year": "2022.0",
          "due_date": "N/A",
          "Category": "Software",
          "VendorName": "vetfm",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-10-31 09:57:35"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "Moderate",
          "approval": "N/A",
          "number": "RITM3699358",
          "state": "Annual Reassessment Completed",
          "Year": "2022",
          "due_date": "N/A",
          "Category": "Advertising Services",
          "VendorName": "mediacom pia",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-01-20 18:49:33"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "Moderate",
          "approval": "N/A",
          "number": "RITM3702555",
          "state": "Annual Reassessment Completed",
          "Year": "2022",
          "due_date": "N/A",
          "Category": "Cloud Services Provider",
          "VendorName": "zendesk - vca ",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-01-20 04:22:37"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "Moderate",
          "approval": "N/A",
          "number": "RITM3708137",
          "state": "Annual Reassessment Completed",
          "Year": "2022",
          "due_date": "N/A",
          "Category": "Others",
          "VendorName": "ivy mobility",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-10-25 07:38:46"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3708654",
          "state": "Closed Complete",
          "Year": "2022.0",
          "due_date": "N/A",
          "Category": "Hosted Services Provider",
          "VendorName": "first b2b",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-11-04 10:44:06"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3713245",
          "state": "Annual Reassessment In Progress",
          "Year": "2022",
          "due_date": "N/A",
          "Category": "Marketing",
          "VendorName": "tencent",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-11-25 03:59:47"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3713688",
          "state": "Incompleted",
          "Year": "2022.0",
          "due_date": "N/A",
          "Category": "Marketing",
          "VendorName": "tony the petfluencer agency gmbh",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-11-11 08:27:29"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3713716",
          "state": "Incompleted",
          "Year": "2022.0",
          "due_date": "N/A",
          "Category": "Marketing",
          "VendorName": "kjero gmbh",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-01-09 11:38:41"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "Moderate",
          "approval": "N/A",
          "number": "RITM3719161",
          "state": "Annual Reassessment Completed",
          "Year": "2022",
          "due_date": "N/A",
          "Category": "Cloud Services Provider",
          "VendorName": "highspot - ant",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-11-25 15:12:31"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3721903",
          "state": "Closed Complete",
          "Year": "2022.0",
          "due_date": "N/A",
          "Category": "Software",
          "VendorName": "360 drc pazarlama organizasyon i̇letisim hizmetleri ticaret limited sirketi",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-01-06 10:04:06"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "Moderate",
          "approval": "N/A",
          "number": "RITM3725351",
          "state": "Annual Reassessment Completed",
          "Year": "2022",
          "due_date": "N/A",
          "Category": "IT Services",
          "VendorName": "wavestone",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-12-29 05:40:42"
      },
      {
          "Inherent_Risk": "Low",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3725479",
          "state": "Incompleted",
          "Year": "2022.0",
          "due_date": "N/A",
          "Category": "Marketing",
          "VendorName": "sa365",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-11-07 08:51:37"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3725571",
          "state": "Incompleted",
          "Year": "2022.0",
          "due_date": "N/A",
          "Category": "Hardware",
          "VendorName": "yubico",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-11-01 10:02:09"
      },
      {
          "Inherent_Risk": "Critical",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3728062",
          "state": "Closed Complete",
          "Year": "2022.0",
          "due_date": "N/A",
          "Category": "Cloud Services Provider",
          "VendorName": "dataline llc",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-12-20 12:58:25"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3730521",
          "state": "Closed Complete",
          "Year": "2022.0",
          "due_date": "N/A",
          "Category": "Software",
          "VendorName": "maersk (created together with e2open the neonav application)",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-01-23 16:44:23"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "Moderate",
          "approval": "N/A",
          "number": "RITM3732026",
          "state": "Annual Reassessment Completed",
          "Year": "2022",
          "due_date": "N/A",
          "Category": "Cloud Services Provider",
          "VendorName": "drift - vca",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-12-01 15:55:09"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "Moderate",
          "approval": "N/A",
          "number": "RITM3735641",
          "state": "Annual Reassessment Completed",
          "Year": "2022",
          "due_date": "N/A",
          "Category": "Financial Institutions",
          "VendorName": "scratch financial",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-12-20 21:38:40"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3735986",
          "state": "Annual Reassessment Completed",
          "Year": "2022",
          "due_date": "N/A",
          "Category": "Cloud Services Provider",
          "VendorName": "heska - mvh",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-12-07 13:06:15"
      },
      {
          "Inherent_Risk": "Low",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3739390",
          "state": "Incompleted",
          "Year": "2022.0",
          "due_date": "N/A",
          "Category": "Software",
          "VendorName": "kennelsoft",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-12-19 08:35:15"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3747379",
          "state": "Incompleted",
          "Year": "2022.0",
          "due_date": "N/A",
          "Category": "Hosted Services Provider",
          "VendorName": "tz",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-01-20 18:59:31"
      },
      {
          "Inherent_Risk": "Moderate",
          "Residual_Risk": "Low",
          "approval": "N/A",
          "number": "RITM3764862",
          "state": "Closed Complete",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "IT Services",
          "VendorName": "first track marketing",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-12-19 08:54:23"
      },
      {
          "Inherent_Risk": "Low",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3768016",
          "state": "Annual Reassessment Completed",
          "Year": "2022",
          "due_date": "N/A",
          "Category": "Others",
          "VendorName": "hansalog gmbh & co kg",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-11-21 05:41:37"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "Moderate",
          "approval": "N/A",
          "number": "RITM3768110",
          "state": "Annual Reassessment Completed",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "Cloud Services Provider",
          "VendorName": "pci pal",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-12-22 12:13:24"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3771735",
          "state": "Annual Reassessment Completed",
          "Year": "2022",
          "due_date": "N/A",
          "Category": "Software",
          "VendorName": "github",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-12-26 09:33:26"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3790156",
          "state": "Closed Complete",
          "Year": "2023.0",
          "due_date": "N/A",
          "Category": "Software",
          "VendorName": "ascentis private limited (company registration number: 200305976r)",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-01-05 20:05:40"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3797071",
          "state": "Incompleted",
          "Year": "2023.0",
          "due_date": "N/A",
          "Category": "IT Services",
          "VendorName": "shanghai kunde info-tech co., ltd",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-12-20 08:55:18"
      },
      {
          "Inherent_Risk": "Low",
          "Residual_Risk": "Low",
          "approval": "N/A",
          "number": "RITM3797610",
          "state": "Closed Complete",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "Software",
          "VendorName": "auvesy-mdt",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-11-29 12:51:48"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3799494",
          "state": "Annual Reassessment In Progress",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "Cloud Services Provider",
          "VendorName": "onestream",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-12-07 07:00:43"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "High",
          "approval": "N/A",
          "number": "RITM3804484",
          "state": "Closed Complete",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "Marketing",
          "VendorName": "fandscreations",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-03-03 18:01:07"
      },
      {
          "Inherent_Risk": "Moderate",
          "Residual_Risk": "Moderate",
          "approval": "N/A",
          "number": "RITM3811811",
          "state": "Closed Complete",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "Software",
          "VendorName": "powersoft",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-12-21 12:29:54"
      },
      {
          "Inherent_Risk": "Low",
          "Residual_Risk": "Low",
          "approval": "N/A",
          "number": "RITM3825950",
          "state": "Closed Complete",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "Others",
          "VendorName": "zelty",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-12-21 12:39:09"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3827774",
          "state": "Closed Complete",
          "Year": "2023.0",
          "due_date": "N/A",
          "Category": "IT Services",
          "VendorName": "infobip asia pacific sdn bhd",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-01-05 11:12:23"
      },
      {
          "Inherent_Risk": "Low",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3836373",
          "state": "Incompleted",
          "Year": "2023.0",
          "due_date": "N/A",
          "Category": "IT Services",
          "VendorName": "taksu digital",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-12-26 10:04:30"
      },
      {
          "Inherent_Risk": "Low",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3836695",
          "state": "Closed Complete",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "Software",
          "VendorName": "interlake mecalux",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-01-16 09:36:43"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3837966",
          "state": "Closed Complete",
          "Year": "2023.0",
          "due_date": "N/A",
          "Category": "Professional Services",
          "VendorName": "nttdata / everis italia s.p.a.",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-12-22 08:44:36"
      },
      {
          "Inherent_Risk": "Very Low",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3844861",
          "state": "Incompleted",
          "Year": "2023.0",
          "due_date": "N/A",
          "Category": "Others",
          "VendorName": "cubex",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-01-16 10:39:40"
      },
      {
          "Inherent_Risk": "Critical",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3848844",
          "state": "Incompleted",
          "Year": "2023.0",
          "due_date": "N/A",
          "Category": "IT Services",
          "VendorName": "kevin.vanblarcom@effem.com",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-01-27 04:00:21"
      },
      {
          "Inherent_Risk": "Low",
          "Residual_Risk": "Low",
          "approval": "N/A",
          "number": "RITM3864572",
          "state": "Closed Complete",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "IT Services",
          "VendorName": "bloomberg finance lp",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-01-10 18:08:55"
      },
      {
          "Inherent_Risk": "Low",
          "Residual_Risk": "Low",
          "approval": "N/A",
          "number": "RITM3864615",
          "state": "Closed Complete",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "IT Services",
          "VendorName": "improbable worlds limited",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-01-05 15:35:36"
      },
      {
          "Inherent_Risk": "Moderate",
          "Residual_Risk": "Low",
          "approval": "N/A",
          "number": "RITM3864617",
          "state": "Annual Reassessment Completed",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "Marketing",
          "VendorName": "mikmak ",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-01-05 09:58:12"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "Moderate",
          "approval": "N/A",
          "number": "RITM3865018",
          "state": "Closed Complete",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "IT Services",
          "VendorName": "bittitan",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-01-20 15:20:31"
      },
      {
          "Inherent_Risk": "Low",
          "Residual_Risk": "Low",
          "approval": "N/A",
          "number": "RITM3865025",
          "state": "Closed Complete",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "IT Services",
          "VendorName": "sharegate",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-01-06 16:46:50"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3867441",
          "state": "Incompleted",
          "Year": "2023.0",
          "due_date": "N/A",
          "Category": "Cloud Services Provider",
          "VendorName": "phenom people, inc",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2022-12-22 08:41:59"
      },
      {
          "Inherent_Risk": "Critical",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3871266",
          "state": "Incompleted",
          "Year": "2023.0",
          "due_date": "N/A",
          "Category": "Hardware",
          "VendorName": "antech diagnostics, inc.",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-01-16 11:46:36"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3877368",
          "state": "Incompleted",
          "Year": "2023.0",
          "due_date": "N/A",
          "Category": "Software",
          "VendorName": "quercus",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-02-20 03:59:54"
      },
      {
          "Inherent_Risk": "Low",
          "Residual_Risk": "Low",
          "approval": "N/A",
          "number": "RITM3880309",
          "state": "Closed Complete",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "Software",
          "VendorName": "nuance communications, inc.",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-01-11 12:18:08"
      },
      {
          "Inherent_Risk": "Moderate",
          "Residual_Risk": "Low",
          "approval": "N/A",
          "number": "RITM3890831",
          "state": "Closed Complete",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "Cloud Services Provider",
          "VendorName": "wolters kluwer",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-01-09 13:09:40"
      },
      {
          "Inherent_Risk": "Low",
          "Residual_Risk": "Low",
          "approval": "N/A",
          "number": "RITM3905226",
          "state": "Closed Complete",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "Business Services",
          "VendorName": "angel labs llc",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-01-11 07:53:00"
      },
      {
          "Inherent_Risk": "Critical",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3907021",
          "state": "Closed Complete",
          "Year": "2023.0",
          "due_date": "N/A",
          "Category": "IT Services",
          "VendorName": "tallships solutions pty ltd (sap 10007715)",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-04-18 10:37:30"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3909069",
          "state": "Closed Complete",
          "Year": "2023.0",
          "due_date": "N/A",
          "Category": "Marketing",
          "VendorName": "nch marketing services ltd, trading as savi (nb, were previously trading as  valassis)",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-03-17 08:38:12"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "Moderate",
          "approval": "N/A",
          "number": "RITM3910369",
          "state": "Annual Reassessment Completed",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "Cloud Services Provider",
          "VendorName": "moveworks - vca",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-02-03 11:31:36"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "Moderate",
          "approval": "N/A",
          "number": "RITM3913962",
          "state": "Annual Reassessment Completed",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "Cloud Services Provider",
          "VendorName": "sikka sofware corporation",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-03-17 14:50:53"
      },
      {
          "Inherent_Risk": "Low",
          "Residual_Risk": "Low",
          "approval": "N/A",
          "number": "RITM3918027",
          "state": "Closed Complete",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "IT Services",
          "VendorName": "shanghai jian gong si yu",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-01-18 14:58:10"
      },
      {
          "Inherent_Risk": "Low",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3925118",
          "state": "Closed Complete",
          "Year": "2023.0",
          "due_date": "N/A",
          "Category": "Logistics",
          "VendorName": "sovtes (transport management system)",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-01-20 15:59:46"
      },
      {
          "Inherent_Risk": "Moderate",
          "Residual_Risk": "Low",
          "approval": "N/A",
          "number": "RITM3926381",
          "state": "Closed Complete",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "Cloud Services Provider",
          "VendorName": "crisp, inc.",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-02-06 17:05:02"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "High",
          "approval": "N/A",
          "number": "RITM3929249",
          "state": "Annual Reassessment Completed",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "Marketing",
          "VendorName": "elite digital inc.",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-04-21 20:04:48"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "Moderate",
          "approval": "N/A",
          "number": "RITM3929610",
          "state": "Annual Reassessment Completed",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "Cloud Services Provider",
          "VendorName": "archer technologies llc ",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-02-20 15:57:37"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3936462",
          "state": "Incompleted",
          "Year": "2023.0",
          "due_date": "N/A",
          "Category": "Advertising Services",
          "VendorName": "freshive",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-04-11 03:56:49"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "Moderate",
          "approval": "N/A",
          "number": "RITM3936773",
          "state": "Annual Reassessment Completed",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "Software",
          "VendorName": "compusense",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-02-13 15:42:13"
      },
      {
          "Inherent_Risk": "Low",
          "Residual_Risk": "Low",
          "approval": "N/A",
          "number": "RITM3937144",
          "state": "Closed Complete",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "Others",
          "VendorName": "esprigas",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-02-08 13:48:25"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3937163",
          "state": "Incompleted",
          "Year": "2023.0",
          "due_date": "N/A",
          "Category": "Cloud Services Provider",
          "VendorName": "martti - cloudbreak",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-02-21 13:49:45"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3939429",
          "state": "Annual Reassessment Completed",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "Marketing",
          "VendorName": "bonsey jaden (thailand) co. ltd.",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-04-24 10:39:00"
      },
      {
          "Inherent_Risk": "Low",
          "Residual_Risk": "Low",
          "approval": "N/A",
          "number": "RITM3939863",
          "state": "Annual Reassessment Completed",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "Hardware",
          "VendorName": "zoetis - mvh ",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-01-20 16:08:26"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "Moderate",
          "approval": "N/A",
          "number": "RITM3953015",
          "state": "Annual Reassessment Completed",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "Others",
          "VendorName": "trianon",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-03-20 09:28:07"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3955169",
          "state": "Incompleted",
          "Year": "2023.0",
          "due_date": "N/A",
          "Category": "Software",
          "VendorName": "lisse",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-02-13 03:58:05"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3957839",
          "state": "Annual Reassessment Completed",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "Telecommunication Services",
          "VendorName": "vs group",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-02-27 05:30:42"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3962522",
          "state": "Incompleted",
          "Year": "2023.0",
          "due_date": "N/A",
          "Category": "Professional Services",
          "VendorName": "upgrading srl",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-04-19 14:24:41"
      },
      {
          "Inherent_Risk": "Moderate",
          "Residual_Risk": "Low",
          "approval": "N/A",
          "number": "RITM3962815",
          "state": "Closed Complete",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "Cloud Services Provider",
          "VendorName": "momentive.ai - getfeedback",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-02-06 10:55:44"
      },
      {
          "Inherent_Risk": "Critical",
          "Residual_Risk": "High",
          "approval": "N/A",
          "number": "RITM3966456",
          "state": "Annual Reassessment Completed",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "Professional Services",
          "VendorName": "actemium",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-03-24 17:07:44"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "Moderate",
          "approval": "N/A",
          "number": "RITM3966879",
          "state": "Annual Reassessment Completed",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "Software",
          "VendorName": "absence soft, llc",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-03-14 16:11:27"
      },
      {
          "Inherent_Risk": "Moderate",
          "Residual_Risk": "Low",
          "approval": "N/A",
          "number": "RITM3971024",
          "state": "Closed Complete",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "Software",
          "VendorName": "metasource",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-02-10 15:31:36"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "High",
          "approval": "N/A",
          "number": "RITM3972567",
          "state": "Annual Reassessment Completed",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "Others",
          "VendorName": "new cold",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-03-27 17:24:18"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3974804",
          "state": "Incompleted",
          "Year": "2023.0",
          "due_date": "N/A",
          "Category": "IT Services",
          "VendorName": "günter badenhop fleischwerke kg",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-03-17 03:58:43"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "Moderate",
          "approval": "N/A",
          "number": "RITM3978044",
          "state": "Annual Reassessment Completed",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "Others",
          "VendorName": "deepdyve ",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-03-21 08:59:56"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM3978872",
          "state": "Annual Reassessment Completed",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "Cloud Services Provider",
          "VendorName": "merchantcentric - vca",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-04-24 10:40:09"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "Moderate",
          "approval": "N/A",
          "number": "RITM3983081",
          "state": "Annual Reassessment Completed",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "Software",
          "VendorName": "goto",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-03-16 13:13:18"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "Moderate",
          "approval": "N/A",
          "number": "RITM3983335",
          "state": "Annual Reassessment Completed",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "Others",
          "VendorName": "clenergyev",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-04-06 07:48:41"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "Moderate",
          "approval": "N/A",
          "number": "RITM4005370",
          "state": "Annual Reassessment Completed",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "IT Services",
          "VendorName": "edicom",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-03-22 13:59:45"
      },
      {
          "Inherent_Risk": "Moderate",
          "Residual_Risk": "Moderate",
          "approval": "N/A",
          "number": "RITM4009318",
          "state": "Closed Complete",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "Advertising Services",
          "VendorName": "none",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-02-17 13:07:37"
      },
      {
          "Inherent_Risk": "Low",
          "Residual_Risk": "Low",
          "approval": "N/A",
          "number": "RITM4010784",
          "state": "Closed Complete",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "Marketing",
          "VendorName": "loyalist, llc",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-02-24 16:43:32"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "Moderate",
          "approval": "N/A",
          "number": "RITM4012991",
          "state": "Annual Reassessment Completed",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "IT Services",
          "VendorName": "anaconda",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-04-20 03:54:45"
      },
      {
          "Inherent_Risk": "Very Low",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM4013238",
          "state": "Incompleted",
          "Year": "2023.0",
          "due_date": "N/A",
          "Category": "Software",
          "VendorName": "rev-trac",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-03-03 03:59:01"
      },
      {
          "Inherent_Risk": "Low",
          "Residual_Risk": "Low",
          "approval": "N/A",
          "number": "RITM4013349",
          "state": "Closed Complete",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "Business Services",
          "VendorName": "seekout",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-03-01 18:39:46"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "High",
          "approval": "N/A",
          "number": "RITM4013409",
          "state": "Annual Reassessment Completed",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "Professional Services",
          "VendorName": "all in works",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-04-17 12:35:14"
      },
      {
          "Inherent_Risk": "Moderate",
          "Residual_Risk": "Low",
          "approval": "N/A",
          "number": "RITM4015265",
          "state": "Closed Complete",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "Business Services",
          "VendorName": "dcactiv impactxp",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-03-01 14:56:30"
      },
      {
          "Inherent_Risk": "Critical",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM4016084",
          "state": "Incompleted",
          "Year": "2023.0",
          "due_date": "N/A",
          "Category": "Accounting/Bookkeeping Services",
          "VendorName": "this is a test - please contact roberto.barros@effem.com for questions is needed",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-02-17 20:24:01"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM4021899",
          "state": "Annual Reassessment Completed",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "Marketing",
          "VendorName": "serviceplan",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-04-07 17:19:59"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "Moderate",
          "approval": "N/A",
          "number": "RITM4023970",
          "state": "Annual Reassessment Completed",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "IT Services",
          "VendorName": "godo kaisha amaris japan",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-03-28 10:08:50"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "Moderate",
          "approval": "N/A",
          "number": "RITM4029479",
          "state": "Annual Reassessment Completed",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "Cloud Services Provider",
          "VendorName": "mirato",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-03-28 18:49:31"
      },
      {
          "Inherent_Risk": "Moderate",
          "Residual_Risk": "Low",
          "approval": "N/A",
          "number": "RITM4034014",
          "state": "Closed Complete",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "Software",
          "VendorName": "maxval group, inc.",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-02-27 13:18:03"
      },
      {
          "Inherent_Risk": "Low",
          "Residual_Risk": "Low",
          "approval": "N/A",
          "number": "RITM4037588",
          "state": "Closed Complete",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "Marketing",
          "VendorName": "quantum",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-03-09 11:13:57"
      },
      {
          "Inherent_Risk": "Very Low",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM4039011",
          "state": "Incompleted",
          "Year": "2023.0",
          "due_date": "N/A",
          "Category": "Office and Commercial Furniture",
          "VendorName": "this is a production smoke test",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-02-28 10:28:18"
      },
      {
          "Inherent_Risk": "Moderate",
          "Residual_Risk": "Low",
          "approval": "N/A",
          "number": "RITM4039036",
          "state": "Closed Complete",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "Cloud Services Provider",
          "VendorName": "privacera",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-03-09 12:41:24"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "Moderate",
          "approval": "N/A",
          "number": "RITM4040923",
          "state": "Annual Reassessment Completed",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "IT Services",
          "VendorName": "leanix",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-04-13 15:05:30"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM4048426",
          "state": "Closed Complete",
          "Year": "2023.0",
          "due_date": "N/A",
          "Category": "Business Services",
          "VendorName": "alejandro jaramillo",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-05-04 15:06:07"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "Moderate",
          "approval": "N/A",
          "number": "RITM4051451",
          "state": "Closed Complete",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "Software",
          "VendorName": "a tune",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-04-25 18:00:40"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "High",
          "approval": "N/A",
          "number": "RITM4051735",
          "state": "Annual Reassessment Completed",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "Software",
          "VendorName": "onsafety",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-04-24 17:29:06"
      },
      {
          "Inherent_Risk": "Low",
          "Residual_Risk": "Low",
          "approval": "N/A",
          "number": "RITM4053324",
          "state": "Annual Reassessment Completed",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "Education/Training",
          "VendorName": "kumullus",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-03-15 13:21:42"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "Moderate",
          "approval": "N/A",
          "number": "RITM4057077",
          "state": "Annual Reassessment Completed",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "Others",
          "VendorName": "people safe",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-04-28 20:29:22"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "High",
          "approval": "N/A",
          "number": "RITM4059741",
          "state": "Annual Reassessment Completed",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "Cloud Services Provider",
          "VendorName": "cognito forms",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-03-24 09:36:55"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "Moderate",
          "approval": "N/A",
          "number": "RITM4069561",
          "state": "Annual Reassessment Completed",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "Telecommunication Services",
          "VendorName": "gupshup",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-04-07 17:32:22"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "Moderate",
          "approval": "N/A",
          "number": "RITM4070954",
          "state": "Annual Reassessment Completed",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "Cloud Services Provider",
          "VendorName": "optimizely",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-04-19 11:28:06"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "Moderate",
          "approval": "N/A",
          "number": "RITM4075879",
          "state": "Annual Reassessment Completed",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "IT Services",
          "VendorName": "stardog",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-03-29 09:41:15"
      },
      {
          "Inherent_Risk": "Low",
          "Residual_Risk": "Low",
          "approval": "N/A",
          "number": "RITM4080032",
          "state": "Closed Complete",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "Software",
          "VendorName": "keepa",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-03-17 09:39:50"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "High",
          "approval": "N/A",
          "number": "RITM4082770",
          "state": "Annual Reassessment Completed",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "Software",
          "VendorName": "bull marketing sas",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-04-04 15:41:14"
      },
      {
          "Inherent_Risk": "Low",
          "Residual_Risk": "Low",
          "approval": "N/A",
          "number": "RITM4083205",
          "state": "Closed Complete",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "Software",
          "VendorName": "digital innovation agency",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-03-24 11:07:13"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "High",
          "approval": "N/A",
          "number": "RITM4085783",
          "state": "Annual Reassessment Completed",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "Others",
          "VendorName": "hansalog",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-04-07 13:39:34"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "High",
          "approval": "N/A",
          "number": "RITM4086354",
          "state": "Annual Reassessment Completed",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "Professional Services",
          "VendorName": "phoneta ltd",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-04-17 07:27:50"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "Moderate",
          "approval": "N/A",
          "number": "RITM4088259",
          "state": "Annual Reassessment Completed",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "Marketing",
          "VendorName": "deepzero",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-04-27 15:15:23"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM4089585",
          "state": "Annual Reassessment Completed",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "Hosted Services Provider",
          "VendorName": "ves - crm hubspot",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-04-11 13:11:50"
      },
      {
          "Inherent_Risk": "Moderate",
          "Residual_Risk": "Low",
          "approval": "N/A",
          "number": "RITM4090072",
          "state": "Closed Complete",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "Software",
          "VendorName": "vecna robotics",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-03-24 14:10:51"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM4090189",
          "state": "Annual Reassessment Completed",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "Cloud Services Provider",
          "VendorName": "kantar",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-04-28 19:17:15"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "High",
          "approval": "N/A",
          "number": "RITM4093835",
          "state": "Annual Reassessment Completed",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "Software",
          "VendorName": "redjade",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-04-10 12:56:33"
      },
      {
          "Inherent_Risk": "Moderate",
          "Residual_Risk": "Low",
          "approval": "N/A",
          "number": "RITM4093884",
          "state": "Closed Complete",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "Others",
          "VendorName": "traxxside",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-03-28 09:53:01"
      },
      {
          "Inherent_Risk": "Low",
          "Residual_Risk": "Low",
          "approval": "N/A",
          "number": "RITM4095897",
          "state": "Closed Complete",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "Others",
          "VendorName": "minivertising",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-04-05 17:33:34"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "Moderate",
          "approval": "N/A",
          "number": "RITM4096409",
          "state": "Annual Reassessment Completed",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "Cloud Services Provider",
          "VendorName": "imc",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-05-02 18:53:18"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "Moderate",
          "approval": "N/A",
          "number": "RITM4097878",
          "state": "Annual Reassessment Completed",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "Others",
          "VendorName": "metro logistics",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-04-19 13:17:58"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "High",
          "approval": "N/A",
          "number": "RITM4099504",
          "state": "Annual Reassessment Completed",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "Real Estate",
          "VendorName": "shanghai quanta logistics co., ltd.",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-04-13 21:18:14"
      },
      {
          "Inherent_Risk": "Moderate",
          "Residual_Risk": "Low",
          "approval": "N/A",
          "number": "RITM4101279",
          "state": "Closed Complete",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "Marketing",
          "VendorName": "journey",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-04-19 12:41:55"
      },
      {
          "Inherent_Risk": "Very Low",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM4101794",
          "state": "Incompleted",
          "Year": "2023.0",
          "due_date": "N/A",
          "Category": "Software",
          "VendorName": "api void",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-04-06 03:57:32"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "High",
          "approval": "N/A",
          "number": "RITM4103207",
          "state": "Annual Reassessment Completed",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "Marketing",
          "VendorName": "5ème etage",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-03-29 16:54:54"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "Moderate",
          "approval": "N/A",
          "number": "RITM4108712",
          "state": "Annual Reassessment In Progress",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "Cloud Services Provider",
          "VendorName": "entelo",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-04-10 11:58:24"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "Moderate",
          "approval": "N/A",
          "number": "RITM4111081",
          "state": "Annual Reassessment Completed",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "IT Services",
          "VendorName": "coas software systems bv",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-05-03 17:38:29"
      },
      {
          "Inherent_Risk": "Low",
          "Residual_Risk": "Low",
          "approval": "N/A",
          "number": "RITM4116106",
          "state": "Closed Complete",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "Software",
          "VendorName": "omega engineering inc.",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-04-21 15:53:45"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "Moderate",
          "approval": "N/A",
          "number": "RITM4117231",
          "state": "Annual Reassessment Completed",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "Cloud Services Provider",
          "VendorName": "vetrocket",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-04-13 05:43:16"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "Moderate",
          "approval": "N/A",
          "number": "RITM4117265",
          "state": "Annual Reassessment Completed",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "Professional Services",
          "VendorName": "inspire11",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-04-17 07:22:31"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "Moderate",
          "approval": "N/A",
          "number": "RITM4117507",
          "state": "Annual Reassessment Completed",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "Cloud Services Provider",
          "VendorName": "sprout social",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-05-03 15:17:58"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM4121593",
          "state": "Annual Reassessment Completed",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "Business Services",
          "VendorName": "eshopbox",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-04-24 07:44:20"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "Moderate",
          "approval": "N/A",
          "number": "RITM4123903",
          "state": "Annual Reassessment Completed",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "Marketing",
          "VendorName": "tik tok",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-04-20 08:25:11"
      },
      {
          "Inherent_Risk": "Critical",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM4123913",
          "state": "Incompleted",
          "Year": "2023.0",
          "due_date": "N/A",
          "Category": "Marketing",
          "VendorName": "bytedance llc",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-04-20 03:58:15"
      },
      {
          "Inherent_Risk": "Moderate",
          "Residual_Risk": "Low",
          "approval": "N/A",
          "number": "RITM4124076",
          "state": "Closed Complete",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "IT Services",
          "VendorName": "b2midia",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-04-14 07:36:47"
      },
      {
          "Inherent_Risk": "Moderate",
          "Residual_Risk": "Low",
          "approval": "N/A",
          "number": "RITM4127414",
          "state": "Closed Complete",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "Audit & Compliance",
          "VendorName": "safety culture",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-04-20 09:49:53"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "Moderate",
          "approval": "N/A",
          "number": "RITM4127561",
          "state": "Annual Reassessment Completed",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "Professional Services",
          "VendorName": "payactiv",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-04-06 05:57:43"
      },
      {
          "Inherent_Risk": "Low",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM4130122",
          "state": "In-Progress",
          "Year": "2023.0",
          "due_date": "N/A",
          "Category": "Others",
          "VendorName": "corporate benefits",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-04-24 11:09:54"
      },
      {
          "Inherent_Risk": "Moderate",
          "Residual_Risk": "Low",
          "approval": "N/A",
          "number": "RITM4134341",
          "state": "Closed Complete",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "Software",
          "VendorName": "soti inc.",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-04-20 10:21:28"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "Moderate",
          "approval": "N/A",
          "number": "RITM4138405",
          "state": "Annual Reassessment Completed",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "Laboratory services",
          "VendorName": "foss",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-05-03 12:32:41"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM4139761",
          "state": "Annual Reassessment Completed",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "Software",
          "VendorName": "clinch",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-05-03 20:23:05"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "Moderate",
          "approval": "N/A",
          "number": "RITM4140252",
          "state": "Annual Reassessment Completed",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "Cloud Services Provider",
          "VendorName": "credly inc.",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-04-24 08:40:39"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "High",
          "approval": "N/A",
          "number": "RITM4141064",
          "state": "Annual Reassessment Completed",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "Logistics",
          "VendorName": "kikaku-umi",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-04-19 06:27:56"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM4143408",
          "state": "Annual Reassessment In Progress",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "Cloud Services Provider",
          "VendorName": "wp engine",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-04-24 04:46:26"
      },
      {
          "Inherent_Risk": "Critical",
          "Residual_Risk": "Moderate",
          "approval": "N/A",
          "number": "RITM4152329",
          "state": "Annual Reassessment Completed",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "Cloud Services Provider",
          "VendorName": "soroco",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-04-24 09:39:20"
      },
      {
          "Inherent_Risk": "Low",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM4158878",
          "state": "Annual Reassessment In Progress",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "Others",
          "VendorName": "egym wellpass gmbh",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-04-24 04:53:09"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "Moderate",
          "approval": "N/A",
          "number": "RITM4162047",
          "state": "Annual Reassessment Completed",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "IT Services",
          "VendorName": "unit4 polska sp. z o.o.",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-04-24 07:42:48"
      },
      {
          "Inherent_Risk": "Moderate",
          "Residual_Risk": "Low",
          "approval": "N/A",
          "number": "RITM4168446",
          "state": "Closed Complete",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "IT Services",
          "VendorName": "ciklum",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-05-03 07:58:29"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "High",
          "approval": "N/A",
          "number": "RITM4170165",
          "state": "Annual Reassessment Completed",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "IT Services",
          "VendorName": "humo co ltd",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-04-24 04:58:00"
      },
      {
          "Inherent_Risk": "Low",
          "Residual_Risk": "Low",
          "approval": "N/A",
          "number": "RITM4170577",
          "state": "Closed Complete",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "Hardware",
          "VendorName": "krones",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-04-29 07:13:24"
      },
      {
          "Inherent_Risk": "Low",
          "Residual_Risk": "Low",
          "approval": "N/A",
          "number": "RITM4170593",
          "state": "Closed Complete",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "Hardware",
          "VendorName": "imogeema",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-04-29 07:16:12"
      },
      {
          "Inherent_Risk": "Moderate",
          "Residual_Risk": "Low",
          "approval": "N/A",
          "number": "RITM4172803",
          "state": "Closed Complete",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "Business Services",
          "VendorName": "people vox",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-04-27 18:08:15"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "Moderate",
          "approval": "N/A",
          "number": "RITM4173631",
          "state": "Annual Reassessment Completed",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "IT Services",
          "VendorName": "adobe",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-04-20 14:48:43"
      },
      {
          "Inherent_Risk": "Critical",
          "Residual_Risk": "High",
          "approval": "N/A",
          "number": "RITM4175098",
          "state": "Annual Reassessment Completed",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "Logistics",
          "VendorName": "hoy haul",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-04-24 10:41:02"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "High",
          "approval": "N/A",
          "number": "RITM4180248",
          "state": "Annual Reassessment Completed",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "Marketing",
          "VendorName": "labellium",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-04-24 18:13:31"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "Moderate",
          "approval": "N/A",
          "number": "RITM4181841",
          "state": "Annual Reassessment Completed",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "Cloud Services Provider",
          "VendorName": "elfo",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-04-24 17:56:57"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "Moderate",
          "approval": "N/A",
          "number": "RITM4186816",
          "state": "Annual Reassessment Completed",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "Software",
          "VendorName": "ioco",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-06-08 19:11:37"
      },
      {
          "Inherent_Risk": "Moderate",
          "Residual_Risk": "Low",
          "approval": "N/A",
          "number": "RITM4187508",
          "state": "Closed Complete",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "Software",
          "VendorName": "vizzuality",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-05-02 16:17:16"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "Moderate",
          "approval": "N/A",
          "number": "RITM4188560",
          "state": "Annual Reassessment Completed",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "Software",
          "VendorName": "origami risk",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-05-27 04:13:53"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "Moderate",
          "approval": "N/A",
          "number": "RITM4188718",
          "state": "Annual Reassessment Completed",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "Business Services",
          "VendorName": "stillae",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-06-07 14:51:29"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "Moderate",
          "approval": "N/A",
          "number": "RITM4190658",
          "state": "Annual Reassessment Completed",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "IT Services",
          "VendorName": "guangzhou xunyi technology co.,ltd.",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-06-19 18:22:15"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "High",
          "approval": "N/A",
          "number": "RITM4191422",
          "state": "Annual Reassessment Completed",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "Business Services",
          "VendorName": "marche ricche",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-06-05 17:37:28"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "Moderate",
          "approval": "N/A",
          "number": "RITM4192779",
          "state": "Annual Reassessment In Progress",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "Software",
          "VendorName": "typeform  ",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-05-27 04:48:22"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "Moderate",
          "approval": "N/A",
          "number": "RITM4194548",
          "state": "Annual Reassessment Completed",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "IT Services",
          "VendorName": "rapid global",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-06-07 18:45:14"
      },
      {
          "Inherent_Risk": "Moderate",
          "Residual_Risk": "Low",
          "approval": "N/A",
          "number": "RITM4196248",
          "state": "Closed Complete",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "IT Services",
          "VendorName": "relex inc.",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-05-02 16:13:08"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "Moderate",
          "approval": "N/A",
          "number": "RITM4197782",
          "state": "Annual Reassessment Completed",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "Software",
          "VendorName": "my pet portal",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-06-26 09:31:29"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "Moderate",
          "approval": "N/A",
          "number": "RITM4203255",
          "state": "Annual Reassessment Completed",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "Cloud Services Provider",
          "VendorName": "actyv",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-06-14 13:18:06"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM4211836",
          "state": "Annual Reassessment Completed",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "IT Services",
          "VendorName": "bizanalytica",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-06-09 18:21:01"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM4218307",
          "state": "Closed Complete",
          "Year": "2023.0",
          "due_date": "N/A",
          "Category": "Software",
          "VendorName": "上海云扩信息科技有限公司",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-07-18 18:27:26"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "High",
          "approval": "N/A",
          "number": "RITM4225824",
          "state": "Annual Reassessment Completed",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "Cloud Services Provider",
          "VendorName": "vetlink pro",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-07-18 08:15:00"
      },
      {
          "Inherent_Risk": "Moderate",
          "Residual_Risk": "Low",
          "approval": "N/A",
          "number": "RITM4225880",
          "state": "Closed Complete",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "IT Services",
          "VendorName": "amnet technology pte ltd",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-05-29 15:54:58"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "Moderate",
          "approval": "N/A",
          "number": "RITM4226848",
          "state": "Annual Reassessment Completed",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "Others",
          "VendorName": "johnson controls inc.",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-07-12 11:45:36"
      },
      {
          "Inherent_Risk": "Moderate",
          "Residual_Risk": "Low",
          "approval": "N/A",
          "number": "RITM4237421",
          "state": "Closed Complete",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "IT Services",
          "VendorName": "wosai",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-05-31 06:59:10"
      },
      {
          "Inherent_Risk": "Moderate",
          "Residual_Risk": "Low",
          "approval": "N/A",
          "number": "RITM4240273",
          "state": "Closed Complete",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "Cleaning & Maintenance Services",
          "VendorName": "aspro crest ltd",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-05-26 10:55:26"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "Moderate",
          "approval": "N/A",
          "number": "RITM4241578",
          "state": "Closed Complete",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "Software",
          "VendorName": "rock solid uk ltd, dba nuqleous",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-07-19 15:16:17"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "Moderate",
          "approval": "N/A",
          "number": "RITM4242271",
          "state": "Annual Reassessment Completed",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "IT Services",
          "VendorName": "bitwise inc",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-06-23 16:28:02"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "Moderate",
          "approval": "N/A",
          "number": "RITM4242457",
          "state": "Annual Reassessment Completed",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "IT Services",
          "VendorName": "w+s datentechnik gmbh",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-07-11 17:53:34"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "Moderate",
          "approval": "N/A",
          "number": "RITM4243444",
          "state": "Annual Reassessment Completed",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "IT Services",
          "VendorName": "arteria technologies private limited",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-07-05 14:57:54"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM4248808",
          "state": "Closed Complete",
          "Year": "2023.0",
          "due_date": "N/A",
          "Category": "Logistics",
          "VendorName": "linfox pty ltdus",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-06-16 12:49:34"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "High",
          "approval": "N/A",
          "number": "RITM4248854",
          "state": "Annual Reassessment Completed",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "IT Services",
          "VendorName": "datasure technology solutions hong kong ltd",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-07-13 11:12:02"
      },
      {
          "Inherent_Risk": "Low",
          "Residual_Risk": "Low",
          "approval": "N/A",
          "number": "RITM4251103",
          "state": "Closed Complete",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "Professional Services",
          "VendorName": "power reviews",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-06-23 08:06:57"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM4259252",
          "state": "Closed Complete",
          "Year": "2023.0",
          "due_date": "N/A",
          "Category": "IT Services",
          "VendorName": "mastercode sp.j. krowicki, sulik",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-06-14 14:01:56"
      },
      {
          "Inherent_Risk": "Low",
          "Residual_Risk": "Low",
          "approval": "N/A",
          "number": "RITM4261429",
          "state": "Closed Complete",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "IT Services",
          "VendorName": "neotix",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-05-29 07:41:08"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "Moderate",
          "approval": "N/A",
          "number": "RITM4261984",
          "state": "Annual Reassessment Completed",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "Software",
          "VendorName": "fortra",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-06-30 14:40:18"
      },
      {
          "Inherent_Risk": "Moderate",
          "Residual_Risk": "Low",
          "approval": "N/A",
          "number": "RITM4262018",
          "state": "Closed Complete",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "Others",
          "VendorName": "reprints desk, inc.",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-07-05 12:13:02"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "Moderate",
          "approval": "N/A",
          "number": "RITM4262573",
          "state": "Annual Reassessment Completed",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "IT Services",
          "VendorName": "cyclotron",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-06-16 15:02:24"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM4264737",
          "state": "Annual Reassessment Completed",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "Professional Services",
          "VendorName": "purpose ai",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-07-04 09:33:52"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "Moderate",
          "approval": "N/A",
          "number": "RITM4275492",
          "state": "Annual Reassessment Completed",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "Software",
          "VendorName": "formstack",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-07-13 11:01:47"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM4281150",
          "state": "Closed Complete",
          "Year": "2023.0",
          "due_date": "N/A",
          "Category": "IT Services",
          "VendorName": "c&f",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-07-11 16:35:06"
      },
      {
          "Inherent_Risk": "Low",
          "Residual_Risk": "Low",
          "approval": "N/A",
          "number": "RITM4283636",
          "state": "Closed Complete",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "Software",
          "VendorName": "dynamic yield",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-06-07 14:26:41"
      },
      {
          "Inherent_Risk": "Moderate",
          "Residual_Risk": "Low",
          "approval": "N/A",
          "number": "RITM4283709",
          "state": "Closed Complete",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "Software",
          "VendorName": "data poem",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-06-22 07:38:21"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "Moderate",
          "approval": "N/A",
          "number": "RITM4283741",
          "state": "Annual Reassessment Completed",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "Business Services",
          "VendorName": "eightfold",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-06-20 14:46:23"
      },
      {
          "Inherent_Risk": "Moderate",
          "Residual_Risk": "Low",
          "approval": "N/A",
          "number": "RITM4283804",
          "state": "Closed Complete",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "Hardware",
          "VendorName": "fukuda denshi usa, inc",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-06-08 10:18:50"
      },
      {
          "Inherent_Risk": "Moderate",
          "Residual_Risk": "Low",
          "approval": "N/A",
          "number": "RITM4283870",
          "state": "Closed Complete",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "IT Services",
          "VendorName": "neo4j",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-06-07 14:03:04"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "High",
          "approval": "N/A",
          "number": "RITM4284285",
          "state": "Annual Reassessment Completed",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "Software",
          "VendorName": "switchpitch",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-07-13 21:40:30"
      },
      {
          "Inherent_Risk": "Low",
          "Residual_Risk": "Low",
          "approval": "N/A",
          "number": "RITM4288390",
          "state": "Closed Complete",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "Software",
          "VendorName": "bloomreach",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-06-13 17:55:46"
      },
      {
          "Inherent_Risk": "Low",
          "Residual_Risk": "Low",
          "approval": "N/A",
          "number": "RITM4288392",
          "state": "Closed Complete",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "Software",
          "VendorName": "algolia",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-06-13 17:22:03"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "Moderate",
          "approval": "N/A",
          "number": "RITM4292455",
          "state": "Annual Reassessment Completed",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "Cloud Services Provider",
          "VendorName": "fieldassist dms",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-07-17 17:46:12"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "High",
          "approval": "N/A",
          "number": "RITM4292972",
          "state": "Closed Complete",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "Others",
          "VendorName": "egym wellpass",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-07-07 06:53:12"
      },
      {
          "Inherent_Risk": "Low",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM4294549",
          "state": "Closed Complete",
          "Year": "2023.0",
          "due_date": "N/A",
          "Category": "Education/Training",
          "VendorName": "execonline",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-07-12 15:41:48"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "Moderate",
          "approval": "N/A",
          "number": "RITM4294677",
          "state": "Annual Reassessment Completed",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "Audit & Compliance",
          "VendorName": "lloyds register quality assurance (lrqa)",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-07-17 18:11:01"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "High",
          "approval": "N/A",
          "number": "RITM4299256",
          "state": "Annual Reassessment Completed",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "IT Services",
          "VendorName": "galina stadnikova",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-07-19 15:31:12"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM4301982",
          "state": "Annual Reassessment Completed",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "Advertising Services",
          "VendorName": "vidmob",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-07-26 15:28:50"
      },
      {
          "Inherent_Risk": "Low",
          "Residual_Risk": "Low",
          "approval": "N/A",
          "number": "RITM4302670",
          "state": "Closed Complete",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "Others",
          "VendorName": "rh synergy (s) pte ltd",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-06-22 08:22:45"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "Moderate",
          "approval": "N/A",
          "number": "RITM4302674",
          "state": "Annual Reassessment Completed",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "Cloud Services Provider",
          "VendorName": "info-tech systems integrators",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-07-19 17:47:26"
      },
      {
          "Inherent_Risk": "Moderate",
          "Residual_Risk": "Low",
          "approval": "N/A",
          "number": "RITM4302678",
          "state": "Closed Complete",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "IT Services",
          "VendorName": "smartlink hong kong ltd",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-06-29 11:52:42"
      },
      {
          "Inherent_Risk": "Moderate",
          "Residual_Risk": "Low",
          "approval": "N/A",
          "number": "RITM4308752",
          "state": "Closed Complete",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "Software",
          "VendorName": "ab tasty",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-06-22 09:10:20"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM4309199",
          "state": "Annual Reassessment In Progress",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "Marketing",
          "VendorName": "go inbound pte ltd",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-07-24 10:30:47"
      },
      {
          "Inherent_Risk": "None",
          "Residual_Risk": "Moderate",
          "approval": "N/A",
          "number": "RITM4314078",
          "state": "Annual Reassessment Completed",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "Cloud Services Provider",
          "VendorName": "baiwang",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-07-07 14:29:49"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "Moderate",
          "approval": "N/A",
          "number": "RITM4314873",
          "state": "Annual Reassessment Completed",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "Professional Services",
          "VendorName": "unit4",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-07-13 09:59:42"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "Moderate",
          "approval": "N/A",
          "number": "RITM4316308",
          "state": "Annual Reassessment Completed",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "Software",
          "VendorName": "screen cloud",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-07-25 08:34:40"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "Moderate",
          "approval": "N/A",
          "number": "RITM4316776",
          "state": "Annual Reassessment Completed",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "Hosted Services Provider",
          "VendorName": "the sourcing group",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-07-14 18:03:37"
      },
      {
          "Inherent_Risk": "Moderate",
          "Residual_Risk": "Low",
          "approval": "N/A",
          "number": "RITM4317563",
          "state": "Closed Complete",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "IT Services",
          "VendorName": "shopex",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-06-23 10:48:16"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "Moderate",
          "approval": "N/A",
          "number": "RITM4330831",
          "state": "Annual Reassessment Completed",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "Marketing",
          "VendorName": "contentsquare",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-07-27 16:30:26"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "Moderate",
          "approval": "N/A",
          "number": "RITM4333980",
          "state": "Annual Reassessment In Progress",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "IT Services",
          "VendorName": "lorax epi",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-08-07 16:37:16"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM4334951",
          "state": "Closed Complete",
          "Year": "2023.0",
          "due_date": "N/A",
          "Category": "Software",
          "VendorName": "redzone",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-06-23 11:02:46"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "Moderate",
          "approval": "N/A",
          "number": "RITM4335155",
          "state": "Annual Reassessment Completed",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "Professional Services",
          "VendorName": "ryan, llc",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-09-15 07:43:34"
      },
      {
          "Inherent_Risk": "Moderate",
          "Residual_Risk": "Moderate",
          "approval": "N/A",
          "number": "RITM4342034",
          "state": "Annual Reassessment In Progress",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "Education/Training",
          "VendorName": "inchainge",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-08-04 08:29:13"
      },
      {
          "Inherent_Risk": "Low",
          "Residual_Risk": "Low",
          "approval": "N/A",
          "number": "RITM4343811",
          "state": "Annual Reassessment Completed",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "Audit & Compliance",
          "VendorName": "observepoint",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-07-04 16:42:54"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "Moderate",
          "approval": "N/A",
          "number": "RITM4344814",
          "state": "Annual Reassessment Completed",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "Business Services",
          "VendorName": "big city / premier sales",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-08-03 18:00:05"
      },
      {
          "Inherent_Risk": "Moderate",
          "Residual_Risk": "Low",
          "approval": "N/A",
          "number": "RITM4346098",
          "state": "Closed Complete",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "Others",
          "VendorName": "persefoni",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-06-29 15:48:14"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "Moderate",
          "approval": "N/A",
          "number": "RITM4346103",
          "state": "Annual Reassessment Completed",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "Others",
          "VendorName": "iab solutions, inc.",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-07-21 14:11:49"
      },
      {
          "Inherent_Risk": "None",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM4352425",
          "state": "Annual Reassessment Completed",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "Cloud Services Provider",
          "VendorName": "accruent llc",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-08-19 20:12:34"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "High",
          "approval": "N/A",
          "number": "RITM4352772",
          "state": "Annual Reassessment Completed",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "Software",
          "VendorName": "shoppinggives",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-07-31 13:42:51"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "Moderate",
          "approval": "N/A",
          "number": "RITM4358454",
          "state": "Annual Reassessment Completed",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "Others",
          "VendorName": "willis tower watson",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-08-21 12:55:09"
      },
      {
          "Inherent_Risk": "Moderate",
          "Residual_Risk": "Low",
          "approval": "N/A",
          "number": "RITM4358660",
          "state": "Closed Complete",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "Software",
          "VendorName": "park place",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-07-12 12:33:06"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "Moderate",
          "approval": "N/A",
          "number": "RITM4358707",
          "state": "Annual Reassessment Completed",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "IT Services",
          "VendorName": "cloud connectors",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-07-25 11:23:05"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "Moderate",
          "approval": "N/A",
          "number": "RITM4360241",
          "state": "Annual Reassessment Completed",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "IT Services",
          "VendorName": "stackbox",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-07-25 11:15:28"
      },
      {
          "Inherent_Risk": "Low",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM4360803",
          "state": "Closed Complete",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "Software",
          "VendorName": "bounteous inc",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-07-17 11:10:24"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "Moderate",
          "approval": "N/A",
          "number": "RITM4365861",
          "state": "Annual Reassessment Completed",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "Legal Services",
          "VendorName": "facil",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-09-01 16:28:18"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "Moderate",
          "approval": "N/A",
          "number": "RITM4368592",
          "state": "Annual Reassessment Completed",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "Advertising Services",
          "VendorName": "planning office s.m.s",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-08-15 08:12:41"
      },
      {
          "Inherent_Risk": "Moderate",
          "Residual_Risk": "Low",
          "approval": "N/A",
          "number": "RITM4373054",
          "state": "Closed Complete",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "Marketing",
          "VendorName": "wam group ltd (t/a landvault)",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-07-21 12:11:10"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM4374167",
          "state": "Annual Reassessment Completed",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "IT Services",
          "VendorName": "threedom pte ltd",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-08-18 08:42:21"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM4375265",
          "state": "Closed Complete",
          "Year": "2023.0",
          "due_date": "N/A",
          "Category": "IT Services",
          "VendorName": "avepoint",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-08-22 10:22:51"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "Moderate",
          "approval": "N/A",
          "number": "RITM4376890",
          "state": "Annual Reassessment Completed",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "Software",
          "VendorName": "neogrid - arker",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-08-08 14:51:47"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM4380193",
          "state": "Annual Reassessment In Progress",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "Software",
          "VendorName": "glow labs co.",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-08-25 15:48:07"
      },
      {
          "Inherent_Risk": "Low",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM4380854",
          "state": "In-Progress",
          "Year": "2023.0",
          "due_date": "N/A",
          "Category": "Others",
          "VendorName": "aquiila",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-09-18 14:58:20"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM4383665",
          "state": "Annual Reassessment Completed",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "IT Services",
          "VendorName": "telus agriculture & consumer goods (uk) ltd",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-08-21 17:46:10"
      },
      {
          "Inherent_Risk": "Low",
          "Residual_Risk": "Low",
          "approval": "N/A",
          "number": "RITM4384435",
          "state": "Closed Complete",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "Marketing",
          "VendorName": "keyvoto",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-07-13 15:50:44"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM4387982",
          "state": "Annual Reassessment In Progress",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "Cloud Services Provider",
          "VendorName": "er express/picis clinical solutions",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-08-28 12:49:32"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM4390852",
          "state": "Annual Reassessment Completed",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "Marketing",
          "VendorName": "permutive",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-08-23 17:37:51"
      },
      {
          "Inherent_Risk": "Moderate",
          "Residual_Risk": "Low",
          "approval": "N/A",
          "number": "RITM4398159",
          "state": "Closed Complete",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "IT Services",
          "VendorName": "pisx(shanghai) co., ltd.",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-07-25 14:54:10"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "Moderate",
          "approval": "N/A",
          "number": "RITM4399560",
          "state": "Annual Reassessment In Progress",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "Professional Services",
          "VendorName": "metaview ",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-08-09 08:52:25"
      },
      {
          "Inherent_Risk": "Moderate",
          "Residual_Risk": "Low",
          "approval": "N/A",
          "number": "RITM4400267",
          "state": "Closed Complete",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "IT Services",
          "VendorName": "suzhou teknect engineering co., ltd",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-08-07 07:55:18"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM4402812",
          "state": "Annual Reassessment In Progress",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "Hosted Services Provider",
          "VendorName": "automation anywhere",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-08-29 14:10:12"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM4402813",
          "state": "Annual Reassessment Completed",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "Hosted Services Provider",
          "VendorName": "abbyy",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-09-06 17:10:21"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "Low",
          "approval": "N/A",
          "number": "RITM4406023",
          "state": "Annual Reassessment Completed",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "Legal Services",
          "VendorName": "morgan, lewis & bockius llp",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-08-30 10:39:50"
      },
      {
          "Inherent_Risk": "Low",
          "Residual_Risk": "None",
          "approval": "N/A",
          "number": "RITM4406027",
          "state": "Annual Reassessment Completed",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "Professional Services",
          "VendorName": "interex",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-08-25 08:13:12"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "Moderate",
          "approval": "N/A",
          "number": "RITM4410735",
          "state": "Annual Reassessment Completed",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "Cloud Services Provider",
          "VendorName": "spidergate pte ltd",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-08-17 11:39:55"
      },
      {
          "Inherent_Risk": "None",
          "Residual_Risk": "None",
          "approval": "N/A",
          "number": "RITM4412588",
          "state": "Closed Complete",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "IT Services",
          "VendorName": "line studio aps",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-09-04 13:17:19"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "Moderate",
          "approval": "N/A",
          "number": "RITM4414806",
          "state": "Annual Reassessment Completed",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "IT Services",
          "VendorName": "javis",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-09-11 10:26:54"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM4423658",
          "state": "Annual Reassessment Completed",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "Marketing",
          "VendorName": "geometry global colombia sas",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-09-13 11:01:56"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "High",
          "approval": "N/A",
          "number": "RITM4426887",
          "state": "Annual Reassessment Completed",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "IT Services",
          "VendorName": "radix top llc",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-09-06 09:40:07"
      },
      {
          "Inherent_Risk": "Low",
          "Residual_Risk": "Low",
          "approval": "N/A",
          "number": "RITM4435377",
          "state": "Closed Complete",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "IT Services",
          "VendorName": "marru communications inc",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-08-18 07:44:00"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM4441411",
          "state": "Annual Reassessment Completed",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "Others",
          "VendorName": "raben",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-09-13 11:40:06"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "Moderate",
          "approval": "N/A",
          "number": "RITM4445140",
          "state": "Annual Reassessment Completed",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "Others",
          "VendorName": "prl",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-09-14 10:40:50"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "Moderate",
          "approval": "N/A",
          "number": "RITM4454498",
          "state": "Annual Reassessment Completed",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "Cloud Services Provider",
          "VendorName": "ownbackup",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-09-04 14:21:06"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "Moderate",
          "approval": "N/A",
          "number": "RITM4454515",
          "state": "Annual Reassessment Completed",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "Marketing",
          "VendorName": "picasso labs/creativex",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-09-15 14:03:57"
      },
      {
          "Inherent_Risk": "Low",
          "Residual_Risk": "Low",
          "approval": "N/A",
          "number": "RITM4458915",
          "state": "Closed Complete",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "Software",
          "VendorName": "journey corporate holdings llc",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-08-17 11:36:07"
      },
      {
          "Inherent_Risk": "Low",
          "Residual_Risk": "Low",
          "approval": "N/A",
          "number": "RITM4460631",
          "state": "Closed Complete",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "Software",
          "VendorName": "form.io",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-08-11 10:06:49"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "Moderate",
          "approval": "N/A",
          "number": "RITM4464244",
          "state": "Annual Reassessment Completed",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "Professional Services",
          "VendorName": "dict8",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-09-08 14:49:51"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "Moderate",
          "approval": "N/A",
          "number": "RITM4465915",
          "state": "Annual Reassessment Completed",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "IT Services",
          "VendorName": "trax retail",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-09-15 14:51:29"
      },
      {
          "Inherent_Risk": "Low",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM4467045",
          "state": "In-Progress",
          "Year": "2023.0",
          "due_date": "N/A",
          "Category": "IT Services",
          "VendorName": "ashish bahuguna <ashish@sociolive.in>",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-09-26 18:33:47"
      },
      {
          "Inherent_Risk": "Low",
          "Residual_Risk": "Low",
          "approval": "N/A",
          "number": "RITM4467319",
          "state": "Closed Complete",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "Others",
          "VendorName": "kore",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-09-05 17:36:06"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "Moderate",
          "approval": "N/A",
          "number": "RITM4467815",
          "state": "Annual Reassessment Completed",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "Professional Services",
          "VendorName": "sypartners, llc",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-08-30 11:18:08"
      },
      {
          "Inherent_Risk": "Critical",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM4469852",
          "state": "Annual Reassessment In Progress",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "Financial Institutions",
          "VendorName": "activpayroll ltd",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-08-29 20:14:39"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM4469919",
          "state": "Annual Reassessment Completed",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "Cloud Services Provider",
          "VendorName": "franconnect - cbw",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-09-12 08:31:27"
      },
      {
          "Inherent_Risk": "Moderate",
          "Residual_Risk": "Low",
          "approval": "N/A",
          "number": "RITM4471740",
          "state": "Closed Complete",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "Others",
          "VendorName": "futurus",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-08-31 12:53:28"
      },
      {
          "Inherent_Risk": "Low",
          "Residual_Risk": "Low",
          "approval": "N/A",
          "number": "RITM4474734",
          "state": "Closed Complete",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "Professional Services",
          "VendorName": "knowledge source ksi",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-09-05 14:48:46"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "Moderate",
          "approval": "N/A",
          "number": "RITM4474981",
          "state": "Annual Reassessment Completed",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "Cloud Services Provider",
          "VendorName": "trident3",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-09-18 07:48:32"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "None",
          "approval": "N/A",
          "number": "RITM4477373",
          "state": "Closed Complete",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "IT Services",
          "VendorName": "simwell",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-09-21 15:09:34"
      },
      {
          "Inherent_Risk": "Low",
          "Residual_Risk": "Low",
          "approval": "N/A",
          "number": "RITM4477558",
          "state": "Closed Complete",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "IT Services",
          "VendorName": "windstream enterprise",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-09-06 07:54:07"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM4481198",
          "state": "Annual Reassessment In Progress",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "Professional Services",
          "VendorName": "artefact",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-08-25 19:39:14"
      },
      {
          "Inherent_Risk": "Moderate",
          "Residual_Risk": "Low",
          "approval": "N/A",
          "number": "RITM4481488",
          "state": "Closed Complete",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "IT Services",
          "VendorName": "wdi information technology(shanghai)",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-09-07 10:51:26"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM4483034",
          "state": "Annual Reassessment In Progress",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "IT Services",
          "VendorName": "stef",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-09-12 08:32:14"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "Moderate",
          "approval": "N/A",
          "number": "RITM4499129",
          "state": "Annual Reassessment Completed",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "Cloud Services Provider",
          "VendorName": "pulpstream ",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-09-22 08:39:52"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM4499297",
          "state": "In-Progress",
          "Year": "2023.0",
          "due_date": "N/A",
          "Category": "Logistics",
          "VendorName": "bega dairy and drinks",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-09-18 07:41:39"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "High",
          "approval": "N/A",
          "number": "RITM4510337",
          "state": "Annual Reassessment Completed",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "Others",
          "VendorName": "natra",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-09-27 13:48:08"
      },
      {
          "Inherent_Risk": "Moderate",
          "Residual_Risk": "Low",
          "approval": "N/A",
          "number": "RITM4514166",
          "state": "Closed Complete",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "Software",
          "VendorName": "chengdu ren wo xing tech co ltd",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-09-08 08:56:11"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "Moderate",
          "approval": "N/A",
          "number": "RITM4518721",
          "state": "Annual Reassessment Completed",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "Hosted Services Provider",
          "VendorName": "kynetec",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-09-28 08:35:35"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "Moderate",
          "approval": "N/A",
          "number": "RITM4528977",
          "state": "Annual Reassessment Completed",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "IT Services",
          "VendorName": "easypicky",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-09-13 13:30:47"
      },
      {
          "Inherent_Risk": "Low",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM4532605",
          "state": "In-Progress",
          "Year": "2023.0",
          "due_date": "N/A",
          "Category": "Business Services",
          "VendorName": "marcio lohmann",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-09-19 18:47:27"
      },
      {
          "Inherent_Risk": "Moderate",
          "Residual_Risk": "Low",
          "approval": "N/A",
          "number": "RITM4536680",
          "state": "Annual Reassessment Completed",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "Others",
          "VendorName": "watcher ",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-09-12 10:55:10"
      },
      {
          "Inherent_Risk": "Low",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM4537715",
          "state": "In-Progress",
          "Year": "2023.0",
          "due_date": "N/A",
          "Category": "Professional Services",
          "VendorName": "cz",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-09-29 14:48:45"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "Moderate",
          "approval": "N/A",
          "number": "RITM4541854",
          "state": "Annual Reassessment Completed",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "IT Services",
          "VendorName": "datadog",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-09-26 13:41:43"
      },
      {
          "Inherent_Risk": "Low",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM4546899",
          "state": "Annual Reassessment In Progress",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "Marketing",
          "VendorName": "coast digital",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-09-13 11:36:00"
      },
      {
          "Inherent_Risk": "Moderate",
          "Residual_Risk": "Low",
          "approval": "N/A",
          "number": "RITM4548561",
          "state": "Closed Complete",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "Education/Training",
          "VendorName": "motimate",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-09-12 19:40:26"
      },
      {
          "Inherent_Risk": "Very Low",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM4549778",
          "state": "In-Progress",
          "Year": "2023.0",
          "due_date": "N/A",
          "Category": "Business Services",
          "VendorName": "boomerang commerce, inc. (dba commerceiq)",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-09-18 07:46:45"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "Moderate",
          "approval": "N/A",
          "number": "RITM4551027",
          "state": "Annual Reassessment Completed",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "Others",
          "VendorName": "sweep pia ",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-09-18 08:01:35"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "Low",
          "approval": "N/A",
          "number": "RITM4557176",
          "state": "Annual Reassessment Completed",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "Marketing",
          "VendorName": "captiv8",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-09-14 14:18:18"
      },
      {
          "Inherent_Risk": "Moderate",
          "Residual_Risk": "Low",
          "approval": "N/A",
          "number": "RITM4560138",
          "state": "Closed Complete",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "Cloud Services Provider",
          "VendorName": "mosyle",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-09-15 13:32:07"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "Moderate",
          "approval": "N/A",
          "number": "RITM4566726",
          "state": "Annual Reassessment Completed",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "Software",
          "VendorName": "transporeon",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-09-19 08:57:06"
      },
      {
          "Inherent_Risk": "Moderate",
          "Residual_Risk": "Low",
          "approval": "N/A",
          "number": "RITM4569835",
          "state": "Closed Complete",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "IT Services",
          "VendorName": "serviceone limited",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-09-20 06:18:44"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM4572217",
          "state": "Annual Reassessment Completed",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "Software",
          "VendorName": "alertus",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-09-20 16:10:42"
      },
      {
          "Inherent_Risk": "Low",
          "Residual_Risk": "Low",
          "approval": "N/A",
          "number": "RITM4582486",
          "state": "Closed Complete",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "Business Services",
          "VendorName": "knowledge works",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-09-25 13:08:26"
      },
      {
          "Inherent_Risk": "Low",
          "Residual_Risk": "Low",
          "approval": "N/A",
          "number": "RITM4583316",
          "state": "Closed Complete",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "Others",
          "VendorName": "greenmetrics",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-09-25 14:13:51"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "Moderate",
          "approval": "N/A",
          "number": "RITM4586214",
          "state": "Annual Reassessment Completed",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "Advertising Services",
          "VendorName": "dentsu promotion exe inc.",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-09-26 09:36:49"
      },
      {
          "Inherent_Risk": "Low",
          "Residual_Risk": "Low",
          "approval": "N/A",
          "number": "RITM4591080",
          "state": "Closed Complete",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "Hosted Services Provider",
          "VendorName": "syndigo",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-09-27 16:21:51"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "Moderate",
          "approval": "N/A",
          "number": "RITM4593005",
          "state": "Annual Reassessment Completed",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "Financial Institutions",
          "VendorName": "datatel communications inc",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-09-28 13:23:18"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM4594917",
          "state": "In-Progress",
          "Year": "2023.0",
          "due_date": "N/A",
          "Category": "Cloud Services Provider",
          "VendorName": "takahiko <sakamoto@ascend-corp.co.jp>",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-09-29 02:51:47"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "Moderate",
          "approval": "N/A",
          "number": "RITM4596060",
          "state": "Annual Reassessment Completed",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "Software",
          "VendorName": "peerox",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-09-29 10:21:10"
      },
      {
          "Inherent_Risk": "Moderate",
          "Residual_Risk": "Low",
          "approval": "N/A",
          "number": "RITM4601044",
          "state": "Closed Complete",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "Marketing",
          "VendorName": "talentneuron",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-10-02 15:48:19"
      },
      {
          "Inherent_Risk": "Moderate",
          "Residual_Risk": "Low",
          "approval": "N/A",
          "number": "RITM4601073",
          "state": "Closed Complete",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "IT Services",
          "VendorName": "retool",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-10-02 15:59:31"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "Moderate",
          "approval": "N/A",
          "number": "RITM4601583",
          "state": "Annual Reassessment Completed",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "Cloud Services Provider",
          "VendorName": "obsidian",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-10-02 20:59:49"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "Moderate",
          "approval": "N/A",
          "number": "RITM4602690",
          "state": "Annual Reassessment Completed",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "Professional Services",
          "VendorName": "prowebce edenred",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-10-03 08:26:56"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "High",
          "approval": "N/A",
          "number": "RITM4605878",
          "state": "Annual Reassessment Completed",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "Business Services",
          "VendorName": "freshboxevents",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-10-04 11:20:11"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "High",
          "approval": "N/A",
          "number": "RITM4606732",
          "state": "Annual Reassessment Completed",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "Cloud Services Provider",
          "VendorName": "winter egency",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-10-04 09:18:12"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "Moderate",
          "approval": "N/A",
          "number": "RITM4608830",
          "state": "Annual Reassessment Completed",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "Software",
          "VendorName": "twinthread",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-10-04 18:30:35"
      },
      {
          "Inherent_Risk": "Moderate",
          "Residual_Risk": "Low",
          "approval": "N/A",
          "number": "RITM4612221",
          "state": "Closed Complete",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "IT Services",
          "VendorName": "6th street consulting (6sc)",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-10-05 17:48:57"
      },
      {
          "Inherent_Risk": "Low",
          "Residual_Risk": "Low",
          "approval": "N/A",
          "number": "RITM4613961",
          "state": "Closed Complete",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "Advertising Services",
          "VendorName": "value 360 india",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-10-06 10:06:02"
      },
      {
          "Inherent_Risk": "Low",
          "Residual_Risk": "Low",
          "approval": "N/A",
          "number": "RITM4614785",
          "state": "Closed Complete",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "Telecommunication Services",
          "VendorName": "expereo",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-10-06 13:18:29"
      },
      {
          "Inherent_Risk": "Low",
          "Residual_Risk": "Low",
          "approval": "N/A",
          "number": "RITM4614853",
          "state": "Closed Complete",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "Hardware",
          "VendorName": "presidio",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-10-06 13:38:08"
      },
      {
          "Inherent_Risk": "None",
          "Residual_Risk": "None",
          "approval": "N/A",
          "number": "RITM4615150",
          "state": "Annual Reassessment Completed",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "Business Services",
          "VendorName": "malt",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-10-06 15:01:25"
      },
      {
          "Inherent_Risk": "Low",
          "Residual_Risk": "Low",
          "approval": "N/A",
          "number": "RITM4620923",
          "state": "Closed Complete",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "Others",
          "VendorName": "adapture",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-10-09 19:12:19"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "Moderate",
          "approval": "N/A",
          "number": "RITM4620978",
          "state": "Annual Reassessment Completed",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "Software",
          "VendorName": "aprimo us llc",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-10-09 20:01:56"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "Moderate",
          "approval": "N/A",
          "number": "RITM4622353",
          "state": "Annual Reassessment Completed",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "Software",
          "VendorName": "jiashu",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-10-10 08:52:16"
      },
      {
          "Inherent_Risk": "Low",
          "Residual_Risk": "Low",
          "approval": "N/A",
          "number": "RITM4624414",
          "state": "Closed Complete",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "Hardware",
          "VendorName": "microware tecnologia de informação ltda",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-10-10 18:09:48"
      },
      {
          "Inherent_Risk": "Low",
          "Residual_Risk": "Low",
          "approval": "N/A",
          "number": "RITM4624457",
          "state": "Closed Complete",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "Software",
          "VendorName": "radancy",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-10-10 18:38:45"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "Moderate",
          "approval": "N/A",
          "number": "RITM4626381",
          "state": "Annual Reassessment Completed",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "IT Services",
          "VendorName": "guangzhou xlink iot technology ltd",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-10-11 10:09:55"
      },
      {
          "Inherent_Risk": "Moderate",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM4626646",
          "state": "Closed Complete",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "Others",
          "VendorName": "xbyte",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-10-11 11:03:15"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "Moderate",
          "approval": "N/A",
          "number": "RITM4629068",
          "state": "Annual Reassessment Completed",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "IT Services",
          "VendorName": "midea cloud technology co., ltd.",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-10-12 05:59:05"
      },
      {
          "Inherent_Risk": "Moderate",
          "Residual_Risk": "Low",
          "approval": "N/A",
          "number": "RITM4631450",
          "state": "Closed Complete",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "Software",
          "VendorName": "waitwhile",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-10-12 17:39:00"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "Moderate",
          "approval": "N/A",
          "number": "RITM4634822",
          "state": "Annual Reassessment Completed",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "Cloud Services Provider",
          "VendorName": "optilogic",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-10-13 17:37:19"
      },
      {
          "Inherent_Risk": "Low",
          "Residual_Risk": "Low",
          "approval": "N/A",
          "number": "RITM4634981",
          "state": "Closed Complete",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "Marketing",
          "VendorName": "cj affiliate",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-10-13 20:29:27"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "High",
          "approval": "N/A",
          "number": "RITM4637016",
          "state": "Annual Reassessment Completed",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "Others",
          "VendorName": "china mobile croup guangdong co., ltd..",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-10-16 08:46:50"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "Moderate",
          "approval": "N/A",
          "number": "RITM4639279",
          "state": "Annual Reassessment Completed",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "Professional Services",
          "VendorName": "ats sistemas y consultoría bi sa de cv",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-10-16 17:15:49"
      },
      {
          "Inherent_Risk": "Moderate",
          "Residual_Risk": "Low",
          "approval": "N/A",
          "number": "RITM4639766",
          "state": "Closed Complete",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "Cloud Services Provider",
          "VendorName": "pageproof",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-10-16 22:12:00"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "Moderate",
          "approval": "N/A",
          "number": "RITM4641069",
          "state": "Annual Reassessment Completed",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "IT Services",
          "VendorName": "beijing 1bu2bu technology co., ltd.",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-10-17 09:23:49"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM4641279",
          "state": "Annual Reassessment In Progress",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "Software",
          "VendorName": "faber system",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-10-17 10:17:51"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "High",
          "approval": "N/A",
          "number": "RITM4641397",
          "state": "Annual Reassessment Completed",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "Advertising Services",
          "VendorName": "seed",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-10-17 10:58:06"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "Moderate",
          "approval": "N/A",
          "number": "RITM4646892",
          "state": "Annual Reassessment Completed",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "Others",
          "VendorName": "sun life - vca",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-10-18 19:34:48"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "Moderate",
          "approval": "N/A",
          "number": "RITM4647538",
          "state": "Annual Reassessment Completed",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "IT Services",
          "VendorName": "kicc(korea information & communications co., ltd.)",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-10-19 10:28:44"
      },
      {
          "Inherent_Risk": "Moderate",
          "Residual_Risk": "Low",
          "approval": "N/A",
          "number": "RITM4651740",
          "state": "Annual Reassessment Completed",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "Audit & Compliance",
          "VendorName": "consotrust",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-10-20 09:06:50"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "Moderate",
          "approval": "N/A",
          "number": "RITM4651800",
          "state": "Annual Reassessment Completed",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "Cloud Services Provider",
          "VendorName": "wegrow pia",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-10-20 09:19:58"
      },
      {
          "Inherent_Risk": "Moderate",
          "Residual_Risk": "Low",
          "approval": "N/A",
          "number": "RITM4651813",
          "state": "Closed Complete",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "Cloud Services Provider",
          "VendorName": "qualimetrie",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-10-20 09:24:28"
      },
      {
          "Inherent_Risk": "Low",
          "Residual_Risk": "Low",
          "approval": "N/A",
          "number": "RITM4652347",
          "state": "Closed Complete",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "Advertising Services",
          "VendorName": "pricespider",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-10-20 11:49:46"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "Moderate",
          "approval": "N/A",
          "number": "RITM4653227",
          "state": "Annual Reassessment Completed",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "Cloud Services Provider",
          "VendorName": "gearset",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-10-20 17:13:51"
      },
      {
          "Inherent_Risk": "Moderate",
          "Residual_Risk": "Low",
          "approval": "N/A",
          "number": "RITM4655117",
          "state": "Closed Complete",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "IT Services",
          "VendorName": "zhejiang kxll internettechnology",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-10-23 07:00:54"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM4658250",
          "state": "Incompleted",
          "Year": "2023.0",
          "due_date": "N/A",
          "Category": "Others",
          "VendorName": "dksh holdings (malaysia) bhd",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-10-24 08:20:19"
      },
      {
          "Inherent_Risk": "Moderate",
          "Residual_Risk": "None",
          "approval": "N/A",
          "number": "RITM4661963",
          "state": "Closed Complete",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "Business Services",
          "VendorName": "realai",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-10-25 03:00:13"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "High",
          "approval": "N/A",
          "number": "RITM4662439",
          "state": "Annual Reassessment Completed",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "Cloud Services Provider",
          "VendorName": "mobileone",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-10-25 06:54:57"
      },
      {
          "Inherent_Risk": "Low",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM4663715",
          "state": "Annual Reassessment Completed",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "IT Services",
          "VendorName": "aays advisory private limited",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-10-25 11:52:34"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "Moderate",
          "approval": "N/A",
          "number": "RITM4664852",
          "state": "Annual Reassessment Completed",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "Professional Services",
          "VendorName": "interworks",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-10-25 17:05:46"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "High",
          "approval": "N/A",
          "number": "RITM4665270",
          "state": "Annual Reassessment Completed",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "IT Services",
          "VendorName": "pensa systems",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-10-25 20:41:53"
      },
      {
          "Inherent_Risk": "Critical",
          "Residual_Risk": "Moderate",
          "approval": "N/A",
          "number": "RITM4673278",
          "state": "Closed Complete",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "Logistics",
          "VendorName": "toll group",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-10-30 00:58:25"
      },
      {
          "Inherent_Risk": "Moderate",
          "Residual_Risk": "Low",
          "approval": "N/A",
          "number": "RITM4673729",
          "state": "Closed Complete",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "IT Services",
          "VendorName": "kedaweixin (beijing) kejiyouxiangongsi",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-10-30 06:24:49"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM4676103",
          "state": "In-Progress",
          "Year": "2023.0",
          "due_date": "N/A",
          "Category": "Others",
          "VendorName": "commercehub & channel advisor",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-10-30 14:45:10"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "Moderate",
          "approval": "N/A",
          "number": "RITM4676561",
          "state": "Annual Reassessment Completed",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "Software",
          "VendorName": "shenzhen wanlink technology",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-10-30 17:08:49"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "Moderate",
          "approval": "N/A",
          "number": "RITM4677950",
          "state": "Annual Reassessment Completed",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "IT Services",
          "VendorName": "beijing mightytech technology",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-10-31 07:51:33"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "Moderate",
          "approval": "N/A",
          "number": "RITM4678179",
          "state": "Annual Reassessment Completed",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "IT Services",
          "VendorName": "optidata pia ",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-10-31 08:48:12"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "Moderate",
          "approval": "N/A",
          "number": "RITM4678469",
          "state": "Annual Reassessment Completed",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "IT Services",
          "VendorName": "inkstone",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-10-31 09:49:30"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM4680406",
          "state": "Annual Reassessment In Progress",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "IT Services",
          "VendorName": "sc&h group, inc.",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-12-12 17:02:38"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "Moderate",
          "approval": "N/A",
          "number": "RITM4680701",
          "state": "Annual Reassessment Completed",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "Cloud Services Provider",
          "VendorName": "corprelo - vca",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-10-31 20:46:21"
      },
      {
          "Inherent_Risk": "Low",
          "Residual_Risk": "Low",
          "approval": "N/A",
          "number": "RITM4682324",
          "state": "Annual Reassessment Completed",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "IT Services",
          "VendorName": "shanghai qintang information &technology ltd.",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-11-01 12:01:07"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM4683203",
          "state": "In-Progress",
          "Year": "2023.0",
          "due_date": "N/A",
          "Category": "IT Services",
          "VendorName": "cegid",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-11-01 16:28:36"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "Moderate",
          "approval": "N/A",
          "number": "RITM4683627",
          "state": "Annual Reassessment Completed",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "Software",
          "VendorName": "tango technologies inc",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-11-01 19:59:22"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM4688164",
          "state": "In-Progress",
          "Year": "2023.0",
          "due_date": "N/A",
          "Category": "IT Services",
          "VendorName": "beijing yuanlong yato culture dissemination co,ltd.",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-11-03 08:02:12"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "Moderate",
          "approval": "N/A",
          "number": "RITM4688561",
          "state": "Annual Reassessment Completed",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "IT Services",
          "VendorName": "iljoja networks",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-11-03 09:47:48"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "Moderate",
          "approval": "N/A",
          "number": "RITM4688787",
          "state": "Annual Reassessment Completed",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "IT Services",
          "VendorName": "infinite sp. z o.o.",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-11-03 10:53:50"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "Moderate",
          "approval": "N/A",
          "number": "RITM4694903",
          "state": "Annual Reassessment Completed",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "Software",
          "VendorName": "shibumi",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-11-06 20:35:19"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "Moderate",
          "approval": "N/A",
          "number": "RITM4694906",
          "state": "Annual Reassessment Completed",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "Others",
          "VendorName": "powtoon",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-11-06 20:38:04"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "Moderate",
          "approval": "N/A",
          "number": "RITM4694914",
          "state": "Annual Reassessment Completed",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "Cloud Services Provider",
          "VendorName": "first orion",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-11-06 20:42:06"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM4694934",
          "state": "Annual Reassessment Completed",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "Cloud Services Provider",
          "VendorName": "boon - vca",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-11-06 20:52:46"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "High",
          "approval": "N/A",
          "number": "RITM4694993",
          "state": "Annual Reassessment Completed",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "Software",
          "VendorName": "agerpoint",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-11-06 21:37:17"
      },
      {
          "Inherent_Risk": "Moderate",
          "Residual_Risk": "Low",
          "approval": "N/A",
          "number": "RITM4697071",
          "state": "Closed Complete",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "Marketing",
          "VendorName": "rapidads",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-11-07 11:59:07"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "Low",
          "approval": "N/A",
          "number": "RITM4697186",
          "state": "Closed Complete",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "Marketing",
          "VendorName": "accutics",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-11-07 12:20:51"
      },
      {
          "Inherent_Risk": "Moderate",
          "Residual_Risk": "Low",
          "approval": "N/A",
          "number": "RITM4697431",
          "state": "Closed Complete",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "IT Services",
          "VendorName": "sauce labs, inc.",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-11-07 13:23:47"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "Moderate",
          "approval": "N/A",
          "number": "RITM4698526",
          "state": "Annual Reassessment In Progress",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "Others",
          "VendorName": "american tradeshow services ",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-11-07 18:38:17"
      },
      {
          "Inherent_Risk": "Critical",
          "Residual_Risk": "Critical",
          "approval": "N/A",
          "number": "RITM4703649",
          "state": "Annual Reassessment Completed",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "IT Services",
          "VendorName": "guangdong hongwei industrial co., l",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-11-09 05:47:50"
      },
      {
          "Inherent_Risk": "Low",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM4707498",
          "state": "Annual Reassessment Completed",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "Marketing",
          "VendorName": "bua whatsapp",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-11-10 08:08:13"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "Moderate",
          "approval": "N/A",
          "number": "RITM4714084",
          "state": "Annual Reassessment Completed",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "Professional Services",
          "VendorName": "course 5, inc.",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-11-13 23:20:48"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "High",
          "approval": "N/A",
          "number": "RITM4714256",
          "state": "Annual Reassessment Completed",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "IT Services",
          "VendorName": "zhong guo lian he wang luo tong xin",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-11-14 02:50:27"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "High",
          "approval": "N/A",
          "number": "RITM4717813",
          "state": "Annual Reassessment Completed",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "IT Services",
          "VendorName": "clever together lab",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-11-14 15:59:02"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "Moderate",
          "approval": "N/A",
          "number": "RITM4718364",
          "state": "Annual Reassessment Completed",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "Software",
          "VendorName": "cognician",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-11-14 20:20:42"
      },
      {
          "Inherent_Risk": "Low",
          "Residual_Risk": "Very Low",
          "approval": "N/A",
          "number": "RITM4719596",
          "state": "Annual Reassessment Completed",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "IT Services",
          "VendorName": "bairesdev",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-11-15 09:00:54"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "Moderate",
          "approval": "N/A",
          "number": "RITM4727274",
          "state": "Annual Reassessment Completed",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "IT Services",
          "VendorName": "appian",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-11-16 15:21:07"
      },
      {
          "Inherent_Risk": "Low",
          "Residual_Risk": "Low",
          "approval": "N/A",
          "number": "RITM4730246",
          "state": "Closed Complete",
          "Year": "2023",
          "due_date": "N/A",
          "Category": "Marketing",
          "VendorName": "ciphermode labs inc (pyte)",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-11-17 13:59:48"
      },
      {
          "Inherent_Risk": "Low",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM4736722",
          "state": "N/A",
          "Year": "2024.0",
          "due_date": "N/A",
          "Category": "IT Services",
          "VendorName": "广州筷子信息科技有限公司",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-11-21 09:52:40"
      },
      {
          "Inherent_Risk": "Low",
          "Residual_Risk": "Very Low",
          "approval": "N/A",
          "number": "RITM4747074",
          "state": "Closed Complete",
          "Year": "2024",
          "due_date": "N/A",
          "Category": "IT Services",
          "VendorName": "althéa",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-11-24 10:17:43"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM4748258",
          "state": "In-Progress",
          "Year": "2024.0",
          "due_date": "N/A",
          "Category": "IT Services",
          "VendorName": "p&i ag",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-11-24 16:42:01"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM4754953",
          "state": "In-Progress",
          "Year": "2024.0",
          "due_date": "N/A",
          "Category": "Software",
          "VendorName": "old st labs ltd",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-11-28 12:52:48"
      },
      {
          "Inherent_Risk": "Low",
          "Residual_Risk": "Low",
          "approval": "N/A",
          "number": "RITM4755061",
          "state": "Closed Complete",
          "Year": "2024",
          "due_date": "N/A",
          "Category": "Business Services",
          "VendorName": "ava genomics consultancy aps",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-11-28 12:41:22"
      },
      {
          "Inherent_Risk": "None",
          "Residual_Risk": "None",
          "approval": "N/A",
          "number": "RITM4756420",
          "state": "Closed Complete",
          "Year": "2024",
          "due_date": "N/A",
          "Category": "Professional Services",
          "VendorName": "avison young professional services",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-11-28 18:11:22"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "High",
          "approval": "N/A",
          "number": "RITM4766164",
          "state": "Closed Complete",
          "Year": "2024",
          "due_date": "N/A",
          "Category": "IT Services",
          "VendorName": "laa global capital ltd.",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-12-01 13:03:29"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM4771548",
          "state": "Annual Reassessment In Progress",
          "Year": "2024",
          "due_date": "N/A",
          "Category": "Others",
          "VendorName": "graebel",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-12-04 16:55:45"
      },
      {
          "Inherent_Risk": "Low",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM4771819",
          "state": "Annual Reassessment Completed",
          "Year": "2024",
          "due_date": "N/A",
          "Category": "IT Services",
          "VendorName": "test evolve",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-12-04 18:50:05"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM4774514",
          "state": "In-Progress",
          "Year": "2024.0",
          "due_date": "N/A",
          "Category": "IT Services",
          "VendorName": "anova sourcing",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-12-05 14:06:05"
      },
      {
          "Inherent_Risk": "Low",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM4781249",
          "state": "In-Progress",
          "Year": "2024.0",
          "due_date": "N/A",
          "Category": "Office and Commercial Furniture",
          "VendorName": "locker vending machines production of locker vending machines - our savebox system our savebox locker vending machine allows you to store and dispense small and medium sized items, tools or equipment in a controlled manner. want to create a unique system? design your individual savebox configuration!",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-12-07 11:54:48"
      },
      {
          "Inherent_Risk": "Low",
          "Residual_Risk": "Low",
          "approval": "N/A",
          "number": "RITM4790753",
          "state": "Closed Complete",
          "Year": "2024",
          "due_date": "N/A",
          "Category": "Software",
          "VendorName": "thales",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-12-11 16:26:52"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM4790840",
          "state": "Closed Complete",
          "Year": "2024",
          "due_date": "N/A",
          "Category": "Laboratory services",
          "VendorName": "sgs analytics germany gmbh",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-12-11 17:01:10"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "Moderate",
          "approval": "N/A",
          "number": "RITM4791373",
          "state": "Closed Complete",
          "Year": "2024",
          "due_date": "N/A",
          "Category": "Professional Services",
          "VendorName": "leverage",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-12-11 21:00:42"
      },
      {
          "Inherent_Risk": "Low",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM4791565",
          "state": "Closed Complete",
          "Year": "2024",
          "due_date": "N/A",
          "Category": "Software",
          "VendorName": "esri",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-12-12 00:33:57"
      },
      {
          "Inherent_Risk": "Low",
          "Residual_Risk": "Low",
          "approval": "N/A",
          "number": "RITM4794016",
          "state": "Closed Complete",
          "Year": "2024",
          "due_date": "N/A",
          "Category": "Professional Services",
          "VendorName": "digital futures group limited",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-12-12 13:12:05"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "Moderate",
          "approval": "N/A",
          "number": "RITM4797845",
          "state": "Annual Reassessment Completed",
          "Year": "2024",
          "due_date": "N/A",
          "Category": "Others",
          "VendorName": "keen decision systems, inc.",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-12-13 12:35:52"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM4799737",
          "state": "N/A",
          "Year": "2024.0",
          "due_date": "N/A",
          "Category": "Cleaning & Maintenance Services",
          "VendorName": "h-visons",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-12-14 01:27:24"
      },
      {
          "Inherent_Risk": "Low",
          "Residual_Risk": "Low",
          "approval": "N/A",
          "number": "RITM4804139",
          "state": "Closed Complete",
          "Year": "2024",
          "due_date": "N/A",
          "Category": "Cloud Services Provider",
          "VendorName": "gurobi",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-12-15 09:01:16"
      },
      {
          "Inherent_Risk": "Low",
          "Residual_Risk": "Low",
          "approval": "N/A",
          "number": "RITM4805123",
          "state": "Closed Complete",
          "Year": "2024",
          "due_date": "N/A",
          "Category": "IT Services",
          "VendorName": "catchpoint",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-12-15 13:57:20"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM4805214",
          "state": "Closed Complete",
          "Year": "2024",
          "due_date": "N/A",
          "Category": "Professional Services",
          "VendorName": "welocalize",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-12-15 14:21:07"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "Moderate",
          "approval": "N/A",
          "number": "RITM4811746",
          "state": "Annual Reassessment Completed",
          "Year": "2024",
          "due_date": "N/A",
          "Category": "Business Services",
          "VendorName": "conveya",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-12-19 09:29:33"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "Moderate",
          "approval": "N/A",
          "number": "RITM4813312",
          "state": "Closed Complete",
          "Year": "2024",
          "due_date": "N/A",
          "Category": "Software",
          "VendorName": "ibm sterling",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-12-19 15:32:59"
      },
      {
          "Inherent_Risk": "Low",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM4817590",
          "state": "In-Progress",
          "Year": "2024.0",
          "due_date": "N/A",
          "Category": "Professional Services",
          "VendorName": "adf engineering",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-12-20 21:17:11"
      },
      {
          "Inherent_Risk": "Moderate",
          "Residual_Risk": "Low",
          "approval": "N/A",
          "number": "RITM4822737",
          "state": "Closed Complete",
          "Year": "2024",
          "due_date": "N/A",
          "Category": "Hardware",
          "VendorName": "powerfleet",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-12-22 12:30:55"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "Moderate",
          "approval": "N/A",
          "number": "RITM4823234",
          "state": "Closed Complete",
          "Year": "2024",
          "due_date": "N/A",
          "Category": "Others",
          "VendorName": "bravon ltd",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-12-22 16:04:25"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM4823275",
          "state": "In-Progress",
          "Year": "2024.0",
          "due_date": "N/A",
          "Category": "Cloud Services Provider",
          "VendorName": "trax technology, s.a. de. c.v.",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-12-22 16:27:22"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "Moderate",
          "approval": "N/A",
          "number": "RITM4823280",
          "state": "Closed Complete",
          "Year": "2024",
          "due_date": "N/A",
          "Category": "Others",
          "VendorName": "great northern corporation",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-12-22 16:33:18"
      },
      {
          "Inherent_Risk": "Critical",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM4823287",
          "state": "In-Progress",
          "Year": "2024.0",
          "due_date": "N/A",
          "Category": "Professional Services",
          "VendorName": "sunfire technologies",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-12-22 16:38:27"
      },
      {
          "Inherent_Risk": "Low",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM4823319",
          "state": "Closed Complete",
          "Year": "2024",
          "due_date": "N/A",
          "Category": "Others",
          "VendorName": "consumer panel poland gfk sp.z.o.o",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-12-22 16:57:54"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM4830059",
          "state": "In-Progress",
          "Year": "2024.0",
          "due_date": "N/A",
          "Category": "Professional Services",
          "VendorName": "infobahn softworld inc.",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-12-28 13:56:49"
      },
      {
          "Inherent_Risk": "Low",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM4830436",
          "state": "Closed Complete",
          "Year": "2024",
          "due_date": "N/A",
          "Category": "Software",
          "VendorName": "faros del sur",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2023-12-28 18:22:16"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "Moderate",
          "approval": "N/A",
          "number": "RITM4834076",
          "state": "Annual Reassessment Completed",
          "Year": "2024",
          "due_date": "N/A",
          "Category": "Cloud Services Provider",
          "VendorName": "mirum india",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2024-01-02 05:59:50"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM4843350",
          "state": "In-Progress",
          "Year": "2024.0",
          "due_date": "N/A",
          "Category": "Software",
          "VendorName": "parsec automation",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2024-01-04 19:46:19"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM4843413",
          "state": "In-Progress",
          "Year": "2024.0",
          "due_date": "N/A",
          "Category": "IT Services",
          "VendorName": "k2 partnering solutions",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2024-01-04 20:35:38"
      },
      {
          "Inherent_Risk": "Critical",
          "Residual_Risk": "Moderate",
          "approval": "N/A",
          "number": "RITM4843461",
          "state": "Annual Reassessment In Progress",
          "Year": "2024",
          "due_date": "N/A",
          "Category": "Legal Services",
          "VendorName": "consilio",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2024-01-04 21:05:56"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM4846285",
          "state": "In-Progress",
          "Year": "2024.0",
          "due_date": "N/A",
          "Category": "IT Services",
          "VendorName": "daugherty systems",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2024-01-05 19:18:49"
      },
      {
          "Inherent_Risk": "Low",
          "Residual_Risk": "Moderate",
          "approval": "N/A",
          "number": "RITM4846305",
          "state": "Annual Reassessment Completed",
          "Year": "2024",
          "due_date": "N/A",
          "Category": "IT Services",
          "VendorName": "wipro limited",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2024-01-05 19:33:19"
      },
      {
          "Inherent_Risk": "Moderate",
          "Residual_Risk": "Low",
          "approval": "N/A",
          "number": "RITM4846344",
          "state": "Closed Complete",
          "Year": "2024",
          "due_date": "N/A",
          "Category": "IT Services",
          "VendorName": "guidepoint security",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2024-01-05 20:00:35"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM4850033",
          "state": "N/A",
          "Year": "2024.0",
          "due_date": "N/A",
          "Category": "Office and Commercial Furniture",
          "VendorName": "astronova",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2024-01-08 14:27:16"
      },
      {
          "Inherent_Risk": "Critical",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM4853429",
          "state": "In-Progress",
          "Year": "2024.0",
          "due_date": "N/A",
          "Category": "Others",
          "VendorName": "hays outsourcing sp. z o.o.",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2024-01-09 12:51:24"
      },
      {
          "Inherent_Risk": "Low",
          "Residual_Risk": "Very Low",
          "approval": "N/A",
          "number": "RITM4854637",
          "state": "Closed Complete",
          "Year": "2024",
          "due_date": "N/A",
          "Category": "Others",
          "VendorName": "international flavors and fragrances (zhangjiagang) co., ltd.",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2024-01-09 17:38:34"
      },
      {
          "Inherent_Risk": "Moderate",
          "Residual_Risk": "Low",
          "approval": "N/A",
          "number": "RITM4858999",
          "state": "Closed Complete",
          "Year": "2024",
          "due_date": "N/A",
          "Category": "Software",
          "VendorName": "ideagen",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2024-01-10 21:22:13"
      },
      {
          "Inherent_Risk": "Moderate",
          "Residual_Risk": "Low",
          "approval": "N/A",
          "number": "RITM4864126",
          "state": "Closed Complete",
          "Year": "2024",
          "due_date": "N/A",
          "Category": "Laboratory services",
          "VendorName": "hanwell ellab temperature monitoring system",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2024-01-12 09:36:11"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "Moderate",
          "approval": "N/A",
          "number": "RITM4865626",
          "state": "Closed Complete",
          "Year": "2024",
          "due_date": "N/A",
          "Category": "Business Services",
          "VendorName": "irrational labs",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2024-01-12 15:28:43"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "None",
          "approval": "N/A",
          "number": "RITM4867398",
          "state": "Annual Reassessment Completed",
          "Year": "2024",
          "due_date": "N/A",
          "Category": "Software",
          "VendorName": "crazibrain solutions",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2024-01-15 05:04:44"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM4868455",
          "state": "In-Progress",
          "Year": "2024.0",
          "due_date": "N/A",
          "Category": "Professional Services",
          "VendorName": "deloitte ireland llp",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2024-01-15 10:55:06"
      },
      {
          "Inherent_Risk": "Low",
          "Residual_Risk": "Low",
          "approval": "N/A",
          "number": "RITM4870857",
          "state": "Closed Complete",
          "Year": "2024",
          "due_date": "N/A",
          "Category": "Marketing",
          "VendorName": "its rapid",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2024-01-16 08:15:39"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "High",
          "approval": "N/A",
          "number": "RITM4871485",
          "state": "Closed Complete",
          "Year": "2024",
          "due_date": "N/A",
          "Category": "IT Services",
          "VendorName": "main.fish 2024",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2024-01-16 10:35:16"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "Moderate",
          "approval": "N/A",
          "number": "RITM4876729",
          "state": "Annual Reassessment Completed",
          "Year": "2024",
          "due_date": "N/A",
          "Category": "Marketing",
          "VendorName": "integral ad science",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2024-01-17 17:17:45"
      },
      {
          "Inherent_Risk": "Critical",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM4876797",
          "state": "Annual Reassessment Completed",
          "Year": "2024",
          "due_date": "N/A",
          "Category": "Software",
          "VendorName": "dematic",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2024-01-17 17:52:26"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM4880989",
          "state": "In-Progress",
          "Year": "2024.0",
          "due_date": "N/A",
          "Category": "Software",
          "VendorName": "google",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2024-01-18 18:59:26"
      },
      {
          "Inherent_Risk": "Moderate",
          "Residual_Risk": "Low",
          "approval": "N/A",
          "number": "RITM4883808",
          "state": "Closed Complete",
          "Year": "2024",
          "due_date": "N/A",
          "Category": "Others",
          "VendorName": "ergo design",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2024-01-19 13:53:26"
      },
      {
          "Inherent_Risk": "Low",
          "Residual_Risk": "Low",
          "approval": "N/A",
          "number": "RITM4884226",
          "state": "Closed Complete",
          "Year": "2024",
          "due_date": "N/A",
          "Category": "Software",
          "VendorName": "appspace",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2024-01-19 15:31:06"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM4884335",
          "state": "Annual Reassessment Completed",
          "Year": "2024",
          "due_date": "N/A",
          "Category": "Software",
          "VendorName": "jebbit",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2024-01-19 16:13:43"
      },
      {
          "Inherent_Risk": "Low",
          "Residual_Risk": "Low",
          "approval": "N/A",
          "number": "RITM4889288",
          "state": "Closed Complete",
          "Year": "2024",
          "due_date": "N/A",
          "Category": "Laboratory services",
          "VendorName": "sys system - uk",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2024-01-22 18:14:23"
      },
      {
          "Inherent_Risk": "Low",
          "Residual_Risk": "Low",
          "approval": "N/A",
          "number": "RITM4894059",
          "state": "Closed Complete",
          "Year": "2024",
          "due_date": "N/A",
          "Category": "IT Services",
          "VendorName": "tridiagonal",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2024-01-24 06:57:18"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "Moderate",
          "approval": "N/A",
          "number": "RITM4894562",
          "state": "Annual Reassessment Completed",
          "Year": "2024",
          "due_date": "N/A",
          "Category": "Hosted Services Provider",
          "VendorName": "claranet",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2024-01-24 15:41:01"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM4894793",
          "state": "Annual Reassessment Completed",
          "Year": "2024",
          "due_date": "N/A",
          "Category": "IT Services",
          "VendorName": "capency",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2024-01-24 10:10:28"
      },
      {
          "Inherent_Risk": "Moderate",
          "Residual_Risk": "Low",
          "approval": "N/A",
          "number": "RITM4896203",
          "state": "Annual Reassessment In Progress",
          "Year": "2024",
          "due_date": "N/A",
          "Category": "Others",
          "VendorName": "atelier box",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2024-01-24 15:45:07"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "Low",
          "approval": "N/A",
          "number": "RITM4896230",
          "state": "Annual Reassessment Completed",
          "Year": "2024",
          "due_date": "N/A",
          "Category": "Professional Services",
          "VendorName": "grid dynamics holdings inc",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2024-01-24 15:52:15"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM4896549",
          "state": "Annual Reassessment Completed",
          "Year": "2024",
          "due_date": "N/A",
          "Category": "Advertising Services",
          "VendorName": "epsilon inc.",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2024-01-24 17:28:24"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "Moderate",
          "approval": "N/A",
          "number": "RITM4896562",
          "state": "Closed Complete",
          "Year": "2024",
          "due_date": "N/A",
          "Category": "Software",
          "VendorName": "realtimeboard inc. dba miro",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2024-01-24 17:34:35"
      },
      {
          "Inherent_Risk": "Low",
          "Residual_Risk": "Low",
          "approval": "N/A",
          "number": "RITM4897526",
          "state": "Closed Complete",
          "Year": "2024",
          "due_date": "N/A",
          "Category": "Advertising Services",
          "VendorName": "reachmax",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2024-01-25 06:19:09"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM4898322",
          "state": "In-Progress",
          "Year": "2024.0",
          "due_date": "N/A",
          "Category": "Employment / Temp agencies",
          "VendorName": "epam systems inc",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2024-01-25 09:54:15"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "Moderate",
          "approval": "N/A",
          "number": "RITM4898671",
          "state": "Annual Reassessment In Progress",
          "Year": "2024",
          "due_date": "N/A",
          "Category": "Others",
          "VendorName": "amcor",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2024-01-25 11:11:18"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "Moderate",
          "approval": "N/A",
          "number": "RITM4905792",
          "state": "Annual Reassessment Completed",
          "Year": "2024",
          "due_date": "N/A",
          "Category": "Software",
          "VendorName": "persado uk limited",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2024-01-29 11:25:49"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "Moderate",
          "approval": "N/A",
          "number": "RITM4906793",
          "state": "Closed Complete",
          "Year": "2024",
          "due_date": "N/A",
          "Category": "Software",
          "VendorName": "davricourt",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2024-01-29 17:14:17"
      },
      {
          "Inherent_Risk": "Low",
          "Residual_Risk": "Low",
          "approval": "N/A",
          "number": "RITM4907314",
          "state": "Closed Complete",
          "Year": "2024",
          "due_date": "N/A",
          "Category": "Marketing",
          "VendorName": "avanci",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2024-01-29 16:59:20"
      },
      {
          "Inherent_Risk": "Low",
          "Residual_Risk": "Very Low",
          "approval": "N/A",
          "number": "RITM4908397",
          "state": "Closed Complete",
          "Year": "2024",
          "due_date": "N/A",
          "Category": "Marketing",
          "VendorName": "schawk / sgk",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2024-01-30 22:30:56"
      },
      {
          "Inherent_Risk": "Moderate",
          "Residual_Risk": "Low",
          "approval": "N/A",
          "number": "RITM4909279",
          "state": "Closed Complete",
          "Year": "2024",
          "due_date": "N/A",
          "Category": "Logistics",
          "VendorName": "qube logistics (vic) pty. ltd.",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2024-01-30 09:49:17"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "Moderate",
          "approval": "N/A",
          "number": "RITM4911450",
          "state": "Annual Reassessment Completed",
          "Year": "2024",
          "due_date": "N/A",
          "Category": "Software",
          "VendorName": "colossyan",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2024-01-30 21:04:34"
      },
      {
          "Inherent_Risk": "Low",
          "Residual_Risk": "Low",
          "approval": "N/A",
          "number": "RITM4911489",
          "state": "Closed Complete",
          "Year": "2024",
          "due_date": "N/A",
          "Category": "IT Services",
          "VendorName": "cristabel pty ltd",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2024-01-30 21:53:12"
      },
      {
          "Inherent_Risk": "Moderate",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM4912286",
          "state": "Closed Complete",
          "Year": "2024",
          "due_date": "N/A",
          "Category": "Utilities",
          "VendorName": "cbre",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2024-01-31 07:57:59"
      },
      {
          "Inherent_Risk": "Moderate",
          "Residual_Risk": "Low",
          "approval": "N/A",
          "number": "RITM4914277",
          "state": "Closed Complete",
          "Year": "2024",
          "due_date": "N/A",
          "Category": "Telecommunication Services",
          "VendorName": "armada",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2024-01-31 15:58:48"
      },
      {
          "Inherent_Risk": "Low",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM4914602",
          "state": "In-Progress",
          "Year": "2024.0",
          "due_date": "N/A",
          "Category": "Logistics",
          "VendorName": "atlanta bonded warehouse",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2024-01-31 17:56:37"
      },
      {
          "Inherent_Risk": "Moderate",
          "Residual_Risk": "Low",
          "approval": "N/A",
          "number": "RITM4917250",
          "state": "Annual Reassessment Completed",
          "Year": "2024",
          "due_date": "N/A",
          "Category": "IT Services",
          "VendorName": "cirtuo",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2024-02-01 15:56:19"
      },
      {
          "Inherent_Risk": "Low",
          "Residual_Risk": "Low",
          "approval": "N/A",
          "number": "RITM4918664",
          "state": "Closed Complete",
          "Year": "2024",
          "due_date": "N/A",
          "Category": "Laboratory services",
          "VendorName": "rs components ltd",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2024-02-01 15:04:02"
      },
      {
          "Inherent_Risk": "Moderate",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM4919370",
          "state": "Closed Complete",
          "Year": "2024",
          "due_date": "N/A",
          "Category": "Cloud Services Provider",
          "VendorName": "kode labs",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2024-02-01 18:27:15"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "Moderate",
          "approval": "N/A",
          "number": "RITM4919387",
          "state": "Annual Reassessment Completed",
          "Year": "2024",
          "due_date": "N/A",
          "Category": "Software",
          "VendorName": "ecovadis",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2024-02-01 18:32:26"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "Moderate",
          "approval": "N/A",
          "number": "RITM4923069",
          "state": "Annual Reassessment Completed",
          "Year": "2024",
          "due_date": "N/A",
          "Category": "Advertising Services",
          "VendorName": "zefr, inc.",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2024-02-02 20:08:44"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM4930999",
          "state": "In-Progress",
          "Year": "2024.0",
          "due_date": "N/A",
          "Category": "IT Services",
          "VendorName": "digitaddict",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2024-02-05 14:27:06"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "Moderate",
          "approval": "N/A",
          "number": "RITM4931984",
          "state": "Closed Complete",
          "Year": "2024",
          "due_date": "N/A",
          "Category": "Professional Services",
          "VendorName": "gspann technologies, inc",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2024-02-05 15:27:58"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "Moderate",
          "approval": "N/A",
          "number": "RITM4932042",
          "state": "Annual Reassessment Completed",
          "Year": "2024",
          "due_date": "N/A",
          "Category": "Professional Services",
          "VendorName": "adesso se",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2024-02-05 14:34:32"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "Moderate",
          "approval": "N/A",
          "number": "RITM4936745",
          "state": "Annual Reassessment Completed",
          "Year": "2024",
          "due_date": "N/A",
          "Category": "Software",
          "VendorName": "swoop analytics",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2024-02-06 23:26:53"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM4936761",
          "state": "In-Progress",
          "Year": "2024.0",
          "due_date": "N/A",
          "Category": "IT Services",
          "VendorName": "fukuda america",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2024-02-06 23:58:27"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "Moderate",
          "approval": "N/A",
          "number": "RITM4937681",
          "state": "Closed Complete",
          "Year": "2024",
          "due_date": "N/A",
          "Category": "IT Services",
          "VendorName": "engaging communications pty ltd",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2024-02-07 15:23:41"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "Moderate",
          "approval": "N/A",
          "number": "RITM4941759",
          "state": "Annual Reassessment Completed",
          "Year": "2024",
          "due_date": "N/A",
          "Category": "Hosted Services Provider",
          "VendorName": "aeb",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2024-02-08 11:12:55"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "Moderate",
          "approval": "N/A",
          "number": "RITM4942300",
          "state": "Annual Reassessment Completed",
          "Year": "2024",
          "due_date": "N/A",
          "Category": "Legal Services",
          "VendorName": "dokobit, uab",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2024-02-08 13:24:56"
      },
      {
          "Inherent_Risk": "Moderate",
          "Residual_Risk": "Low",
          "approval": "N/A",
          "number": "RITM4942645",
          "state": "Closed Complete",
          "Year": "2024",
          "due_date": "N/A",
          "Category": "Education/Training",
          "VendorName": "pettalk services",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2024-02-08 14:39:11"
      },
      {
          "Inherent_Risk": "Moderate",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM4944523",
          "state": "Closed Complete",
          "Year": "2024",
          "due_date": "N/A",
          "Category": "Utilities",
          "VendorName": "jll",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2024-02-09 08:28:50"
      },
      {
          "Inherent_Risk": "Very Low",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM4945762",
          "state": "In-Progress",
          "Year": "2024.0",
          "due_date": "N/A",
          "Category": "Others",
          "VendorName": "igloo",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2024-02-09 14:12:46"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "Moderate",
          "approval": "N/A",
          "number": "RITM4956326",
          "state": "Annual Reassessment Completed",
          "Year": "2024",
          "due_date": "N/A",
          "Category": "Others",
          "VendorName": "commercial ltd",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2024-02-14 17:05:17"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM4958785",
          "state": "Annual Reassessment Completed",
          "Year": "2024",
          "due_date": "N/A",
          "Category": "Software",
          "VendorName": "sievo",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2024-02-15 13:10:36"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "Moderate",
          "approval": "N/A",
          "number": "RITM4958957",
          "state": "Annual Reassessment Completed",
          "Year": "2024",
          "due_date": "N/A",
          "Category": "Cloud Services Provider",
          "VendorName": "jotform pty ltd",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2024-02-15 13:42:48"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "Moderate",
          "approval": "N/A",
          "number": "RITM4962439",
          "state": "Annual Reassessment Completed",
          "Year": "2024",
          "due_date": "N/A",
          "Category": "Professional Services",
          "VendorName": "royal cyber inc.",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2024-02-16 14:59:59"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM4967868",
          "state": "Annual Reassessment In Progress",
          "Year": "2024",
          "due_date": "N/A",
          "Category": "Cloud Services Provider",
          "VendorName": "cloudm",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2024-02-19 22:22:45"
      },
      {
          "Inherent_Risk": "Moderate",
          "Residual_Risk": "Low",
          "approval": "N/A",
          "number": "RITM4969349",
          "state": "Closed Complete",
          "Year": "2024",
          "due_date": "N/A",
          "Category": "Professional Services",
          "VendorName": "lingaro",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2024-02-20 10:59:38"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "Moderate",
          "approval": "N/A",
          "number": "RITM4969973",
          "state": "Annual Reassessment Completed",
          "Year": "2024",
          "due_date": "N/A",
          "Category": "Marketing",
          "VendorName": "sampltech",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2024-02-20 13:19:22"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM4970528",
          "state": "Annual Reassessment Completed",
          "Year": "2024",
          "due_date": "N/A",
          "Category": "IT Services",
          "VendorName": "eptura inc.",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2024-02-20 15:11:18"
      },
      {
          "Inherent_Risk": "Critical",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM4974142",
          "state": "Annual Reassessment Completed",
          "Year": "2024",
          "due_date": "N/A",
          "Category": "Business Services",
          "VendorName": "kion-linde",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2024-02-21 15:26:13"
      },
      {
          "Inherent_Risk": "Very Low",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM4978444",
          "state": "In-Progress",
          "Year": "2024.0",
          "due_date": "N/A",
          "Category": "IT Services",
          "VendorName": "brent reichow <brent.reichow@eiresystems.com>",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2024-02-23 04:41:44"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM4979972",
          "state": "Closed Complete",
          "Year": "2024",
          "due_date": "N/A",
          "Category": "Cloud Services Provider",
          "VendorName": "evolt",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2024-02-23 13:35:44"
      },
      {
          "Inherent_Risk": "Low",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM4983781",
          "state": "Annual Reassessment In Progress",
          "Year": "2024",
          "due_date": "N/A",
          "Category": "Professional Services",
          "VendorName": "inexchange facotrum ab",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2024-02-26 13:20:31"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM4984673",
          "state": "Annual Reassessment In Progress",
          "Year": "2024",
          "due_date": "N/A",
          "Category": "IT Services",
          "VendorName": "smartcat",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2024-02-26 17:07:04"
      },
      {
          "Inherent_Risk": "Critical",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM4984784",
          "state": "Incompleted",
          "Year": "2024.0",
          "due_date": "N/A",
          "Category": "Cleaning & Maintenance Services",
          "VendorName": "test",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2024-02-26 18:30:00"
      },
      {
          "Inherent_Risk": "Moderate",
          "Residual_Risk": "Low",
          "approval": "N/A",
          "number": "RITM4993331",
          "state": "Closed Complete",
          "Year": "2024",
          "due_date": "N/A",
          "Category": "Others",
          "VendorName": "areka consulting",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2024-02-29 09:33:31"
      },
      {
          "Inherent_Risk": "Low",
          "Residual_Risk": "Low",
          "approval": "N/A",
          "number": "RITM4993354",
          "state": "Closed Complete",
          "Year": "2024",
          "due_date": "N/A",
          "Category": "Marketing",
          "VendorName": "fressnapf tiernahrungs gmbh",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2024-02-29 09:41:25"
      },
      {
          "Inherent_Risk": "Moderate",
          "Residual_Risk": "Low",
          "approval": "N/A",
          "number": "RITM4993611",
          "state": "Closed Complete",
          "Year": "2024",
          "due_date": "N/A",
          "Category": "Professional Services",
          "VendorName": "honestus insights inc",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2024-02-29 10:55:48"
      },
      {
          "Inherent_Risk": "Low",
          "Residual_Risk": "Low",
          "approval": "N/A",
          "number": "RITM4993708",
          "state": "Closed Complete",
          "Year": "2024",
          "due_date": "N/A",
          "Category": "IT Services",
          "VendorName": "tuatara consulting inc",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2024-02-29 11:24:35"
      },
      {
          "Inherent_Risk": "Low",
          "Residual_Risk": "Low",
          "approval": "N/A",
          "number": "RITM4993770",
          "state": "Closed Complete",
          "Year": "2024",
          "due_date": "N/A",
          "Category": "Professional Services",
          "VendorName": "neteffects inc.",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2024-02-29 11:46:43"
      },
      {
          "Inherent_Risk": "Low",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM4995412",
          "state": "In-Progress",
          "Year": "2024.0",
          "due_date": "N/A",
          "Category": "IT Services",
          "VendorName": "navvis ivion",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2024-02-29 20:42:13"
      },
      {
          "Inherent_Risk": "Moderate",
          "Residual_Risk": "Low",
          "approval": "N/A",
          "number": "RITM5008590",
          "state": "Closed Complete",
          "Year": "2024",
          "due_date": "N/A",
          "Category": "Software",
          "VendorName": "heytaco",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2024-03-05 20:53:22"
      },
      {
          "Inherent_Risk": "Low",
          "Residual_Risk": "Very Low",
          "approval": "N/A",
          "number": "RITM5011570",
          "state": "Closed Complete",
          "Year": "2024",
          "due_date": "N/A",
          "Category": "Software",
          "VendorName": "writer",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2024-03-06 14:53:07"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "Moderate",
          "approval": "N/A",
          "number": "RITM5015559",
          "state": "Closed Complete",
          "Year": "2024",
          "due_date": "N/A",
          "Category": "Business Services",
          "VendorName": "blip",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2024-03-07 18:28:04"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM5018157",
          "state": "In-Progress",
          "Year": "2024.0",
          "due_date": "N/A",
          "Category": "Cloud Services Provider",
          "VendorName": "transperfect",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2024-03-08 16:38:26"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "Moderate",
          "approval": "N/A",
          "number": "RITM5020377",
          "state": "Closed Complete",
          "Year": "2024",
          "due_date": "N/A",
          "Category": "Software",
          "VendorName": "hightouch",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2024-03-11 07:58:06"
      },
      {
          "Inherent_Risk": "Critical",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM5027149",
          "state": "In-Progress",
          "Year": "2024.0",
          "due_date": "N/A",
          "Category": "Software",
          "VendorName": "lexis nexis",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2024-03-12 19:26:38"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM5031038",
          "state": "In-Progress",
          "Year": "2024.0",
          "due_date": "N/A",
          "Category": "IT Services",
          "VendorName": "the pistol (tiger pistol)",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2024-03-13 23:16:08"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "Moderate",
          "approval": "N/A",
          "number": "RITM5031466",
          "state": "Closed Complete",
          "Year": "2024",
          "due_date": "N/A",
          "Category": "IT Services",
          "VendorName": "customore",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2024-03-14 14:44:37"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM5032464",
          "state": "Closed Complete",
          "Year": "2024",
          "due_date": "N/A",
          "Category": "Software",
          "VendorName": "marketing technology partners uk limited",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2024-03-14 10:56:41"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM5033371",
          "state": "In-Progress",
          "Year": "2024.0",
          "due_date": "N/A",
          "Category": "IT Services",
          "VendorName": "groupe isia",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2024-03-14 14:25:19"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "Moderate",
          "approval": "N/A",
          "number": "RITM5033728",
          "state": "Annual Reassessment In Progress",
          "Year": "2024",
          "due_date": "N/A",
          "Category": "Software",
          "VendorName": "mendix",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2024-03-14 15:50:09"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM5034219",
          "state": "In-Progress",
          "Year": "2024.0",
          "due_date": "N/A",
          "Category": "Employment / Temp agencies",
          "VendorName": "symphony talent limited",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2024-03-14 19:41:07"
      },
      {
          "Inherent_Risk": "Low",
          "Residual_Risk": "Low",
          "approval": "N/A",
          "number": "RITM5039149",
          "state": "Annual Reassessment Completed",
          "Year": "2024",
          "due_date": "N/A",
          "Category": "Marketing",
          "VendorName": "luze",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2024-03-18 09:06:19"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM5043231",
          "state": "Closed Complete",
          "Year": "2024",
          "due_date": "N/A",
          "Category": "Others",
          "VendorName": "edge strategy ag",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2024-03-19 14:55:20"
      },
      {
          "Inherent_Risk": "Moderate",
          "Residual_Risk": "Low",
          "approval": "N/A",
          "number": "RITM5043303",
          "state": "Closed Complete",
          "Year": "2024",
          "due_date": "N/A",
          "Category": "Professional Services",
          "VendorName": "appoena tecnologia da informacao ltda",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2024-03-19 13:23:57"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM5043326",
          "state": "In-Progress",
          "Year": "2024.0",
          "due_date": "N/A",
          "Category": "Others",
          "VendorName": "tw logística spa",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2024-03-19 08:58:37"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM5043346",
          "state": "In-Progress",
          "Year": "2024.0",
          "due_date": "N/A",
          "Category": "Software",
          "VendorName": "pecan ai ltd",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2024-03-19 16:46:21"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "Low",
          "approval": "N/A",
          "number": "RITM5045996",
          "state": "Closed Complete",
          "Year": "2024",
          "due_date": "N/A",
          "Category": "Hosted Services Provider",
          "VendorName": "trovata",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2024-03-19 20:20:12"
      },
      {
          "Inherent_Risk": "Low",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM5047221",
          "state": "In-Progress",
          "Year": "2024.0",
          "due_date": "N/A",
          "Category": "IT Services",
          "VendorName": "vpod smart solutions",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2024-03-20 09:11:42"
      },
      {
          "Inherent_Risk": "Low",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM5048844",
          "state": "Closed Complete",
          "Year": "2024",
          "due_date": "N/A",
          "Category": "Others",
          "VendorName": "nielsen",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2024-03-20 15:10:48"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM5052258",
          "state": "In-Progress",
          "Year": "2024.0",
          "due_date": "N/A",
          "Category": "IT Services",
          "VendorName": "ocelot it sp. z o.o.",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2024-03-21 14:40:58"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM5052585",
          "state": "Closed Complete",
          "Year": "2024",
          "due_date": "N/A",
          "Category": "Others",
          "VendorName": "dentsu",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2024-03-21 15:59:23"
      },
      {
          "Inherent_Risk": "Low",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM5052990",
          "state": "In-Progress",
          "Year": "2024.0",
          "due_date": "N/A",
          "Category": "IT Services",
          "VendorName": "workato",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2024-03-21 19:15:55"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "Moderate",
          "approval": "N/A",
          "number": "RITM5060293",
          "state": "Closed Complete",
          "Year": "2024",
          "due_date": "N/A",
          "Category": "Others",
          "VendorName": "yumuuv",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2024-03-25 17:59:03"
      },
      {
          "Inherent_Risk": "Low",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM5060308",
          "state": "Closed Complete",
          "Year": "2024",
          "due_date": "N/A",
          "Category": "Others",
          "VendorName": "rolling partners advisory",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2024-03-25 18:03:43"
      },
      {
          "Inherent_Risk": "Low",
          "Residual_Risk": "Low",
          "approval": "N/A",
          "number": "RITM5064007",
          "state": "Closed Complete",
          "Year": "2024",
          "due_date": "N/A",
          "Category": "Professional Services",
          "VendorName": "jihan bechara",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2024-03-26 18:00:04"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "Moderate",
          "approval": "N/A",
          "number": "RITM5064891",
          "state": "Annual Reassessment Completed",
          "Year": "2024",
          "due_date": "N/A",
          "Category": "Cloud Services Provider",
          "VendorName": "alibaba cloud",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2024-03-27 06:56:57"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM5065062",
          "state": "In-Progress",
          "Year": "2024.0",
          "due_date": "N/A",
          "Category": "Business Services",
          "VendorName": "axxam spa",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2024-03-27 07:43:54"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM5065115",
          "state": "In-Progress",
          "Year": "2024.0",
          "due_date": "N/A",
          "Category": "Laboratory services",
          "VendorName": "eurofins food and feed testing budapest kft",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2024-03-27 12:33:13"
      },
      {
          "Inherent_Risk": "Low",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM5067467",
          "state": "In-Progress",
          "Year": "2024.0",
          "due_date": "N/A",
          "Category": "Marketing",
          "VendorName": "zappistore inc",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2024-03-27 17:24:54"
      },
      {
          "Inherent_Risk": "Low",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM5067765",
          "state": "Closed Complete",
          "Year": "2024",
          "due_date": "N/A",
          "Category": "Cloud Services Provider",
          "VendorName": "recruitrooster",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2024-03-27 20:48:39"
      },
      {
          "Inherent_Risk": "Low",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM5067981",
          "state": "Closed Complete",
          "Year": "2024",
          "due_date": "N/A",
          "Category": "Marketing",
          "VendorName": "k10x ventures limited",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2024-03-28 01:09:54"
      },
      {
          "Inherent_Risk": "Low",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM5071165",
          "state": "In-Progress",
          "Year": "2024.0",
          "due_date": "N/A",
          "Category": "Cleaning & Maintenance Services",
          "VendorName": "w.w. grainger, inc.",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2024-03-28 21:29:30"
      },
      {
          "Inherent_Risk": "Low",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM5075879",
          "state": "Closed Complete",
          "Year": "2024",
          "due_date": "N/A",
          "Category": "Software",
          "VendorName": "convergint",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2024-04-01 12:58:01"
      },
      {
          "Inherent_Risk": "None",
          "Residual_Risk": "None",
          "approval": "N/A",
          "number": "RITM5076671",
          "state": "Closed Complete",
          "Year": "2024",
          "due_date": "N/A",
          "Category": "Professional Services",
          "VendorName": "insync",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2024-04-01 20:22:21"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "Low",
          "approval": "N/A",
          "number": "RITM5076708",
          "state": "Closed Complete",
          "Year": "2024",
          "due_date": "N/A",
          "Category": "Software",
          "VendorName": "persuit",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2024-04-01 20:51:04"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM5076792",
          "state": "Annual Reassessment In Progress",
          "Year": "2024",
          "due_date": "N/A",
          "Category": "Cloud Services Provider",
          "VendorName": "textus - vca",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2024-04-01 22:17:49"
      },
      {
          "Inherent_Risk": "Low",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM5077060",
          "state": "Annual Reassessment In Progress",
          "Year": "2024",
          "due_date": "N/A",
          "Category": "Logistics",
          "VendorName": "cainiao",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2024-04-02 03:47:04"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "Moderate",
          "approval": "N/A",
          "number": "RITM5077647",
          "state": "Annual Reassessment In Progress",
          "Year": "2024",
          "due_date": "N/A",
          "Category": "Cloud Services Provider",
          "VendorName": "synaos",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2024-04-02 07:41:08"
      },
      {
          "Inherent_Risk": "Low",
          "Residual_Risk": "Low",
          "approval": "N/A",
          "number": "RITM5079220",
          "state": "Closed Complete",
          "Year": "2024",
          "due_date": "N/A",
          "Category": "Others",
          "VendorName": "gurulocity, llc",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2024-04-02 13:26:53"
      },
      {
          "Inherent_Risk": "Moderate",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM5080225",
          "state": "Closed Complete",
          "Year": "2024",
          "due_date": "N/A",
          "Category": "Professional Services",
          "VendorName": "control service company",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2024-04-02 18:40:30"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "Low",
          "approval": "N/A",
          "number": "RITM5080445",
          "state": "Closed Complete",
          "Year": "2024",
          "due_date": "N/A",
          "Category": "Cloud Services Provider",
          "VendorName": "gong.io inc.",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2024-04-02 21:11:55"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM5080474",
          "state": "Annual Reassessment In Progress",
          "Year": "2024",
          "due_date": "N/A",
          "Category": "Cloud Services Provider",
          "VendorName": "gingr - vca",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2024-04-02 21:46:31"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "Moderate",
          "approval": "N/A",
          "number": "RITM5081704",
          "state": "Annual Reassessment Completed",
          "Year": "2024",
          "due_date": "N/A",
          "Category": "IT Services",
          "VendorName": "jingdong technology & information technology co.",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2024-04-03 18:00:51"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM5083606",
          "state": "In-Progress",
          "Year": "2024.0",
          "due_date": "N/A",
          "Category": "IT Services",
          "VendorName": "skill dynamic",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2024-04-03 16:12:36"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "Moderate",
          "approval": "N/A",
          "number": "RITM5084261",
          "state": "Annual Reassessment Completed",
          "Year": "2024",
          "due_date": "N/A",
          "Category": "IT Services",
          "VendorName": "reflex - vca",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2024-04-03 23:48:05"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM5087506",
          "state": "In-Progress",
          "Year": "2024.0",
          "due_date": "N/A",
          "Category": "Software",
          "VendorName": "c3 ai",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2024-04-04 21:03:58"
      },
      {
          "Inherent_Risk": "Moderate",
          "Residual_Risk": "Low",
          "approval": "N/A",
          "number": "RITM5090099",
          "state": "Closed Complete",
          "Year": "2024",
          "due_date": "N/A",
          "Category": "Others",
          "VendorName": "syntegon",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2024-04-05 16:32:36"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM5092630",
          "state": "In-Progress",
          "Year": "2024.0",
          "due_date": "N/A",
          "Category": "Others",
          "VendorName": "acrolinx gmbh",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2024-04-08 07:12:41"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM5093760",
          "state": "In-Progress",
          "Year": "2024.0",
          "due_date": "N/A",
          "Category": "Hosted Services Provider",
          "VendorName": "postman",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2024-04-08 11:34:04"
      },
      {
          "Inherent_Risk": "Low",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM5095175",
          "state": "In-Progress",
          "Year": "2024.0",
          "due_date": "N/A",
          "Category": "Marketing",
          "VendorName": "yext, inc.",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2024-04-08 17:13:28"
      },
      {
          "Inherent_Risk": "Low",
          "Residual_Risk": "Low",
          "approval": "N/A",
          "number": "RITM5098054",
          "state": "Closed Complete",
          "Year": "2024",
          "due_date": "N/A",
          "Category": "Others",
          "VendorName": "claire joster group slu",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2024-04-09 13:57:24"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM5098163",
          "state": "In-Progress",
          "Year": "2024.0",
          "due_date": "N/A",
          "Category": "Others",
          "VendorName": "analytic partners",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2024-04-09 14:28:59"
      },
      {
          "Inherent_Risk": "Moderate",
          "Residual_Risk": "Low",
          "approval": "N/A",
          "number": "RITM5102861",
          "state": "Annual Reassessment Completed",
          "Year": "2024",
          "due_date": "N/A",
          "Category": "Professional Services",
          "VendorName": "uplimit",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2024-04-10 20:06:59"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM5103143",
          "state": "In-Progress",
          "Year": "2024.0",
          "due_date": "N/A",
          "Category": "Professional Services",
          "VendorName": "adriano pisa",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2024-04-10 22:14:24"
      },
      {
          "Inherent_Risk": "Critical",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM5104005",
          "state": "In-Progress",
          "Year": "2024.0",
          "due_date": "N/A",
          "Category": "Marketing",
          "VendorName": "toppan inc.",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2024-04-11 06:31:38"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM5110392",
          "state": "In-Progress",
          "Year": "2024.0",
          "due_date": "N/A",
          "Category": "Others",
          "VendorName": "newcold melbourne pty ltd",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2024-04-12 14:32:54"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM5110433",
          "state": "In-Progress",
          "Year": "2024.0",
          "due_date": "N/A",
          "Category": "Others",
          "VendorName": "sweep sas",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2024-04-12 14:42:10"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM5110503",
          "state": "In-Progress",
          "Year": "2024.0",
          "due_date": "N/A",
          "Category": "Others",
          "VendorName": "elevate (us) limited",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2024-04-12 15:02:15"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM5110516",
          "state": "In-Progress",
          "Year": "2024.0",
          "due_date": "N/A",
          "Category": "Others",
          "VendorName": "rud pedersen a/s",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2024-04-12 15:08:32"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM5114965",
          "state": "In-Progress",
          "Year": "2024.0",
          "due_date": "N/A",
          "Category": "Others",
          "VendorName": "figma, inc.",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2024-04-15 09:28:52"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM5114983",
          "state": "In-Progress",
          "Year": "2024.0",
          "due_date": "N/A",
          "Category": "Others",
          "VendorName": "ioco software distribution pty limited",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2024-04-15 09:33:12"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM5116318",
          "state": "In-Progress",
          "Year": "2024.0",
          "due_date": "N/A",
          "Category": "Cloud Services Provider",
          "VendorName": "energy robotics",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2024-04-15 14:26:20"
      },
      {
          "Inherent_Risk": "Moderate",
          "Residual_Risk": "Low",
          "approval": "N/A",
          "number": "RITM5122460",
          "state": "Closed Complete",
          "Year": "2024",
          "due_date": "N/A",
          "Category": "Software",
          "VendorName": "maarga systems pvt ltd",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2024-04-17 11:08:34"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM5123279",
          "state": "In-Progress",
          "Year": "2024.0",
          "due_date": "N/A",
          "Category": "Others",
          "VendorName": "aays technologies pte ltd",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2024-04-17 14:06:37"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM5123307",
          "state": "In-Progress",
          "Year": "2024.0",
          "due_date": "N/A",
          "Category": "Others",
          "VendorName": "redstone intermediate archer holdco llc",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2024-04-17 12:42:23"
      },
      {
          "Inherent_Risk": "Critical",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM5123361",
          "state": "In-Progress",
          "Year": "2024.0",
          "due_date": "N/A",
          "Category": "Others",
          "VendorName": "hubspot japan co.,ltd",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2024-04-17 12:50:09"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM5123396",
          "state": "In-Progress",
          "Year": "2024.0",
          "due_date": "N/A",
          "Category": "Others",
          "VendorName": "leverage global consulting llc",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2024-04-17 12:57:46"
      },
      {
          "Inherent_Risk": "Low",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM5133393",
          "state": "In-Progress",
          "Year": "2024.0",
          "due_date": "N/A",
          "Category": "Marketing",
          "VendorName": "cj retail solutions",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2024-04-22 08:59:07"
      },
      {
          "Inherent_Risk": "Moderate",
          "Residual_Risk": "Low",
          "approval": "N/A",
          "number": "RITM5133907",
          "state": "Closed Complete",
          "Year": "2024",
          "due_date": "N/A",
          "Category": "Education/Training",
          "VendorName": "gemba (tln training limited)",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2024-04-22 11:09:16"
      },
      {
          "Inherent_Risk": "Low",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM5137281",
          "state": "In-Progress",
          "Year": "2024.0",
          "due_date": "N/A",
          "Category": "IT Services",
          "VendorName": "yext",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2024-04-22 17:50:47"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM5139730",
          "state": "In-Progress",
          "Year": "2024.0",
          "due_date": "N/A",
          "Category": "Professional Services",
          "VendorName": "rapdev llc",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2024-05-03 07:39:30"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM5140102",
          "state": "In-Progress",
          "Year": "2024.0",
          "due_date": "N/A",
          "Category": "Marketing",
          "VendorName": "colensobbdo",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2024-04-23 13:48:28"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM5142492",
          "state": "In-Progress",
          "Year": "2024.0",
          "due_date": "N/A",
          "Category": "Others",
          "VendorName": "from and to ltd",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2024-04-24 15:23:11"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM5146842",
          "state": "In-Progress",
          "Year": "2024.0",
          "due_date": "N/A",
          "Category": "Software",
          "VendorName": "displayplan",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2024-04-25 15:46:44"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM5146882",
          "state": "In-Progress",
          "Year": "2024.0",
          "due_date": "N/A",
          "Category": "Others",
          "VendorName": "orion digital solutions fzco",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2024-04-25 15:34:05"
      },
      {
          "Inherent_Risk": "Low",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM5147597",
          "state": "In-Progress",
          "Year": "2024.0",
          "due_date": "N/A",
          "Category": "Software",
          "VendorName": "computer packages inc. (cpi)",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2024-04-25 18:17:38"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM5154984",
          "state": "N/A",
          "Year": "2024.0",
          "due_date": "N/A",
          "Category": "Professional Services",
          "VendorName": "lincoln financial group",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2024-04-29 14:31:21"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM5159195",
          "state": "In-Progress",
          "Year": "2024.0",
          "due_date": "N/A",
          "Category": "IT Services",
          "VendorName": "slalom",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2024-04-30 19:46:53"
      },
      {
          "Inherent_Risk": "Very Low",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM5160599",
          "state": "In-Progress",
          "Year": "2024.0",
          "due_date": "N/A",
          "Category": "Others",
          "VendorName": "the hackett group",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2024-05-01 12:38:08"
      },
      {
          "Inherent_Risk": "Low",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM5160938",
          "state": "In-Progress",
          "Year": "2024.0",
          "due_date": "N/A",
          "Category": "Professional Services",
          "VendorName": "talent radar",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2024-05-01 13:33:52"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM5163942",
          "state": "In-Progress",
          "Year": "2024.0",
          "due_date": "N/A",
          "Category": "Cleaning & Maintenance Services",
          "VendorName": "avetta, llc",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2024-05-02 13:54:09"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM5167455",
          "state": "In-Progress",
          "Year": "2024.0",
          "due_date": "N/A",
          "Category": "Cloud Services Provider",
          "VendorName": "supplier.io",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2024-05-03 14:54:24"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM5167497",
          "state": "Closed Complete",
          "Year": "2024",
          "due_date": "N/A",
          "Category": "Hosted Services Provider",
          "VendorName": "certa",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2024-05-03 15:12:20"
      },
      {
          "Inherent_Risk": "Very Low",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM5167921",
          "state": "In-Progress",
          "Year": "2024.0",
          "due_date": "N/A",
          "Category": "Software",
          "VendorName": "litera",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2024-05-03 19:04:10"
      },
      {
          "Inherent_Risk": "None",
          "Residual_Risk": "None",
          "approval": "N/A",
          "number": "RITM5168029",
          "state": "Annual Reassessment In Progress",
          "Year": "2024",
          "due_date": "N/A",
          "Category": "Software",
          "VendorName": "thomson reuters",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2024-05-03 20:59:31"
      },
      {
          "Inherent_Risk": "Critical",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM5168030",
          "state": "In-Progress",
          "Year": "2024.0",
          "due_date": "N/A",
          "Category": "Professional Services",
          "VendorName": "smsb consulting group, inc.",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2024-05-03 20:59:59"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM5169525",
          "state": "In-Progress",
          "Year": "2024.0",
          "due_date": "N/A",
          "Category": "Audit & Compliance",
          "VendorName": "dss",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2024-05-06 06:54:02"
      },
      {
          "Inherent_Risk": "Low",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM5173405",
          "state": "In-Progress",
          "Year": "2024.0",
          "due_date": "N/A",
          "Category": "IT Services",
          "VendorName": "wecheer",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2024-05-07 10:32:10"
      },
      {
          "Inherent_Risk": "N/A",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM5174403",
          "state": "In-Progress",
          "Year": "2024.0",
          "due_date": "N/A",
          "Category": "IT Services",
          "VendorName": "nanjing kiwi network technology co",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2024-05-07 10:26:11"
      },
      {
          "Inherent_Risk": "High",
          "Residual_Risk": "N/A",
          "approval": "N/A",
          "number": "RITM5176390",
          "state": "In-Progress",
          "Year": "2024.0",
          "due_date": "N/A",
          "Category": "Others",
          "VendorName": "sirva (formally known as bgrs)",
          "MSA_Completion_Date": 1715126400000,
          "assessment_date": "2024-05-07 19:07:25"
      }
  ]);
    const [currentPagelimit, setPageLimit] = useState(20);
    const [filterStatus, setStatus] = useState("");
   // const [filterApproval, setApproval] = useState("");
    const [filterByResidual, setByResidual] = useState("");
    const [filterByInherent, setByInherent] = useState("");
    const [query, setQuery] = useState("");
    const [isloadingTable, setloadingTable] = useState(false);
    // const [isSortByDate, setSortByDate] = useState(false);
    const [FilterBydate, setFilterBydate] = useState("");
    const [Summrydata, setSummrydata] = useState({
      "totalNofVendors": 40481,
      "vendorsByRiskCategories": {
          "High": 15,
          "Very Low": 2,
          "Moderate": 94,
          "NA_in_Archer": 40322,
          "Low": 45,
          "Critical": 1,
          "None": 2
      },
      "vendorCategoriesByPeriod": null,
      "vendorsBySegments": null,
      "difference": 3,
      "homeLastUpdate": "2024-03-31 00:00:00.0",
      "workflowLastUpdate": "2024-05-08 00:00:00.0"
  });
    const [isRefreshed, setisRefreshed] = useState(false);

    const componentRef_1 = useRef(null);
    // const ref = useRef([]);

   // const baseUrl = 'http://localhost:8082/workflow'
  

    useEffect(() => {  
      document.title = `RISK - DATAFLOW`;
    }, []);

  

    const getDateTimeInfo = (dateStr) => {
      const dateObj = new Date(dateStr);
    
      // Get the month name
      const monthName = new Intl.DateTimeFormat('en-US', { month: 'long' }).format(dateObj);
    
      // Get the year
      const year = dateObj.getFullYear();
    
      // Get the number of days in the month
      const numberOfDays = new Date(year, dateObj.getMonth() + 1, 0).getDate();
    
      // Get the day of the week
      const dayOfWeek = new Intl.DateTimeFormat('en-US', { weekday: 'long' }).format(dateObj);
  
      // Get the day with ordinal suffix
      const day = dateObj.getDate();
      const dayWithSuffix = addOrdinalSuffix(day);
  
      // Get the time
      let hours = dateObj.getHours();
      const minutes = dateObj.getMinutes();
      const seconds = dateObj.getSeconds();
      const ampm = hours >= 12 ? 'PM' : 'AM';
      hours = hours % 12;
      hours = hours ? hours : 12;
    
      return {
        monthName,
        year,
        numberOfDays,
        dayOfWeek,
        dayWithSuffix,
        hours,
        minutes,
        seconds,
        ampm
      };
    };
  
    const addOrdinalSuffix = (number) => {
      const suffixes = ['th', 'st', 'nd', 'rd'];
      const remainder = number % 100;
      const suffix = suffixes[(remainder - 20) % 10] || suffixes[remainder] || suffixes[0];
      return `${number}${suffix}`;
    };
    
    
    // const sortedAsc = (items === null ? '' :  items.slice(0).sort((a,b)=>
    // b.Year - a.Year))
    const sortedAsc = (items === null ? '' :  items.slice(0).sort((a,b)=>
    b.assessment_date.localeCompare(a.assessment_date)))

    

   const data = sortedAsc === null ? " " : Object.values(sortedAsc);
   
    const search_parameters = ["Year","VendorName","number"];
    const filter_status = [...new Set(data.map((item) => item.state))];
    //const filter_approve = [...new Set(data.map((item) => item.approval))];
    const filter_inherent = [...new Set(data.map((item) => item.Inherent_Risk))];
    const filter_residual_1 = [...new Set(data.map((item) => item.Residual_Risk))];

    const check = data.filter(item => item.Category === null)

    console.log(check)

   //console.log("dates")
    console.log(filter_residual_1)

    const filter_residual = filter_residual_1.filter(item => item !== '-');

    console.log(filter_residual);

    let filteredData = [];

    if(items !== null){
        filteredData = sortedAsc.filter((obj) => {
            return obj;
          });
    }

 

    const handlePageChange = ({ selected }) => {
        setCurrentPage(selected);
      };
     
      
    function search(sortedAsc) {
        return sortedAsc.filter(
          (item) =>
            item.state.includes(filterStatus) &&
            item.Inherent_Risk.includes(filterByInherent) &&
            item.Residual_Risk.includes(filterByResidual) &&
            search_parameters.some(
              (parameter) =>
                item[parameter].toString().toLowerCase().includes(query.toLowerCase()) 
            )
           
        );
    }

      let NUM_OF_RECORDS = search(filteredData).length;
      if (NUM_OF_RECORDS <= currentPagelimit) {
        $(".pagination").hide(true);
      } else {
        $(".pagination").show(true);
      }

      const currentData = search(filteredData).slice(
        (currentPage - 1) * currentPagelimit,
        (currentPage - 1) * currentPagelimit + currentPagelimit
      );
    
      const pageCount = Math.ceil(search(filteredData).length / currentPagelimit);

      const handelepageclick = (data) => {
        console.log(data.selected);
        setCurrentPage(data.selected + 1);
      };

     // console.log("currentData")
      //console.log(search(filteredData))
      const resetAll = () =>{
        handlePageChange({ selected: 1 });
        setStatus("")
        setQuery("")
        setFilterBydate("")
        setByInherent("")
        setByResidual("")


      }

      const printSegment = useReactToPrint({
        content: () => {
          const tableStat_1 = componentRef_1.current.cloneNode(true);
      
          const PrintElem = document.createElement("div");
          const header = 
            `<img src="${Logo}" style="padding-bottom:2rem" alt="image" class="watermark"/>`;
          PrintElem.innerHTML = header;
        
          PrintElem.appendChild(tableStat_1);
      
          return PrintElem;
        },
      });

     

   

   
  /**
   * const checkDetails = () =>{
    var table = document.getElementsByTagName("table")[0];
var tbody = table.getElementsByTagName("tbody")[0];
tbody.onclick = function (e) {
    e = e || window.event;
    var data = [];
    var target = e.srcElement || e.target;
    while (target && target.nodeName !== "TR") {
        target = target.parentNode;
    }
    if (target) {
        var cells = target.getElementsByTagName("td");
        for (var i = 0; i < cells.length; i++) {
            data.push(cells[i].innerText);
        }
    }
    console.log(data);
};
  }
  const sortbyname = () =>{
    alert("sorting")
   filteredData =  items.sort((a, b) => {
    if (a.VendorName < b.VendorName) {
      return -1;
    }
    if (a.VendorName > b.VendorName) {
      return 1;
    }
    return 0;
  });
  }
   */

  function capitalizeWords(str) {
    return str.replace(/\b\w/g, function(match) {
      return match.toUpperCase();
    });
  }

  function rearrangeObjects(array) {
    return array.map(({ Year, VendorName, Inherent_Risk,state,Residual_Risk,Category,number }) => ({ Year, VendorName, Inherent_Risk,state,Residual_Risk,Category,number }));
  }
  
  // Call the function to rearrange the objects
  const rearrangedData = rearrangeObjects(search(filteredData));
  if(!isloading){
    console.log(rearrangedData)
  }
  
     
    


  return (
    <div>
      <NavigarionBar />
      <SideMenu />
      <div id="main">
        {isloading && (
          <div
            style={{
              height: "100%",
              display: "grid",
              alignItems: "center",
              alignContent: "center",
            }}
          >
            <div className="PlaseWait">
              <div className="spinner_class">
                <Loading />
              </div>
              <div>Please wait sometime</div>
            </div>
          </div>
        )}

        {!isloading && filteredData.length !== 0 && !error && (
          <>
            <div className="wrapper">
              <div
                style={{ paddingBottom: "2rem", gridTemplateColumns: "100%" }}
                className="searchBarSections"
              >
                <div
                  style={{
                    paddingBottom: "0rem",
                    display: "flex",
                    gridTemplateColumns: "100%",
                    justifyContent: "space-between",
                  }}
                  className="searchBarSections"
                >
                  <h1 className="vender_Risk_Management_System text_header_color">
                    Vendor Risk Management

                  </h1>

                  <div style={{ display: "flex", gridGap: "2rem" }}>
                    
                    
                    <div>
                      <ReportGenerate
                        excelData={rearrangeObjects(search(filteredData))}
                        fileName={`WorkFlow_${new Date().getTime()}`}
                      />
                    </div>

                    <div
                      style={{ cursor: "initial" }}
                      className="print_sagments"
                    >
                      <div className="Print_flex">
                        <span style={{ fontWeight: "600", color: "#0000a0" }}>
                          Print
                        </span>
                        <span
                          style={{ cursor: "pointer" }}
                          onClick={printSegment}
                        >
                          <FaPrint color="#0000a0" fontSize={26} />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  paddingBottom: "0rem",
                  flexWrap: "wrap",
                  gridGap: "10px",
                }}
              >
                <div className="search-wrapper">
                  <input
                    type="search"
                    name="search-form"
                    id="search-form"
                    value={query}
                    className="SearchBox_newworkflow"
                    placeholder="Search for Year, Vendor Name and Snow Ticket"
                    autoComplete="off"
                    onChange={(e) => {
                      setQuery(e.target.value);
                      handlePageChange({ selected: 1 });
                    }}
                  />
                </div>

                <div style={{ display: "flex", gridGap: "20px" }}>
                
                 {/* <div className="selectParent select">
                   <input 
                      type="date"
                      className="SearchBox_new"
                      style={{width:"100%"}}
                      value={FilterBydate}
                      onChange={(e) => {
                        setFilterBydate(e.target.value);
                        handlePageChange({ selected: 1 });
                      }}
                    />
                    </div>*/}
                  

                  <div className="selectParent select">
                    <select
                      value={filterByResidual}
                      onChange={(e) => {
                        setByResidual(e.target.value);
                        handlePageChange({ selected: 1 });
                      }}
                      className="custom-select custom-selectworkflow"
                      aria-label="Filter Residual"
                    >
                      <option value="">Residual Risk</option>
                      {filter_residual.map((item, index) => (
                        <option key={index} value={item}>
                          {item}
                        </option>
                      ))}
                    </select>
                    <span className="focus"></span>
                  </div>

                  <div className="selectParent select">
                    <select
                      value={filterByInherent}
                      onChange={(e) => {
                        setByInherent(e.target.value);
                        handlePageChange({ selected: 1 });
                      }}
                      className="custom-select custom-selectworkflow"
                      aria-label="Filter Inherent"
                    >
                      <option value="">Inherent Risk</option>
                      {filter_inherent.map((item, index) => (
                        <option key={index} value={item}>
                          {item}
                        </option>
                      ))}
                    </select>
                    <span className="focus"></span>
                  </div>

                

                  <div className="selectParent select">
                    <select
                      value={filterStatus}
                      onChange={(e) => {
                        setStatus(e.target.value);
                        handlePageChange({ selected: 1 });
                      }}
                      className="custom-select custom-selectworkflow"
                      aria-label="Filter status"
                    >
                      <option value="">Filter By Status</option>
                      {filter_status.map((item, index) => (
                        <option key={index} value={item}>
                          {item}
                        </option>
                      ))}
                    </select>
                    <span className="focus"></span>
                  </div>

               

                  <Button
                    style={{
                      background: "#253878",
                      border: "none",
                      outline: "none",
                    }}
                    onClick={resetAll}
                  >
                    Reset
                  </Button>
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  paddingBottom: "1rem",
                  paddingTop: "1rem",
                }}
              >
                <div
                  style={{
                    fontSize: "18px",
                    paddingBottom: "1rem",
                    paddingTop: "1rem",
                    display:"flex",
                    alignItems:"center"
                  }}
                >
                  Total number of Vendors{" "}
                  <span
                    style={{
                      fontSize: "18px",
                      fontWeight: "600",
                      color: "#253978",
                      paddingLeft:"10px"
                    }}
                  >
                    {search(filteredData).length}
                  </span>
                  {isRefreshed && (
                  <div style={{paddingLeft:"10px",fontSize:"12px"}}>Last Refreshed On {getDateTimeInfo(Summrydata.workflowLastUpdate).dayWithSuffix +" " + getDateTimeInfo(Summrydata.workflowLastUpdate).monthName +", "+getDateTimeInfo(Summrydata.workflowLastUpdate).year + " "+ getDateTimeInfo(Summrydata.workflowLastUpdate).hours+ "" +getDateTimeInfo(Summrydata.workflowLastUpdate).ampm}</div>
                  )}

                </div>

                <div
                  style={{
                    display: "flex",
                    gridGap: "10px",
                    alignItems: "center",
                    alignContent: "center",
                  }}
                >
                  <div>
                    <ReactPaginate
                      previousLabel={"Previous"}
                      nextLabel={"Next"}
                      breakLabel={"..."}
                      pageCount={pageCount}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={3}
                      onPageChange={handelepageclick}
                      containerClassName={"pagination"}
                      pageClassName={"page-item"}
                      pageLinkClassName={"page-link"}
                      previousClassName={"page-item"}
                      previousLinkClassName={"page-link"}
                      nextClassName={"page-item"}
                      nextLinkClassName={"page-link"}
                      breakClassName={"page-item"}
                      breakLinkClassName={"page-link"}
                      activeClassName={"active"}
                      forcePage={currentPage - 1}
                    />
                  </div>
                </div>
              </div>

              {!isloadingTable && (
                <table
                  id="DataTable"
                  ref={componentRef_1}
                  style={{ width: "100%" }}
                  className="workflowTable"
                >
                  <tbody>
                    <tr>
                      <th
                        style={{
                          background: "#d0d8e2",
                          color: "#253978",
                          textAlign: "center",
                        }}
                      >
                        Year
                      </th>
                      <th
                        style={{
                          textAlign: "center",
                          background: "#d0d8e2",
                          color: "#253978",
                          width: "18%",
                          
                        }}
                      >
                        Vendor Name 
                      </th>

                      <th
                        style={{
                          textAlign: "center",
                          background: "#d0d8e2",
                          color: "#253978",
                        }}
                      >
                        Residual Risk
                      </th>
                      <th
                        style={{
                          textAlign: "center",
                          background: "#d0d8e2",
                          color: "#253978",
                        }}
                      >
                        Inherent Risk
                      </th>

                      <th
                        style={{
                          textAlign: "center",
                          background: "#d0d8e2",
                          color: "#253978",
                        }}
                      >
                        Snow Ticket
                      </th>

                      <th
                        style={{
                          textAlign: "center",
                          background: "#d0d8e2",
                          color: "#253978",
                          width: "12%",
                        }}
                      >
                        Category
                      </th>

                      <th
                        style={{
                          textAlign: "center",
                          background: "#d0d8e2",
                          color: "#253978",
                        }}
                      >
                        Assessment Date
                      </th>

                     

                      <th
                        style={{
                          textAlign: "center",
                          background: "#d0d8e2",
                          color: "#253978",
                        }}
                      >
                        Status
                      </th>
                    </tr>

                    {search(filteredData).length !== 0 && (
                      <>
                        {currentData.map((item, index) => (
                          <tr key={index}>
                            <td
                              className="vendor_risk"
                              style={{
                                justifyContent: "center",
                                textAlign: "center",
                                cursor:"pointer"
                              }}
                              title={item.Year}
                            >
                              {item.Year}
                            </td>

                            <td style={{ fontWeight: "normal" }}>
                              <span
                                style={{
                                  display: "grid",
                                  justifyContent: "center",
                                  cursor: "pointer",
                                }}
                              >
                                <span
                                  title= {capitalizeWords(item.VendorName)} 
                                  className="vendor_risk_items_cls"
                                >
                                      {capitalizeWords(item.VendorName)}                                 
                                </span>
                              </span>
                            </td>

                            <td style={{ fontWeight: "normal" }}>
                              <span
                                style={{
                                  display: "grid",
                                  justifyContent: "center",
                                  cursor:"pointer"
                                }}
                              >
                                {item.Residual_Risk === "Low" ? (
                                  <div className="vendor_risk_items_low vendor_loc itemsFlex">
                                    <span title={item.Residual_Risk}>{item.Residual_Risk}</span>
                                  </div>
                                ) : item.Residual_Risk === "High" ? (
                                  <div className="vendor_risk_items_high vendor_loc itemsFlex">
                                    <span title={item.Residual_Risk}>{item.Residual_Risk}</span>
                                  </div>
                                ) : item.Residual_Risk === "Moderate" ? (
                                  <div className="vendor_risk_items_modrate vendor_loc itemsFlex">
                                    <span title={item.Residual_Risk}>{item.Residual_Risk}</span>
                                  </div>
                                ) : item.Residual_Risk === "Very Low" ? (
                                  <div className="vendor_risk_items_veryLow vendor_loc itemsFlex">
                                    <span title={item.Residual_Risk}>{item.Residual_Risk}</span>
                                  </div>
                                )
                                : item.Residual_Risk === "-" ? (
                                  <div>
                                    <span title={`No Data Available because of state is ${item.state}`}>{item.Residual_Risk}</span>
                                  </div>
                                ) : (
                                  <div className="vendor_risk_items_critical vendor_loc itemsFlex">
                                    <span title={item.Residual_Risk}>
                                      {item.Residual_Risk === "" ||
                                      item.Residual_Risk === "NA"
                                        ? "N/A"
                                        : item.Residual_Risk}
                                    </span>
                                  </div>
                                )}
                              </span>
                            </td>

                            <td style={{ fontWeight: "normal" }}>
                              <span
                                style={{
                                  display: "grid",
                                  justifyContent: "center",
                                  cursor:"pointer"
                                }}
                              >
                                {item.Inherent_Risk === "Low" ? (
                                  <div className="vendor_risk_items_low vendor_loc itemsFlex">
                                    <span title={item.Inherent_Risk}>{item.Inherent_Risk}</span>
                                  </div>
                                ) : item.Inherent_Risk === "High" ? (
                                  <div className="vendor_risk_items_high vendor_loc itemsFlex">
                                    <span title={item.Inherent_Risk}>{item.Inherent_Risk}</span>
                                  </div>
                                ) : item.Inherent_Risk === "Moderate" ? (
                                  <div className="vendor_risk_items_modrate vendor_loc itemsFlex">
                                    <span title={item.Inherent_Risk}>{item.Inherent_Risk}</span>
                                  </div>
                                ) : item.Inherent_Risk === "Very Low" ? (
                                  <div className="vendor_risk_items_veryLow vendor_loc itemsFlex">
                                    <span title={item.Inherent_Risk}>{item.Inherent_Risk}</span>
                                  </div>
                                ) : (
                                  <div className="vendor_risk_items_critical vendor_loc itemsFlex">
                                    <span title={item.Inherent_Risk}>
                                      {item.Inherent_Risk === "" ||
                                      item.Inherent_Risk === "NA"
                                        ? "N/A"
                                        : item.Inherent_Risk}
                                    </span>
                                  </div>
                                )}
                              </span>
                            </td>

                            <td>
                              <span
                                style={{
                                  display: "grid",
                                  justifyContent: "center",
                                  cursor:"pointer"
                                }}
                              >
                                <span className="vendor_risk_items_cls state_na_in_snow">
                                 
                                  {item.number !== 'N/A' && (
                                    <a rel="noreferrer" title={item.number} target="_blank" href={`https://marsprod.service-now.com/nav_to.do?uri=sc_req_item.do?sysparm_query=number=${item.number}`}>
                                    {item.number}
                                  </a>
                                  )}

                                  {(item.number === 'N/A') && (
                                    <>{item.number}</>                                  
                                  )}

                                
                                  
                                </span>
                              </span>
                            </td>

                            <td>
                              <span
                                style={{
                                  display: "grid",
                                  justifyContent: "center",
                                  cursor:"pointer"
                                }}
                              >
                                <span title={item.Category} className="vendor_risk_items_cls state_na_in_snow">
                                  {(item.Category === 'NA') ? 'N/A' : item.Category}
                                </span>
                              </span>
                            </td>

                            <td>
                              <span
                                style={{
                                  display: "grid",
                                  justifyContent: "center",
                                  cursor:"pointer"
                                }}
                              >
                                <span title={item.assessment_date} className="vendor_risk_items_cls state_na_in_snow">
                                  {(item.assessment_date === 'NA') ? 'N/A' : (item.assessment_date).split(" ")[0]}
                                </span>
                              </span>
                            </td>

                    
                            <td>
                              <span
                                style={{
                                  display: "grid",
                                  justifyContent: "center",
                                  cursor:"pointer"
                                }}
                              >
                                {(item.state === "In-Progress" || item.state === "Work in Progress" || item.state === "Annual Reassessment In Progress") ? (
                                  <div className="vendor_risk_items_low vendor_loc itemsFlex workInprogress">
                                    <span title={item.state}>{item.state}</span>
                                  </div>
                                ) : item.state === "Cancelled" ? (
                                  <div className="vendor_risk_items_high vendor_loc itemsFlex cancelled">
                                    <span title={item.state}>{item.state}</span>
                                  </div>
                                ) : (item.state === "Annual Reassessment Completed" || item.state === "Completed" || item.state === "Closed Completed" || item.state === "Closed Complete") ? (
                                  <div className="vendor_risk_items_modrate vendor_loc itemsFlex closedComplete">
                                    <span title={item.state}>{item.state}</span>
                                  </div>
                                ) : (item.state === "Closed Incomplete" || item.state === "Incompleted") ? (
                                  <div className="vendor_risk_items_veryLow vendor_loc itemsFlex closedIncomplete">
                                    <span title={item.state}>{item.state}</span>
                                  </div>
                                ) : (
                                  <div className="vendor_risk_items_critical vendor_loc itemsFlex state_na_in_snow">
                                    <span title={item.state}>
                                      {item.state === "" ? "N/A" : item.state}
                                    </span>
                                  </div>
                                )}
                              </span>
                            </td>
                          </tr>
                        ))}
                      </>
                    )}

                    {search(filteredData).length === 0 && (
                      <tr style={{ textAlign: "center" }}>
                        <td
                          style={{ textAlign: "center", color: "red" }}
                          rowSpan={4}
                          colSpan={9}
                        >
                          No Data available
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              )}
            </div>
          </>
        )}

        {error && (
          <div className="PlaseWait">
            <div className="spinner_class">
              {`There is a problem fetching the post data ${iserror}`}
            </div>
            <div>Please try after sometime</div>
          </div>
        )}
      </div>
    </div>
  );
}

export default WorkflowReadOnly
