import React, { useEffect, useRef, useState } from 'react'
import Popup from "reactjs-popup";
import { useReactToPrint } from "react-to-print";
import Logo from "../../img/WaterMark.png";
import Skeleton from "react-loading-skeleton";
import styled from "styled-components";
import Loading from '../spinner/Loading';
import {FaSortAmountDownAlt, FaSortAmountUpAlt } from "react-icons/fa";

const contentStyle = {
  maxWidth: "800px",
  width: "100%",
  background:"white",
  borderRadius: "20px"
};

const VendorPageWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Active_po_popup = (props) => {
    const [ListofPoPopup, setListofPopup] = useState([
      {
          "VendorID": "15238510",
          "VendorName": "aays advisory private limited",
          "PO_Numbers": 4700100121,
          "SourceSystem": "AEP",
          "VendorNameCombTranslation": "aays advisory private limited",
          "Region": "Telangana",
          "Finished_Good_Values": 76563
      },
      {
          "VendorID": "15238510",
          "VendorName": "aays advisory private limited",
          "PO_Numbers": 4700195925,
          "SourceSystem": "AEP",
          "VendorNameCombTranslation": "aays advisory private limited",
          "Region": "Telangana",
          "Finished_Good_Values": 304200
      },
      {
          "VendorID": "15238510",
          "VendorName": "aays advisory private limited",
          "PO_Numbers": 4700214287,
          "SourceSystem": "AEP",
          "VendorNameCombTranslation": "aays advisory private limited",
          "Region": "Telangana",
          "Finished_Good_Values": 50000
      },
      {
          "VendorID": "15238510",
          "VendorName": "aays advisory private limited",
          "PO_Numbers": 4700210956,
          "SourceSystem": "AEP",
          "VendorNameCombTranslation": "aays advisory private limited",
          "Region": "Telangana",
          "Finished_Good_Values": 49000
      },
      {
          "VendorID": "15238510",
          "VendorName": "aays advisory private limited",
          "PO_Numbers": 4700166945,
          "SourceSystem": "AEP",
          "VendorNameCombTranslation": "aays advisory private limited",
          "Region": "Telangana",
          "Finished_Good_Values": 360000
      },
      {
          "VendorID": "15238510",
          "VendorName": "aays advisory private limited",
          "PO_Numbers": 4700074757,
          "SourceSystem": "AEP",
          "VendorNameCombTranslation": "aays advisory private limited",
          "Region": "Telangana",
          "Finished_Good_Values": 47750
      },
      {
          "VendorID": "15238510",
          "VendorName": "aays advisory private limited",
          "PO_Numbers": 4700204162,
          "SourceSystem": "AEP",
          "VendorNameCombTranslation": "aays advisory private limited",
          "Region": "Telangana",
          "Finished_Good_Values": 8000
      },
      {
          "VendorID": "15238510",
          "VendorName": "aays advisory private limited",
          "PO_Numbers": 4700234195,
          "SourceSystem": "AEP",
          "VendorNameCombTranslation": "aays advisory private limited",
          "Region": "Telangana",
          "Finished_Good_Values": 65000
      },
      {
          "VendorID": "15238510",
          "VendorName": "aays advisory private limited",
          "PO_Numbers": 4700211704,
          "SourceSystem": "AEP",
          "VendorNameCombTranslation": "aays advisory private limited",
          "Region": "Telangana",
          "Finished_Good_Values": 32500
      },
      {
          "VendorID": "15238510",
          "VendorName": "aays advisory private limited",
          "PO_Numbers": 4700201583,
          "SourceSystem": "AEP",
          "VendorNameCombTranslation": "aays advisory private limited",
          "Region": "Telangana",
          "Finished_Good_Values": 4500
      },
      {
          "VendorID": "15238510",
          "VendorName": "aays advisory private limited",
          "PO_Numbers": 4700187929,
          "SourceSystem": "AEP",
          "VendorNameCombTranslation": "aays advisory private limited",
          "Region": "Telangana",
          "Finished_Good_Values": 88000
      },
      {
          "VendorID": "15238510",
          "VendorName": "aays advisory private limited",
          "PO_Numbers": 4700247060,
          "SourceSystem": "AEP",
          "VendorNameCombTranslation": "aays advisory private limited",
          "Region": "Telangana",
          "Finished_Good_Values": 10000
      },
      {
          "VendorID": "15238510",
          "VendorName": "aays advisory private limited",
          "PO_Numbers": 4700210272,
          "SourceSystem": "AEP",
          "VendorNameCombTranslation": "aays advisory private limited",
          "Region": "Telangana",
          "Finished_Good_Values": 3000
      },
      {
          "VendorID": "15238510",
          "VendorName": "aays advisory private limited",
          "PO_Numbers": 4700253239,
          "SourceSystem": "AEP",
          "VendorNameCombTranslation": "aays advisory private limited",
          "Region": "Telangana",
          "Finished_Good_Values": 162500
      },
      {
          "VendorID": "15238510",
          "VendorName": "aays advisory private limited",
          "PO_Numbers": 4700207178,
          "SourceSystem": "AEP",
          "VendorNameCombTranslation": "aays advisory private limited",
          "Region": "Telangana",
          "Finished_Good_Values": 13000
      },
      {
          "VendorID": "15238510",
          "VendorName": "aays advisory private limited",
          "PO_Numbers": 4700162874,
          "SourceSystem": "AEP",
          "VendorNameCombTranslation": "aays advisory private limited",
          "Region": "Telangana",
          "Finished_Good_Values": 22500
      },
      {
          "VendorID": "15238510",
          "VendorName": "aays advisory private limited",
          "PO_Numbers": 4700243490,
          "SourceSystem": "AEP",
          "VendorNameCombTranslation": "aays advisory private limited",
          "Region": "Telangana",
          "Finished_Good_Values": 14000
      },
      {
          "VendorID": "15238510",
          "VendorName": "aays advisory private limited",
          "PO_Numbers": 4700208709,
          "SourceSystem": "AEP",
          "VendorNameCombTranslation": "aays advisory private limited",
          "Region": "Telangana",
          "Finished_Good_Values": 50000
      },
      {
          "VendorID": "15238510",
          "VendorName": "aays advisory private limited",
          "PO_Numbers": 4700216590,
          "SourceSystem": "AEP",
          "VendorNameCombTranslation": "aays advisory private limited",
          "Region": "Telangana",
          "Finished_Good_Values": 20000
      },
      {
          "VendorID": "15238510",
          "VendorName": "aays advisory private limited",
          "PO_Numbers": 4700161459,
          "SourceSystem": "AEP",
          "VendorNameCombTranslation": "aays advisory private limited",
          "Region": "Telangana",
          "Finished_Good_Values": 300000
      },
      {
          "VendorID": "15238510",
          "VendorName": "aays advisory private limited",
          "PO_Numbers": 4700185822,
          "SourceSystem": "AEP",
          "VendorNameCombTranslation": "aays advisory private limited",
          "Region": "Telangana",
          "Finished_Good_Values": 54000
      },
      {
          "VendorID": "15238510",
          "VendorName": "aays advisory private limited",
          "PO_Numbers": 4700192760,
          "SourceSystem": "AEP",
          "VendorNameCombTranslation": "aays advisory private limited",
          "Region": "Telangana",
          "Finished_Good_Values": 42000
      },
      {
          "VendorID": "15238510",
          "VendorName": "aays advisory private limited",
          "PO_Numbers": 4700237526,
          "SourceSystem": "AEP",
          "VendorNameCombTranslation": "aays advisory private limited",
          "Region": "Telangana",
          "Finished_Good_Values": 195125
      },
      {
          "VendorID": "15238510",
          "VendorName": "aays advisory private limited",
          "PO_Numbers": 4700228306,
          "SourceSystem": "AEP",
          "VendorNameCombTranslation": "aays advisory private limited",
          "Region": "Telangana",
          "Finished_Good_Values": 22000
      },
      {
          "VendorID": "15238510",
          "VendorName": "aays advisory private limited",
          "PO_Numbers": 4700200492,
          "SourceSystem": "AEP",
          "VendorNameCombTranslation": "aays advisory private limited",
          "Region": "Telangana",
          "Finished_Good_Values": 52000
      },
      {
          "VendorID": "15238510",
          "VendorName": "aays advisory private limited",
          "PO_Numbers": 4700152467,
          "SourceSystem": "AEP",
          "VendorNameCombTranslation": "aays advisory private limited",
          "Region": "Telangana",
          "Finished_Good_Values": 48100
      },
      {
          "VendorID": "15238510",
          "VendorName": "aays advisory private limited",
          "PO_Numbers": 4700180876,
          "SourceSystem": "AEP",
          "VendorNameCombTranslation": "aays advisory private limited",
          "Region": "Telangana",
          "Finished_Good_Values": 33378
      },
      {
          "VendorID": "15238510",
          "VendorName": "aays advisory private limited",
          "PO_Numbers": 4700259233,
          "SourceSystem": "AEP",
          "VendorNameCombTranslation": "aays advisory private limited",
          "Region": "Telangana",
          "Finished_Good_Values": 66500
      },
      {
          "VendorID": "15238510",
          "VendorName": "aays advisory private limited",
          "PO_Numbers": 4700105235,
          "SourceSystem": "AEP",
          "VendorNameCombTranslation": "aays advisory private limited",
          "Region": "Telangana",
          "Finished_Good_Values": 16688
      },
      {
          "VendorID": "15238510",
          "VendorName": "aays advisory private limited",
          "PO_Numbers": 4700195707,
          "SourceSystem": "AEP",
          "VendorNameCombTranslation": "aays advisory private limited",
          "Region": "Telangana",
          "Finished_Good_Values": 4000
      },
      {
          "VendorID": "15238510",
          "VendorName": "aays advisory private limited",
          "PO_Numbers": 4700176493,
          "SourceSystem": "AEP",
          "VendorNameCombTranslation": "aays advisory private limited",
          "Region": "Telangana",
          "Finished_Good_Values": 14000
      },
      {
          "VendorID": "15238510",
          "VendorName": "aays advisory private limited",
          "PO_Numbers": 4700041603,
          "SourceSystem": "AEP",
          "VendorNameCombTranslation": "aays advisory private limited",
          "Region": "Telangana",
          "Finished_Good_Values": 15000
      },
      {
          "VendorID": "15238510",
          "VendorName": "aays advisory private limited",
          "PO_Numbers": 4700104761,
          "SourceSystem": "AEP",
          "VendorNameCombTranslation": "aays advisory private limited",
          "Region": "Telangana",
          "Finished_Good_Values": 59500
      },
      {
          "VendorID": "15238510",
          "VendorName": "aays advisory private limited",
          "PO_Numbers": 4700186500,
          "SourceSystem": "AEP",
          "VendorNameCombTranslation": "aays advisory private limited",
          "Region": "Telangana",
          "Finished_Good_Values": 21750
      },
      {
          "VendorID": "15238510",
          "VendorName": "aays advisory private limited",
          "PO_Numbers": 4700030643,
          "SourceSystem": "AEP",
          "VendorNameCombTranslation": "aays advisory private limited",
          "Region": "Telangana",
          "Finished_Good_Values": 11914
      },
      {
          "VendorID": "15238510",
          "VendorName": "aays advisory private limited",
          "PO_Numbers": 4700239752,
          "SourceSystem": "AEP",
          "VendorNameCombTranslation": "aays advisory private limited",
          "Region": "Telangana",
          "Finished_Good_Values": 45688
      },
      {
          "VendorID": "15238510",
          "VendorName": "aays advisory private limited",
          "PO_Numbers": 4700224018,
          "SourceSystem": "AEP",
          "VendorNameCombTranslation": "aays advisory private limited",
          "Region": "Telangana",
          "Finished_Good_Values": 106110
      },
      {
          "VendorID": "15238510",
          "VendorName": "aays advisory private limited",
          "PO_Numbers": 4700225755,
          "SourceSystem": "AEP",
          "VendorNameCombTranslation": "aays advisory private limited",
          "Region": "Telangana",
          "Finished_Good_Values": 135500
      },
      {
          "VendorID": "15238510",
          "VendorName": "aays advisory private limited",
          "PO_Numbers": 4700182914,
          "SourceSystem": "AEP",
          "VendorNameCombTranslation": "aays advisory private limited",
          "Region": "Telangana",
          "Finished_Good_Values": 28875
      },
      {
          "VendorID": "15238510",
          "VendorName": "aays advisory private limited",
          "PO_Numbers": 4700215343,
          "SourceSystem": "AEP",
          "VendorNameCombTranslation": "aays advisory private limited",
          "Region": "Telangana",
          "Finished_Good_Values": 16250
      },
      {
          "VendorID": "15238510",
          "VendorName": "aays advisory private limited",
          "PO_Numbers": 4700027528,
          "SourceSystem": "AEP",
          "VendorNameCombTranslation": "aays advisory private limited",
          "Region": "Telangana",
          "Finished_Good_Values": 39750
      },
      {
          "VendorID": "15238510",
          "VendorName": "aays advisory private limited",
          "PO_Numbers": 4700257254,
          "SourceSystem": "AEP",
          "VendorNameCombTranslation": "aays advisory private limited",
          "Region": "Telangana",
          "Finished_Good_Values": 10000
      },
      {
          "VendorID": "15238510",
          "VendorName": "aays advisory private limited",
          "PO_Numbers": 4700252341,
          "SourceSystem": "AEP",
          "VendorNameCombTranslation": "aays advisory private limited",
          "Region": "Telangana",
          "Finished_Good_Values": 19750
      },
      {
          "VendorID": "15238510",
          "VendorName": "aays advisory private limited",
          "PO_Numbers": 4700223970,
          "SourceSystem": "AEP",
          "VendorNameCombTranslation": "aays advisory private limited",
          "Region": "Telangana",
          "Finished_Good_Values": 28900
      },
      {
          "VendorID": "15238510",
          "VendorName": "aays advisory private limited",
          "PO_Numbers": 4700195936,
          "SourceSystem": "AEP",
          "VendorNameCombTranslation": "aays advisory private limited",
          "Region": "Telangana",
          "Finished_Good_Values": 175125
      },
      {
          "VendorID": "15238510",
          "VendorName": "aays advisory private limited",
          "PO_Numbers": 4700055170,
          "SourceSystem": "AEP",
          "VendorNameCombTranslation": "aays advisory private limited",
          "Region": "Telangana",
          "Finished_Good_Values": 47250
      },
      {
          "VendorID": "15238510",
          "VendorName": "aays advisory private limited",
          "PO_Numbers": 4700226041,
          "SourceSystem": "AEP",
          "VendorNameCombTranslation": "aays advisory private limited",
          "Region": "Telangana",
          "Finished_Good_Values": 3500
      },
      {
          "VendorID": "15238510",
          "VendorName": "aays advisory private limited",
          "PO_Numbers": 4700068591,
          "SourceSystem": "AEP",
          "VendorNameCombTranslation": "aays advisory private limited",
          "Region": "Telangana",
          "Finished_Good_Values": 13750
      },
      {
          "VendorID": "15238510",
          "VendorName": "aays advisory private limited",
          "PO_Numbers": 4700248752,
          "SourceSystem": "AEP",
          "VendorNameCombTranslation": "aays advisory private limited",
          "Region": "Telangana",
          "Finished_Good_Values": 289000
      },
      {
          "VendorID": "15238510",
          "VendorName": "aays advisory private limited",
          "PO_Numbers": 4700188170,
          "SourceSystem": "AEP",
          "VendorNameCombTranslation": "aays advisory private limited",
          "Region": "Telangana",
          "Finished_Good_Values": 6500
      },
      {
          "VendorID": "15238510",
          "VendorName": "aays advisory private limited",
          "PO_Numbers": 4700217247,
          "SourceSystem": "AEP",
          "VendorNameCombTranslation": "aays advisory private limited",
          "Region": "Telangana",
          "Finished_Good_Values": 112000
      },
      {
          "VendorID": "15238510",
          "VendorName": "aays advisory private limited",
          "PO_Numbers": 4700228489,
          "SourceSystem": "AEP",
          "VendorNameCombTranslation": "aays advisory private limited",
          "Region": "Telangana",
          "Finished_Good_Values": 117750
      },
      {
          "VendorID": "15238510",
          "VendorName": "aays advisory private limited",
          "PO_Numbers": 4700209840,
          "SourceSystem": "AEP",
          "VendorNameCombTranslation": "aays advisory private limited",
          "Region": "Telangana",
          "Finished_Good_Values": 3000
      },
      {
          "VendorID": "15238510",
          "VendorName": "aays advisory private limited",
          "PO_Numbers": 4700227327,
          "SourceSystem": "AEP",
          "VendorNameCombTranslation": "aays advisory private limited",
          "Region": "Telangana",
          "Finished_Good_Values": 18500
      },
      {
          "VendorID": "15238510",
          "VendorName": "aays advisory private limited",
          "PO_Numbers": 4700218055,
          "SourceSystem": "AEP",
          "VendorNameCombTranslation": "aays advisory private limited",
          "Region": "Telangana",
          "Finished_Good_Values": 6500
      },
      {
          "VendorID": "15238510",
          "VendorName": "aays advisory private limited",
          "PO_Numbers": 4700243195,
          "SourceSystem": "AEP",
          "VendorNameCombTranslation": "aays advisory private limited",
          "Region": "Telangana",
          "Finished_Good_Values": 52000
      },
      {
          "VendorID": "15238510",
          "VendorName": "aays advisory private limited",
          "PO_Numbers": 4700257615,
          "SourceSystem": "AEP",
          "VendorNameCombTranslation": "aays advisory private limited",
          "Region": "Telangana",
          "Finished_Good_Values": 52000
      },
      {
          "VendorID": "15238510",
          "VendorName": "aays advisory private limited",
          "PO_Numbers": 4700181281,
          "SourceSystem": "AEP",
          "VendorNameCombTranslation": "aays advisory private limited",
          "Region": "Telangana",
          "Finished_Good_Values": 60000
      },
      {
          "VendorID": "15238510",
          "VendorName": "aays advisory private limited",
          "PO_Numbers": 4700252928,
          "SourceSystem": "AEP",
          "VendorNameCombTranslation": "aays advisory private limited",
          "Region": "Telangana",
          "Finished_Good_Values": 17375
      },
      {
          "VendorID": "15238510",
          "VendorName": "aays advisory private limited",
          "PO_Numbers": 4700029209,
          "SourceSystem": "AEP",
          "VendorNameCombTranslation": "aays advisory private limited",
          "Region": "Telangana",
          "Finished_Good_Values": 13000
      },
      {
          "VendorID": "15238510",
          "VendorName": "aays advisory private limited",
          "PO_Numbers": 4700212361,
          "SourceSystem": "AEP",
          "VendorNameCombTranslation": "aays advisory private limited",
          "Region": "Telangana",
          "Finished_Good_Values": 51300
      },
      {
          "VendorID": "15238510",
          "VendorName": "aays advisory private limited",
          "PO_Numbers": 4700100282,
          "SourceSystem": "AEP",
          "VendorNameCombTranslation": "aays advisory private limited",
          "Region": "Telangana",
          "Finished_Good_Values": 60000
      },
      {
          "VendorID": "15238510",
          "VendorName": "aays advisory private limited",
          "PO_Numbers": 4700160343,
          "SourceSystem": "AEP",
          "VendorNameCombTranslation": "aays advisory private limited",
          "Region": "Telangana",
          "Finished_Good_Values": 28000
      },
      {
          "VendorID": "15238510",
          "VendorName": "aays advisory private limited",
          "PO_Numbers": 4700201337,
          "SourceSystem": "AEP",
          "VendorNameCombTranslation": "aays advisory private limited",
          "Region": "Telangana",
          "Finished_Good_Values": 13000
      },
      {
          "VendorID": "15238510",
          "VendorName": "aays advisory private limited",
          "PO_Numbers": 4700231360,
          "SourceSystem": "AEP",
          "VendorNameCombTranslation": "aays advisory private limited",
          "Region": "Telangana",
          "Finished_Good_Values": 10000
      },
      {
          "VendorID": "15238510",
          "VendorName": "aays advisory private limited",
          "PO_Numbers": 4700183374,
          "SourceSystem": "AEP",
          "VendorNameCombTranslation": "aays advisory private limited",
          "Region": "Telangana",
          "Finished_Good_Values": 69000
      },
      {
          "VendorID": "15238510",
          "VendorName": "aays advisory private limited",
          "PO_Numbers": 4700242922,
          "SourceSystem": "AEP",
          "VendorNameCombTranslation": "aays advisory private limited",
          "Region": "Telangana",
          "Finished_Good_Values": 19500
      },
      {
          "VendorID": "15238510",
          "VendorName": "aays advisory private limited",
          "PO_Numbers": 4700212180,
          "SourceSystem": "AEP",
          "VendorNameCombTranslation": "aays advisory private limited",
          "Region": "Telangana",
          "Finished_Good_Values": 17225
      },
      {
          "VendorID": "15238510",
          "VendorName": "aays advisory private limited",
          "PO_Numbers": 4700168804,
          "SourceSystem": "AEP",
          "VendorNameCombTranslation": "aays advisory private limited",
          "Region": "Telangana",
          "Finished_Good_Values": 49684
      },
      {
          "VendorID": "15238510",
          "VendorName": "aays advisory private limited",
          "PO_Numbers": 4700237785,
          "SourceSystem": "AEP",
          "VendorNameCombTranslation": "aays advisory private limited",
          "Region": "Telangana",
          "Finished_Good_Values": 38000
      },
      {
          "VendorID": "15238510",
          "VendorName": "aays advisory private limited",
          "PO_Numbers": 4700082156,
          "SourceSystem": "AEP",
          "VendorNameCombTranslation": "aays advisory private limited",
          "Region": "Telangana",
          "Finished_Good_Values": 21250
      },
      {
          "VendorID": "15238510",
          "VendorName": "aays advisory private limited",
          "PO_Numbers": 4700249466,
          "SourceSystem": "AEP",
          "VendorNameCombTranslation": "aays advisory private limited",
          "Region": "Telangana",
          "Finished_Good_Values": 22813
      },
      {
          "VendorID": "15238510",
          "VendorName": "aays advisory private limited",
          "PO_Numbers": 4700222845,
          "SourceSystem": "AEP",
          "VendorNameCombTranslation": "aays advisory private limited",
          "Region": "Telangana",
          "Finished_Good_Values": 80000
      },
      {
          "VendorID": "15238510",
          "VendorName": "aays advisory private limited",
          "PO_Numbers": 4700215777,
          "SourceSystem": "AEP",
          "VendorNameCombTranslation": "aays advisory private limited",
          "Region": "Telangana",
          "Finished_Good_Values": 10000
      },
      {
          "VendorID": "15238510",
          "VendorName": "aays advisory private limited",
          "PO_Numbers": 4700184555,
          "SourceSystem": "AEP",
          "VendorNameCombTranslation": "aays advisory private limited",
          "Region": "Telangana",
          "Finished_Good_Values": 57750
      },
      {
          "VendorID": "15238510",
          "VendorName": "aays advisory private limited",
          "PO_Numbers": 4700221363,
          "SourceSystem": "AEP",
          "VendorNameCombTranslation": "aays advisory private limited",
          "Region": "Telangana",
          "Finished_Good_Values": 15000
      },
      {
          "VendorID": "15238510",
          "VendorName": "aays advisory private limited",
          "PO_Numbers": 4700204116,
          "SourceSystem": "AEP",
          "VendorNameCombTranslation": "aays advisory private limited",
          "Region": "Telangana",
          "Finished_Good_Values": 18000
      },
      {
          "VendorID": "15238510",
          "VendorName": "aays advisory private limited",
          "PO_Numbers": 4700194920,
          "SourceSystem": "AEP",
          "VendorNameCombTranslation": "aays advisory private limited",
          "Region": "Telangana",
          "Finished_Good_Values": 9625
      },
      {
          "VendorID": "15238510",
          "VendorName": "aays advisory private limited",
          "PO_Numbers": 4700062909,
          "SourceSystem": "AEP",
          "VendorNameCombTranslation": "aays advisory private limited",
          "Region": "Telangana",
          "Finished_Good_Values": 15750
      },
      {
          "VendorID": "15238510",
          "VendorName": "aays advisory private limited",
          "PO_Numbers": 4700195638,
          "SourceSystem": "AEP",
          "VendorNameCombTranslation": "aays advisory private limited",
          "Region": "Telangana",
          "Finished_Good_Values": 136500
      },
      {
          "VendorID": "15238510",
          "VendorName": "aays advisory private limited",
          "PO_Numbers": 4700173833,
          "SourceSystem": "AEP",
          "VendorNameCombTranslation": "aays advisory private limited",
          "Region": "Telangana",
          "Finished_Good_Values": 9750
      },
      {
          "VendorID": "15238510",
          "VendorName": "aays advisory private limited",
          "PO_Numbers": 4700217637,
          "SourceSystem": "AEP",
          "VendorNameCombTranslation": "aays advisory private limited",
          "Region": "Telangana",
          "Finished_Good_Values": 30400
      },
      {
          "VendorID": "15238510",
          "VendorName": "aays advisory private limited",
          "PO_Numbers": 4700241076,
          "SourceSystem": "AEP",
          "VendorNameCombTranslation": "aays advisory private limited",
          "Region": "Telangana",
          "Finished_Good_Values": 3175
      },
      {
          "VendorID": "15238510",
          "VendorName": "aays advisory private limited",
          "PO_Numbers": 4700169400,
          "SourceSystem": "AEP",
          "VendorNameCombTranslation": "aays advisory private limited",
          "Region": "Telangana",
          "Finished_Good_Values": 15000
      },
      {
          "VendorID": "15238510",
          "VendorName": "aays advisory private limited",
          "PO_Numbers": 4700041604,
          "SourceSystem": "AEP",
          "VendorNameCombTranslation": "aays advisory private limited",
          "Region": "Telangana",
          "Finished_Good_Values": 32200
      }
  ]);
    const [isLoading, setLoading] = useState(false);
    const [isError, setError] = useState(null);  
    const componentRef_1 = useRef(null);
    const [TogglePlusIons, setTogglePlusIons] = useState(true);

    // const passtoken = JSON.parse(localStorage.getItem("okta-token-storage"))[
    //     "accessToken"
    //   ]["accessToken"];

    const baseUrlPo = "/vrm/activepos?q=";
    let dataPo = props.Link_to_Commercial_Contract_1;
    const dataValidation = Math.round(dataPo);
   // console.log(dataValidation)
    const getUser = props.name;

    const Po_name_data = decodeURI(getUser)
    //console.log("Po_name_data")
    //console.log(Po_name_data)

    
    // const encodebaseURLPO_1 = `${baseUrlPo}${getUser}&limit=${dataValidation}`;

    // useEffect(() => {
    //     const fetchUsersdDetails_1 = async () => {
    //       await fetch(`${encodebaseURLPO_1}`, {
    //         headers: {
    //           Authorization: `Bearer ${passtoken}`,
    //         },
    //       })
    //         .then((response) => {
    //           if (response.ok) {
    //             response.json().then((json) => {
    //               setListofPopup(json);
    //               setLoading(false)
    //               setError(null);
    //             });
    //           }
    //         })
    //         .catch((err) => {
    //           setError(err);
    //           setListofPopup(null);
    //           setLoading(false)
    //           console.log(isError);
    //         });
    //     };
    //     fetchUsersdDetails_1();
    //   }, [baseUrlPo]);

      let sortData = ListofPoPopup.sort((a, b) => (a.Finished_Good_Values > b.Finished_Good_Values) ? 1 : -1)
    //  console.log("ListofPoPopup")

      const PrintPopup = useReactToPrint({
        content: () => {
          const tableStat_1 = componentRef_1.current.cloneNode(true);
    
          const PrintElem = document.createElement("div");
          const header = 
            `<img src="${Logo}" style="padding-bottom:2rem" alt="image" class="watermark"/>`;
          PrintElem.innerHTML = header;
        
          PrintElem.appendChild(tableStat_1);
    
          return PrintElem;
        },
      });

      // countCheck = true
     // console.log(countCheck);
      const sortingByvalues = () =>{
       // console.log("Sorting.....")
        //console.log(countCheck);
        sortTable();
        CollapseButton();
       
      }

      function sortTable() {
        var table, rows, switching, i, x, y,dir, shouldSwitch,switchcount = 0;;
        table = document.getElementById("NewTable");
        switching = true;
        dir = "asc";
        while (switching) {
          switching = false;
          rows = table.rows;
          for (i = 1; i < (rows.length - 1); i++) {
            shouldSwitch = false;
            x = rows[i].getElementsByTagName("TD")[0];
            y = rows[i + 1].getElementsByTagName("TD")[0];
           
            if (dir === "asc") {
              if (parseFloat(x.innerHTML) > parseFloat(y.innerHTML)) {
                // If so, mark as a switch and break the loop:
                shouldSwitch = true;
                break;
              }
            } else if (dir === "desc") {
              if (parseFloat(x.innerHTML) < parseFloat(y.innerHTML)) {
                shouldSwitch = true;
                break;
              }
            }
          }
          if (shouldSwitch) {
            rows[i].parentNode.insertBefore(rows[i + 1], rows[i]);
            switching = true;
            switchcount ++;
          }else{
            if (switchcount === 0 && dir === "asc") {
              dir = "desc";
              switching = true;
            }
          }
        }
      }
  
      const CollapseButton = () => {
        setTogglePlusIons((prev) => !prev);
      };
  
  return (
    <div>
      <Popup
          trigger={
            <button className="button">
              <span style={{background:"#253978",color:"white",padding:'10px'}}>{Math.round(props.Link_to_Commercial_Contract_1)}</span>{" "}
            </button>
          }
          modal
          contentStyle={contentStyle}
        >
          {(close) => (
            <div style={{ display: "contents" }} className="modal">
              <a className="close" onClick={close}>
                &times;
              </a>
              <div className="header"> Active Contract </div>

              {isLoading && (
                 <VendorPageWrapper>
                 <div className="PlaseWait">
                   <div className="spinner_class">
                     <Loading />
                   </div>
                   <div>Please wait sometime</div>
                 </div>
               </VendorPageWrapper>
              )}

              {!isLoading && !isError && (
                <>
                
              <div ref={componentRef_1} className="content">
                <div className="Vname_PO">
                  <div style={{fontSize:"18px",color:"#253978"}}>
                    Total number of PO is :{" "}
                    {Math.round(props.Link_to_Commercial_Contract_1)}{" "}
                  </div>
                  <div style={{fontSize:"18px",color:"#253978"}}>Vendors ID : {Po_name_data}</div>
                </div>

                <table id="NewTable" style={{ width: "100%" }}>
                  <tbody>
                    <tr>

                      <th>Values <button style={{border:"none",color:"#253978"}} onClick={sortingByvalues}>{TogglePlusIons ? <FaSortAmountDownAlt />: <FaSortAmountUpAlt />}</button></th>

                      <th>Po Number</th>
                    </tr>
                    {ListofPoPopup &&
                      ListofPoPopup.map((items, index) => {
                        return (
                          <tr key={index}>
                            <td>{(items.Finished_Good_Values).toLocaleString("en-US")}</td>
                            <td>{items.PO_Numbers}</td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
                </>
              )}

              {isError &&(
                <div className="PlaseWait">
                <div className="spinner_class">
                {`There is a problem fetching the post data - ${isError}`}
                </div>
                <div>Please try after sometime</div>
              </div>
              )}
              
              <div className="actions">
                <button
                  style={{ margin: "10px 15px" }}
                  className="button"
                  onClick={() => {
                    console.log("Download");
                    PrintPopup();
                  }}
                >
                  Print
                </button>

                <button
                  style={{ margin: "10px 15px" }}
                  className="button"
                  onClick={() => {
                    console.log("closed ");
                    close();
                  }}
                >
                  Close
                </button>
              </div>
            </div>
          )}
        </Popup>

     
    </div>
  );
}

export default Active_po_popup
