import React, { useState, useEffect, useRef } from "react";
import NavigarionBar from "../nav/NavigarionBar";
import SideMenu from "../nav/SideMenu";
import Loading from "../spinner/Loading";
import BasicInformations from "../reuseable-layout/BasicInformations";
import Description from "../reuseable-layout/Description";
import ContactInformations from "../reuseable-layout/ContactInformations";
import ActiveContact from "../reuseable-layout/ActiveContact";
import TechnicalInformations from "../reuseable-layout/TechnicalInformations";
import $ from "jquery";
import { FaPrint } from "react-icons/fa";
import { useReactToPrint } from "react-to-print";
import { Link,useLocation } from "react-router-dom";
import styled from "styled-components";
import SimilarityScore from "../reuseable-layout/SimilarityScore";
import Logo from "../../img/WaterMark.png";
import SearchBox from "../search/SearchBox";
import noDataAvailable from "../../img/No_Data_Available.jpg";
import RiskProfile from "../reuseable-layout/RiskProfile";
import { useParams } from 'react-router-dom';
import dataJson from '../../Data.json'
const VendorPageWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ErrorWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: red;
`;

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};


const ListUsers = () => {


//   const [ListofUsers, setListofUsers] = useState([
//     {
//         "VendorName": "aays advisory private limited",
//         "VendorID": "15238510",
//         "VendorName_Region": "aays advisory private limited(Telangana)",
//         "Risk_Rating": "Moderate",
//         "ISF_Inherent_Risk_Rating": "Low",
//         "MSA_Completion_Date": 1711843200000,
//         "Counts_Of_Close_Contracts": "83.0",
//         "Counts_Of_Open_Contracts": "85.0",
//         "Address": "Madhapur Road  1-90/7/b/38, HYDERABAD, None, None, 500018, Telangana, India, 9987131629",
//         "Description_of_Project": "TASK54822681\nTechnology consulting services, data analytics and system development across several Mars segments.",
//         "Vendor_URL": "<a>https://www.aaysanalytics.com</a>",
//         "Vendor_Category": "Software Development",
//         "Vendor_Contact": "Avinash Sidha",
//         "Vendor_Contact_EmailID": "avinashs@aaysinsight.com",
//         "Business_Partner_EmailID": "lisa.carone@effem.com",
//         "Owner": "Weronika Maj",
//         "BitsightScore": "770",
//         "VendorURL": "NA_in_Snow",
//         "Segment_Association": "NA_in_Snow",
//         "Vendor_Account_Manager_ContactName": "NA_in_Snow",
//         "Vendor_Account_ManagerEmail": "NA_in_Snow",
//         "Vendor_ContactName": "NA_in_Snow",
//         "VendorEmail": "NA_in_Snow",
//         "Description_of_Services": "NA_in_Snow",
//         "Category": "NA_in_Snow",
//         "MarsCommercialContactEmails": "NA_in_Snow",
//         "MarsCommercialContactName": "NA_in_Snow",
//         "Technical_Contact_Name": "NA_in_Snow",
//         "Technical_Contact_Email": "NA_in_Snow",
//         "Segment": "NA_in_Snow",
//         "Finished_Goods_Value_Close_Contracts": "5648720.5",
//         "Finished_Goods_Value_Open_Contracts": "5040695.0",
//         "SourceSystem": "SAP_AEP",
//         "Region": "Telangana",
//         "CountryName": "India",
//         "Archer_VendorName": "aays advisory private limited",
//         "Archer_SimilarityScore": "1.0",
//         "Ariba_VendorName": "aays advisory private limited",
//         "Ariba_SimilarityScore": "1.0",
//         "Bitsight_VendorName": "NA_in_BitSight",
//         "Bitsight_SimilarityScore": "NA_in_BitSight",
//         "Snow_VendorName": "NA_in_Snow",
//         "Snow_SimilarityScore": "NA_in_Snow",
//         "VendorNameCombTranslation": "aays advisory private limited(Telangana)",
//         "Year": 2024,
//         "Period": 4
//     }
// ]);
  const [isLoading, setLoading] = useState(false);
  const [isError, setError] = useState(null);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const searchString = location.search.replace('?name=', '');
  const decodedValue = decodeURIComponent(searchString);

  console.log(dataJson)
  const name = searchParams.get('name');
  console.log(name)

  const DataByname = dataJson.filter((item) => item.VendorName === name)
  console.log(DataByname)

  const ListofUsers  = DataByname || []

  // const passtoken = JSON.parse(localStorage.getItem("okta-token-storage"))[
  //   "accessToken"
  // ]["accessToken"];


  const componentRef_1 = useRef(null);
  const componentRef_2 = useRef(null);
  const componentRef_3 = useRef(null);
  const componentRef_4 = useRef(null);
  const componentRef_5 = useRef(null);
  const componentRef_6 = useRef(null);







  let ValueLow = 0;
  let inherentRisk = 0;


  useEffect(() => {
    document.title = `RISK INSIGHT - VENDOR DETAILS`;
  });

  const dataRiskCheck = () => {
    {
      ListofUsers &&
        ListofUsers.map((products) => {
            //console.log(products)
          if (products.ISF_Inherent_Risk_Rating === "Low") {
            ValueLow = 250;
          } else if (products.ISF_Inherent_Risk_Rating === "Very Low") {
            ValueLow = 100;
          } else if (products.ISF_Inherent_Risk_Rating === "Moderate") {
            ValueLow = 500;
          } else if (products.ISF_Inherent_Risk_Rating === "High") {
            ValueLow = 700;
          } else if (products.ISF_Inherent_Risk_Rating === "Critical") {
            ValueLow = 900;
          }
          if (products.Risk_Rating === "Low") {
            inherentRisk = 250;
          } else if (products.Risk_Rating === "Very Low") {
            inherentRisk = 100;
          } else if (products.Risk_Rating === "Moderate") {
            inherentRisk = 500;
          } else if (products.Risk_Rating === "High") {
            inherentRisk = 700;
          } else if (products.Risk_Rating === "Critical") {
            inherentRisk = 900;
          }
        });
    }
  };

  const print_btn = () => {
    $("#MultiCheckbox_div").slideToggle(true);
  };

  const PrintDivComponent = useReactToPrint({
    content: () => {
      const tableStat_1 = componentRef_1.current.cloneNode(true);
      const tableStat_2 = componentRef_2.current.cloneNode(true);
      const tableStat_3 = componentRef_3.current.cloneNode(true);
      const tableStat_4 = componentRef_4.current.cloneNode(true);
      const tableStat_5 = componentRef_5.current.cloneNode(true);
      const tableStat_6 = componentRef_6.current.cloneNode(true);

      const PrintElem = document.createElement("div");
      const header = 
        `<img src="${Logo}" alt="Logo" class="watermark"/>`;
      PrintElem.innerHTML = header;
      dataItemsArray.forEach((element) => {
        // console.log("Data is " + element);
        if (element === "tableStat_1") {
          element = tableStat_1;
        } else if (element === "tableStat_2") {
          element = tableStat_2;
        } else if (element === "tableStat_3") {
          element = tableStat_3;
        } else if (element === "tableStat_4") {
          element = tableStat_4;
        } else if (element === "tableStat_5") {
          element = tableStat_5;
        } else if (element === "tableStat_6") {
          element = tableStat_6;
        }
        PrintElem.appendChild(element);
      });

      return PrintElem;
    },
  });

  const dataItemsArray = [];

  const Printing_report_by_id = () => {
    dataItemsArray.splice(0, dataItemsArray.length);

    var checked = document.querySelectorAll("#checkboxes :checked");
    var selected = [...checked].map((option) => option.value);

   // console.log("Selected value", selected);
    if (selected.length === 0) {
      alert("Please checked the box for Print");
    } else {
      let selectedValue = "";
      selected.forEach((element) => {
        selectedValue = "tableStat_" + element;
        //  console.log("selectedValue is ", selectedValue);
        dataItemsArray.push(selectedValue);
      });
      PrintDivComponent();
    }
  };

  const closeDiv = () => {
    $(document).mouseup(function (e) {
      var container = $("#MultiCheckbox_div");

      // if the target of the click isn't the container nor a descendant of the container
      if (!container.is(e.target) && container.has(e.target).length === 0) {
        container.hide(true);
        $(":checkbox").prop("checked", false).removeAttr("checked");
      }
    });
  };



  dataRiskCheck();
  closeDiv();

  function capitalizeWords(str) {
    return str.replace(/\b\w/g, function(match) {
      return match.toUpperCase();
    });
  }
  return (
    <div id="fullpage">
      <NavigarionBar />
      <SideMenu />
      <div id="main">
        {isLoading && (
          <VendorPageWrapper>
            <div className="PlaseWait">
              <div className="spinner_class">
                <Loading />
              </div>
              <div>Please wait sometime</div>
            </div>
          </VendorPageWrapper>
        )}

        {isError && (
          <VendorPageWrapper>
            <ErrorWrapper>
              <div className="PlaseWait">
                <div className="spinner_class">
                  {`There is a problem fetching the data - ${isError}`}
                </div>
                <div>Please try after sometime</div>
              </div>
            </ErrorWrapper>
          </VendorPageWrapper>
        )}

        {!isLoading && !isError && ListofUsers.length !== 0 && (
          <>
            {ListofUsers &&
              ListofUsers.map((user) => (
                <div key={user}>
                  <div>
                    <div>
                      <div className="List_users_page">
                        <div
                          className="searchBarSections"
                        >
                          <h1 className="vender_Risk_Management_System text_header_color">
                            Vendor Risk Management

                          </h1>

                         

                          <div style={{ position: "relative" }}>
                            <SearchBox />
                          </div>
                        </div>

                        <div style={{justifyContent:"space-between",marginTop:"20px"}} className="pathDisplay">
                         
                          <div style={{display:"flex",gridGap:"5px"}}>
                            <span>
                              <Link to="/">Home</Link> /
                            </span>
                            <div>{capitalizeWords(user.VendorName)}</div>
                          </div>
                          <div>

                          <div className="Search_section">
                            <div className="MutipleSeclections_div">
                              <button
                                onClick={print_btn}
                                id="printBtn"
                                className="btn_Print"
                              >
                                <div className="Print_flex">
                                  <span style={{fontWeight:"600",color:"#0000a0"}}>Print</span>
                                  <span>
                                    <FaPrint color="#0000a0" fontSize={26} />
                                  </span>
                                </div>
                              </button>

                              <div id="MultiCheckbox_div">
                                <div id="checkboxes">
                                  <label id="checkbox_div_id_1" htmlFor="one">
                                    <input
                                      type="checkbox"
                                      name="input_check"
                                      value={1}
                                      className="item_input checkbox-round"
                                      id="one"
                                    />
                                    Basic Informations
                                  </label>
                                  <label id="checkbox_div_id_2" htmlFor="two">
                                    <input
                                      type="checkbox"
                                      name="input_check"
                                      value={2}
                                      className="item_input checkbox-round"
                                      id="two"
                                    />
                                    Descriptions Of Services
                                  </label>
                                  <label id="checkbox_div_id_3" htmlFor="three">
                                    <input
                                      type="checkbox"
                                      name="input_check"
                                      value={3}
                                      className="item_input checkbox-round"
                                      id="three"
                                    />
                                    Contact Informations
                                  </label>

                                  <label id="checkbox_div_id_4" htmlFor="four">
                                    <input
                                      type="checkbox"
                                      name="input_check"
                                      value={4}
                                      className="item_input checkbox-round"
                                      id="four"
                                    />
                                    Active Contract
                                  </label>
                                  <label id="checkbox_div_id_5" htmlFor="five">
                                    <input
                                      type="checkbox"
                                      name="input_check"
                                      value={5}
                                      className="item_input checkbox-round"
                                      id="five"
                                    />
                                    Technical Informations
                                  </label>
                                  <label id="checkbox_div_id_6" htmlFor="six">
                                    <input
                                      type="checkbox"
                                      name="input_check"
                                      value={6}
                                      className="item_input checkbox-round"
                                      id="six"
                                    />
                                    Risk Profile
                                  </label>

                                  <input
                                    onClick={Printing_report_by_id}
                                    type="submit"
                                    className="Print_rep"
                                    value="Print Report"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>

                          </div>
                        </div>

                        <div className="allcomponents">
                          <div
                            ref={componentRef_1}
                            id="Basic_info_div_id"
                            className="Basic_info_div"
                          >
                            <BasicInformations
                              title="Basic Informations"
                              Owner={capitalizeWords(user.VendorName)}
                              Address={user.Address}
                              Vendor_URL={user.Vendor_URL}
                              VendorID={user.VendorID}
                              CommericalCategory={user.Vendor_Category}
                            />
                          </div>

                          <div
                            id="Descriptions_Of_Services_id"
                            ref={componentRef_2}
                            className="Descriptions_Of_Services"
                          >
                            <Description
                              title="Descriptions Of Services"
                              des={user.Description_of_Services}
                            />
                          </div>

                          <div
                            ref={componentRef_3}
                            className="Contact_Informations"
                          >
                            <ContactInformations
                              title="Contact Informations"
                              Vendor_Account_Manager_ContactName={
                                user.Vendor_Account_Manager_ContactName
                              }
                              Account_email={user.Vendor_Account_ManagerEmail}
                              technical_name={user.Technical_Contact_Name}
                              technical_email={user.Technical_Contact_Email}
                              Vendor_Contact={user.Vendor_ContactName}
                              Vendor_Contact_Email_ID={user.VendorEmail}
                              Business_Partner_Manager={(user.Business_Partner_Manager === undefined || user.Business_Partner_Manager === null )? 'N/A' : user.Business_Partner_Manager}
                              Business_Partner_Email_ID={
                                user.Business_Partner_EmailID
                              }
                              MarsCommercialContact={
                                user.MarsCommercialContactName
                              }
                              Commercial_email={
                                user.MarsCommercialContactEmails
                              }
                              Ownername={user.Owner}
                            />
                          </div>

                          <div ref={componentRef_4} className="Active_Contact">
                            <ActiveContact
                              Vname = {user.VendorName}
                              Po_name = {user.VendorID}
                              title="Active Contract"
                              Link_to_Commercial_Contract={
                                user.Counts_Of_Open_Contracts
                              }
                            />
                          </div>

                          <div
                            ref={componentRef_5}
                            className="Technical_Informations"
                          >
                            <TechnicalInformations
                              title="Technical Informations"
                              Segment_Associations={user.Segment_Association}
                              Saas_Address="N/A"
                              Type_of_Data="Public, Internal-only, Confidential, And Restricted"
                              EA="EA0022568"
                              PIA="Project Initiation, Data Flow Analysis, Privacy Analysis, Privacy Impact Assessment Report"
                              s2s="N/A"
                              VDI="DesktopWave"
                              fw="Allow any traffic from IP address X to port Y"
                            />
                          </div>

                          <div ref={componentRef_6} className="Risk_Profile">
                          <RiskProfile
                              title="Risk Profile"
                              Risk_Rating={user.Risk_Rating}
                              ISF_Inherent_Risk_Rating={
                                user.ISF_Inherent_Risk_Rating
                              }
                              inherentRisk={inherentRisk}
                              ValueLow={ValueLow}
                              BitsightScore= {`${(user.BitsightScore === 'NA_in_BitSight') ? 0 :  user.BitsightScore }`}
                              BitsightScoreText = {user.BitsightScore}
                            />
                          </div>

                          <div className="similarity_info_div">
                            <SimilarityScore
                              archer_smilarity_score={
                                user.Archer_SimilarityScore
                              }
                              archer_vendor_name={user.Archer_VendorName}
                              ariba_smilarity_score={user.Ariba_SimilarityScore}
                              ariba_vendor_name={user.Ariba_VendorName}
                              bitsight_smilarity_score={
                                user.Bitsight_SimilarityScore
                              }
                              bitsight_vendor_name={user.Bitsight_VendorName}
                              snow_smilarity_score={user.Snow_SimilarityScore}
                              snow_vendor_name={user.Snow_VendorName}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </>
        )}

        {!isLoading && !isError && ListofUsers.length === 0 && (
          <div style={{ height: "100%" }}>
            <div
              style={{
                display: "grid",
                justifyContent: "center",
                alignItems: "center",
                alignContent:"center",
                height:"100%"
              }}
            >
              
              <div style={{display:"grid",justifyContent:"center"}}>
                 <img width={200} alt="nodata" src={noDataAvailable} />
              </div>
              <div style={{ textAlign: "center", fontSize: "24px",fontWeight:"600",color:"#252978",textTransform:"capitalize" }}>
                   Vendor Details not available, Please Check with your Adminstator
              </div>
            </div>
          </div>
        )}


      </div>
    </div>
  );
};

export default ListUsers;
