import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { IoClose, IoSearch } from "react-icons/io5";
import { AnimatePresence, motion } from "framer-motion";
import { useClickOutside } from "react-click-outside-hook";
import MoonLoader from "react-spinners/MoonLoader";
import { Link } from "react-router-dom";
import "../../css/SearchBar.css";
import bgimage from "../../img/Search_img1.png";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import { Button } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import { useOktaAuth } from "@okta/okta-react";
import axios from "axios";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const MainContainer = styled.div`
  display: flex;
  grid-gap: 10px;
`;

const SearchBarContainer = styled(motion.div)`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 50px;
  background-color: #fff;
  border-radius: 6px;
  box-shadow: rgb(0 0 0 / 14%) 0px 0px 0px 1px;

  overflow: hidden;
  align-item: center;
  position: absolute;
  top: -20px;
  right: 0;
  z-index: 1;
`;

const ToggleButtonContainer = styled.div`
  display: flex;
  margin-top: 41px;
  position: absolute;
  grid-gap: 10px;
`;

const SearchInputContainer = styled.div`
  width: auto;
  min-height: 50px;
  display: flex;
  align-items: center;
  position: relative;
  padding: 0px 15px;
`;

const SearchInput = styled.input`
  width: 100%;
  height: 100%;
  outline: none;
  border: none;
  font-size: 16px;
  color: #12112e;
  border-radius: 6px;
  background-color: transparent;

  &:focus {
    outline: none;
    &::placeholder {
      opacity: 1;
    }
  }

  &::placeholder {
    color: #bebebe;
    transition: all 250ms ease-in-out;
  }
`;

const SearchIcon = styled.span`
  color: #bebebe;
  font-size: 22px;
  margin-right: 10px;
  vertical-align: middle;
  display: grid;
`;

const CloseIcon = styled(motion.span)`
  color: #bebebe;
  font-size: 22px;
  vertical-align: middle;
  transition: all 200ms ease-in-out;
  cursor: pointer;
  display: grid;

  &:hover {
    color: #dfdfdf;
  }
`;

const LineSeperator = styled.span`
  display: flex;
  min-width: 100%;
  min-height: 1px;
  background-color: #d8d8d878;
`;

const SearchContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  background: white;
  flex-direction: column;
  overflow-y: auto;
  z-index:1

  &::-webkit-scrollbar {
    display: none !important
  }

`;

const LoadingWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const containerVariants = {
  expanded: {
    height: "500px",
  },
  collapsed: {
    height: "50px",
  },
};

const SearchBox = (props) => {
  const [isExpanded, setExpanded] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  // const [issaved, setIssaved] = useState(false);
  const [isauth, setIsauth] = useState(false);

  const [parentRef, isClickedOutside] = useClickOutside();
  const [query, setQuery] = useState("");
  const [items, setItems] = useState([
    {
        "VendorName": "Alpha Solutions Ltd.",
        "VendorID": "1000001",
        "vnct": "Alpha Company Name"
    },
    {
        "VendorName": "Beta Enterprises Inc.",
        "VendorID": "1000002",
        "vnct": "Beta Company Name"
    },
    {
        "VendorName": "Charlie Trading Co.",
        "VendorID": "1000003",
        "vnct": "Charlie Company Name"
    },
    {
        "VendorName": "Delta Innovations LLC",
        "VendorID": "1000004",
        "vnct": "Delta Company Name"
    },
    {
        "VendorName": "Echo Industries Ltd.",
        "VendorID": "1000005",
        "vnct": "Echo Company Name"
    },
    {
        "VendorName": "Foxtrot Manufacturing Corp.",
        "VendorID": "1000006",
        "vnct": "Foxtrot Company Name"
    },
    {
        "VendorName": "Gamma Technologies Inc.",
        "VendorID": "1000007",
        "vnct": "Gamma Company Name"
    },
    {
        "VendorName": "Hotel Services Ltd.",
        "VendorID": "1000008",
        "vnct": "Hotel Company Name"
    },
    {
        "VendorName": "India Solutions Pvt. Ltd.",
        "VendorID": "1000009",
        "vnct": "India Company Name"
    },
    {
        "VendorName": "Juliet Ventures LLC",
        "VendorID": "1000010",
        "vnct": "Juliet Company Name"
    }
]
);
  const [SaveList, setSaveList] = useState([]);
  const { authState, oktaAuth } = useOktaAuth();
  const [userInfo, setUserInfo] = useState(null);



  useEffect(() => {
    if (!authState || !authState.isAuthenticated) {
      setUserInfo(null);
    } else {	 
      setUserInfo(authState.idToken.claims);
      setIsauth(true)
    }
  }, [authState, oktaAuth]);

  var myemailid = localStorage.getItem("myemail");

  // const [RecentItem, setRecentItem] = useState([]);
  // const [RecentItemLimit, setRecentItemLimit] = useState(1);
  const inputRef = useRef(null);

  const passtoken = JSON.parse(localStorage.getItem("okta-token-storage"))[
    "accessToken"
  ]["accessToken"];

  const expandContainer = () => {
    setExpanded(true);
  };

  const collapseContainer = (e) => {
    setExpanded(false);
    setQuery("");
    inputRef.current.value = "";
  };

  useEffect(() => {
    if (isClickedOutside) collapseContainer();
  }, [isClickedOutside]);

  

  const data = Object.values(items);
  const search_parameters = Object.keys(Object.assign({}, ...data));

    useEffect(() => { 
        // fetchSearchSaveVendor();
    }, []);

    function capitalizeWords(str) {
      return str.replace(/\b\w/g, function(match) {
        return match.toUpperCase();
      });
    }


  function search(data) {
    return items.filter((item) =>
      search_parameters.some(
        (parameter) =>
          item[parameter].toString().toLowerCase().includes(query.toLowerCase()) 
      )
    );
  }

  function capitalizeWords(str) {
    return str.replace(/\b\w/g, function(match) {
      return match.toUpperCase();
    });
  }

  const getDateTimeInfo = (dateStr) => {
    const dateObj = new Date(dateStr);
  
    // Get the month name
    const monthName = new Intl.DateTimeFormat('en-US', { month: 'long' }).format(dateObj);
  
    // Get the year
    const year = dateObj.getFullYear();
  
    // Get the number of days in the month
    const numberOfDays = new Date(year, dateObj.getMonth() + 1, 0).getDate();
  
    // Get the day of the week
    const dayOfWeek = new Intl.DateTimeFormat('en-US', { weekday: 'long' }).format(dateObj);

    // Get the time
    let hours = dateObj.getHours();
    const minutes = dateObj.getMinutes();
    const seconds = dateObj.getSeconds();
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12;
  
    return {
      monthName,
      year,
      numberOfDays,
      dayOfWeek,
      hours,
      minutes,
      seconds,
      ampm
    };
  };

 

  return (
    <MainContainer>
      <SearchBarContainer
        animate={isExpanded ? "expanded" : "collapsed"}
        variants={containerVariants}
        ref={parentRef}
      >
        <SearchInputContainer>
          <SearchIcon>
            <IoSearch />
          </SearchIcon>

          <SearchInput
            ref={inputRef}
            className="search-input"
            onFocus={expandContainer}
            placeholder="Search by Vendors name from SAP"
            onChange={(e) => setQuery(e.target.value)}
          />
          <AnimatePresence>
            {isExpanded && (
              <CloseIcon
                key="close-icon"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.2 }}
                onClick={collapseContainer}
              >
                <IoClose />
              </CloseIcon>
            )}
          </AnimatePresence>
        </SearchInputContainer>
        <LineSeperator />
        <SearchContent>
          {isLoading && (
            <LoadingWrapper>
              <div className="PlaseWait">
                <div className="spinner_class">
                  <MoonLoader loading color="#0000a0" size={15} />
                </div>
                <div>Please wait sometime</div>
              </div>
            </LoadingWrapper>
          )}

          {!isLoading && query.length === 0 && (
            <div style={{ height: "100%" }}>
              <LoadingWrapper>
                <div style={{ width: "100%", height: "100%" }}>
                  <div style={{height:"100%"}}>
                    <div
                      style={{
                        color: "#0000a0",
                        paddingBottom: "0rem",
                        height:"100%"
                      }}
                    >
                      {SaveList.length === 0 && (
                        <div
                          style={{
                            display: "grid",
                            gridGap: "10px",
                            alignItems: "center",
                            alignContent: "center",
                            height: "400px",
                            justifyContent: "center",
                            textAlign: "center",
                          }}
                        >
                          <span>
                            <img
                              style={{ width: "220px" }}
                              src={bgimage}
                              alt="Search"
                            />
                          </span>

                          <div> Please Search For Vendors from SAP</div>
                        </div>
                      )}

                      {SaveList.length !== 0 && (
                        <>
                          <div className='pieChatsDiv' style={{ padding: "0px" }}>

                            <List>
                              <span className="recetSearch-title">
                                  Your favorite search
                              </span>
                              <hr style={{ marginBottom: "0px" }} />

                              {SaveList.length === 0 && (
                                <div style={{ width: "500px", height: "165px", alignItems: "center", display: "flex", alignContent: "center", justifyContent: "center" }}>There is no save data available</div>
                              )}

                              {SaveList.length !== 0 && (
                                <div style={{ alignItems: "center", display: "block", alignContent: "baseline" }}>
                                  {SaveList.slice(0, 5).map((item, index) => {
                                    return (
                                      <div key={index}>
                                        <ListItem alignItems="center">
                                          <ListItemAvatar>
                                            <Avatar
                                              sx={{
                                                background:
                                                  "rgb(220, 233, 249)",
                                                color: "#253978",
                                              }}
                                              alt="Remy Sharp"
                                            >
                                              {item.favoriteIdentifier
                                                .charAt(0)
                                                .toUpperCase()}
                                            </Avatar>
                                          </ListItemAvatar>
                                          <Link
                                            to={
                                              `/vendor` +
                                              `?name=${item.favoriteIdentifier}`
                                            }
                                            className="dataItem"
                                            data={item.vnct}
                                          >
                                            <ListItemText
                                              sx={{
                                                color: "#253978",
                                                fontWeight: "700",
                                              }}
                                              primary={capitalizeWords(
                                                item.favoriteIdentifier
                                              )}
                                              secondary={
                                                <React.Fragment>
                                              
                                                  <div>
                                                    {getDateTimeInfo(item.created).monthName+ ", " +getDateTimeInfo(item.created).year +", "+ getDateTimeInfo(item.created).dayOfWeek  + " " +  getDateTimeInfo(item.created).hours+":"+getDateTimeInfo(item.created).minutes+ "  "+getDateTimeInfo(item.created).ampm}
                        
                                                  </div>
                                                </React.Fragment>
                                              }
                                            />
                                          </Link>
                                          <Button
                                            // onClick={(e) =>
                                            //   // DeleteSave(
                                            //   //   item.favoriteId,
                                            //   //   item.favoriteIdentifier
                                            //   // )
                                            // }
                                            sx={{
                                              color: "#253978",
                                              justifyContent: "end",
                                              display: "grid",
                                            }}
                                            title="Remove"
                                            startIcon={<DeleteIcon />}
                                          />
                                        </ListItem>
                                        <Divider />
                                      </div>
                                    );
                                  })}

                                </div>
                              )}


                            </List>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </LoadingWrapper>
            </div>
          )}

         <List>
         {!isLoading && query.length !== 0 && search(data).length !== 0 && (
            <>
              {search(data)
                .slice(0, 15)
                .map((item, index) => (
                  <div
                    data-about={item.vnct}
                    key={index}
                    className="serachItemList"
                  >
                   

                      <ListItem alignItems="center">
                        <ListItemAvatar>
                          <Avatar
                            sx={{
                              background: "rgb(220, 233, 249)",
                              color: "#253978",
                            }}
                            alt="Remy Sharp"
                          >
                            {(item.vnct.charAt(0)).toUpperCase()}
                          </Avatar>
                        </ListItemAvatar>
                      
                      <Link
                        to={`/vendor` + `?name=${item.vnct}`}
                        className="dataItem"
                        data={item.vnct}
                      >
                        <ListItemText
                          sx={{ color: "#253978", fontWeight: "700" }}
                          className="list-items-single-line"
                          primary={capitalizeWords(item.vnct)}
                          secondary={
                            <React.Fragment>{item.VendorID}</React.Fragment>
                          }
                        />
                      </Link>

                     

                      {(SaveList.some(id => id.favoriteIdentifier === `${item.vnct}`) === true) &&(
                        <CheckCircleOutlineIcon sx={{color:"green",fontSize:"26px"}}/>
                        
                      )}

                      {(SaveList.some(id => id.favoriteIdentifier === `${item.vnct}`) === false) &&(
                         <Button 
                        //  onClick={(e) => postData(item.vnct)}
                         id="save"
                         sx={{
                           color: "#253978",
                           justifyContent: "end",
                           display: "grid",
            
                         }}
                         title="Save"
                         startIcon={<SaveAltIcon />}
                       />
                        
                      )}
                        
                      </ListItem>
                   
                  </div>
                ))}
            </>
          )}
         </List>

          {!isLoading && query.length !== 0 && search(data).length === 0 && (
            <div style={{ height: "100%" }}>
              <LoadingWrapper>
                <div className="novendorFound">
                  <div
                    style={{
                      display: "grid",
                      gridGap: "10px",
                      alignItems: "center",
                      alignContent: "center",
                      height: "100%",
                    }}
                  >
                    <span>
                      <img
                        style={{ width: "220px" }}
                        src={bgimage}
                        alt="Search"
                      />
                    </span>
                    <div
                      className="noVender"
                      style={{
                        fontSize: "18px",
                        fontWeight: "600",
                        paddingBottom: "2rem",
                        color:"#3d3d3d"
                      }}
                    >
                       Vendors name is not found in SAP system
                    </div>
                  </div>
                </div>
              </LoadingWrapper>
            </div>
          )}
        </SearchContent>
      </SearchBarContainer>

      <ToastContainer style={{position:"fixed",top:"10px"}}/>
    </MainContainer>
  );
};

export default SearchBox;
