import React from 'react';
import  '../../css/loading.css'

export default function Loading() {
  return (
    <div className='loading_alignment'>
        <div className='lds-facebook'><div></div><div></div><div></div></div>
    </div>
    
  )
}
